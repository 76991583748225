// <<< DO NOT EDIT GENERATED FILES DIRECTLY! api-store-mutation-types.mustache >>>
export const API_REQUEST = 'API_REQUEST';


export const GETKAFKACONNECTIONS_ITEM_REQ = 'GETKAFKACONNECTIONS_ITEM_REQ';
export const GETKAFKACONNECTIONS_ITEM_ERROR = 'GETKAFKACONNECTIONS_ITEM_ERROR';
export const GETKAFKACONNECTIONS_ITEM_SUCCESS = 'GETKAFKACONNECTIONS_ITEM_SUCCESS'

export const GETRABBITMQCONNECTIONS_ITEM_REQ = 'GETRABBITMQCONNECTIONS_ITEM_REQ';
export const GETRABBITMQCONNECTIONS_ITEM_ERROR = 'GETRABBITMQCONNECTIONS_ITEM_ERROR';
export const GETRABBITMQCONNECTIONS_ITEM_SUCCESS = 'GETRABBITMQCONNECTIONS_ITEM_SUCCESS'

export const GETSQSCONNECTIONS_ITEM_REQ = 'GETSQSCONNECTIONS_ITEM_REQ';
export const GETSQSCONNECTIONS_ITEM_ERROR = 'GETSQSCONNECTIONS_ITEM_ERROR';
export const GETSQSCONNECTIONS_ITEM_SUCCESS = 'GETSQSCONNECTIONS_ITEM_SUCCESS'

export const UPDATE_FIELDS = 'UPDATE_FIELDS';
export const CLEAR_FILTER = 'CLEAR_FILTER';
export const CLEAR_MESSAGE = 'CLEAR_MESSAGE';

export const CREATEKAFKACONNECTIONS_REQ = 'CREATEKAFKACONNECTIONS_REQ';
export const CREATEKAFKACONNECTIONS_ERROR = 'CREATEKAFKACONNECTIONS_ERROR';
export const CREATEKAFKACONNECTIONS_SUCCESS = 'CREATEKAFKACONNECTIONS_SUCCESS';

export const CREATERABBITMQCONNECTIONS_REQ = 'CREATERABBITMQCONNECTIONS_REQ';
export const CREATERABBITMQCONNECTIONS_ERROR = 'CREATERABBITMQCONNECTIONS_ERROR';
export const CREATERABBITMQCONNECTIONS_SUCCESS = 'CREATERABBITMQCONNECTIONS_SUCCESS';

export const CREATESQSCONNECTIONS_REQ = 'CREATESQSCONNECTIONS_REQ';
export const CREATESQSCONNECTIONS_ERROR = 'CREATESQSCONNECTIONS_ERROR';
export const CREATESQSCONNECTIONS_SUCCESS = 'CREATESQSCONNECTIONS_SUCCESS';

export const DELETEKAFKACONNECTIONS_REQ = 'DELETEKAFKACONNECTIONS_REQ';
export const DELETEKAFKACONNECTIONS_ERROR = 'DELETEKAFKACONNECTIONS_ERROR';
export const DELETEKAFKACONNECTIONS_SUCCESS = 'DELETEKAFKACONNECTIONS_SUCCESS';

export const DELETEKAFKACONNECTIONSBYQUERY_REQ = 'DELETEKAFKACONNECTIONSBYQUERY_REQ';
export const DELETEKAFKACONNECTIONSBYQUERY_ERROR = 'DELETEKAFKACONNECTIONSBYQUERY_ERROR';
export const DELETEKAFKACONNECTIONSBYQUERY_SUCCESS = 'DELETEKAFKACONNECTIONSBYQUERY_SUCCESS';

export const DELETERABBITMQCONNECTIONS_REQ = 'DELETERABBITMQCONNECTIONS_REQ';
export const DELETERABBITMQCONNECTIONS_ERROR = 'DELETERABBITMQCONNECTIONS_ERROR';
export const DELETERABBITMQCONNECTIONS_SUCCESS = 'DELETERABBITMQCONNECTIONS_SUCCESS';

export const DELETERABBITMQCONNECTIONSBYQUERY_REQ = 'DELETERABBITMQCONNECTIONSBYQUERY_REQ';
export const DELETERABBITMQCONNECTIONSBYQUERY_ERROR = 'DELETERABBITMQCONNECTIONSBYQUERY_ERROR';
export const DELETERABBITMQCONNECTIONSBYQUERY_SUCCESS = 'DELETERABBITMQCONNECTIONSBYQUERY_SUCCESS';

export const DELETESQSCONNECTIONS_REQ = 'DELETESQSCONNECTIONS_REQ';
export const DELETESQSCONNECTIONS_ERROR = 'DELETESQSCONNECTIONS_ERROR';
export const DELETESQSCONNECTIONS_SUCCESS = 'DELETESQSCONNECTIONS_SUCCESS';

export const DELETESQSCONNECTIONSBYQUERY_REQ = 'DELETESQSCONNECTIONSBYQUERY_REQ';
export const DELETESQSCONNECTIONSBYQUERY_ERROR = 'DELETESQSCONNECTIONSBYQUERY_ERROR';
export const DELETESQSCONNECTIONSBYQUERY_SUCCESS = 'DELETESQSCONNECTIONSBYQUERY_SUCCESS';

export const GETKAFKACONNECTIONS_REQ = 'GETKAFKACONNECTIONS_REQ';
export const GETKAFKACONNECTIONS_ERROR = 'GETKAFKACONNECTIONS_ERROR';
export const GETKAFKACONNECTIONS_SUCCESS = 'GETKAFKACONNECTIONS_SUCCESS';

export const GETKAFKACONNECTIONSBYQUERY_REQ = 'GETKAFKACONNECTIONSBYQUERY_REQ';
export const GETKAFKACONNECTIONSBYQUERY_ERROR = 'GETKAFKACONNECTIONSBYQUERY_ERROR';
export const GETKAFKACONNECTIONSBYQUERY_SUCCESS = 'GETKAFKACONNECTIONSBYQUERY_SUCCESS';

export const GETRABBITMQCONNECTIONS_REQ = 'GETRABBITMQCONNECTIONS_REQ';
export const GETRABBITMQCONNECTIONS_ERROR = 'GETRABBITMQCONNECTIONS_ERROR';
export const GETRABBITMQCONNECTIONS_SUCCESS = 'GETRABBITMQCONNECTIONS_SUCCESS';

export const GETRABBITMQCONNECTIONSBYQUERY_REQ = 'GETRABBITMQCONNECTIONSBYQUERY_REQ';
export const GETRABBITMQCONNECTIONSBYQUERY_ERROR = 'GETRABBITMQCONNECTIONSBYQUERY_ERROR';
export const GETRABBITMQCONNECTIONSBYQUERY_SUCCESS = 'GETRABBITMQCONNECTIONSBYQUERY_SUCCESS';

export const GETSQSCONNECTIONS_REQ = 'GETSQSCONNECTIONS_REQ';
export const GETSQSCONNECTIONS_ERROR = 'GETSQSCONNECTIONS_ERROR';
export const GETSQSCONNECTIONS_SUCCESS = 'GETSQSCONNECTIONS_SUCCESS';

export const GETSQSCONNECTIONSBYQUERY_REQ = 'GETSQSCONNECTIONSBYQUERY_REQ';
export const GETSQSCONNECTIONSBYQUERY_ERROR = 'GETSQSCONNECTIONSBYQUERY_ERROR';
export const GETSQSCONNECTIONSBYQUERY_SUCCESS = 'GETSQSCONNECTIONSBYQUERY_SUCCESS';

export const UPDATECONNECTIONSSTATE_REQ = 'UPDATECONNECTIONSSTATE_REQ';
export const UPDATECONNECTIONSSTATE_ERROR = 'UPDATECONNECTIONSSTATE_ERROR';
export const UPDATECONNECTIONSSTATE_SUCCESS = 'UPDATECONNECTIONSSTATE_SUCCESS';

export const UPDATEKAFKACONNECTIONS_REQ = 'UPDATEKAFKACONNECTIONS_REQ';
export const UPDATEKAFKACONNECTIONS_ERROR = 'UPDATEKAFKACONNECTIONS_ERROR';
export const UPDATEKAFKACONNECTIONS_SUCCESS = 'UPDATEKAFKACONNECTIONS_SUCCESS';

export const UPDATEKAFKACONNECTIONSSTATE_REQ = 'UPDATEKAFKACONNECTIONSSTATE_REQ';
export const UPDATEKAFKACONNECTIONSSTATE_ERROR = 'UPDATEKAFKACONNECTIONSSTATE_ERROR';
export const UPDATEKAFKACONNECTIONSSTATE_SUCCESS = 'UPDATEKAFKACONNECTIONSSTATE_SUCCESS';

export const UPDATERABBITMQCONNECTIONS_REQ = 'UPDATERABBITMQCONNECTIONS_REQ';
export const UPDATERABBITMQCONNECTIONS_ERROR = 'UPDATERABBITMQCONNECTIONS_ERROR';
export const UPDATERABBITMQCONNECTIONS_SUCCESS = 'UPDATERABBITMQCONNECTIONS_SUCCESS';

export const UPDATERABBITMQCONNECTIONSSTATE_REQ = 'UPDATERABBITMQCONNECTIONSSTATE_REQ';
export const UPDATERABBITMQCONNECTIONSSTATE_ERROR = 'UPDATERABBITMQCONNECTIONSSTATE_ERROR';
export const UPDATERABBITMQCONNECTIONSSTATE_SUCCESS = 'UPDATERABBITMQCONNECTIONSSTATE_SUCCESS';

export const UPDATESQSCONNECTIONS_REQ = 'UPDATESQSCONNECTIONS_REQ';
export const UPDATESQSCONNECTIONS_ERROR = 'UPDATESQSCONNECTIONS_ERROR';
export const UPDATESQSCONNECTIONS_SUCCESS = 'UPDATESQSCONNECTIONS_SUCCESS';

export const UPDATESQSCONNECTIONSSTATE_REQ = 'UPDATESQSCONNECTIONSSTATE_REQ';
export const UPDATESQSCONNECTIONSSTATE_ERROR = 'UPDATESQSCONNECTIONSSTATE_ERROR';
export const UPDATESQSCONNECTIONSSTATE_SUCCESS = 'UPDATESQSCONNECTIONSSTATE_SUCCESS';

