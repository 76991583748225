// <<< DO NOT EDIT GENERATED FILES DIRECTLY! api-store-actions.mustache >>>
import { delay, cloneDeep } from 'lodash';
import * as types from './mutation-types';
import sdkApiConfig from '@/config/sdkApiConfig';
import updateFields from '@/utils/updateFields';
import { languagesApiZiqni } from '@/generated/ziqni/api/LanguagesApiZiqni';
import router from '@/router';

import apiResponseFields from '../../../fields/ApiResponseZq.js';
import createLanguageRequestFields from '../../../fields/CreateLanguageRequestZq.js';
import languageResponseFields from '../../../fields/LanguageResponseZq.js';
import queryRequestFields from '../../../fields/QueryRequestZq.js';
import updateLanguageRequestFields from '../../../fields/UpdateLanguageRequestZq.js';
import {handleRequest,handleRequestWithBody} from "../../../../../utils/ZiqniAdminApiHandler";

export const handleFields = ({ commit, state }, data) => {
  updateFields(commit, state, data, 'hiddenLanguagesKey');
};

// Clear languages Message
export const handleClearMessage = ({ commit }) => {
  commit(types.CLEAR_MESSAGE);
};

// clear filter
export const handleClearFilter = ({ commit }) => {
  commit(types.CLEAR_FILTER);
};

//LANGUAGES - LANGUAGES
export const handleLanguagesChanges = ({ commit }, data) => {
  commit('SET_LANGUAGES', data);
};
/**
 * LanguageResponse from backend.
 * GET getLanguages and return [LanguageResponse]
 * vars: [
 *    [[id]] id[ opts, isArray, isQueryParam]
 *    [[limit]] _limit[ opts, isInteger, isQueryParam]
 *    [[skip]] _skip[ opts, isInteger, isQueryParam]]
 */
export const handleGetLanguages_item = ({ commit }, idArray, limit, skip) => {
  commit(types.API_REQUEST);
  let opts = { 
    id: idArray, 
    limit: limit, 
    skip: skip
  };

  return languagesApiZiqni
    .getLanguages(
      opts
    )
    .then((response) => {
      commit(types.GETLANGUAGES_ITEM_SUCCESS, { response });
      return response.results;
    })
    .catch((e) => {
      commit(types.GETLANGUAGES_ITEM_ERROR, e);
      return [];
    });
};

/**
 * ApiResponse from backend.
 * POST createLanguages and return [ApiResponse]
 * vars: [
 *    [[body]] CreateLanguageRequest[ required, isArray, isBodyParam, ]]
 */
export const handleCreateLanguages = ({ commit }, payload) => {
  const { createLanguageRequestArray } = payload;

  commit(types.API_REQUEST);
  

  return languagesApiZiqni
    .createLanguages(
      createLanguageRequestArray
    )
    .then((response) => {
      commit(types.CREATELANGUAGES_SUCCESS, { response });
      return response.results;
    })
    .catch((e) => {
      commit(types.CREATELANGUAGES_ERROR, e);
      return [];
    });
};

/**
 * ApiResponse from backend.
 * DELETE deleteLanguages and return [ApiResponse]
 * vars: [
 *    [[id]] id[ opts, isArray, isQueryParam]]
 */
export const handleDeleteLanguages = ({ commit }, payload) => {
  const { idArray } = payload;

  commit(types.API_REQUEST);
  let opts = { 
    id: idArray
  };

  return languagesApiZiqni
    .deleteLanguages(
      opts
    )
    .then((response) => {
      commit(types.DELETELANGUAGES_SUCCESS, { response });
      return response.results;
    })
    .catch((e) => {
      commit(types.DELETELANGUAGES_ERROR, e);
      return [];
    });
};

/**
 * ApiResponse from backend.
 * POST deleteLanguagesByQuery and return [ApiResponse]
 * vars: [
 *    [[body]] QueryRequest[ opts, isBodyParam, ]]
 */
export const handleDeleteLanguagesByQuery = ({ commit }, payload) => {
  const { queryRequest } = payload;

  commit(types.API_REQUEST);
  let opts = { 
    body: queryRequest
  };

  return languagesApiZiqni
    .deleteLanguagesByQuery(
      opts
    )
    .then((response) => {
      commit(types.DELETELANGUAGESBYQUERY_SUCCESS, { response });
      return response.results;
    })
    .catch((e) => {
      commit(types.DELETELANGUAGESBYQUERY_ERROR, e);
      return [];
    });
};

/**
 * LanguageResponse from backend.
 * GET getLanguages and return [LanguageResponse]
 * vars: [
 *    [[id]] id[ opts, isArray, isQueryParam]
 *    [[limit]] _limit[ opts, isInteger, isQueryParam]
 *    [[skip]] _skip[ opts, isInteger, isQueryParam]]
 */
export const handleGetLanguages = ({ commit }, payload) => {
  const { idArray, limit, skip } = payload;

  commit(types.API_REQUEST);
  let opts = { 
    id: idArray, 
    limit: limit, 
    skip: skip
  };

  return languagesApiZiqni
    .getLanguages(
      opts
    )
    .then((response) => {
      commit(types.GETLANGUAGES_SUCCESS, { response });
      return response.results;
    })
    .catch((e) => {
      commit(types.GETLANGUAGES_ERROR, e);
      return [];
    });
};

/**
 * LanguageResponse from backend.
 * POST getLanguagesByQuery and return [LanguageResponse]
 * vars: [
 *    [[body]] QueryRequest[ opts, isBodyParam, ]]
 */
export const handleGetLanguagesByQuery = ({ commit }, payload) => {
  const { queryRequest } = payload;

  commit(types.API_REQUEST);
  let opts = { 
    body: queryRequest
  };

  return languagesApiZiqni
    .getLanguagesByQuery(
      opts
    )
    .then((response) => {
      commit(types.GETLANGUAGESBYQUERY_SUCCESS, { response });
      return response.results;
    })
    .catch((e) => {
      commit(types.GETLANGUAGESBYQUERY_ERROR, e);
      return [];
    });
};

/**
 * LanguageResponse from backend.
 * GET getListOfAllLanguages and return [LanguageResponse]
 * vars: [
 *    [[limit]] _limit[ opts, isInteger, isQueryParam]
 *    [[skip]] _skip[ opts, isInteger, isQueryParam]]
 */
export const handleGetListOfAllLanguages = ({ commit }, payload) => {
  const { limit, skip } = payload;

  commit(types.API_REQUEST);
  let opts = { 
    limit: limit, 
    skip: skip
  };

  return languagesApiZiqni
    .getListOfAllLanguages(
      opts
    )
    .then((response) => {
      commit(types.GETLISTOFALLLANGUAGES_SUCCESS, { response });
      return response.results;
    })
    .catch((e) => {
      commit(types.GETLISTOFALLLANGUAGES_ERROR, e);
      return [];
    });
};

/**
 * ApiResponse from backend.
 * PUT updateLanguages and return [ApiResponse]
 * vars: [
 *    [[body]] UpdateLanguageRequest[ required, isArray, isBodyParam, ]]
 */
export const handleUpdateLanguages = ({ commit }, payload) => {
  const { updateLanguageRequestArray } = payload;

  commit(types.API_REQUEST);
  

  return languagesApiZiqni
    .updateLanguages(
      updateLanguageRequestArray
    )
    .then((response) => {
      commit(types.UPDATELANGUAGES_SUCCESS, { response });
      return response.results;
    })
    .catch((e) => {
      commit(types.UPDATELANGUAGES_ERROR, e);
      return [];
    });
};
