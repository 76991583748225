import mutations from './mutations';
import * as actions from './actions';
import * as getters from './getters';

const initialState = {
  currentPageNumber: 1,
  itemsPerPageData: 10,
  tableModel: '',
  currentStatus: ''
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations,
};
