//SettingsStep
const Settings = () => import('@/views/ziqni/settings/Settings');

//Collaborators
const Collaborators = () => import('@/views/ziqni/settings/collaborators');
const PreviewCollaborator = () => import('@/views/ziqni/settings/collaborators/CollaboratorDetails');
const CreateCollaborator = () => import('@/views/ziqni/settings/collaborators/CreateCollaborator');
const EditCollaborator = () => import('@/views/ziqni/settings/collaborators/UpdateCollaborator');


//Action Types
const ActionTypes = () => import('@/generated/ziqni/views/actionTypes/ActionTypesTable');
const CreateActionType = () => import('@/generated/ziqni/views/actionTypes/CreateActionType');
const EditActionType = () => import('@/generated/ziqni/views/actionTypes/UpdateActionType');
const PreviewActionType = () => import('@/generated/ziqni/views/actionTypes/ActionTypeDetails');

//Reward Types
const RewardTypes = () => import('@/views/ziqni/settings/reward-types')
const CreateRewardType = () => import('@/views/ziqni/settings/reward-types/CreateRewardType');
const EditRewardType = () => import('@/views/ziqni/settings/reward-types/UpdateRewardType');
// const PreviewRewardType = () => import('@/views/ziqni/settings/reward-types/PreviewRewardType');
const PreviewRewardType = () => import('@/generated/ziqni/views/rewardTypes/RewardTypeDetails');

//CustomFields
const CustomFields = () => import('@/views/ziqni/settings/custom-fields')
const CreateCustomField = () => import('@/views/ziqni/settings/custom-fields/CreateCustomField');
const EditCustomField = () => import('@/views/ziqni/settings/custom-fields/EditCustomField');
const PreviewCustomField = () => import('@/views/ziqni/settings/custom-fields/PreviewCustomField');
//Languages
const Languages = () => import('@/generated/ziqni/views/languages/LanguagesTable');
// const PreviewLanguage = () => import('@/views/ziqni/settings/languages/PreviewLanguage');
const PreviewLanguage = () => import('@/generated/ziqni/views/languages/LanguageDetails.vue');
const CreateLanguage = () => import('@/generated/ziqni/views/languages/CreateLanguage');
// const EditLanguage = () => import('@/views/ziqni/settings/languages/EditLanguage');
const EditLanguage = () => import('@/generated/ziqni/views/languages/UpdateLanguage.vue');

//Units Of Measure
const UnitsOfMeasure = () => import('@/views/ziqni/settings/units-of-measure');
const PreviewUnitOfMeasure = () => import('@/generated/ziqni/views/unitsOfMeasure/UnitOfMeasureDetails');
const CreateUnitOfMeasure = () => import('@/generated/ziqni/views/unitsOfMeasure/CreateUnitOfMeasure');
const EditUnitOfMeasure = () => import('@/generated/ziqni/views/unitsOfMeasure/UpdateUnitOfMeasure');


//webhooks
const Webhooks = () => import('@/views/ziqni/settings/webhooks');
const PreviewWebhook = () => import('@/views/ziqni/settings/webhooks/PreviewWebhook');
const EditWebhook = () => import('@/views/ziqni/settings/webhooks/UpdateWebhook');
const CreateWebhook = () => import('@/views/ziqni/settings/webhooks/CreateWebhook');
// const CreateWebhook = () => import('@/generated/ziqni/views/webhooks/CreateWebhook')

//Connections
const Connections = () => import('@/views/ziqni/settings/connections')
const PreviewConnection = () => import('@/views/ziqni/settings/connections/PreviewConnection')
const PreviewSQSConnection = () => import('@/views/ziqni/settings/connections/PreviewSQSConnection')
const PreviewRabbitMQConnection = () => import('@/views/ziqni/settings/connections/PreviewRabbitMQConnection')
const PreviewKafkaConnection = () => import('@/views/ziqni/settings/connections/PreviewKafkaConnection')
const EditConnection = () => import('@/views/ziqni/settings/connections/EditConnection')
const EditConnectionSQS = () => import('@/views/ziqni/settings/connections/edit/EditConnectionSQS')
const EditConnectionRabbitMQ = () => import('@/views/ziqni/settings/connections/edit/EditConnectionRabbitMQ')
const EditConnectionKafka = () => import('@/views/ziqni/settings/connections/edit/EditConnectionKafka')
const CreateSqsConnection = () => import('@/views/ziqni/settings/connections/create/CreateSqsConnection')
const CreateKafkaConnection = () => import('@/views/ziqni/settings/connections/create/CreateKafkaConnection')
const CreateRabbitMqConnection = () => import('@/views/ziqni/settings/connections/create/CreateRabbitMqConnection')
const CreateScheduledConnection = () => import('@/views/ziqni/settings/connections/create/CreateScheduledConnection')

//Transformers
const Transformers = () => import('@/views/ziqni/settings/transformers')
const CreateTransformer = () => import('@/views/ziqni/settings/transformers/CreateTransformer')
// const CreateTransformer = () => import('@/generated/ziqni/views/transformers/CreateTransformer')
const EditTransformer = () => import('@/views/ziqni/settings/transformers/EditTransformer')
const PreviewTransformer = () => import('@/views/ziqni/settings/transformers/PreviewTransformer')

//API Keys
const ApiKeys = () => import('@/views/ziqni/settings/api-keys')
const PreviewApiKey = () => import('@/views/ziqni/settings/api-keys/PreviewApiKey')
const CreateApiKey = () => import('@/views/ziqni/settings/api-keys/CreateApiKey')
const EditApiKey = () => import('@/views/ziqni/settings/api-keys/UpdateApiKey')

//Tags
const Tags = () => import('@/views/ziqni/settings/tags')
const PreviewTag = () => import('@/views/ziqni/settings/tags/PreviewTag')
const CreateTag = () => import('@/generated/ziqni/views/tags/CreateTag')
const EditTag = () => import('@/generated/ziqni/views/tags/UpdateTag')

//Compute Engines
const ComputeEngines = () => import('@/views/ziqni/settings/computeEngines')
const CreateComputeEngine = () => import('@/views/ziqni/settings/computeEngines/CreateComputeEngine')
const PreviewComputeEngine = () => import('@/views/ziqni/settings/computeEngines/PreviewComputeEngine')
const EditComputeEngine = () => import('@/views/ziqni/settings/computeEngines/EditComputeEngine')

// Log Events
const LogEvents = () => import('@/views/ziqni/log-events')

export default {
  path: 'settings',
  meta: {
    label: 'Settings'
  },
  component: {
    render(c) {
      return c('router-view')
    }
  },
  children: [
    {
      path: '',
      name: 'Settings',
      component: Settings
    },
    {
      path: 'collaborators',
      component: {
        render(c) {
          return c('router-view');
        },
      },
      children: [
        {
          path: '/',
          name: 'Collaborators',
          component: Collaborators,

          meta: {
            label: 'Collaborators',
          },
        },
        {
          path: 'create-collaborator',
          name: 'CreateCollaborator',
          component: CreateCollaborator,
          meta: {
            label: 'Create Collaborator',
          },
        },
        {
          path: 'edit-collaborator/:id',
          name: 'EditCollaborator',
          component: EditCollaborator,
          meta: {
            label: 'Edit Collaborator',
          },
          props: true,
        },
        {
          path: 'detail/:id',
          name: 'PreviewCollaborator',
          component: PreviewCollaborator,
          props: true,
        },

      ],
    },

    {
      path: 'api-keys',
      component: {
        render(c) {
          return c('router-view');
        },
      },
      children: [
        {
          path: '/',
          name: 'ApiKeys',
          component: ApiKeys,

          meta: {
            label: 'Api Keys',
          },
        },
        {
          path: 'create-apiKey',
          name: 'CreateApiKey',
          component: CreateApiKey,
          meta: {
            label: 'Create Api Key',
          },
        },
        {
          path: 'edit-apiKey/:id',
          name: 'EditApiKey',
          component: EditApiKey,
          meta: {
            label: 'Edit Api Key',
          },
          props: true,
        },
        {
          path: 'detail/:id',
          name: 'PreviewApiKey',
          component: PreviewApiKey,
          props: true,
        },

      ],
    },

    {
      path: 'reward-types',
      component: {
        render(c) {
          return c('router-view');
        },
      },
      children: [
        {
          path: '/',
          name: 'RewardTypes',
          component: RewardTypes,

          meta: {
            label: 'Reward Types',
          },
        },
        {
          path: 'edit-rewardType/:id/:name',
          name: 'EditRewardType',
          component: EditRewardType,
          meta: {
            label: 'Edit Reward Type',
          },
          props: true,
        },
        {
          path: 'detail/:id',
          name: 'PreviewRewardType',
          component: PreviewRewardType,
          props: true,
        },
        {
          path: 'create-rewardType',
          name: 'CreateRewardType',
          component: CreateRewardType,
          meta: {
            label: 'Create Reward Type',
          },
        },
      ],
    },
    {
      path: 'action-types',
      component: {
        render(c) {
          return c('router-view');
        },
      },
      children: [
        {
          path: '/',
          name: 'ActionTypes',
          component: ActionTypes,

          meta: {
            label: 'ActionTypes',
          },
        },
        {
          path: 'edit-actionType/:id/:name',
          name: 'EditActionType',
          component: EditActionType,
          meta: {
            label: 'EditActionType',
          },
          props: true,
        },
        {
          path: 'detail/:id',
          name: 'PreviewActionType',
          component: PreviewActionType,
          props: true,
        },
        {
          path: 'create-actionType',
          name: 'CreateActionType',
          component: CreateActionType,
          meta: {
            label: 'Create Action Type',
          },
        },
      ],
    },

    {
      path: 'custom-fields',
      component: {
        render(c) {
          return c('router-view');
        },
      },
      children: [
        {
          path: '/',
          name: 'CustomFields',
          component: CustomFields,

          meta: {
            label: 'Custom Fields',
          },
        },
        {
          path: 'edit-customField/:id/:name',
          name: 'EditCustomField',
          component: EditCustomField,
          meta: {
            label: 'Edit Custom Field',
          },
          props: true,
        },
        {
          path: 'detail/:id',
          name: 'PreviewCustomField',
          component: PreviewCustomField,
          props: true,
        },
        {
          path: 'create-customField',
          name: 'CreateCustomField',
          component: CreateCustomField,
          meta: {
            label: 'Create Custom Field',
          },
        },
      ],
    },

    {
      path: 'languages',
      component: {
        render(c) {
          return c('router-view');
        },
      },
      children: [
        {
          path: '/',
          name: 'Languages',
          component: Languages,

          meta: {
            label: 'Languages',
          },
        },
        {
          path: 'detail/:id',
          name: 'PreviewLanguage',
          component: PreviewLanguage,
          props: true,
        },
        {
          path: 'add-language',
          name: 'CreateLanguage',
          component: CreateLanguage,
          meta: {
            label: 'Add Language',
          },
        },
        {
          path: 'edit-language/:id/:name',
          name: 'EditLanguage',
          component: EditLanguage,
          meta: {
            label: 'Edit Language',
          },
          props: true,
        },


      ],
    },

    {
      path: 'units-of-measure',
      component: {
        render(c) {
          return c('router-view');
        },
      },
      children: [
        {
          path: '/',
          name: 'UnitOfMeasures',
          component: UnitsOfMeasure,

          meta: {
            label: 'Units Of Measures',
          },
        },
        {
          path: 'detail/:id',
          name: 'PreviewUnitOfMeasure',
          component: PreviewUnitOfMeasure,
          props: true,
        },
        {
          path: 'create-unit-of-measure',
          name: 'CreateUnitOfMeasure',
          component: CreateUnitOfMeasure,
          meta: {
            label: 'CreateUnitOfMeasure',
          },
        },
        {
          path: 'edit-unit-of-measure/:id/:name',
          name: 'EditUnitOfMeasure',
          component: EditUnitOfMeasure,
          meta: {
            label: 'Edit Unit Of Measure',
          },
          props: true,
        },

      ],
    },

    {
      path: 'transformers',
      component: {
        render(c) {
          return c('router-view');
        },
      },
      children: [
        {
          path: '/',
          name: 'Transformers',
          component: Transformers,

          meta: {
            label: 'Transformers',
          },
        },
        {
          path: 'create-transformer',
          name: 'CreateTransformer',
          component: CreateTransformer,
          meta: {
            label: 'Create Transformer',
          },
        },
        {
          path: 'edit-transformer/:id/:name',
          name: 'EditTransformer',
          component: EditTransformer,
          meta: {
            label: 'Edit Transformer',
          },
          props: true,
        },
        {
          path: 'detail/:id',
          name: 'PreviewTransformer',
          component: PreviewTransformer,
          props: true,
        },
      ],
    },

    {
      path: 'webhooks',
      component: {
        render(c) {
          return c('router-view');
        },
      },
      children: [
        {
          path: '/',
          name: 'Webhooks',
          component: Webhooks,

          meta: {
            label: 'Webhooks',
          },
        },
        {
          path: 'detail/:id',
          name: 'PreviewWebhook',
          component: PreviewWebhook,
          props: true,
        },
        {
          path: 'create-webhook',
          name: 'CreateWebhook',
          component: CreateWebhook,
          meta: {
            label: 'Create Webhook',
          },
        },
        {
          path: 'edit-webhook/:id',
          name: 'EditWebhook',
          component: EditWebhook,
          meta: {
            label: 'Edit Webhook',
          },
          props: true,
        },
      ],
    },

    {
      path: 'connections',
      component: {
        render(c) {
          return c('router-view');
        },
      },
      children: [
        {
          path: '/',
          name: 'Connections',
          component: Connections,

          meta: {
            label: 'Connections',
          },
        },
        {
          path: 'detail/:id',
          name: 'PreviewConnection',
          component: PreviewConnection,
          meta: {
            label: 'Preview Connection',
          },
          props: true,
        },
        {
          path: 'detail/sqs/:id',
          name: 'PreviewSqsConnection',
          component: PreviewSQSConnection,
          meta: {
            label: 'Preview SQS Connection',
          },
          props: true,
        },
        {
          path: 'detail/rabbitMQ/:id',
          name: 'PreviewRabbitMqConnection',
          component: PreviewRabbitMQConnection,
          meta: {
            label: 'Preview RabbitMQ Connection',
          },
          props: true,
        },
        {
          path: 'detail/kafka/:id',
          name: 'PreviewKafkaConnection',
          component: PreviewKafkaConnection,
          meta: {
            label: 'Preview Kafka Connection',
          },
          props: true,
        },
        {
          path: 'create-sqs-connection',
          name: 'CreateSqsConnection',
          component: CreateSqsConnection,
          meta: {
            label: 'Create SQS Connection',
          },
        },
        {
          path: 'create-kafka-connection',
          name: 'CreateKafkaConnection',
          component: CreateKafkaConnection,
          meta: {
            label: 'Create Kafka Connection',
          },
        },
        {
          path: 'create-rabbitmq-connection',
          name: 'CreateRabbitMqConnection',
          component: CreateRabbitMqConnection,
          meta: {
            label: 'Create RabbitMQ Connection',
          },
        },
        {
          path: 'create-scheduled-connection',
          name: 'CreateScheduledConnection',
          component: CreateScheduledConnection,
          meta: {
            label: 'Create Scheduled Connection',
          },
        },
        {
          path: 'edit-connection/:id',
          name: 'EditConnection',
          component: EditConnection,
          meta: {
            label: 'Edit Connection',
          },
          props: true,
        },
        {
          path: 'edit-connection/sqs/:id',
          name: 'EditSqsConnection',
          component: EditConnectionSQS,
          meta: {
            label: 'Edit SQS Connection',
          },
          props: true,
        },
        {
          path: 'edit-connection/kafka/:id',
          name: 'EditKafkaConnection',
          component: EditConnectionKafka,
          meta: {
            label: 'EditKafkaConnection',
          },
          props: true,
        },
        {
          path: 'edit-connection/rabbitmq/:id',
          name: 'EditRabbitMqConnection',
          component: EditConnectionRabbitMQ,
          meta: {
            label: 'Edit RabbitMQ Connection',
          },
          props: true,
        },
      ],
    },
    {
      path: 'tags',
      component: {
        render(c) {
          return c('router-view');
        },
      },
      children: [
        {
          path: '/',
          name: 'Tags',
          component: Tags,

          meta: {
            label: 'Tags',
          },
        },
        {
          path: 'detail/:id',
          name: 'PreviewTag',
          component: PreviewTag,
          props: true,
        },
        {
          path: 'create-tag',
          name: 'CreateTag',
          component: CreateTag,
          meta: {
            label: 'create Tag',
          },
        },
        {
          path: 'edit-tag/:id/:name',
          name: 'EditTag',
          component: EditTag,
          meta: {
            label: 'Edit Tag',
          },
          props: true,
        },


      ],
    },
    {
      path: 'compute-engines',
      component: {
        render(c) {
          return c('router-view');
        },
      },
      children: [
        {
          path: '/',
          name: 'ComputeEngines',
          component: ComputeEngines,

          meta: {
            label: 'Compute Engines',
          },
        },
        {
          path: 'create-compute-engine',
          name: 'CreateComputeEngine',
          component: CreateComputeEngine,
          meta: {
            label: 'Create Compute Engine',
          },
        },
        {
          path: 'detail/:id',
          name: 'PreviewComputeEngine',
          component: PreviewComputeEngine,
          props: true,
        },
        {
          path: 'edit-compute-engine/:id/:name',
          name: 'EditComputeEngine',
          component: EditComputeEngine,
          meta: {
            label: 'Edit Compute Engine',
          },
          props: true,
        },
      ],
    },
    {
      path: 'log-events',
      component: {
        render(c) {
          return c('router-view');
        },
      },
      children: [
        {
          path: '/',
          name: 'LogEvents',
          component: LogEvents,
          meta: {
            label: 'LogEvents',
          },
        },
      ],
    },
  ]
}