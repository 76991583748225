// <<< DO NOT EDIT GENERATED FILES DIRECTLY! api-store-mutation-types.mustache >>>
export const API_REQUEST = 'API_REQUEST';


export const GETMEMBERS_ITEM_REQ = 'GETMEMBERS_ITEM_REQ';
export const GETMEMBERS_ITEM_ERROR = 'GETMEMBERS_ITEM_ERROR';
export const GETMEMBERS_ITEM_SUCCESS = 'GETMEMBERS_ITEM_SUCCESS'

export const UPDATE_FIELDS = 'UPDATE_FIELDS';
export const CLEAR_FILTER = 'CLEAR_FILTER';
export const CLEAR_MESSAGE = 'CLEAR_MESSAGE';

export const CREATEMEMBERS_REQ = 'CREATEMEMBERS_REQ';
export const CREATEMEMBERS_ERROR = 'CREATEMEMBERS_ERROR';
export const CREATEMEMBERS_SUCCESS = 'CREATEMEMBERS_SUCCESS';

export const DELETEMEMBERS_REQ = 'DELETEMEMBERS_REQ';
export const DELETEMEMBERS_ERROR = 'DELETEMEMBERS_ERROR';
export const DELETEMEMBERS_SUCCESS = 'DELETEMEMBERS_SUCCESS';

export const DELETEMEMBERSBYQUERY_REQ = 'DELETEMEMBERSBYQUERY_REQ';
export const DELETEMEMBERSBYQUERY_ERROR = 'DELETEMEMBERSBYQUERY_ERROR';
export const DELETEMEMBERSBYQUERY_SUCCESS = 'DELETEMEMBERSBYQUERY_SUCCESS';

export const GETACHIEVEMENTSFORMEMBERS_REQ = 'GETACHIEVEMENTSFORMEMBERS_REQ';
export const GETACHIEVEMENTSFORMEMBERS_ERROR = 'GETACHIEVEMENTSFORMEMBERS_ERROR';
export const GETACHIEVEMENTSFORMEMBERS_SUCCESS = 'GETACHIEVEMENTSFORMEMBERS_SUCCESS';

export const GETAWARDSFORMEMBERS_REQ = 'GETAWARDSFORMEMBERS_REQ';
export const GETAWARDSFORMEMBERS_ERROR = 'GETAWARDSFORMEMBERS_ERROR';
export const GETAWARDSFORMEMBERS_SUCCESS = 'GETAWARDSFORMEMBERS_SUCCESS';

export const GETCOMPETITIONSFORMEMBERS_REQ = 'GETCOMPETITIONSFORMEMBERS_REQ';
export const GETCOMPETITIONSFORMEMBERS_ERROR = 'GETCOMPETITIONSFORMEMBERS_ERROR';
export const GETCOMPETITIONSFORMEMBERS_SUCCESS = 'GETCOMPETITIONSFORMEMBERS_SUCCESS';

export const GETCONTESTSFORMEMBERS_REQ = 'GETCONTESTSFORMEMBERS_REQ';
export const GETCONTESTSFORMEMBERS_ERROR = 'GETCONTESTSFORMEMBERS_ERROR';
export const GETCONTESTSFORMEMBERS_SUCCESS = 'GETCONTESTSFORMEMBERS_SUCCESS';

export const GETMEMBERS_REQ = 'GETMEMBERS_REQ';
export const GETMEMBERS_ERROR = 'GETMEMBERS_ERROR';
export const GETMEMBERS_SUCCESS = 'GETMEMBERS_SUCCESS';

export const GETMEMBERSBYQUERY_REQ = 'GETMEMBERSBYQUERY_REQ';
export const GETMEMBERSBYQUERY_ERROR = 'GETMEMBERSBYQUERY_ERROR';
export const GETMEMBERSBYQUERY_SUCCESS = 'GETMEMBERSBYQUERY_SUCCESS';

export const GETMEMBERSBYREFID_REQ = 'GETMEMBERSBYREFID_REQ';
export const GETMEMBERSBYREFID_ERROR = 'GETMEMBERSBYREFID_ERROR';
export const GETMEMBERSBYREFID_SUCCESS = 'GETMEMBERSBYREFID_SUCCESS';

export const GETMESSAGESFORMEMBERS_REQ = 'GETMESSAGESFORMEMBERS_REQ';
export const GETMESSAGESFORMEMBERS_ERROR = 'GETMESSAGESFORMEMBERS_ERROR';
export const GETMESSAGESFORMEMBERS_SUCCESS = 'GETMESSAGESFORMEMBERS_SUCCESS';

export const UPDATEMEMBERS_REQ = 'UPDATEMEMBERS_REQ';
export const UPDATEMEMBERS_ERROR = 'UPDATEMEMBERS_ERROR';
export const UPDATEMEMBERS_SUCCESS = 'UPDATEMEMBERS_SUCCESS';

