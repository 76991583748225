import * as getters from "./getters";
import * as actions from "./actions";
import mutations from "./mutations";


const initialState = {
  user: {},
  resultCount: 0,
  originalFields: [],
  fields: [],
  originHiddenFields: [
    'firstName',
    'lastName',
    'email',
    'constraints',
    'spaces',
  ],
  hiddenFields: [
  ],
  pages: 0,
  meta: {},
  loading: false,
  loaded: true,
  success: false,
  message: null,
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations,
};
