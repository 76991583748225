// <<< DO NOT EDIT GENERATED FILES DIRECTLY! api-store-mutations.mustache >>>
import * as types from './mutation-types';
import * as _ from 'lodash';
import {
  handleErrorResponse, handleItemErrorResponse,
  handleItemSuccessResponse,
  handleSuccessResponse
} from "../../../../../utils/ZiqniAdminApiHandler";
import achievementReducedResponseFields from '../../../fields/AchievementReducedResponseZq.js';
import apiResponseFields from '../../../fields/ApiResponseZq.js';
import competitionReducedResponseFields from '../../../fields/CompetitionReducedResponseZq.js';
import contestReducedResponseFields from '../../../fields/ContestReducedResponseZq.js';
import createMemberRequestFields from '../../../fields/CreateMemberRequestZq.js';
import memberAwardResponseFields from '../../../fields/MemberAwardResponseZq.js';
import memberMessageResponseFields from '../../../fields/MemberMessageResponseZq.js';
import memberResponseFields from '../../../fields/MemberResponseZq.js';
import queryRequestFields from '../../../fields/QueryRequestZq.js';
import updateMemberRequestFields from '../../../fields/UpdateMemberRequestZq.js';

const mutations = {
    [types.API_REQUEST](state) {
        state.loading = true;
        state.loaded = false;
    },
    [types.CLEAR_MESSAGE](state){
        state.message = null;
    },

    [types.UPDATE_FIELDS](state, data) {
        state.hiddenFields = data._hiddenFields;
        state.fields = _.difference(state.originalFields, state.hiddenFields);
        localStorage.setItem('hiddenMembersKey', JSON.stringify(state.hiddenFields));
    },

    [types.CLEAR_FILTER](state) {
        state.fields = _.difference(
            state.originalFields,
            state.originHiddenFields
        );
        state.hiddenFields = state.originHiddenFields;
        localStorage.removeItem('hiddenMembersKey');
    },

    //MemberResponse
    [types.GETMEMBERS_ITEM_SUCCESS](state, response) {
      handleItemSuccessResponse(types.GETMEMBERS_ITEM_SUCCESS,memberResponseFields, state, response, 'member');
    },

    //MemberResponse
    [types.GETMEMBERS_ERROR](state, error) {
      handleItemErrorResponse(types.GETMEMBERS_ITEM_ITEM_ERROR, memberResponseFields, state, error);
    },


    //ApiResponse
    [types.CREATEMEMBERS_SUCCESS](state, response) {
      handleSuccessResponse(types.CREATEMEMBERS_SUCCESS,apiResponseFields, state, response);
    },

    //ApiResponse
    [types.CREATEMEMBERS_ERROR](state, error) {
      handleErrorResponse(types.CREATEMEMBERS_ERROR, apiResponseFields, state, error);
    },

    //ApiResponse
    [types.DELETEMEMBERS_SUCCESS](state, response) {
      handleSuccessResponse(types.DELETEMEMBERS_SUCCESS,apiResponseFields, state, response);
    },

    //ApiResponse
    [types.DELETEMEMBERS_ERROR](state, error) {
      handleErrorResponse(types.DELETEMEMBERS_ERROR, apiResponseFields, state, error);
    },

    //ApiResponse
    [types.DELETEMEMBERSBYQUERY_SUCCESS](state, response) {
      handleSuccessResponse(types.DELETEMEMBERSBYQUERY_SUCCESS,apiResponseFields, state, response);
    },

    //ApiResponse
    [types.DELETEMEMBERSBYQUERY_ERROR](state, error) {
      handleErrorResponse(types.DELETEMEMBERSBYQUERY_ERROR, apiResponseFields, state, error);
    },

    //AchievementReducedResponse
    [types.GETACHIEVEMENTSFORMEMBERS_SUCCESS](state, response) {
      handleSuccessResponse(types.GETACHIEVEMENTSFORMEMBERS_SUCCESS,achievementReducedResponseFields, state, response);
    },

    //AchievementReducedResponse
    [types.GETACHIEVEMENTSFORMEMBERS_ERROR](state, error) {
      handleErrorResponse(types.GETACHIEVEMENTSFORMEMBERS_ERROR, achievementReducedResponseFields, state, error);
    },

    //MemberAwardResponse
    [types.GETAWARDSFORMEMBERS_SUCCESS](state, response) {
      handleSuccessResponse(types.GETAWARDSFORMEMBERS_SUCCESS,memberAwardResponseFields, state, response);
    },

    //MemberAwardResponse
    [types.GETAWARDSFORMEMBERS_ERROR](state, error) {
      handleErrorResponse(types.GETAWARDSFORMEMBERS_ERROR, memberAwardResponseFields, state, error);
    },

    //CompetitionReducedResponse
    [types.GETCOMPETITIONSFORMEMBERS_SUCCESS](state, response) {
      handleSuccessResponse(types.GETCOMPETITIONSFORMEMBERS_SUCCESS,competitionReducedResponseFields, state, response);
    },

    //CompetitionReducedResponse
    [types.GETCOMPETITIONSFORMEMBERS_ERROR](state, error) {
      handleErrorResponse(types.GETCOMPETITIONSFORMEMBERS_ERROR, competitionReducedResponseFields, state, error);
    },

    //ContestReducedResponse
    [types.GETCONTESTSFORMEMBERS_SUCCESS](state, response) {
      handleSuccessResponse(types.GETCONTESTSFORMEMBERS_SUCCESS,contestReducedResponseFields, state, response);
    },

    //ContestReducedResponse
    [types.GETCONTESTSFORMEMBERS_ERROR](state, error) {
      handleErrorResponse(types.GETCONTESTSFORMEMBERS_ERROR, contestReducedResponseFields, state, error);
    },

    //MemberResponse
    [types.GETMEMBERS_SUCCESS](state, response) {
      handleSuccessResponse(types.GETMEMBERS_SUCCESS,memberResponseFields, state, response);
    },

    //MemberResponse
    [types.GETMEMBERS_ERROR](state, error) {
      handleErrorResponse(types.GETMEMBERS_ERROR, memberResponseFields, state, error);
    },

    //MemberResponse
    [types.GETMEMBERSBYQUERY_SUCCESS](state, response) {
      handleSuccessResponse(types.GETMEMBERSBYQUERY_SUCCESS,memberResponseFields, state, response);
    },

    //MemberResponse
    [types.GETMEMBERSBYQUERY_ERROR](state, error) {
      handleErrorResponse(types.GETMEMBERSBYQUERY_ERROR, memberResponseFields, state, error);
    },

    //MemberResponse
    [types.GETMEMBERSBYREFID_SUCCESS](state, response) {
      handleSuccessResponse(types.GETMEMBERSBYREFID_SUCCESS,memberResponseFields, state, response);
    },

    //MemberResponse
    [types.GETMEMBERSBYREFID_ERROR](state, error) {
      handleErrorResponse(types.GETMEMBERSBYREFID_ERROR, memberResponseFields, state, error);
    },

    //MemberMessageResponse
    [types.GETMESSAGESFORMEMBERS_SUCCESS](state, response) {
      handleSuccessResponse(types.GETMESSAGESFORMEMBERS_SUCCESS,memberMessageResponseFields, state, response);
    },

    //MemberMessageResponse
    [types.GETMESSAGESFORMEMBERS_ERROR](state, error) {
      handleErrorResponse(types.GETMESSAGESFORMEMBERS_ERROR, memberMessageResponseFields, state, error);
    },

    //ApiResponse
    [types.UPDATEMEMBERS_SUCCESS](state, response) {
      handleSuccessResponse(types.UPDATEMEMBERS_SUCCESS,apiResponseFields, state, response);
    },

    //ApiResponse
    [types.UPDATEMEMBERS_ERROR](state, error) {
      handleErrorResponse(types.UPDATEMEMBERS_ERROR, apiResponseFields, state, error);
    },
};

export default mutations;
