export const GET_EVENTS_REQUEST = 'GET_EVENTS_REQUEST';
// Get Events
export const GET_EVENTS_SUCCESS = 'GET_EVENTS_SUCCESS';
export const GET_EVENTS_ERROR = 'GET_EVENTS_ERROR';

// GET Event
export const GET_EVENT_SUCCESS = 'GET_EVENT_SUCCESS';
export const GET_EVENT_ERROR = 'GET_EVENT_ERROR';
//Create Events
export const CREATE_EVENT_ERROR = 'CREATE_EVENT_ERROR';
export const CREATE_EVENT_SUCCESS = 'CREATE_EVENT_SUCCESS';

// ACTIVE TAB
export const SET_ACTIVE_TAB = 'SET_ACTIVE_TAB';
export const RESET_ACTIVE_TAB = 'RESET_ACTIVE_TAB';

// FIELDS
export const UPDATE_FIELDS = 'UPDATE_FIELDS';
export const CLEAR_FILTER = 'CLEAR_FILTER';

// Clear Message
export const CLEAR_MESSAGE = 'CLEAR_MESSAGE';