import * as types from './mutation-types';
import {spacesApiZiqni} from '@/generated/ziqni/api/SpacesApiZiqni.js';
import {delay} from "lodash";
import router from "@/router";

export const handleGetSpaceInformation = async ({commit}) => {
  commit(types.GET_SPACES_FOR_USER_REQUEST);

  try {
    const data = await spacesApiZiqni.getSpaceInfromation();
    const pages = Math.ceil(data.meta.resultCount / data.meta.limit);
    commit(types.GET_SPACES_FOR_USER_REQUEST_SUCCESS, {data, pages});
  } catch (e) {
    commit(types.GET_SPACES_FOR_USER_REQUEST_ERROR, e);
  }

};

export const handleCreateSpaces = ({commit}, formData) => {
  commit(types.API_REQUEST);
  spacesApiZiqni.createSpaces(formData)
      .then(data => {
        delay(() => {
          commit(types.CREATE_SPACES_REQUEST_SUCCESS, data);
          router.push({
            name: 'Laboratory',
          });
        }, 1000);

      })
      .catch(e => {
        console.log(e.message);
        commit(types.CREATE_SPACES_REQUEST_ERROR, e);
      })
};

export const handleUpdateSpace = ({commit}, formData) => {
  commit(types.API_REQUEST);

  spacesApiZiqni.updateSpace(formData)
    .then(data => {
      const pages = Math.ceil(data.meta.resultCount / data.meta.limit);
      commit(types.UPDATE_SPACES_REQUEST_SUCCESS, {data, pages});
    })
    .catch(e => {
      commit(types.UPDATE_SPACES_REQUEST_ERROR, e);
      console.log(e)
    })
};

export const handleGetSpacesByName = ({commit}, spaceName) => {
  const body = {
    spaceName: spaceName
  };
  commit(types.API_REQUEST);

  return spacesApiZiqni.getSpacesByName(body)
    .then(data => {
      commit(types.GET_SPACES_BY_NAME_REQUEST_SUCCESS, {data});
      return data.results;
    })
    .catch(e => {
      commit(types.GET_SPACES_BY_NAME_REQUEST_ERROR, e);
    })
};

export const handleAppSwitcherCreated = ({commit}, data) => {
  commit(types.APP_SWITCHER_CREATED, {data});
};
