const EditNotificationMessage = () => import('@/views/ziqni/messages/inbox/edit/EditNotificationMessage');
const EditMemberMessage = () => import('@/views/ziqni/messages/inbox/edit/EditMemberMessage');
const Inbox = () => import('@/views/ziqni/messages/inbox');
const CreateNotification = () => import('@/views/ziqni/messages/inbox/create/CreateNotification');
const CreateMemberMessage = () => import('@/views/ziqni/messages/CreateMemberMessage');
const PreviewNotificationMessage = () => import('@/generated/ziqni/views/notifications/NotificationDetails');
const PreviewMemberMessage = () => import('@/generated/ziqni/views/memberMessages/MemberMessageDetails');

export default   {
  path: 'inbox',
  meta: {
    label: 'Messages'
  },
  component: {
    render(c) {
      return c('router-view')
    }
  },
  children: [
    {
      path: '/',
      name: 'Inbox',
      component: Inbox,
      meta: {
        label: 'Inbox',
      },
    },
    {
      path: ':id',
      name: 'PreviewNotification',
      component: PreviewNotificationMessage,
      meta: {
        label: 'Preview Inbox Message',
      },
    },
    {
      path: ':id',
      name: 'PreviewMemberMessage',
      component: PreviewMemberMessage,
      meta: {
        label: 'Preview Inbox Message',
      },
    },
    {
      path: 'create/create-notification',
      name: 'CreateNotification',
      component: CreateNotification,
      meta: {
        label: 'Create Notification',
      },
    },
    {
      path: 'create/member-message',
      name: 'CreateMemberMessage',
      component: CreateMemberMessage,
      meta: {
        label: 'Create Member Message',
      },
    },
    {
      path: 'edit-member-message/:id',
      name: 'EditMemberMessage',
      component: EditMemberMessage,
      meta: {
        label: 'EditMember Message',
      },
    },
    {
      path: 'edit-notification-message/:id',
      name: 'EditNotification',
      component: EditNotificationMessage,
      meta: {
        label: 'Edit Notification Message',
      },
    },
    {
      path: '/member-message',
      name: 'MemberMessages',
      redirect: { name: 'Inbox' }
    },
    {
      path: '/notification-message',
      name: 'Notifications',
      redirect: { name: 'Inbox' }
    }
  ],
}