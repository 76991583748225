// <<< DO NOT EDIT GENERATED FILES DIRECTLY! api-store-mutations.mustache >>>
import * as types from './mutation-types';
import * as _ from 'lodash';
import {
  handleErrorResponse, handleItemErrorResponse,
  handleItemSuccessResponse,
  handleSuccessResponse
} from "../../../../../utils/ZiqniAdminApiHandler";
import apiResponseFields from '../../../fields/ApiResponseZq.js';
import createTagRequestFields from '../../../fields/CreateTagRequestZq.js';
import queryRequestFields from '../../../fields/QueryRequestZq.js';
import tagResponseFields from '../../../fields/TagResponseZq.js';
import updateTagRequestFields from '../../../fields/UpdateTagRequestZq.js';

const mutations = {
    [types.API_REQUEST](state) {
        state.loading = true;
        state.loaded = false;
    },
    [types.CLEAR_MESSAGE](state){
        state.message = null;
    },

    [types.UPDATE_FIELDS](state, data) {
        state.hiddenFields = data._hiddenFields;
        state.fields = _.difference(state.originalFields, state.hiddenFields);
        localStorage.setItem('hiddenTagsKey', JSON.stringify(state.hiddenFields));
    },

    [types.CLEAR_FILTER](state) {
        state.fields = _.difference(
            state.originalFields,
            state.originHiddenFields
        );
        state.hiddenFields = state.originHiddenFields;
        localStorage.removeItem('hiddenTagsKey');
    },

    //TagResponse
    [types.GETTAGS_ITEM_SUCCESS](state, response) {
      handleItemSuccessResponse(types.GETTAGS_ITEM_SUCCESS,tagResponseFields, state, response, 'tag');
    },

    //TagResponse
    [types.GETTAGS_ERROR](state, error) {
      handleItemErrorResponse(types.GETTAGS_ITEM_ITEM_ERROR, tagResponseFields, state, error);
    },


    //ApiResponse
    [types.CREATETAGS_SUCCESS](state, response) {
      handleSuccessResponse(types.CREATETAGS_SUCCESS,apiResponseFields, state, response);
    },

    //ApiResponse
    [types.CREATETAGS_ERROR](state, error) {
      handleErrorResponse(types.CREATETAGS_ERROR, apiResponseFields, state, error);
    },

    //ApiResponse
    [types.DELETETAGS_SUCCESS](state, response) {
      handleSuccessResponse(types.DELETETAGS_SUCCESS,apiResponseFields, state, response);
    },

    //ApiResponse
    [types.DELETETAGS_ERROR](state, error) {
      handleErrorResponse(types.DELETETAGS_ERROR, apiResponseFields, state, error);
    },

    //ApiResponse
    [types.DELETETAGSBYQUERY_SUCCESS](state, response) {
      handleSuccessResponse(types.DELETETAGSBYQUERY_SUCCESS,apiResponseFields, state, response);
    },

    //ApiResponse
    [types.DELETETAGSBYQUERY_ERROR](state, error) {
      handleErrorResponse(types.DELETETAGSBYQUERY_ERROR, apiResponseFields, state, error);
    },

    //TagResponse
    [types.GETTAGS_SUCCESS](state, response) {
      handleSuccessResponse(types.GETTAGS_SUCCESS,tagResponseFields, state, response);
    },

    //TagResponse
    [types.GETTAGS_ERROR](state, error) {
      handleErrorResponse(types.GETTAGS_ERROR, tagResponseFields, state, error);
    },

    //TagResponse
    [types.GETTAGSBYQUERY_SUCCESS](state, response) {
      handleSuccessResponse(types.GETTAGSBYQUERY_SUCCESS,tagResponseFields, state, response);
    },

    //TagResponse
    [types.GETTAGSBYQUERY_ERROR](state, error) {
      handleErrorResponse(types.GETTAGSBYQUERY_ERROR, tagResponseFields, state, error);
    },

    //ApiResponse
    [types.UPDATETAGS_SUCCESS](state, response) {
      handleSuccessResponse(types.UPDATETAGS_SUCCESS,apiResponseFields, state, response);
    },

    //ApiResponse
    [types.UPDATETAGS_ERROR](state, error) {
      handleErrorResponse(types.UPDATETAGS_ERROR, apiResponseFields, state, error);
    },
};

export default mutations;
