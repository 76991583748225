// <<< DO NOT EDIT GENERATED FILES DIRECTLY! model-store-fields-all.mustache >>>


import eventRefType from './EventRefTypeZq';
import messageLink from './MessageLinkZq';
import messageStatus from './MessageStatusZq';
import messageType from './MessageTypeZq';

const memberMessageAllOf = this;

export default {
  objectType: 'MemberMessage_allOf',
  allowableValuesKeys: [],
  constraints: ['isMap',],
  getter: null,
  hiddenFields: [],
  searchableFields: ['eventRefId','subject','body','expiry',], 
  searchableAllFields: ['eventRefId','subject','body',],
  translatableFields: ['subject','body',],
  sortableFields: [],
  customFieldsFieldName: [],
  metadataFieldName: [],
  tagFieldName: [],
  entityLinksFrom: [],

  route: {
    
    
  },
  fieldList: ['eventRefType','eventRefId','messageType','subject','body','status','expiry','links','memberIds'],
  fields: { 
    eventRefType: {
      name: 'eventRefType',
      complexType: eventRefType,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['required',
                     ],
      formType: null,
    },
    eventRefId: {
      name: 'eventRefId',
      complexType: null,
      displayOrder: 1001,
      allowableValuesKeys: [],
      constraints: ['isPrimitiveType','isString',
                     ],
      formType: null,
    },
    messageType: {
      name: 'messageType',
      complexType: messageType,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['required',
                     ],
      formType: null,
    },
    subject: {
      name: 'subject',
      complexType: null,
      displayOrder: 1002,
      allowableValuesKeys: [],
      constraints: ['required','isPrimitiveType','isString',
                     ],
      formType: 'TEXTAREA',
    },
    body: {
      name: 'body',
      complexType: null,
      displayOrder: 1003,
      allowableValuesKeys: [],
      constraints: ['required','isPrimitiveType','isString',
                     ],
      formType: 'TEXTAREA',
    },
    status: {
      name: 'status',
      complexType: messageStatus,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['required',
                     ],
      formType: null,
    },
    expiry: {
      name: 'expiry',
      complexType: null,
      displayOrder: 1004,
      allowableValuesKeys: [],
      constraints: ['required','isPrimitiveType','isDateTime',
                     ],
      formType: null,
    },
    links: {
      name: 'links',
      complexType: messageLink,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['required','isModel',
                     ],
      formType: null,
    },
    memberIds: {
      name: 'memberIds',
      complexType: null,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['isPrimitiveType','isContainer','isArray',
                    'isString', ],
      formType: null,
    }
  }
};
