// <<< DO NOT EDIT GENERATED FILES DIRECTLY! api-store-mutation-types.mustache >>>
export const API_REQUEST = 'API_REQUEST';


export const GETRULES_ITEM_REQ = 'GETRULES_ITEM_REQ';
export const GETRULES_ITEM_ERROR = 'GETRULES_ITEM_ERROR';
export const GETRULES_ITEM_SUCCESS = 'GETRULES_ITEM_SUCCESS'

export const UPDATE_FIELDS = 'UPDATE_FIELDS';
export const CLEAR_FILTER = 'CLEAR_FILTER';
export const CLEAR_MESSAGE = 'CLEAR_MESSAGE';

export const DELETERULESBYQUERY_REQ = 'DELETERULESBYQUERY_REQ';
export const DELETERULESBYQUERY_ERROR = 'DELETERULESBYQUERY_ERROR';
export const DELETERULESBYQUERY_SUCCESS = 'DELETERULESBYQUERY_SUCCESS';

export const GETRULESCHEMAS_REQ = 'GETRULESCHEMAS_REQ';
export const GETRULESCHEMAS_ERROR = 'GETRULESCHEMAS_ERROR';
export const GETRULESCHEMAS_SUCCESS = 'GETRULESCHEMAS_SUCCESS';

export const GETRULES_REQ = 'GETRULES_REQ';
export const GETRULES_ERROR = 'GETRULES_ERROR';
export const GETRULES_SUCCESS = 'GETRULES_SUCCESS';

export const GETRULESBYQUERY_REQ = 'GETRULESBYQUERY_REQ';
export const GETRULESBYQUERY_ERROR = 'GETRULESBYQUERY_ERROR';
export const GETRULESBYQUERY_SUCCESS = 'GETRULESBYQUERY_SUCCESS';

export const LOOKUPOPERANDSRULES_REQ = 'LOOKUPOPERANDSRULES_REQ';
export const LOOKUPOPERANDSRULES_ERROR = 'LOOKUPOPERANDSRULES_ERROR';
export const LOOKUPOPERANDSRULES_SUCCESS = 'LOOKUPOPERANDSRULES_SUCCESS';

export const UPDATERULES_REQ = 'UPDATERULES_REQ';
export const UPDATERULES_ERROR = 'UPDATERULES_ERROR';
export const UPDATERULES_SUCCESS = 'UPDATERULES_SUCCESS';

export const VALIDATERULES_REQ = 'VALIDATERULES_REQ';
export const VALIDATERULES_ERROR = 'VALIDATERULES_ERROR';
export const VALIDATERULES_SUCCESS = 'VALIDATERULES_SUCCESS';

