// <<< DO NOT EDIT GENERATED FILES DIRECTLY! api-store-mutations.mustache >>>
import * as types from './mutation-types';
import * as _ from 'lodash';
import {
  handleErrorResponse, handleItemErrorResponse,
  handleItemSuccessResponse,
  handleSuccessResponse
} from "../../../../../utils/ZiqniAdminApiHandler";
import apiResponseFields from '../../../fields/ApiResponseZq.js';
import createEventRequestFields from '../../../fields/CreateEventRequestZq.js';
import eventResponseFields from '../../../fields/EventResponseZq.js';
import queryRequestFields from '../../../fields/QueryRequestZq.js';

const mutations = {
    [types.API_REQUEST](state) {
        state.loading = true;
        state.loaded = false;
    },
    [types.CLEAR_MESSAGE](state){
        state.message = null;
    },

    [types.UPDATE_FIELDS](state, data) {
        state.hiddenFields = data._hiddenFields;
        state.fields = _.difference(state.originalFields, state.hiddenFields);
        localStorage.setItem('hiddenEventsKey', JSON.stringify(state.hiddenFields));
    },

    [types.CLEAR_FILTER](state) {
        state.fields = _.difference(
            state.originalFields,
            state.originHiddenFields
        );
        state.hiddenFields = state.originHiddenFields;
        localStorage.removeItem('hiddenEventsKey');
    },

    //EventResponse
    [types.GETEVENTS_ITEM_SUCCESS](state, response) {
      handleItemSuccessResponse(types.GETEVENTS_ITEM_SUCCESS,eventResponseFields, state, response, 'event');
    },

    //EventResponse
    [types.GETEVENTS_ERROR](state, error) {
      handleItemErrorResponse(types.GETEVENTS_ITEM_ITEM_ERROR, eventResponseFields, state, error);
    },


    //ApiResponse
    [types.CREATEEVENTS_SUCCESS](state, response) {
      handleSuccessResponse(types.CREATEEVENTS_SUCCESS,apiResponseFields, state, response);
    },

    //ApiResponse
    [types.CREATEEVENTS_ERROR](state, error) {
      handleErrorResponse(types.CREATEEVENTS_ERROR, apiResponseFields, state, error);
    },

    //EventResponse
    [types.GETEVENTS_SUCCESS](state, response) {
      handleSuccessResponse(types.GETEVENTS_SUCCESS,eventResponseFields, state, response);
    },

    //EventResponse
    [types.GETEVENTS_ERROR](state, error) {
      handleErrorResponse(types.GETEVENTS_ERROR, eventResponseFields, state, error);
    },

    //EventResponse
    [types.GETEVENTSBYQUERY_SUCCESS](state, response) {
      handleSuccessResponse(types.GETEVENTSBYQUERY_SUCCESS,eventResponseFields, state, response);
    },

    //EventResponse
    [types.GETEVENTSBYQUERY_ERROR](state, error) {
      handleErrorResponse(types.GETEVENTSBYQUERY_ERROR, eventResponseFields, state, error);
    },
};

export default mutations;
