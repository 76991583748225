// <<< DO NOT EDIT GENERATED FILES DIRECTLY! api-store-mutation-types.mustache >>>
export const API_REQUEST = 'API_REQUEST';


export const GETACHIEVEMENTSFORPRODUCTS_ITEM_REQ = 'GETACHIEVEMENTSFORPRODUCTS_ITEM_REQ';
export const GETACHIEVEMENTSFORPRODUCTS_ITEM_ERROR = 'GETACHIEVEMENTSFORPRODUCTS_ITEM_ERROR';
export const GETACHIEVEMENTSFORPRODUCTS_ITEM_SUCCESS = 'GETACHIEVEMENTSFORPRODUCTS_ITEM_SUCCESS'

export const GETCOMPETITIONSFORPRODUCTS_ITEM_REQ = 'GETCOMPETITIONSFORPRODUCTS_ITEM_REQ';
export const GETCOMPETITIONSFORPRODUCTS_ITEM_ERROR = 'GETCOMPETITIONSFORPRODUCTS_ITEM_ERROR';
export const GETCOMPETITIONSFORPRODUCTS_ITEM_SUCCESS = 'GETCOMPETITIONSFORPRODUCTS_ITEM_SUCCESS'

export const GETCONTESTSFORPRODUCTS_ITEM_REQ = 'GETCONTESTSFORPRODUCTS_ITEM_REQ';
export const GETCONTESTSFORPRODUCTS_ITEM_ERROR = 'GETCONTESTSFORPRODUCTS_ITEM_ERROR';
export const GETCONTESTSFORPRODUCTS_ITEM_SUCCESS = 'GETCONTESTSFORPRODUCTS_ITEM_SUCCESS'

export const GETPRODUCTS_ITEM_REQ = 'GETPRODUCTS_ITEM_REQ';
export const GETPRODUCTS_ITEM_ERROR = 'GETPRODUCTS_ITEM_ERROR';
export const GETPRODUCTS_ITEM_SUCCESS = 'GETPRODUCTS_ITEM_SUCCESS'

export const UPDATE_FIELDS = 'UPDATE_FIELDS';
export const CLEAR_FILTER = 'CLEAR_FILTER';
export const CLEAR_MESSAGE = 'CLEAR_MESSAGE';

export const CREATEPRODUCTS_REQ = 'CREATEPRODUCTS_REQ';
export const CREATEPRODUCTS_ERROR = 'CREATEPRODUCTS_ERROR';
export const CREATEPRODUCTS_SUCCESS = 'CREATEPRODUCTS_SUCCESS';

export const DELETEPRODUCTS_REQ = 'DELETEPRODUCTS_REQ';
export const DELETEPRODUCTS_ERROR = 'DELETEPRODUCTS_ERROR';
export const DELETEPRODUCTS_SUCCESS = 'DELETEPRODUCTS_SUCCESS';

export const DELETEPRODUCTSBYQUERY_REQ = 'DELETEPRODUCTSBYQUERY_REQ';
export const DELETEPRODUCTSBYQUERY_ERROR = 'DELETEPRODUCTSBYQUERY_ERROR';
export const DELETEPRODUCTSBYQUERY_SUCCESS = 'DELETEPRODUCTSBYQUERY_SUCCESS';

export const GETACHIEVEMENTSFORPRODUCTS_REQ = 'GETACHIEVEMENTSFORPRODUCTS_REQ';
export const GETACHIEVEMENTSFORPRODUCTS_ERROR = 'GETACHIEVEMENTSFORPRODUCTS_ERROR';
export const GETACHIEVEMENTSFORPRODUCTS_SUCCESS = 'GETACHIEVEMENTSFORPRODUCTS_SUCCESS';

export const GETCOMPETITIONSFORPRODUCTS_REQ = 'GETCOMPETITIONSFORPRODUCTS_REQ';
export const GETCOMPETITIONSFORPRODUCTS_ERROR = 'GETCOMPETITIONSFORPRODUCTS_ERROR';
export const GETCOMPETITIONSFORPRODUCTS_SUCCESS = 'GETCOMPETITIONSFORPRODUCTS_SUCCESS';

export const GETCONTESTSFORPRODUCTS_REQ = 'GETCONTESTSFORPRODUCTS_REQ';
export const GETCONTESTSFORPRODUCTS_ERROR = 'GETCONTESTSFORPRODUCTS_ERROR';
export const GETCONTESTSFORPRODUCTS_SUCCESS = 'GETCONTESTSFORPRODUCTS_SUCCESS';

export const GETPRODUCTS_REQ = 'GETPRODUCTS_REQ';
export const GETPRODUCTS_ERROR = 'GETPRODUCTS_ERROR';
export const GETPRODUCTS_SUCCESS = 'GETPRODUCTS_SUCCESS';

export const GETPRODUCTSBYQUERY_REQ = 'GETPRODUCTSBYQUERY_REQ';
export const GETPRODUCTSBYQUERY_ERROR = 'GETPRODUCTSBYQUERY_ERROR';
export const GETPRODUCTSBYQUERY_SUCCESS = 'GETPRODUCTSBYQUERY_SUCCESS';

export const GETPRODUCTSBYREFID_REQ = 'GETPRODUCTSBYREFID_REQ';
export const GETPRODUCTSBYREFID_ERROR = 'GETPRODUCTSBYREFID_ERROR';
export const GETPRODUCTSBYREFID_SUCCESS = 'GETPRODUCTSBYREFID_SUCCESS';

export const UPDATEPRODUCTS_REQ = 'UPDATEPRODUCTS_REQ';
export const UPDATEPRODUCTS_ERROR = 'UPDATEPRODUCTS_ERROR';
export const UPDATEPRODUCTS_SUCCESS = 'UPDATEPRODUCTS_SUCCESS';

