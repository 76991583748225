// <<< DO NOT EDIT GENERATED FILES DIRECTLY! api-store-actions.mustache >>>
import { delay, cloneDeep } from 'lodash';
import * as types from './mutation-types';
import sdkApiConfig from '@/config/sdkApiConfig';
import updateFields from '@/utils/updateFields';
import { instantWinsApiZiqni } from '@/generated/ziqni/api/InstantWinsApiZiqni';
import router from '@/router';

import apiResponseFields from '../../../fields/ApiResponseZq.js';
import createInstantWinRequestFields from '../../../fields/CreateInstantWinRequestZq.js';
import instantWinResponseFields from '../../../fields/InstantWinResponseZq.js';
import queryRequestFields from '../../../fields/QueryRequestZq.js';
import updateInstantWinRequestFields from '../../../fields/UpdateInstantWinRequestZq.js';
import {handleRequest,handleRequestWithBody} from "../../../../../utils/ZiqniAdminApiHandler";

export const handleFields = ({ commit, state }, data) => {
  updateFields(commit, state, data, 'hiddenInstantWinsKey');
};

// Clear instantWins Message
export const handleClearMessage = ({ commit }) => {
  commit(types.CLEAR_MESSAGE);
};

// clear filter
export const handleClearFilter = ({ commit }) => {
  commit(types.CLEAR_FILTER);
};

//INSTANTWINS - INSTANTWINS
export const handleInstantWinsChanges = ({ commit }, data) => {
  commit('SET_INSTANTWINS', data);
};
/**
 * InstantWinResponse from backend.
 * GET getInstantWins and return [InstantWinResponse]
 * vars: [
 *    [[id]] id[ opts, isArray, isQueryParam]
 *    [[limit]] _limit[ opts, isInteger, isQueryParam]
 *    [[skip]] _skip[ opts, isInteger, isQueryParam]]
 */
export const handleGetInstantWins_item = ({ commit }, idArray, limit, skip) => {
  commit(types.API_REQUEST);
  let opts = { 
    id: idArray, 
    limit: limit, 
    skip: skip
  };

  return instantWinsApiZiqni
    .getInstantWins(
      opts
    )
    .then((response) => {
      commit(types.GETINSTANTWINS_ITEM_SUCCESS, { response });
      return response.results;
    })
    .catch((e) => {
      commit(types.GETINSTANTWINS_ITEM_ERROR, e);
      return [];
    });
};

/**
 * ApiResponse from backend.
 * POST createInstantWins and return [ApiResponse]
 * vars: [
 *    [[body]] CreateInstantWinRequest[ required, isArray, isBodyParam, ]]
 */
export const handleCreateInstantWins = ({ commit }, payload) => {
  const { createInstantWinRequestArray } = payload;

  commit(types.API_REQUEST);
  

  return instantWinsApiZiqni
    .createInstantWins(
      createInstantWinRequestArray
    )
    .then((response) => {
      commit(types.CREATEINSTANTWINS_SUCCESS, { response });
      return response.results;
    })
    .catch((e) => {
      commit(types.CREATEINSTANTWINS_ERROR, e);
      return [];
    });
};

/**
 * ApiResponse from backend.
 * DELETE deleteInstantWins and return [ApiResponse]
 * vars: [
 *    [[id]] id[ opts, isArray, isQueryParam]]
 */
export const handleDeleteInstantWins = ({ commit }, payload) => {
  const { idArray } = payload;

  commit(types.API_REQUEST);
  let opts = { 
    id: idArray
  };

  return instantWinsApiZiqni
    .deleteInstantWins(
      opts
    )
    .then((response) => {
      commit(types.DELETEINSTANTWINS_SUCCESS, { response });
      return response.results;
    })
    .catch((e) => {
      commit(types.DELETEINSTANTWINS_ERROR, e);
      return [];
    });
};

/**
 * ApiResponse from backend.
 * POST deleteInstantWinsByQuery and return [ApiResponse]
 * vars: [
 *    [[body]] QueryRequest[ opts, isBodyParam, ]]
 */
export const handleDeleteInstantWinsByQuery = ({ commit }, payload) => {
  const { queryRequest } = payload;

  commit(types.API_REQUEST);
  let opts = { 
    body: queryRequest
  };

  return instantWinsApiZiqni
    .deleteInstantWinsByQuery(
      opts
    )
    .then((response) => {
      commit(types.DELETEINSTANTWINSBYQUERY_SUCCESS, { response });
      return response.results;
    })
    .catch((e) => {
      commit(types.DELETEINSTANTWINSBYQUERY_ERROR, e);
      return [];
    });
};

/**
 * InstantWinResponse from backend.
 * GET getInstantWins and return [InstantWinResponse]
 * vars: [
 *    [[id]] id[ opts, isArray, isQueryParam]
 *    [[limit]] _limit[ opts, isInteger, isQueryParam]
 *    [[skip]] _skip[ opts, isInteger, isQueryParam]]
 */
export const handleGetInstantWins = ({ commit }, payload) => {
  const { idArray, limit, skip } = payload;

  commit(types.API_REQUEST);
  let opts = { 
    id: idArray, 
    limit: limit, 
    skip: skip
  };

  return instantWinsApiZiqni
    .getInstantWins(
      opts
    )
    .then((response) => {
      commit(types.GETINSTANTWINS_SUCCESS, { response });
      return response.results;
    })
    .catch((e) => {
      commit(types.GETINSTANTWINS_ERROR, e);
      return [];
    });
};

/**
 * InstantWinResponse from backend.
 * POST getInstantWinsByQuery and return [InstantWinResponse]
 * vars: [
 *    [[body]] QueryRequest[ opts, isBodyParam, ]]
 */
export const handleGetInstantWinsByQuery = ({ commit }, payload) => {
  const { queryRequest } = payload;

  commit(types.API_REQUEST);
  let opts = { 
    body: queryRequest
  };

  return instantWinsApiZiqni
    .getInstantWinsByQuery(
      opts
    )
    .then((response) => {
      commit(types.GETINSTANTWINSBYQUERY_SUCCESS, { response });
      return response.results;
    })
    .catch((e) => {
      commit(types.GETINSTANTWINSBYQUERY_ERROR, e);
      return [];
    });
};

/**
 * ApiResponse from backend.
 * PUT updateInstantWins and return [ApiResponse]
 * vars: [
 *    [[body]] UpdateInstantWinRequest[ required, isArray, isBodyParam, ]]
 */
export const handleUpdateInstantWins = ({ commit }, payload) => {
  const { updateInstantWinRequestArray } = payload;

  commit(types.API_REQUEST);
  

  return instantWinsApiZiqni
    .updateInstantWins(
      updateInstantWinRequestArray
    )
    .then((response) => {
      commit(types.UPDATEINSTANTWINS_SUCCESS, { response });
      return response.results;
    })
    .catch((e) => {
      commit(types.UPDATEINSTANTWINS_ERROR, e);
      return [];
    });
};
