// <<< DO NOT EDIT GENERATED FILES DIRECTLY! model-store-fields-all.mustache >>>


import translationEntry from './TranslationEntryZq';
import updateModelDefault from './UpdateModelDefaultZq';
import updateTranslationRequestAllOf from './UpdateTranslationRequestAllOfZq';

const updateTranslationRequest = this;

export default {
  objectType: 'UpdateTranslationRequest',
  allowableValuesKeys: [],
  constraints: [],
  getter: null,
  hiddenFields: [],
  searchableFields: [], 
  searchableAllFields: [],
  translatableFields: [],
  sortableFields: [],
  customFieldsFieldName: [],
  metadataFieldName: [],
  tagFieldName: [],
  entityLinksFrom: [],

  route: {
    
    
  },
  fieldList: ['id','entityId','entityType','languageId','languageKey','translations'],
  fields: { 
    id: {
      name: 'id',
      complexType: null,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['required','isPrimitiveType','isString',
                     ],
      formType: null,
    },
    entityId: {
      name: 'entityId',
      complexType: null,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['isPrimitiveType','isString',
                     ],
      formType: null,
    },
    entityType: {
      name: 'entityType',
      complexType: null,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['isPrimitiveType','isString',
                     ],
      formType: null,
    },
    languageId: {
      name: 'languageId',
      complexType: null,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['isPrimitiveType','isString',
                     ],
      formType: null,
    },
    languageKey: {
      name: 'languageKey',
      complexType: null,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['isPrimitiveType','isString',
                     ],
      formType: null,
    },
    translations: {
      name: 'translations',
      complexType: translationEntry,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['required','isContainer','isArray',
                    'isModel', ],
      formType: null,
    }
  }
};
