// <<< DO NOT EDIT GENERATED FILES DIRECTLY! model-store-fields-all.mustache >>>


import achievementStrategies from './AchievementStrategiesZq';
import createAchievementRequestAllOf from './CreateAchievementRequestAllOfZq';
import createOptParamModels from './CreateOptParamModelsZq';
import createRewardRequest from './CreateRewardRequestZq';
import dependantOn from './DependantOnZq';
import dependancy from './DependancyZq';
import rule from './RuleZq';
import scheduling from './SchedulingZq';
import translation from './TranslationZq';

const createAchievementRequest = this;

export default {
  objectType: 'CreateAchievementRequest',
  allowableValuesKeys: [],
  constraints: [],
  getter: null,
  hiddenFields: [],
  searchableFields: [], 
  searchableAllFields: [],
  translatableFields: [],
  sortableFields: [],
  customFieldsFieldName: ['customFields',],
  metadataFieldName: ['metadata'],
  tagFieldName: ['tags'],
  entityLinksFrom: [],

  route: {
    
    
  },
  fieldList: ['customFields','tags','metadata','name','description','termsAndConditions','icon','banner','bannerLowResolution','bannerHighResolution','rules','dependantOn','scheduling','maxNumberOfIssues','translations','rewards','constraints','productTagsFilter','addConstraints'],
  fields: {
    customFields: {
      name: 'customFields',
      complexType: null,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['isCustomFieldsArray','isPrimitiveType','isContainer','isFreeFormObject','isMap',
                    'isFreeFormObject','isNullable', ],
      formType: 'CUSTOM',
    },
    tags: {
      name: 'tags',
      complexType: null,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['isTagsArray','isPrimitiveType','isContainer','isArray',
                    'isString', ],
      formType: 'TAG',
    },
    metadata: {
      name: 'metadata',
      complexType: null,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['isMetadataMap','isPrimitiveType','isContainer','isMap',
                    'isString', ],
      formType: 'META',
    },
    name: {
      name: 'name',
      complexType: null,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['required','isPrimitiveType','isString',
                     ],
      formType: null,
    },
    description: {
      name: 'description',
      complexType: null,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['isPrimitiveType','isString',
                     ],
      formType: null,
    },
    termsAndConditions: {
      name: 'termsAndConditions',
      complexType: null,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['isPrimitiveType','isString',
                     ],
      formType: null,
    },
    icon: {
      name: 'icon',
      complexType: null,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['isPrimitiveType','isString',
                     ],
      formType: null,
    },
    banner: {
      name: 'banner',
      complexType: null,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['isPrimitiveType','isString',
                     ],
      formType: null,
    },
    bannerLowResolution: {
      name: 'bannerLowResolution',
      complexType: null,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['isPrimitiveType','isString',
                     ],
      formType: null,
    },
    bannerHighResolution: {
      name: 'bannerHighResolution',
      complexType: null,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['isPrimitiveType','isString',
                     ],
      formType: null,
    },
    rules: {
      name: 'rules',
      complexType: rule,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['isContainer','isArray',
                    'isModel', ],
      formType: null,
    },
    dependantOn: {
      name: 'dependantOn',
      complexType: dependancy,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['isModel',
                     ],
      formType: null,
    },
    scheduling: {
      name: 'scheduling',
      complexType: scheduling,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['required','isModel',
                     ],
      formType: null,
    },
    maxNumberOfIssues: {
      name: 'maxNumberOfIssues',
      complexType: null,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['isPrimitiveType','isNumeric','isInteger','isShort',
                     ],
      formType: null,
    },
    translations: {
      name: 'translations',
      complexType: translation,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['isContainer','isArray',
                    'isModel', ],
      formType: null,
    },
    rewards: {
      name: 'rewards',
      complexType: createRewardRequest,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['isContainer','isArray',
                    'isModel', ],
      formType: null,
    },
    constraints: {
      name: 'constraints',
      complexType: null,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['required','deprecated','isPrimitiveType','isContainer','isArray',
                    'isString', ],
      formType: null,
    },
    // achievementDependencies: {
    //   name: 'achievementDependencies',
    //   complexType: dependantOn,
    //   displayOrder: 2000,
    //   allowableValuesKeys: [],
    //   constraints: ['isModel',
    //                  ],
    //   formType: null,
    // },
    // memberTagsFilter: {
    //   name: 'memberTagsFilter',
    //   complexType: dependantOn,
    //   displayOrder: 2000,
    //   allowableValuesKeys: [],
    //   constraints: ['isModel',
    //                  ],
    //   formType: null,
    // },
    // productTagsFilter: {
    //   name: 'productTagsFilter',
    //   complexType: dependantOn,
    //   displayOrder: 2000,
    //   allowableValuesKeys: [],
    //   constraints: ['isModel',
    //                  ],
    //   formType: null,
    // },
    addConstraints: {
      name: 'addConstraints',
      complexType: null,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['isPrimitiveType','isContainer','isArray',
                    'isString', ],
      formType: null,
    },
    // strategies: {
    //   name: 'strategies',
    //   complexType: achievementStrategies,
    //   displayOrder: 2000,
    //   allowableValuesKeys: [],
    //   constraints: ['isModel',
    //                  ],
    //   formType: null,
    // }
  }
};
