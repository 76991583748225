const Products = () => import('@/views/ziqni/products');
const CreateProduct = () => import('@/views/ziqni/products/CreateProduct');
const PreviewProduct = () => import('@/views/ziqni/products/PreviewProduct');
const EditProduct = () => import('@/generated/ziqni/views/products/UpdateProduct');

export default {
  path: 'products',
  component: {
    render(c) {
      return c('router-view');
    },
  },
  children: [
    {
      path: '',
      name: 'Products',
      component: Products,
    },
    {
      path: 'create-product',
      name: 'CreateProduct',
      component: CreateProduct,
    },
    {
      path: 'detail/:id',
      name: 'PreviewProduct',
      component: PreviewProduct,
    },
    {
      path: 'edit-product/:id',
      name: 'EditProduct',
      component: EditProduct,
      meta: {
        label: 'Edit Product',
      },
      props: true,
    },
  ],
}