import Keycloak from "keycloak-js";
import {prodConfig, devConfig} from "@/config/keycloakConfig";

const hostname = window.location.hostname;
const regexpDomain = /\.(ziqni.app)/;
export const isProd = regexpDomain.test(hostname) && hostname.split('.').length === 3;
export const isStaging = regexpDomain.test(hostname)
    && hostname.split('.').length === 4
    && hostname.split('.')[1] === 'staging';
const config = isProd ? prodConfig : devConfig;
export const keycloak = Keycloak(config);