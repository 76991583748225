// <<< DO NOT EDIT GENERATED FILES DIRECTLY! model-store-fields-all.mustache >>>


import appliesTo from './AppliesToZq';
import fieldType from './FieldTypeZq';

const updateCustomFieldRequestAllOf = this;

export default {
  objectType: 'UpdateCustomFieldRequest_allOf',
  allowableValuesKeys: [],
  constraints: ['isMap',],
  getter: null,
  hiddenFields: [],
  searchableFields: [], 
  searchableAllFields: [],
  translatableFields: [],
  sortableFields: [],
  customFieldsFieldName: [],
  metadataFieldName: [],
  tagFieldName: [],
  entityLinksFrom: [],

  route: {
    
    
  },
  fieldList: ['name','description','fieldType','appliesTo'],
  fields: { 
    name: {
      name: 'name',
      complexType: null,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['isPrimitiveType','isString',
                     ],
      formType: null,
    },
    description: {
      name: 'description',
      complexType: null,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['isPrimitiveType','isString',
                     ],
      formType: 'TEXTAREA',
    },
    fieldType: {
      name: 'fieldType',
      complexType: fieldType,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: [
                     ],
      formType: null,
    },
    appliesTo: {
      name: 'appliesTo',
      complexType: appliesTo,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: [
                     ],
      formType: null,
    }
  }
};
