// <<< DO NOT EDIT GENERATED FILES DIRECTLY! api-store-mutations.mustache >>>
import * as types from './mutation-types';
import * as _ from 'lodash';
import {
  handleErrorResponse, handleItemErrorResponse,
  handleItemSuccessResponse,
  handleSuccessResponse
} from "../../../../../utils/ZiqniAdminApiHandler";
import apiResponseFields from '../../../fields/ApiResponseZq.js';
import createRewardTypeRequestFields from '../../../fields/CreateRewardTypeRequestZq.js';
import queryRequestFields from '../../../fields/QueryRequestZq.js';
import rewardTypeResponseFields from '../../../fields/RewardTypeResponseZq.js';
import updateRewardTypeRequestFields from '../../../fields/UpdateRewardTypeRequestZq.js';

const mutations = {
    [types.API_REQUEST](state) {
        state.loading = true;
        state.loaded = false;
    },
    [types.CLEAR_MESSAGE](state){
        state.message = null;
    },

    [types.UPDATE_FIELDS](state, data) {
        state.hiddenFields = data._hiddenFields;
        state.fields = _.difference(state.originalFields, state.hiddenFields);
        localStorage.setItem('hiddenRewardTypesKey', JSON.stringify(state.hiddenFields));
    },

    [types.CLEAR_FILTER](state) {
        state.fields = _.difference(
            state.originalFields,
            state.originHiddenFields
        );
        state.hiddenFields = state.originHiddenFields;
        localStorage.removeItem('hiddenRewardTypesKey');
    },

    //RewardTypeResponse
    [types.GETREWARDTYPES_ITEM_SUCCESS](state, response) {
      handleItemSuccessResponse(types.GETREWARDTYPES_ITEM_SUCCESS,rewardTypeResponseFields, state, response, 'rewardType');
    },

    //RewardTypeResponse
    [types.GETREWARDTYPES_ERROR](state, error) {
      handleItemErrorResponse(types.GETREWARDTYPES_ITEM_ITEM_ERROR, rewardTypeResponseFields, state, error);
    },


    //ApiResponse
    [types.CREATEREWARDTYPES_SUCCESS](state, response) {
      handleSuccessResponse(types.CREATEREWARDTYPES_SUCCESS,apiResponseFields, state, response);
    },

    //ApiResponse
    [types.CREATEREWARDTYPES_ERROR](state, error) {
      handleErrorResponse(types.CREATEREWARDTYPES_ERROR, apiResponseFields, state, error);
    },

    //ApiResponse
    [types.DELETEREWARDTYPES_SUCCESS](state, response) {
      handleSuccessResponse(types.DELETEREWARDTYPES_SUCCESS,apiResponseFields, state, response);
    },

    //ApiResponse
    [types.DELETEREWARDTYPES_ERROR](state, error) {
      handleErrorResponse(types.DELETEREWARDTYPES_ERROR, apiResponseFields, state, error);
    },

    //ApiResponse
    [types.DELETEREWARDTYPESBYQUERY_SUCCESS](state, response) {
      handleSuccessResponse(types.DELETEREWARDTYPESBYQUERY_SUCCESS,apiResponseFields, state, response);
    },

    //ApiResponse
    [types.DELETEREWARDTYPESBYQUERY_ERROR](state, error) {
      handleErrorResponse(types.DELETEREWARDTYPESBYQUERY_ERROR, apiResponseFields, state, error);
    },

    //RewardTypeResponse
    [types.GETREWARDTYPES_SUCCESS](state, response) {
      handleSuccessResponse(types.GETREWARDTYPES_SUCCESS,rewardTypeResponseFields, state, response);
    },

    //RewardTypeResponse
    [types.GETREWARDTYPES_ERROR](state, error) {
      handleErrorResponse(types.GETREWARDTYPES_ERROR, rewardTypeResponseFields, state, error);
    },

    //RewardTypeResponse
    [types.GETREWARDTYPESBYQUERY_SUCCESS](state, response) {
      handleSuccessResponse(types.GETREWARDTYPESBYQUERY_SUCCESS,rewardTypeResponseFields, state, response);
    },

    //RewardTypeResponse
    [types.GETREWARDTYPESBYQUERY_ERROR](state, error) {
      handleErrorResponse(types.GETREWARDTYPESBYQUERY_ERROR, rewardTypeResponseFields, state, error);
    },

    //ApiResponse
    [types.UPDATEREWARDTYPES_SUCCESS](state, response) {
      handleSuccessResponse(types.UPDATEREWARDTYPES_SUCCESS,apiResponseFields, state, response);
    },

    //ApiResponse
    [types.UPDATEREWARDTYPES_ERROR](state, error) {
      handleErrorResponse(types.UPDATEREWARDTYPES_ERROR, apiResponseFields, state, error);
    },
};

export default mutations;
