// <<< DO NOT EDIT GENERATED FILES DIRECTLY! api-store-mutations.mustache >>>
import * as types from './mutation-types';
import * as _ from 'lodash';
import {
  handleErrorResponse, handleItemErrorResponse,
  handleItemSuccessResponse,
  handleSuccessResponse
} from "../../../../../utils/ZiqniAdminApiHandler";
import apiResponseFields from '../../../fields/ApiResponseZq.js';
import queryRequestFields from '../../../fields/QueryRequestZq.js';
import responseFields from '../../../fields/ResponseZq.js';
import ruleOperandLookupRequestFields from '../../../fields/RuleOperandLookupRequestZq.js';
import ruleOperandLookupResponseFields from '../../../fields/RuleOperandLookupResponseZq.js';
import ruleResponseFields from '../../../fields/RuleResponseZq.js';
import updateRuleRequestFields from '../../../fields/UpdateRuleRequestZq.js';

const mutations = {
    [types.API_REQUEST](state) {
        state.loading = true;
        state.loaded = false;
    },
    [types.CLEAR_MESSAGE](state){
        state.message = null;
    },

    [types.UPDATE_FIELDS](state, data) {
        state.hiddenFields = data._hiddenFields;
        state.fields = _.difference(state.originalFields, state.hiddenFields);
        localStorage.setItem('hiddenRulesKey', JSON.stringify(state.hiddenFields));
    },

    [types.CLEAR_FILTER](state) {
        state.fields = _.difference(
            state.originalFields,
            state.originHiddenFields
        );
        state.hiddenFields = state.originHiddenFields;
        localStorage.removeItem('hiddenRulesKey');
    },

    //RuleResponse
    [types.GETRULES_ITEM_SUCCESS](state, response) {
      handleItemSuccessResponse(types.GETRULES_ITEM_SUCCESS,ruleResponseFields, state, response, 'rule');
    },

    //RuleResponse
    [types.GETRULES_ERROR](state, error) {
      handleItemErrorResponse(types.GETRULES_ITEM_ITEM_ERROR, ruleResponseFields, state, error);
    },


    //ApiResponse
    [types.DELETERULESBYQUERY_SUCCESS](state, response) {
      handleSuccessResponse(types.DELETERULESBYQUERY_SUCCESS,apiResponseFields, state, response);
    },

    //ApiResponse
    [types.DELETERULESBYQUERY_ERROR](state, error) {
      handleErrorResponse(types.DELETERULESBYQUERY_ERROR, apiResponseFields, state, error);
    },

    //Response
    [types.GETRULESCHEMAS_SUCCESS](state, response) {
      handleSuccessResponse(types.GETRULESCHEMAS_SUCCESS,responseFields, state, response);
    },

    //Response
    [types.GETRULESCHEMAS_ERROR](state, error) {
      handleErrorResponse(types.GETRULESCHEMAS_ERROR, responseFields, state, error);
    },

    //RuleResponse
    [types.GETRULES_SUCCESS](state, response) {
      handleSuccessResponse(types.GETRULES_SUCCESS,ruleResponseFields, state, response);
    },

    //RuleResponse
    [types.GETRULES_ERROR](state, error) {
      handleErrorResponse(types.GETRULES_ERROR, ruleResponseFields, state, error);
    },

    //RuleResponse
    [types.GETRULESBYQUERY_SUCCESS](state, response) {
      handleSuccessResponse(types.GETRULESBYQUERY_SUCCESS,ruleResponseFields, state, response);
    },

    //RuleResponse
    [types.GETRULESBYQUERY_ERROR](state, error) {
      handleErrorResponse(types.GETRULESBYQUERY_ERROR, ruleResponseFields, state, error);
    },

    //RuleOperandLookupResponse
    [types.LOOKUPOPERANDSRULES_SUCCESS](state, response) {
      handleSuccessResponse(types.LOOKUPOPERANDSRULES_SUCCESS,ruleOperandLookupResponseFields, state, response);
    },

    //RuleOperandLookupResponse
    [types.LOOKUPOPERANDSRULES_ERROR](state, error) {
      handleErrorResponse(types.LOOKUPOPERANDSRULES_ERROR, ruleOperandLookupResponseFields, state, error);
    },

    //ApiResponse
    [types.UPDATERULES_SUCCESS](state, response) {
      handleSuccessResponse(types.UPDATERULES_SUCCESS,apiResponseFields, state, response);
    },

    //ApiResponse
    [types.UPDATERULES_ERROR](state, error) {
      handleErrorResponse(types.UPDATERULES_ERROR, apiResponseFields, state, error);
    },

    //ApiResponse
    [types.VALIDATERULES_SUCCESS](state, response) {
      handleSuccessResponse(types.VALIDATERULES_SUCCESS,apiResponseFields, state, response);
    },

    //ApiResponse
    [types.VALIDATERULES_ERROR](state, error) {
      handleErrorResponse(types.VALIDATERULES_ERROR, apiResponseFields, state, error);
    },
};

export default mutations;
