import {difference, cloneDeep} from 'lodash';

import * as types from './mutation-types';

const mutations = {
    [types.GET_SPACES_FOR_USER_REQUEST](state) {
        state.loading = true;
        state.loaded = false;
    },

    [types.API_REQUEST](state) {
        state.loading = true;
        state.loaded = false;
    },

    [types.GET_SPACES_FOR_USER_REQUEST_SUCCESS](state, spaces) {
        state.spaces = spaces.data.results;
        if (spaces.data.results.length > 0) {
            const fields = Object.keys(spaces.data.results[0]);
            fields.push('actions');
            state.originalFields = cloneDeep(fields);
        }
        // state.fields = difference(state.originalFields, state.hiddenFields);
        state.pages = spaces.pages;
        state.meta = spaces.data.meta;
        state.loading = false;
        state.loaded = true;
    },

    [types.CREATE_SPACES_REQUEST_SUCCESS](state) {
        state.loading = false;
        state.loaded = true;
        state.success = true;
        state.message = 'This Space has been created successfully!';
    },

    [types.CREATE_SPACES_REQUEST_ERROR](state, error) {
        state.loading = false;
        state.loaded = true;
        state.success = false;
        state.message = error;
    },

    [types.GET_SPACES_FOR_USER_REQUEST_ERROR](state, error) {
        state.error = error;
        state.loading = false;
        state.loaded = true;
    },

    [types.APP_SWITCHER_CREATED](state, data) {
        state.appSwitcher = data;
    },

    [types.GET_SPACES_BY_NAME_REQUEST_SUCCESS](state, space) {
        state.space = space.data.results[0];
        state.loading = false;
        state.loaded = true;
    },

    [types.UPDATE_SPACES_REQUEST_ERROR](state, error) {
        state.loading = false;
        state.loaded = true;
        state.success = false;
        state.message = error;
    },
};

export default mutations;
