// <<< DO NOT EDIT GENERATED FILES DIRECTLY! api-store-actions.mustache >>>
import { delay, cloneDeep } from 'lodash';
import * as types from './mutation-types';
import sdkApiConfig from '@/config/sdkApiConfig';
import updateFields from '@/utils/updateFields';
import { fileObjectsApiZiqni } from '@/generated/ziqni/api/FileObjectsApiZiqni';
import router from '@/router';

import apiResponseFields from '@/generated/ziqni/fields/ApiResponseZq.js';
import createFileObjectRequestFields from '@/generated/ziqni/fields/CreateFileObjectRequestZq.js';
import fileObjectsResponseFields from '@/generated/ziqni/fields/FileObjectsResponseZq.js';
import queryRequestFields from '@/generated/ziqni/fields/QueryRequestZq.js';
import updateFileObjectRequestFields from '@/generated/ziqni/fields/UpdateFileObjectRequestZq.js';
import {handleRequest,handleRequestWithBody} from '@/utils/ZiqniAdminApiHandler.js';

export const handleFields = ({ commit, state }, data) => {
  updateFields(commit, state, data, 'hiddenFileObjectsKey');
};

// Clear fileObjects Message
export const handleClearMessage = ({ commit }) => {
  commit(types.CLEAR_MESSAGE);
};

// clear filter
export const handleClearFilter = ({ commit }) => {
  commit(types.CLEAR_FILTER);
};

//FILEOBJECTS - FILEOBJECTS
export const handleFileObjectsChanges = ({ commit }, data) => {
  commit('SET_FILEOBJECTS', data);
};
/**
 * FileObjectsResponse from backend.
 * GET getFileObjects and return [FileObjectsResponse]
 * vars: [
 *    [[limit]] _limit[ opts, isInteger, isQueryParam]
 *    [[skip]] _skip[ opts, isInteger, isQueryParam]
 *    [[id]] id[ opts, isArray, isQueryParam]
 *    [[download]] download[ opts, isString, isQueryParam]]
 */
export const handleGetFileObjects_item = ({ commit }, payload) => {
  const {limit, skip, idArray, download} = payload

  commit(types.API_REQUEST);
  let opts = {
    limit: limit,
    skip: skip,
    id: idArray,
    download: download
  };

  return fileObjectsApiZiqni
    .getFileObjects(
      opts
    )
    .then((response) => {
      commit(types.GETFILEOBJECTS_ITEM_SUCCESS, { response });
      return response.results;
    })
    .catch((e) => {
      commit(types.GETFILEOBJECTS_ITEM_ERROR, e);
      return [];
    });
};

/**
 * ApiResponse from backend.
 * POST copyFileObjects and return [ApiResponse]
 * vars: [
 *    [[body]] UpdateFileObjectRequest[ required, isBodyParam, ]
 *    [[id]] id[ opts, isArray, isQueryParam]]
 */
export const handleCopyFileObjects = ({ commit }, payload) => {
  const { updateFileObjectRequest, idArray } = payload;

  commit(types.API_REQUEST);
  let opts = {
    id: idArray
  };

  return fileObjectsApiZiqni
    .copyFileObjects(
      updateFileObjectRequest,opts
    )
    .then((response) => {
      commit(types.COPYFILEOBJECTS_SUCCESS, { response });
      return response.results;
    })
    .catch((e) => {
      commit(types.COPYFILEOBJECTS_ERROR, e);
      return [];
    });
};

/**
 * ApiResponse from backend.
 * POST createFileObject and return [ApiResponse]
 * vars: [
 *    [[body]] CreateFileObjectRequest[ required, isBodyParam, ]]
 */
export const handleCreateFileObject = ({ commit }, payload) => {
  const { createFileObjectRequest } = payload;

  commit(types.API_REQUEST);


  return fileObjectsApiZiqni
    .createFileObjects(
      createFileObjectRequest
    )
    .then((response) => {
      commit(types.CREATEFILEOBJECT_SUCCESS, { response });
      return response.results;
    })
    .catch((e) => {
      commit(types.CREATEFILEOBJECT_ERROR, e);
      return [];
    });
};

/**
 * ApiResponse from backend.
 * DELETE deleteFileObjectById and return [ApiResponse]
 * vars: [
 *    [[id]] id[ opts, isArray, isQueryParam]
 *    [[permanent]] permanent[ opts, isQueryParam]]
 */
export const handleDeleteFileObjects = ({ commit }, payload) => {
  const { idArray, permanent } = payload;

  commit(types.API_REQUEST);
  let opts = {
    id: idArray,
    permanent: permanent
  };

  return fileObjectsApiZiqni
    .deleteFileObjects(
      opts
    )
    .then((response) => {
      commit(types.DELETEFILEOBJECTBYID_SUCCESS, { response });
      return response.results;
    })
    .catch((e) => {
      commit(types.DELETEFILEOBJECTBYID_ERROR, e);
      return [];
    });
};

/**
 * ApiResponse from backend.
 * POST deleteFileObjectsByQuery and return [ApiResponse]
 * vars: [
 *    [[id]] id[ opts, isArray, isQueryParam]
 *    [[body]] QueryRequest[ opts, isBodyParam, ]]
 */
export const handleDeleteFileObjectsByQuery = ({ commit }, payload) => {
  const { idArray, queryRequest } = payload;

  commit(types.API_REQUEST);
  let opts = {
    id: idArray,
    body: queryRequest
  };

  return fileObjectsApiZiqni
    .deleteFileObjectsByQuery(
      opts
    )
    .then((response) => {
      commit(types.DELETEFILEOBJECTSBYQUERY_SUCCESS, { response });
      return response.results;
    })
    .catch((e) => {
      commit(types.DELETEFILEOBJECTSBYQUERY_ERROR, e);
      return [];
    });
};

/**
 * File from backend.
 * GET downloadFileObjects and return [File]
 * vars: [
 *    [[path]] path[ opts, isString, isQueryParam]]
 */
export const handleDownloadFileObjects = ({ commit }, payload) => {
  const { path } = payload;

  commit(types.API_REQUEST);
  let opts = {
    path: path
  };

  return fileObjectsApiZiqni
    .downloadFileObjects(
      opts
    )
    .then((response) => {
      commit(types.DOWNLOADFILEOBJECTS_SUCCESS, { response });
      return response;
    })
    .catch((e) => {
      commit(types.DOWNLOADFILEOBJECTS_ERROR, e);
      return [];
    });
};

/**
 * FileObjectsResponse from backend.
 * GET getFileObjects and return [FileObjectsResponse]
 * vars: [
 *    [[limit]] _limit[ opts, isInteger, isQueryParam]
 *    [[skip]] _skip[ opts, isInteger, isQueryParam]
 *    [[id]] id[ opts, isArray, isQueryParam]
 *    [[download]] download[ opts, isString, isQueryParam]]
 */
export const handleGetFileObjects = ({ commit }, payload) => {
  const { limit, skip, idArray, download } = payload;

  commit(types.API_REQUEST);
  let opts = {
    limit: limit,
    skip: skip,
    id: idArray,
    download: download
  };

  return fileObjectsApiZiqni
    .getFileObjects(
      opts
    )
    .then((response) => {
      commit(types.GETFILEOBJECTS_SUCCESS, { response });
      return response.results;
    })
    .catch((e) => {
      commit(types.GETFILEOBJECTS_ERROR, e);
      return [];
    });
};

/**
 * FileObjectsResponse from backend.
 * POST getFileObjectsByQuery and return [FileObjectsResponse]
 * vars: [
 *    [[id]] id[ opts, isArray, isQueryParam]
 *    [[body]] QueryRequest[ opts, isBodyParam, ]]
 */
export const handleGetFileObjectsByQuery = ({ commit }, payload) => {
  const { idArray, queryRequest } = payload;

  commit(types.API_REQUEST);
  let opts = {
    id: idArray,
    body: queryRequest
  };

  return fileObjectsApiZiqni
    .getFileObjectsByQuery(
      opts
    )
    .then((response) => {
      commit(types.GETFILEOBJECTSBYQUERY_SUCCESS, { response });
      return response.results;
    })
    .catch((e) => {
      commit(types.GETFILEOBJECTSBYQUERY_ERROR, e);
      return [];
    });
};

export const handleGetBreadcrumbs = async ({ commit }, payload) => {
  let { repositoryId, parentFolderPath } = payload;
  if (Array.from(parentFolderPath)[0] === '/') {
    parentFolderPath = parentFolderPath.slice(1);
  }
  let pathArray = parentFolderPath.split('/');
  if (!pathArray || !pathArray.length) {
    return [];
  }

  const pathQueryArray = [];

  for (let i = pathArray.length; i > 0; i--) {
    pathQueryArray.push('/' + pathArray.join('/'));
    pathArray.pop();
  }

  let breadcrumbs = [];

  for (const parentFolderPath of pathQueryArray) {
    let pathArray = parentFolderPath.split('/');
    const name = pathArray.pop();
    const item = await fileObjectsApiZiqni.getFileObjectsByQuery({
      id: [],
      body: {
        must: [
          {
            queryField: 'repositoryId',
            queryValues: [repositoryId],
          },
          {
            queryField: 'name',
            queryValues: [name],
          },
          {
            queryField: 'parentFolderPath',
            queryValues: [pathArray.join('/').length ? pathArray.join('/') : '/'],
          }
        ]
      }
    });

    if (item.results.length) {
      breadcrumbs.push({name: name, id: item.results[0].id})
    }
  }

  return breadcrumbs;
}

/**
 * ApiResponse from backend.
 * POST saveTemplates and return [ApiResponse]
 * vars: [
 *    [[tags]] tags[ opts, isArray, ]
 *    [[parentFolderPath]] parentFolderPath[ opts, isString, ]
 *    [[files]] files[ opts, isArray, ]
 *    [[repositoryId]] repositoryId[ opts, isString, ]]
 */
export const handleSaveTemplates = ({ commit }, payload) => {
  const { tagsArray, parentFolderPath, repositoryId, templateToSave, templateName } = payload;

  commit(types.API_REQUEST);
  let opts = {
    tags: tagsArray,
    parentFolderPath: parentFolderPath,
    repositoryId: repositoryId,
    templateToSave: templateToSave,
    templateName: templateName
  };

  return fileObjectsApiZiqni
      .saveTemplates(
          opts
      )
      .then((response) => {
        commit(types.SAVETEMPLATES_SUCCESS, { response });
        return response.results;
      })
      .catch((e) => {
        commit(types.SAVETEMPLATES_ERROR, e);
        return [];
      });
};

/**
 * ApiResponse from backend.
 * PUT updateFileObjects and return [ApiResponse]
 * vars: [
 *    [[body]] UpdateFileObjectRequest[ required, isArray, isBodyParam, ]]
 */
export const handleUpdateFileObjects = ({ commit }, payload) => {
  const { updateFileObjectRequestArray } = payload;

  commit(types.API_REQUEST);


  return fileObjectsApiZiqni
    .updateFileObjects(
      updateFileObjectRequestArray
    )
    .then((response) => {
      commit(types.UPDATEFILEOBJECTS_SUCCESS, { response });
      return response.results;
    })
    .catch((e) => {
      commit(types.UPDATEFILEOBJECTS_ERROR, e);
      return [];
    });
};

/**
 * ApiResponse from backend.
 * POST uploadFileObjects and return [ApiResponse]
 * vars: [
 *    [[tags]] tags[ opts, isArray, ]
 *    [[parentFolderPath]] parentFolderPath[ opts, isString, ]
 *    [[files]] files[ opts, isArray, ]
 *    [[repositoryId]] repositoryId[ opts, isString, ]]
 */
export const handleUploadFileObjects = ({ commit }, payload) => {
  const { tagsArray, parentFolderPath, filesArray, repositoryId } = payload;

  commit(types.API_REQUEST);
  let opts = {
    tags: tagsArray,
    parentFolderPath: parentFolderPath,
    files: filesArray,
    repositoryId: repositoryId
  };

  return fileObjectsApiZiqni
    .uploadFileObjects(
      opts
    )
    .then((response) => {
      commit(types.UPLOADFILEOBJECTS_SUCCESS, { response });
      return response.results;
    })
    .catch((e) => {
      commit(types.UPLOADFILEOBJECTS_ERROR, e);
      return [];
    });
};
