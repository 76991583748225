const Sent = () => import('@/views/ziqni/messages/sent')
const PreviewSentMessage = () => import('@/views/ziqni/messages/sent/PreviewSentMessage')

export default     {
  path: 'sent',
  meta: {
    label: 'Sent'
  },
  component: {
    render(c) {
      return c('router-view')
    }
  },
  children: [
    {
      path: '/',
      name: 'Sent',
      component: Sent,

      meta: {
        label: 'Sent',
      },
    },

    {
      path: ':id',
      name: 'PreviewSentMessage',
      component: PreviewSentMessage,
      meta: {
        label: 'Preview Sent Message',
      },
    },
  ],
}