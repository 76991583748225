// <<< DO NOT EDIT GENERATED FILES DIRECTLY! api-store-mutation-types.mustache >>>
export const API_REQUEST = 'API_REQUEST';


export const GETINSTANTWINS_ITEM_REQ = 'GETINSTANTWINS_ITEM_REQ';
export const GETINSTANTWINS_ITEM_ERROR = 'GETINSTANTWINS_ITEM_ERROR';
export const GETINSTANTWINS_ITEM_SUCCESS = 'GETINSTANTWINS_ITEM_SUCCESS'

export const UPDATE_FIELDS = 'UPDATE_FIELDS';
export const CLEAR_FILTER = 'CLEAR_FILTER';
export const CLEAR_MESSAGE = 'CLEAR_MESSAGE';

export const CREATEINSTANTWINS_REQ = 'CREATEINSTANTWINS_REQ';
export const CREATEINSTANTWINS_ERROR = 'CREATEINSTANTWINS_ERROR';
export const CREATEINSTANTWINS_SUCCESS = 'CREATEINSTANTWINS_SUCCESS';

export const DELETEINSTANTWINS_REQ = 'DELETEINSTANTWINS_REQ';
export const DELETEINSTANTWINS_ERROR = 'DELETEINSTANTWINS_ERROR';
export const DELETEINSTANTWINS_SUCCESS = 'DELETEINSTANTWINS_SUCCESS';

export const DELETEINSTANTWINSBYQUERY_REQ = 'DELETEINSTANTWINSBYQUERY_REQ';
export const DELETEINSTANTWINSBYQUERY_ERROR = 'DELETEINSTANTWINSBYQUERY_ERROR';
export const DELETEINSTANTWINSBYQUERY_SUCCESS = 'DELETEINSTANTWINSBYQUERY_SUCCESS';

export const GETINSTANTWINS_REQ = 'GETINSTANTWINS_REQ';
export const GETINSTANTWINS_ERROR = 'GETINSTANTWINS_ERROR';
export const GETINSTANTWINS_SUCCESS = 'GETINSTANTWINS_SUCCESS';

export const GETINSTANTWINSBYQUERY_REQ = 'GETINSTANTWINSBYQUERY_REQ';
export const GETINSTANTWINSBYQUERY_ERROR = 'GETINSTANTWINSBYQUERY_ERROR';
export const GETINSTANTWINSBYQUERY_SUCCESS = 'GETINSTANTWINSBYQUERY_SUCCESS';

export const UPDATEINSTANTWINS_REQ = 'UPDATEINSTANTWINS_REQ';
export const UPDATEINSTANTWINS_ERROR = 'UPDATEINSTANTWINS_ERROR';
export const UPDATEINSTANTWINS_SUCCESS = 'UPDATEINSTANTWINS_SUCCESS';

