// <<< DO NOT EDIT GENERATED FILES DIRECTLY! api-store-mutation-types.mustache >>>
export const API_REQUEST = 'API_REQUEST';


export const GETMEMBERMESSAGES_ITEM_REQ = 'GETMEMBERMESSAGES_ITEM_REQ';
export const GETMEMBERMESSAGES_ITEM_ERROR = 'GETMEMBERMESSAGES_ITEM_ERROR';
export const GETMEMBERMESSAGES_ITEM_SUCCESS = 'GETMEMBERMESSAGES_ITEM_SUCCESS'

export const UPDATE_FIELDS = 'UPDATE_FIELDS';
export const CLEAR_FILTER = 'CLEAR_FILTER';
export const CLEAR_MESSAGE = 'CLEAR_MESSAGE';

export const CREATEMEMBERMESSAGES_REQ = 'CREATEMEMBERMESSAGES_REQ';
export const CREATEMEMBERMESSAGES_ERROR = 'CREATEMEMBERMESSAGES_ERROR';
export const CREATEMEMBERMESSAGES_SUCCESS = 'CREATEMEMBERMESSAGES_SUCCESS';

export const DELETEMEMBERMESSAGES_REQ = 'DELETEMEMBERMESSAGES_REQ';
export const DELETEMEMBERMESSAGES_ERROR = 'DELETEMEMBERMESSAGES_ERROR';
export const DELETEMEMBERMESSAGES_SUCCESS = 'DELETEMEMBERMESSAGES_SUCCESS';

export const DELETEMEMBERMESSAGESBYQUERY_REQ = 'DELETEMEMBERMESSAGESBYQUERY_REQ';
export const DELETEMEMBERMESSAGESBYQUERY_ERROR = 'DELETEMEMBERMESSAGESBYQUERY_ERROR';
export const DELETEMEMBERMESSAGESBYQUERY_SUCCESS = 'DELETEMEMBERMESSAGESBYQUERY_SUCCESS';

export const GETMEMBERMESSAGES_REQ = 'GETMEMBERMESSAGES_REQ';
export const GETMEMBERMESSAGES_ERROR = 'GETMEMBERMESSAGES_ERROR';
export const GETMEMBERMESSAGES_SUCCESS = 'GETMEMBERMESSAGES_SUCCESS';

export const GETMEMBERMESSAGESBYQUERY_REQ = 'GETMEMBERMESSAGESBYQUERY_REQ';
export const GETMEMBERMESSAGESBYQUERY_ERROR = 'GETMEMBERMESSAGESBYQUERY_ERROR';
export const GETMEMBERMESSAGESBYQUERY_SUCCESS = 'GETMEMBERMESSAGESBYQUERY_SUCCESS';

export const UPDATEMEMBERMESSAGESSTATUS_REQ = 'UPDATEMEMBERMESSAGESSTATUS_REQ';
export const UPDATEMEMBERMESSAGESSTATUS_ERROR = 'UPDATEMEMBERMESSAGESSTATUS_ERROR';
export const UPDATEMEMBERMESSAGESSTATUS_SUCCESS = 'UPDATEMEMBERMESSAGESSTATUS_SUCCESS';

