// <<< DO NOT EDIT GENERATED FILES DIRECTLY! model-store-fields-all.mustache >>>


import ruleExpression from './RuleExpressionZq';

const rule = this;

export default {
  objectType: 'Rule',
  allowableValuesKeys: [],
  constraints: ['isMap',],
  getter: 'tags',
  hiddenFields: [],
  searchableFields: [], 
  stringBuilderFields: [],
  operations: ['Update'], 
  searchableAllFields: [],
  translatableFields: [],
  sortableFields: [],
  customFieldsFieldName: [],
  metadataFieldName: [],
  tagFieldName: [],
  entityLinksFrom: [],

  route: {
    list: 'ListRule',
    preview: 'PreviewRule',
    update: 'UpdateRule',
  },
  skip: 0,
  limit: 10,
  sortBy: [
    {
      "queryField": "id",
      "order": "Asc"
    }
  ],
  fieldList: ['entityId','action','context','rules','id','entityType'],
  fields: { 
    entityId: {
      name: 'entityId',
      complexType: null,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['required','isPrimitiveType','isString',
                     ],
      formType: null,
    },
    action: {
      name: 'action',
      complexType: null,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['required','isPrimitiveType','isString',
                     ],
      formType: null,
    },
    context: {
      name: 'context',
      complexType: null,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['required','isPrimitiveType','isString',
                     ],
      formType: null,
    },
    rules: {
      name: 'rules',
      complexType: ruleExpression,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['required','isModel',
                     ],
      formType: null,
    },
    id: {
      name: 'id',
      complexType: null,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['required','isPrimitiveType','isString','isReadOnly',
                     ],
      formType: null,
    },
    entityType: {
      name: 'entityType',
      complexType: null,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['required','isPrimitiveType','isString',
                     ],
      formType: null,
    }
  }
};
