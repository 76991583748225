// <<< DO NOT EDIT GENERATED FILES DIRECTLY! api-store-actions.mustache >>>
import { delay, cloneDeep } from 'lodash';
import * as types from './mutation-types';
import sdkApiConfig from '@/config/sdkApiConfig';
import updateFields from '@/utils/updateFields';
import { memberMessagesApiZiqni } from '@/generated/ziqni/api/MemberMessagesApiZiqni';
import router from '@/router';

import apiResponseFields from '../../../fields/ApiResponseZq.js';
import createMemberMessageRequestFields from '../../../fields/CreateMemberMessageRequestZq.js';
import memberMessageResponseFields from '../../../fields/MemberMessageResponseZq.js';
import queryRequestFields from '../../../fields/QueryRequestZq.js';
import updateMessageStatusRequestFields from '../../../fields/UpdateMessageStatusRequestZq.js';
import {handleRequest,handleRequestWithBody} from "../../../../../utils/ZiqniAdminApiHandler";

export const handleFields = ({ commit, state }, data) => {
  updateFields(commit, state, data, 'hiddenMemberMessagesKey');
};

// Clear memberMessages Message
export const handleClearMessage = ({ commit }) => {
  commit(types.CLEAR_MESSAGE);
};

// clear filter
export const handleClearFilter = ({ commit }) => {
  commit(types.CLEAR_FILTER);
};

//MEMBERMESSAGES - MEMBERMESSAGES
export const handleMemberMessagesChanges = ({ commit }, data) => {
  commit('SET_MEMBERMESSAGES', data);
};
/**
 * MemberMessageResponse from backend.
 * GET getMemberMessages and return [MemberMessageResponse]
 * vars: [
 *    [[id]] id[ opts, isArray, isQueryParam]
 *    [[limit]] _limit[ opts, isInteger, isQueryParam]
 *    [[skip]] _skip[ opts, isInteger, isQueryParam]]
 */
export const handleGetMemberMessages_item = ({ commit }, idArray, limit, skip) => {
  commit(types.API_REQUEST);
  let opts = { 
    id: idArray, 
    limit: limit, 
    skip: skip
  };

  return memberMessagesApiZiqni
    .getMemberMessages(
      opts
    )
    .then((response) => {
      commit(types.GETMEMBERMESSAGES_ITEM_SUCCESS, { response });
      return response.results;
    })
    .catch((e) => {
      commit(types.GETMEMBERMESSAGES_ITEM_ERROR, e);
      return [];
    });
};

/**
 * ApiResponse from backend.
 * POST createMemberMessages and return [ApiResponse]
 * vars: [
 *    [[body]] CreateMemberMessageRequest[ required, isArray, isBodyParam, ]]
 */
export const handleCreateMemberMessages = ({ commit }, payload) => {
  const { createMemberMessageRequestArray } = payload;

  commit(types.API_REQUEST);
  

  return memberMessagesApiZiqni
    .createMemberMessages(
      createMemberMessageRequestArray
    )
    .then((response) => {
      commit(types.CREATEMEMBERMESSAGES_SUCCESS, { response });
      return response.results;
    })
    .catch((e) => {
      commit(types.CREATEMEMBERMESSAGES_ERROR, e);
      return [];
    });
};

/**
 * ApiResponse from backend.
 * DELETE deleteMemberMessages and return [ApiResponse]
 * vars: [
 *    [[id]] id[ opts, isArray, isQueryParam]]
 */
export const handleDeleteMemberMessages = ({ commit }, payload) => {
  const { idArray } = payload;

  commit(types.API_REQUEST);
  let opts = { 
    id: idArray
  };

  return memberMessagesApiZiqni
    .deleteMemberMessages(
      opts
    )
    .then((response) => {
      commit(types.DELETEMEMBERMESSAGES_SUCCESS, { response });
      return response.results;
    })
    .catch((e) => {
      commit(types.DELETEMEMBERMESSAGES_ERROR, e);
      return [];
    });
};

/**
 * ApiResponse from backend.
 * POST deleteMemberMessagesByQuery and return [ApiResponse]
 * vars: [
 *    [[body]] QueryRequest[ opts, isBodyParam, ]]
 */
export const handleDeleteMemberMessagesByQuery = ({ commit }, payload) => {
  const { queryRequest } = payload;

  commit(types.API_REQUEST);
  let opts = { 
    body: queryRequest
  };

  return memberMessagesApiZiqni
    .deleteMemberMessagesByQuery(
      opts
    )
    .then((response) => {
      commit(types.DELETEMEMBERMESSAGESBYQUERY_SUCCESS, { response });
      return response.results;
    })
    .catch((e) => {
      commit(types.DELETEMEMBERMESSAGESBYQUERY_ERROR, e);
      return [];
    });
};

/**
 * MemberMessageResponse from backend.
 * GET getMemberMessages and return [MemberMessageResponse]
 * vars: [
 *    [[id]] id[ opts, isArray, isQueryParam]
 *    [[limit]] _limit[ opts, isInteger, isQueryParam]
 *    [[skip]] _skip[ opts, isInteger, isQueryParam]]
 */
export const handleGetMemberMessages = ({ commit }, payload) => {
  const { idArray, limit, skip } = payload;

  commit(types.API_REQUEST);
  let opts = { 
    id: idArray, 
    limit: limit, 
    skip: skip
  };

  return memberMessagesApiZiqni
    .getMemberMessages(
      opts
    )
    .then((response) => {
      commit(types.GETMEMBERMESSAGES_SUCCESS, { response });
      return response.results;
    })
    .catch((e) => {
      commit(types.GETMEMBERMESSAGES_ERROR, e);
      return [];
    });
};

/**
 * MemberMessageResponse from backend.
 * POST getMemberMessagesByQuery and return [MemberMessageResponse]
 * vars: [
 *    [[body]] QueryRequest[ opts, isBodyParam, ]]
 */
export const handleGetMemberMessagesByQuery = ({ commit }, payload) => {
  const { queryRequest } = payload;

  commit(types.API_REQUEST);
  let opts = { 
    body: queryRequest
  };

  return memberMessagesApiZiqni
    .getMemberMessagesByQuery(
      opts
    )
    .then((response) => {
      commit(types.GETMEMBERMESSAGESBYQUERY_SUCCESS, { response });
      return response.results;
    })
    .catch((e) => {
      commit(types.GETMEMBERMESSAGESBYQUERY_ERROR, e);
      return [];
    });
};

/**
 * ApiResponse from backend.
 * PUT updateMemberMessagesStatus and return [ApiResponse]
 * vars: [
 *    [[body]] UpdateMessageStatusRequest[ required, isArray, isBodyParam, ]]
 */
export const handleUpdateMemberMessagesStatus = ({ commit }, payload) => {
  const { updateMessageStatusRequestArray } = payload;

  commit(types.API_REQUEST);
  

  return memberMessagesApiZiqni
    .updateMemberMessagesStatus(
      updateMessageStatusRequestArray
    )
    .then((response) => {
      commit(types.UPDATEMEMBERMESSAGESSTATUS_SUCCESS, { response });
      return response.results;
    })
    .catch((e) => {
      commit(types.UPDATEMEMBERMESSAGESSTATUS_ERROR, e);
      return [];
    });
};
