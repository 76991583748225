// <<< DO NOT EDIT GENERATED FILES DIRECTLY! model-store-fields-all.mustache >>>


import collaboratorRolesAvailable from './CollaboratorRolesAvailableZq';

const collaboratorAllOf = this;

export default {
  objectType: 'Collaborator_allOf',
  allowableValuesKeys: [],
  constraints: ['isMap',],
  getter: null,
  hiddenFields: ['roles',],
  searchableFields: ['email','roles','lastName','firstName',], 
  searchableAllFields: ['email','roles','lastName','firstName',],
  translatableFields: [],
  sortableFields: [],
  customFieldsFieldName: [],
  metadataFieldName: [],
  tagFieldName: [],
  entityLinksFrom: [],

  route: {
    
    
  },
  fieldList: ['email','roles','lastName','firstName','availableRoles'],
  fields: { 
    email: {
      name: 'email',
      complexType: null,
      displayOrder: 1001,
      allowableValuesKeys: [],
      constraints: ['required','isPrimitiveType','isString',
                     ],
      formType: null,
    },
    roles: {
      name: 'roles',
      complexType: null,
      displayOrder: 1004,
      allowableValuesKeys: [],
      constraints: ['isPrimitiveType','isContainer','isArray',
                    'isString', ],
      formType: null,
    },
    lastName: {
      name: 'lastName',
      complexType: null,
      displayOrder: 1003,
      allowableValuesKeys: [],
      constraints: ['isPrimitiveType','isString',
                     ],
      formType: null,
    },
    firstName: {
      name: 'firstName',
      complexType: null,
      displayOrder: 1002,
      allowableValuesKeys: [],
      constraints: ['isPrimitiveType','isString',
                     ],
      formType: null,
    },
    availableRoles: {
      name: 'availableRoles',
      complexType: collaboratorRolesAvailable,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['isModel',
                     ],
      formType: null,
    }
  }
};
