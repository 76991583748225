// <<< DO NOT EDIT GENERATED FILES DIRECTLY! api-store-mutations.mustache >>>
import * as types from './mutation-types';
import * as _ from 'lodash';
import {
  handleErrorResponse, handleItemErrorResponse,
  handleItemSuccessResponse,
  handleSuccessResponse
} from "../../../../../utils/ZiqniAdminApiHandler";
import apiResponseFields from '../../../fields/ApiResponseZq.js';
import createUnitOfMeasureRequestFields from '../../../fields/CreateUnitOfMeasureRequestZq.js';
import queryRequestFields from '../../../fields/QueryRequestZq.js';
import unitOfMeasureResponseFields from '../../../fields/UnitOfMeasureResponseZq.js';
import updateUnitOfMeasureRequestFields from '../../../fields/UpdateUnitOfMeasureRequestZq.js';

const mutations = {
    [types.API_REQUEST](state) {
        state.loading = true;
        state.loaded = false;
    },
    [types.CLEAR_MESSAGE](state){
        state.message = null;
    },

    [types.UPDATE_FIELDS](state, data) {
        state.hiddenFields = data._hiddenFields;
        state.fields = _.difference(state.originalFields, state.hiddenFields);
        localStorage.setItem('hiddenUnitsOfMeasureKey', JSON.stringify(state.hiddenFields));
    },

    [types.CLEAR_FILTER](state) {
        state.fields = _.difference(
            state.originalFields,
            state.originHiddenFields
        );
        state.hiddenFields = state.originHiddenFields;
        localStorage.removeItem('hiddenUnitsOfMeasureKey');
    },

    //UnitOfMeasureResponse
    [types.GETUNITSOFMEASURE_ITEM_SUCCESS](state, response) {
      handleItemSuccessResponse(types.GETUNITSOFMEASURE_ITEM_SUCCESS,unitOfMeasureResponseFields, state, response, 'unitOfMeasure');
    },

    //UnitOfMeasureResponse
    [types.GETUNITSOFMEASURE_ERROR](state, error) {
      handleItemErrorResponse(types.GETUNITSOFMEASURE_ITEM_ITEM_ERROR, unitOfMeasureResponseFields, state, error);
    },


    //ApiResponse
    [types.CREATEUNITSOFMEASURE_SUCCESS](state, response) {
      handleSuccessResponse(types.CREATEUNITSOFMEASURE_SUCCESS,apiResponseFields, state, response);
    },

    //ApiResponse
    [types.CREATEUNITSOFMEASURE_ERROR](state, error) {
      handleErrorResponse(types.CREATEUNITSOFMEASURE_ERROR, apiResponseFields, state, error);
    },

    //ApiResponse
    [types.DELETECOLLABORATORSBYQUERY_SUCCESS](state, response) {
      handleSuccessResponse(types.DELETECOLLABORATORSBYQUERY_SUCCESS,apiResponseFields, state, response);
    },

    //ApiResponse
    [types.DELETECOLLABORATORSBYQUERY_ERROR](state, error) {
      handleErrorResponse(types.DELETECOLLABORATORSBYQUERY_ERROR, apiResponseFields, state, error);
    },

    //ApiResponse
    [types.DELETEUNITSOFMEASURE_SUCCESS](state, response) {
      handleSuccessResponse(types.DELETEUNITSOFMEASURE_SUCCESS,apiResponseFields, state, response);
    },

    //ApiResponse
    [types.DELETEUNITSOFMEASURE_ERROR](state, error) {
      handleErrorResponse(types.DELETEUNITSOFMEASURE_ERROR, apiResponseFields, state, error);
    },

    //ApiResponse
    [types.DELETEUNITSOFMEASUREBYQUERY_SUCCESS](state, response) {
      handleSuccessResponse(types.DELETEUNITSOFMEASUREBYQUERY_SUCCESS,apiResponseFields, state, response);
    },

    //ApiResponse
    [types.DELETEUNITSOFMEASUREBYQUERY_ERROR](state, error) {
      handleErrorResponse(types.DELETEUNITSOFMEASUREBYQUERY_ERROR, apiResponseFields, state, error);
    },

    //UnitOfMeasureResponse
    [types.GETUNITSOFMEASURE_SUCCESS](state, response) {
      handleSuccessResponse(types.GETUNITSOFMEASURE_SUCCESS,unitOfMeasureResponseFields, state, response);
    },

    //UnitOfMeasureResponse
    [types.GETUNITSOFMEASURE_ERROR](state, error) {
      handleErrorResponse(types.GETUNITSOFMEASURE_ERROR, unitOfMeasureResponseFields, state, error);
    },

    //UnitOfMeasureResponse
    [types.GETUNITSOFMEASUREBYQUERY_SUCCESS](state, response) {
      handleSuccessResponse(types.GETUNITSOFMEASUREBYQUERY_SUCCESS,unitOfMeasureResponseFields, state, response);
    },

    //UnitOfMeasureResponse
    [types.GETUNITSOFMEASUREBYQUERY_ERROR](state, error) {
      handleErrorResponse(types.GETUNITSOFMEASUREBYQUERY_ERROR, unitOfMeasureResponseFields, state, error);
    },

    //ApiResponse
    [types.UPDATEUNITSOFMEASURE_SUCCESS](state, response) {
      handleSuccessResponse(types.UPDATEUNITSOFMEASURE_SUCCESS,apiResponseFields, state, response);
    },

    //ApiResponse
    [types.UPDATEUNITSOFMEASURE_ERROR](state, error) {
      handleErrorResponse(types.UPDATEUNITSOFMEASURE_ERROR, apiResponseFields, state, error);
    },
};

export default mutations;
