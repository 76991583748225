// <<< DO NOT EDIT GENERATED FILES DIRECTLY! model-store-fields-all.mustache >>>


import achievementStrategies from './AchievementStrategiesZq';
import createRewardRequest from './CreateRewardRequestZq';
import dependantOn from './DependantOnZq';
import dependancy from './DependancyZq';
import rule from './RuleZq';
import scheduling from './SchedulingZq';
import translation from './TranslationZq';

const createAchievementRequestAllOf = this;

export default {
  objectType: 'CreateAchievementRequest_allOf',
  allowableValuesKeys: [],
  constraints: ['isMap',],
  getter: null,
  hiddenFields: [],
  searchableFields: [], 
  searchableAllFields: [],
  translatableFields: [],
  sortableFields: [],
  customFieldsFieldName: [],
  metadataFieldName: [],
  tagFieldName: [],
  entityLinksFrom: [],

  route: {
    
    
  },
  fieldList: ['name','description','termsAndConditions','icon','banner','bannerLowResolution','bannerHighResolution','rules','dependantOn','scheduling','maxNumberOfIssues','translations','rewards','constraints','achievementDependencies','memberTagsFilter','productTagsFilter','addConstraints','strategies'],
  fields: { 
    name: {
      name: 'name',
      complexType: null,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['required','isPrimitiveType','isString',
                     ],
      formType: null,
    },
    description: {
      name: 'description',
      complexType: null,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['isPrimitiveType','isString',
                     ],
      formType: null,
    },
    termsAndConditions: {
      name: 'termsAndConditions',
      complexType: null,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['isPrimitiveType','isString',
                     ],
      formType: null,
    },
    icon: {
      name: 'icon',
      complexType: null,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['isPrimitiveType','isString',
                     ],
      formType: null,
    },
    banner: {
      name: 'banner',
      complexType: null,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['isPrimitiveType','isString',
                     ],
      formType: null,
    },
    bannerLowResolution: {
      name: 'bannerLowResolution',
      complexType: null,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['isPrimitiveType','isString',
                     ],
      formType: null,
    },
    bannerHighResolution: {
      name: 'bannerHighResolution',
      complexType: null,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['isPrimitiveType','isString',
                     ],
      formType: null,
    },
    rules: {
      name: 'rules',
      complexType: rule,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['isContainer','isArray',
                    'isModel', ],
      formType: null,
    },
    dependantOn: {
      name: 'dependantOn',
      complexType: dependancy,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['isModel',
                     ],
      formType: null,
    },
    scheduling: {
      name: 'scheduling',
      complexType: scheduling,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['required','isModel',
                     ],
      formType: null,
    },
    maxNumberOfIssues: {
      name: 'maxNumberOfIssues',
      complexType: null,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['isPrimitiveType','isNumeric','isInteger','isShort',
                     ],
      formType: null,
    },
    translations: {
      name: 'translations',
      complexType: translation,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['isContainer','isArray',
                    'isModel', ],
      formType: null,
    },
    rewards: {
      name: 'rewards',
      complexType: createRewardRequest,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['isContainer','isArray',
                    'isModel', ],
      formType: null,
    },
    constraints: {
      name: 'constraints',
      complexType: null,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['required','deprecated','isPrimitiveType','isContainer','isArray',
                    'isString', ],
      formType: null,
    },
    achievementDependencies: {
      name: 'achievementDependencies',
      complexType: dependantOn,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['isModel',
                     ],
      formType: null,
    },
    memberTagsFilter: {
      name: 'memberTagsFilter',
      complexType: dependantOn,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['isModel',
                     ],
      formType: null,
    },
    productTagsFilter: {
      name: 'productTagsFilter',
      complexType: dependantOn,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['isModel',
                     ],
      formType: null,
    },
    addConstraints: {
      name: 'addConstraints',
      complexType: null,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['isPrimitiveType','isContainer','isArray',
                    'isString', ],
      formType: null,
    },
    strategies: {
      name: 'strategies',
      complexType: achievementStrategies,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['isModel',
                     ],
      formType: null,
    }
  }
};
