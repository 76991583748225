// <<< DO NOT EDIT GENERATED FILES DIRECTLY! api-store-mutation-types.mustache >>>
export const API_REQUEST = 'API_REQUEST';


export const GETFILEOBJECTS_ITEM_REQ = 'GETFILEOBJECTS_ITEM_REQ';
export const GETFILEOBJECTS_ITEM_ERROR = 'GETFILEOBJECTS_ITEM_ERROR';
export const GETFILEOBJECTS_ITEM_SUCCESS = 'GETFILEOBJECTS_ITEM_SUCCESS'

export const UPDATE_FIELDS = 'UPDATE_FIELDS';
export const CLEAR_FILTER = 'CLEAR_FILTER';
export const CLEAR_MESSAGE = 'CLEAR_MESSAGE';

export const COPYFILEOBJECTS_REQ = 'COPYFILEOBJECTS_REQ';
export const COPYFILEOBJECTS_ERROR = 'COPYFILEOBJECTS_ERROR';
export const COPYFILEOBJECTS_SUCCESS = 'COPYFILEOBJECTS_SUCCESS';

export const CREATEFILEOBJECT_REQ = 'CREATEFILEOBJECT_REQ';
export const CREATEFILEOBJECT_ERROR = 'CREATEFILEOBJECT_ERROR';
export const CREATEFILEOBJECT_SUCCESS = 'CREATEFILEOBJECT_SUCCESS';

export const DELETEFILEOBJECTBYID_REQ = 'DELETEFILEOBJECTBYID_REQ';
export const DELETEFILEOBJECTBYID_ERROR = 'DELETEFILEOBJECTBYID_ERROR';
export const DELETEFILEOBJECTBYID_SUCCESS = 'DELETEFILEOBJECTBYID_SUCCESS';

export const DELETEFILEOBJECTSBYQUERY_REQ = 'DELETEFILEOBJECTSBYQUERY_REQ';
export const DELETEFILEOBJECTSBYQUERY_ERROR = 'DELETEFILEOBJECTSBYQUERY_ERROR';
export const DELETEFILEOBJECTSBYQUERY_SUCCESS = 'DELETEFILEOBJECTSBYQUERY_SUCCESS';

export const DOWNLOADFILEOBJECTS_REQ = 'DOWNLOADFILEOBJECTS_REQ';
export const DOWNLOADFILEOBJECTS_ERROR = 'DOWNLOADFILEOBJECTS_ERROR';
export const DOWNLOADFILEOBJECTS_SUCCESS = 'DOWNLOADFILEOBJECTS_SUCCESS';

export const GETFILEOBJECTS_REQ = 'GETFILEOBJECTS_REQ';
export const GETFILEOBJECTS_ERROR = 'GETFILEOBJECTS_ERROR';
export const GETFILEOBJECTS_SUCCESS = 'GETFILEOBJECTS_SUCCESS';

export const GETFILEOBJECTSBYQUERY_REQ = 'GETFILEOBJECTSBYQUERY_REQ';
export const GETFILEOBJECTSBYQUERY_ERROR = 'GETFILEOBJECTSBYQUERY_ERROR';
export const GETFILEOBJECTSBYQUERY_SUCCESS = 'GETFILEOBJECTSBYQUERY_SUCCESS';

export const SAVETEMPLATES_REQ = 'SAVETEMPLATES_REQ';
export const SAVETEMPLATES_ERROR = 'SAVETEMPLATES_ERROR';
export const SAVETEMPLATES_SUCCESS = 'SAVETEMPLATES_SUCCESS';

export const UPDATEFILEOBJECTS_REQ = 'UPDATEFILEOBJECTS_REQ';
export const UPDATEFILEOBJECTS_ERROR = 'UPDATEFILEOBJECTS_ERROR';
export const UPDATEFILEOBJECTS_SUCCESS = 'UPDATEFILEOBJECTS_SUCCESS';

export const UPLOADFILEOBJECTS_REQ = 'UPLOADFILEOBJECTS_REQ';
export const UPLOADFILEOBJECTS_ERROR = 'UPLOADFILEOBJECTS_ERROR';
export const UPLOADFILEOBJECTS_SUCCESS = 'UPLOADFILEOBJECTS_SUCCESS';

