import * as types from './mutation-types';
import * as _ from 'lodash';
import {
  handleErrorResponse,
  handleSuccessResponse
} from "../../../utils/ZiqniAdminApiHandler";

import logEventResponseFields from '../../../generated/ziqni/fields/LogEventResponseZq';

const mutations = {
    [types.API_REQUEST](state) {
        state.loading = true;
        state.loaded = false;
    },
    [types.CLEAR_MESSAGE](state){
        state.message = null;
    },

    [types.UPDATE_FIELDS](state, data) {
        state.hiddenFields = data._hiddenFields;
        state.fields = _.difference(state.originalFields, state.hiddenFields);
        localStorage.setItem('hiddenLogEventsKey', JSON.stringify(state.hiddenFields));
    },

    [types.CLEAR_FILTER](state) {
        state.fields = _.difference(
            state.originalFields,
            state.originHiddenFields
        );
        state.hiddenFields = state.originHiddenFields;
        localStorage.removeItem('hiddenLogEventsKey');
    },

    //LogEventResponse
    [types.GETLOGEVENTSBYQUERY_SUCCESS](state, response) {
      handleSuccessResponse(types.GETLOGEVENTSBYQUERY_SUCCESS,logEventResponseFields, state, response);
    },

    //LogEventResponse
    [types.GETLOGEVENTSBYQUERY_ERROR](state, error) {
      handleErrorResponse(types.GETLOGEVENTSBYQUERY_ERROR, logEventResponseFields, state, error);
    },
};

export default mutations;
