export const connections = state => state.connections;
export const connection = state => state.connection;
export const connectionType = state => state.connectionType;
export const transformers = state => state.transformers;
export const status = state => state.status;
export const fields = state => state.fields;
export const pages = state => state.pages;
export const loading = state => state.loading;
export const resultCount = state => state.resultCount;
export const originalFields = state => state.originalFields;
export const message = state => state.message;
export const success = state => state.success;
export const sortableFields = state => state.sortableFields
