import * as types from './mutation-types';
import updateFields from '@/utils/updateFields';
import { connectionsApiZiqni } from '@/generated/ziqni/api/ConnectionsApiZiqni';

export const handleFields = ({ commit, state }, data) => {
  updateFields(commit, state, data, 'hiddenConsumersKey');
};

// Clear consumers Message
export const handleClearMessage = ({ commit }) => {
  commit(types.CLEAR_MESSAGE);
};

// clear filter
export const handleClearFilter = ({ commit }) => {
  commit(types.CLEAR_CONSUMERS_FILTER);
};

//CONSUMERS - CONSUMERS
export const handleConsumersChanges = ({ commit }, data) => {
  commit('SET_CONSUMERS', data);
};

export const handleGetConnectionsByQuery = ({ commit }, payload) => {
  const { queryRequest } = payload;

  commit(types.API_REQUEST);
  let opts = {
    body: queryRequest
  };

  return connectionsApiZiqni
    .getConnectionsByQuery(
      opts
    )
    .then((response) => {
      commit(types.GETSQSCONNECTIONSBYQUERY_SUCCESS, { response });
      return response.results;
    })
    .catch((e) => {
      commit(types.GETSQSCONNECTIONSBYQUERY_ERROR, e);
      return [];
    });
};

export const handleDeleteConnections = ({ commit }, payload) => {
  const { idArray } = payload;

  commit(types.API_REQUEST);
  let opts = {
    id: idArray
  };

  return connectionsApiZiqni
    .deleteConnections(
      opts
    )
    .then((response) => {
      commit(types.DELETESQSCONNECTIONS_SUCCESS, { response });
      return response.results;
    })
    .catch((e) => {
      commit(types.DELETESQSCONNECTIONS_ERROR, e);
      return [];
    });
};

/**
 * ApiResponse from backend.
 * POST updateConnectionsState and return [ApiResponse]
 * vars: [
 *    [[body]] UpdateConnectionStateRequest[ opts, isArray, isBodyParam, ]]
 */
export const handleUpdateConnectionsState = ({ commit }, payload) => {
  const { updateConnectionStateRequestArray } = payload;

  commit(types.API_REQUEST);
  let opts = {
    body: updateConnectionStateRequestArray
  };

  return connectionsApiZiqni
      .updateConnectionsState(
          opts
      )
      .then((response) => {
        commit(types.UPDATECONNECTIONSSTATE_SUCCESS, { response });
        return response.results;
      })
      .catch((e) => {
        commit(types.UPDATECONNECTIONSSTATE_ERROR, e);
        return [];
      });
};
