/**
 * <<< DO NOT EDIT GENERATED FILES DIRECTLY! Generated using model-store-fields.mustache >>>
 *
 * The annotations for the config is made on the OAS schema model using these x-annotations
 * Root x-annotations:
 *
 *  [Base Model]
 *
 *    x-vue-list: true [To generate a table view]
 *    x-vue-show: member [To generate a preview view]
 *    x-vue-getter: languages [The name to use for the getter]
 *    x-vue-section: settings [The section this belongs to if it not in the root of the nav structure]
 *    x-vue-csv-supported: true [If the service supports csv uploads]
 *    x-vue-sort-by: name [Set the default field to sort by]
 *    x-vue-sort-order: Asc [Change the default sort to Asc or Desc]
 *    x-vue-limit: 10 [Change the default limit]
 *    x-vue-ops:
 *      - Create
 *      - Update
 *
 *  [Create Model]
 *    x-vue-create: member
 *    x-vue-creator: members
 *
 *  [Update Model]
 *    x-vue-update: member
 *    x-vue-updater: members
 *
 * Field annotations:
 *
 *  [Base Model]
 *    x-vue-as-string: true
 *    x-vue-translatable: true
 *    x-vue-hidden: true
 *    x-vue-search-all: true
 *    x-vue-search-field: true
 *    x-vue-table-getter: getMetaData(item.metadata)
 *    x-vue-disaply-order: 1001 [a value from 1000 to 1999]
 *
 *    x-vue-custom-fields: true
 *    x-vue-tags: true
 *    x-vue-metadata: true
 *    x-vue-constraint: true
 *
 *  [Create Model]
 *    x-vue-form-type: CUSTOM [Can be TEXT <<default>>, NUMBER, META, CUSTOM, TAG, TEXTAREA, CREATED, 'NAMEKEY, showKey: true']
 *
 *  [Update Model]
 *    x-vue-form-type: CUSTOM [Can be TEXT <<default>>, NUMBER, META, CUSTOM, TAG, TEXTAREA, CREATED, 'NAMEKEY, showKey: true']
 *
 */

import baseFields from '@/generated/ziqni/fields/FileObjectsResponseZq';
import createFields from '@/generated/ziqni/fields/CreateFileObjectRequestZq';
import updateFields from '@/generated/ziqni/fields/UpdateFileObjectRequestZq';

export default {
  createFields,
  updateFields,
  baseFields
};