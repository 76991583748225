// <<< DO NOT EDIT GENERATED FILES DIRECTLY! api-store-mutations.mustache >>>
import * as types from './mutation-types';
import * as _ from 'lodash';
import {
  handleErrorResponse, handleItemErrorResponse,
  handleItemSuccessResponse,
  handleSuccessResponse
} from "../../../../../utils/ZiqniAdminApiHandler";
import apiResponseFields from '../../../fields/ApiResponseZq.js';
import cloneContestResponseFields from '../../../fields/CloneContestResponseZq.js';
import contestLeaderboardResponseFields from '../../../fields/ContestLeaderboardResponseZq.js';
import contestResponseFields from '../../../fields/ContestResponseZq.js';
import createContestForCompetitionRequestFields from '../../../fields/CreateContestForCompetitionRequestZq.js';
import entrantResponseFields from '../../../fields/EntrantResponseZq.js';
import queryRequestFields from '../../../fields/QueryRequestZq.js';
import updateContestRequestFields from '../../../fields/UpdateContestRequestZq.js';
import updateContestStateRequestFields from '../../../fields/UpdateContestStateRequestZq.js';

const mutations = {
    [types.API_REQUEST](state) {
        state.loading = true;
        state.loaded = false;
    },
    [types.CLEAR_MESSAGE](state){
        state.message = null;
    },

    [types.UPDATE_FIELDS](state, data) {
        state.hiddenFields = data._hiddenFields;
        state.fields = _.difference(state.originalFields, state.hiddenFields);
        localStorage.setItem('hiddenContestsKey', JSON.stringify(state.hiddenFields));
    },

    [types.CLEAR_FILTER](state) {
        state.fields = _.difference(
            state.originalFields,
            state.originHiddenFields
        );
        state.hiddenFields = state.originHiddenFields;
        localStorage.removeItem('hiddenContestsKey');
    },

    //ContestResponse
    [types.GETCONTESTS_ITEM_SUCCESS](state, response) {
      handleItemSuccessResponse(types.GETCONTESTS_ITEM_SUCCESS,contestResponseFields, state, response, 'contest');
    },

    //ContestResponse
    [types.GETCONTESTS_ERROR](state, error) {
      handleItemErrorResponse(types.GETCONTESTS_ITEM_ITEM_ERROR, contestResponseFields, state, error);
    },


    //ApiResponse
    [types.CREATECONTESTS_SUCCESS](state, response) {
      handleSuccessResponse(types.CREATECONTESTS_SUCCESS,apiResponseFields, state, response);
    },

    //ApiResponse
    [types.CREATECONTESTS_ERROR](state, error) {
      handleErrorResponse(types.CREATECONTESTS_ERROR, apiResponseFields, state, error);
    },

    //ContestResponse
    [types.GETCHILDRENOFCONTEST_SUCCESS](state, response) {
      handleSuccessResponse(types.GETCHILDRENOFCONTEST_SUCCESS,contestResponseFields, state, response);
    },

    //ContestResponse
    [types.GETCHILDRENOFCONTEST_ERROR](state, error) {
      handleErrorResponse(types.GETCHILDRENOFCONTEST_ERROR, contestResponseFields, state, error);
    },

    //ContestResponse
    [types.GETCONTESTS_SUCCESS](state, response) {
      handleSuccessResponse(types.GETCONTESTS_SUCCESS,contestResponseFields, state, response);
    },

    //ContestResponse
    [types.GETCONTESTS_ERROR](state, error) {
      handleErrorResponse(types.GETCONTESTS_ERROR, contestResponseFields, state, error);
    },

    //ContestResponse
    [types.GETCONTESTSBYQUERY_SUCCESS](state, response) {
      handleSuccessResponse(types.GETCONTESTSBYQUERY_SUCCESS,contestResponseFields, state, response);
    },

    //ContestResponse
    [types.GETCONTESTSBYQUERY_ERROR](state, error) {
      handleErrorResponse(types.GETCONTESTSBYQUERY_ERROR, contestResponseFields, state, error);
    },

    //ContestLeaderboardResponse
    [types.GETCONTESTSLEADERBOARDBYID_SUCCESS](state, response) {
      handleSuccessResponse(types.GETCONTESTSLEADERBOARDBYID_SUCCESS,contestLeaderboardResponseFields, state, response);
    },

    //ContestLeaderboardResponse
    [types.GETCONTESTSLEADERBOARDBYID_ERROR](state, error) {
      handleErrorResponse(types.GETCONTESTSLEADERBOARDBYID_ERROR, contestLeaderboardResponseFields, state, error);
    },

    //CloneContestResponse
    [types.GETCONTESTSTOCLONE_SUCCESS](state, response) {
      handleSuccessResponse(types.GETCONTESTSTOCLONE_SUCCESS,cloneContestResponseFields, state, response);
    },

    //CloneContestResponse
    [types.GETCONTESTSTOCLONE_ERROR](state, error) {
      handleErrorResponse(types.GETCONTESTSTOCLONE_ERROR, cloneContestResponseFields, state, error);
    },

    //EntrantResponse
    [types.GETLISTOFENTRANTSFORCONTEST_SUCCESS](state, response) {
      handleSuccessResponse(types.GETLISTOFENTRANTSFORCONTEST_SUCCESS,entrantResponseFields, state, response);
    },

    //EntrantResponse
    [types.GETLISTOFENTRANTSFORCONTEST_ERROR](state, error) {
      handleErrorResponse(types.GETLISTOFENTRANTSFORCONTEST_ERROR, entrantResponseFields, state, error);
    },

    //ContestResponse
    [types.GETPARENTSOFCONTEST_SUCCESS](state, response) {
      handleSuccessResponse(types.GETPARENTSOFCONTEST_SUCCESS,contestResponseFields, state, response);
    },

    //ContestResponse
    [types.GETPARENTSOFCONTEST_ERROR](state, error) {
      handleErrorResponse(types.GETPARENTSOFCONTEST_ERROR, contestResponseFields, state, error);
    },

    //ApiResponse
    [types.UPDATECONTEST_SUCCESS](state, response) {
      handleSuccessResponse(types.UPDATECONTEST_SUCCESS,apiResponseFields, state, response);
    },

    //ApiResponse
    [types.UPDATECONTEST_ERROR](state, error) {
      handleErrorResponse(types.UPDATECONTEST_ERROR, apiResponseFields, state, error);
    },

    //ApiResponse
    [types.UPDATECONTESTSSTATE_SUCCESS](state, response) {
      handleSuccessResponse(types.UPDATECONTESTSSTATE_SUCCESS,apiResponseFields, state, response);
    },

    //ApiResponse
    [types.UPDATECONTESTSSTATE_ERROR](state, error) {
      handleErrorResponse(types.UPDATECONTESTSSTATE_ERROR, apiResponseFields, state, error);
    },
};

export default mutations;
