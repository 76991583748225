// <<< DO NOT EDIT GENERATED FILES DIRECTLY! api-store-actions.mustache >>>
import { delay, cloneDeep } from 'lodash';
import * as types from './mutation-types';
import sdkApiConfig from '@/config/sdkApiConfig';
import updateFields from '@/utils/updateFields';
import { rewardsApiZiqni } from '@/generated/ziqni/api/RewardsApiZiqni';
import router from '@/router';

import apiResponseFields from '../../../fields/ApiResponseZq.js';
import createEntityRewardRequestFields from '../../../fields/CreateEntityRewardRequestZq.js';
import queryRequestFields from '../../../fields/QueryRequestZq.js';
import rewardResponseFields from '../../../fields/RewardResponseZq.js';
import updateEntityRewardRequestFields from '../../../fields/UpdateEntityRewardRequestZq.js';
import {handleRequest,handleRequestWithBody} from "../../../../../utils/ZiqniAdminApiHandler";

export const handleFields = ({ commit, state }, data) => {
  updateFields(commit, state, data, 'hiddenRewardsKey');
};

// Clear rewards Message
export const handleClearMessage = ({ commit }) => {
  commit(types.CLEAR_MESSAGE);
};

// clear filter
export const handleClearFilter = ({ commit }) => {
  commit(types.CLEAR_FILTER);
};

//REWARDS - REWARDS
export const handleRewardsChanges = ({ commit }, data) => {
  commit('SET_REWARDS', data);
};
/**
 * RewardResponse from backend.
 * GET getRewards and return [RewardResponse]
 * vars: [
 *    [[id]] id[ opts, isArray, isQueryParam]
 *    [[limit]] _limit[ opts, isInteger, isQueryParam]
 *    [[skip]] _skip[ opts, isInteger, isQueryParam]]
 */
export const handleGetRewards_item = ({ commit }, idArray, limit, skip) => {
  commit(types.API_REQUEST);
  let opts = { 
    id: idArray, 
    limit: limit, 
    skip: skip
  };

  return rewardsApiZiqni
    .getRewards(
      opts
    )
    .then((response) => {
      commit(types.GETREWARDS_ITEM_SUCCESS, { response });
      return response.results;
    })
    .catch((e) => {
      commit(types.GETREWARDS_ITEM_ERROR, e);
      return [];
    });
};

/**
 * ApiResponse from backend.
 * POST createRewards and return [ApiResponse]
 * vars: [
 *    [[body]] CreateEntityRewardRequest[ required, isArray, isBodyParam, ]]
 */
export const handleCreateRewards = ({ commit }, payload) => {
  const { createEntityRewardRequestArray } = payload;

  commit(types.API_REQUEST);
  

  return rewardsApiZiqni
    .createRewards(
      createEntityRewardRequestArray
    )
    .then((response) => {
      commit(types.CREATEREWARDS_SUCCESS, { response });
      return response.results;
    })
    .catch((e) => {
      commit(types.CREATEREWARDS_ERROR, e);
      return [];
    });
};

/**
 * ApiResponse from backend.
 * DELETE deleteRewards and return [ApiResponse]
 * vars: [
 *    [[id]] id[ opts, isArray, isQueryParam]]
 */
export const handleDeleteRewards = ({ commit }, payload) => {
  const { idArray } = payload;

  commit(types.API_REQUEST);
  let opts = { 
    id: idArray
  };

  return rewardsApiZiqni
    .deleteRewards(
      opts
    )
    .then((response) => {
      commit(types.DELETEREWARDS_SUCCESS, { response });
      return response.results;
    })
    .catch((e) => {
      commit(types.DELETEREWARDS_ERROR, e);
      return [];
    });
};

/**
 * ApiResponse from backend.
 * POST deleteRewardsByQuery and return [ApiResponse]
 * vars: [
 *    [[body]] QueryRequest[ opts, isBodyParam, ]]
 */
export const handleDeleteRewardsByQuery = ({ commit }, payload) => {
  const { queryRequest } = payload;

  commit(types.API_REQUEST);
  let opts = { 
    body: queryRequest
  };

  return rewardsApiZiqni
    .deleteRewardsByQuery(
      opts
    )
    .then((response) => {
      commit(types.DELETEREWARDSBYQUERY_SUCCESS, { response });
      return response.results;
    })
    .catch((e) => {
      commit(types.DELETEREWARDSBYQUERY_ERROR, e);
      return [];
    });
};

/**
 * RewardResponse from backend.
 * GET getRewards and return [RewardResponse]
 * vars: [
 *    [[id]] id[ opts, isArray, isQueryParam]
 *    [[limit]] _limit[ opts, isInteger, isQueryParam]
 *    [[skip]] _skip[ opts, isInteger, isQueryParam]]
 */
export const handleGetRewards = ({ commit }, payload) => {
  const { idArray, limit, skip } = payload;

  commit(types.API_REQUEST);
  let opts = { 
    id: idArray, 
    limit: limit, 
    skip: skip
  };

  return rewardsApiZiqni
    .getRewards(
      opts
    )
    .then((response) => {
      commit(types.GETREWARDS_SUCCESS, { response });
      return response.results;
    })
    .catch((e) => {
      commit(types.GETREWARDS_ERROR, e);
      return [];
    });
};

/**
 * RewardResponse from backend.
 * GET getRewardsByAchievementId and return [RewardResponse]
 * vars: [
 *    [[id]] id[ required, isString, isPathParam, ]
 *    [[limit]] _limit[ opts, isInteger, isQueryParam]
 *    [[skip]] _skip[ opts, isInteger, isQueryParam]]
 */
export const handleGetRewardsByAchievementId = ({ commit }, payload) => {
  const { id, limit, skip } = payload;

  commit(types.API_REQUEST);
  let opts = { 
    limit: limit, 
    skip: skip
  };

  return rewardsApiZiqni
    .getRewardsByAchievementId(
      id,opts
    )
    .then((response) => {
      commit(types.GETREWARDSBYACHIEVEMENTID_SUCCESS, { response });
      return response.results;
    })
    .catch((e) => {
      commit(types.GETREWARDSBYACHIEVEMENTID_ERROR, e);
      return [];
    });
};

/**
 * RewardResponse from backend.
 * GET getRewardsByContestId and return [RewardResponse]
 * vars: [
 *    [[id]] id[ required, isString, isPathParam, ]
 *    [[limit]] _limit[ opts, isInteger, isQueryParam]
 *    [[skip]] _skip[ opts, isInteger, isQueryParam]]
 */
export const handleGetRewardsByContestId = ({ commit }, payload) => {
  const { id, limit, skip } = payload;

  commit(types.API_REQUEST);
  let opts = { 
    limit: limit, 
    skip: skip
  };

  return rewardsApiZiqni
    .getRewardsByContestId(
      id,opts
    )
    .then((response) => {
      commit(types.GETREWARDSBYCONTESTID_SUCCESS, { response });
      return response.results;
    })
    .catch((e) => {
      commit(types.GETREWARDSBYCONTESTID_ERROR, e);
      return [];
    });
};

/**
 * RewardResponse from backend.
 * POST getRewardsByQuery and return [RewardResponse]
 * vars: [
 *    [[body]] QueryRequest[ opts, isBodyParam, ]]
 */
export const handleGetRewardsByQuery = ({ commit }, payload) => {
  const { queryRequest } = payload;

  commit(types.API_REQUEST);
  let opts = { 
    body: queryRequest
  };

  return rewardsApiZiqni
    .getRewardsByQuery(
      opts
    )
    .then((response) => {
      commit(types.GETREWARDSBYQUERY_SUCCESS, { response });
      return response.results;
    })
    .catch((e) => {
      commit(types.GETREWARDSBYQUERY_ERROR, e);
      return [];
    });
};

/**
 * ApiResponse from backend.
 * PUT updateRewards and return [ApiResponse]
 * vars: [
 *    [[body]] UpdateEntityRewardRequest[ required, isArray, isBodyParam, ]]
 */
export const handleUpdateRewards = ({ commit }, payload) => {
  const { updateEntityRewardRequestArray } = payload;

  commit(types.API_REQUEST);
  

  return rewardsApiZiqni
    .updateRewards(
      updateEntityRewardRequestArray
    )
    .then((response) => {
      commit(types.UPDATEREWARDS_SUCCESS, { response });
      return response.results;
    })
    .catch((e) => {
      commit(types.UPDATEREWARDS_ERROR, e);
      return [];
    });
};
