const Account = () => import('@/views/ziqni/messages/account')
const PreviewAccountMessage = () => import('@/views/ziqni/messages/account/PreviewAccountMessage')

export default   {
    path: 'account-messages',
    meta: {
        label: 'Messages'
    },
    component: {
        render(c) {
            return c('router-view')
        }
    },
    children: [
        {
            path: '/',
            name: 'Account',
            component: Account,
            meta: {
                label: 'Account',
            },
        },
        {
            path: ':id',
            name: 'PreviewAccountMessage',
            component: PreviewAccountMessage,
            meta: {
                label: 'PreviewAccountMessage',
            },
        },
    ],
}