import * as types from './mutation-types';
import * as _ from 'lodash';

const mutations = {
    [types.API_REQUEST](state) {
        state.loading = true;
        state.loaded = false;
    },
    [types.CLEAR_MESSAGE](state){
        state.message = null;
    },

    [types.GET_CONNECTIONS_SUCCESS](state, connections) {
        state.connections = connections.data.results;
        if (connections.data.results.length > 0) {
            const fields = Object.keys(connections.data.results[0]);

            var finalFields = ["select", "id","name","objectType","created","spaceName" ,"actions"];
            state.originalFields = _.cloneDeep(finalFields);
        }
        const _fields = _.difference(state.originalFields, state.hiddenFields);
        state.fields = _fields;
        state.pages = connections.pages;
        state.meta = connections.data.meta;
        state.resultCount = connections.data.meta.resultCount;
        state.loading = false;
        state.loaded = true;
    },

    [types.GET_CONNECTIONS_ERROR](state) {
        state.loading = false;
        state.loaded = true;
    },

    [types.GET_TRANSFORMERS_SUCCESS](state,data) {
        state.transformers = _.cloneDeep(data.results);
        state.loading = false;
        state.loaded = true;

    },

    [types.GET_TRANSFORMERS_ERROR](state, error) {
        state.loading = false;
        state.loaded = true;
        state.success = false;
        state.message = error;
    },

    // GET BY IDS
    [types.GET_CONNECTIONS_BY_IDS_SUCCESS](state, connection) {
        state.connection = connection.results;
        state.loading = false;
        state.loaded = true;
    },

    [types.GET_CONNECTIONS_BY_IDS_ERROR](state, error) {
        state.loading = false;
        state.loaded = true;
        state.success = false;
        state.message = error;
    },

    // GET BY ID
    [types.GET_CONNECTIONS_BY_ID_SUCCESS](state, connection) {
        state.connection = connection.results[0];
        state.loading = false;
        state.loaded = true;
    },

    [types.GET_CONNECTIONS_BY_ID_ERROR](state, error) {
        state.loading = false;
        state.loaded = true;
        state.success = false;
        state.message = error;
    },

    [types.CREATE_CONNECTION_SUCCESS](state) {
        state.loading = false;
        state.loaded = true;
        state.success = true;
        state.message = 'This Connection has been created successfully!';
    },

    [types.CREATE_CONNECTION_ERROR](state, error) {
        state.loading = false;
        state.loaded = true;
        state.success = false;
        state.message = error;
    },

    [types.UPDATE_CONNECTION_SUCCESS](state) {
        state.loading = false;
        state.loaded = true;
        state.success = true;
        state.message = 'This Connection has been updated successfully!';
    },

    [types.UPDATE_CONNECTION_ERROR](state, error) {
        state.loading = false;
        state.loaded = true;
        state.success = false;
        state.message = error;
    },

    [types.DELETE_CONNECTION_SUCCESS](state) {
        state.loading = false;
        state.loaded = true;
        state.success = true;
        state.message = 'Custom field deletion is successful!';
    },

    [types.DELETE_CONNECTION_ERROR](state, error) {
        state.loading = false;
        state.loaded = true;
        state.success = false;
        state.message = error;
    },

    [types.UPDATE_FIELDS](state, data) {
        state.hiddenFields = data._hiddenFields;
        state.fields = _.difference(state.originalFields, state.hiddenFields);
        localStorage.setItem('hiddenCustomFieldKey', JSON.stringify(state.hiddenFields));
    },

    [types.CLEAR_FILTER](state) {
        state.fields = _.difference(
            state.originalFields,
            state.originHiddenFields
        );
        state.hiddenFields = state.originHiddenFields;
        localStorage.removeItem('hiddenCustomFieldKey');
    },

    [types.SET_CONNECTION](state, data) {
        state.connections = data;
    },
    // Get Connections
    [types.CONNECTIONS_SUCCESS](state, data){
        state.meta = data.meta
        state.connections = data.results
        state.loading = false;
        state.loaded = true;
        state.resultCount = data.meta.resultCount
    },
    [types.CONNECTIONS_ERROR](state, error){
        state.error = error;
        state.loading = false;
        state.loaded = true;
    },
    [types.UPDATE_CONNECTION_STATUS_SUCCESS](state ,data){
        state.loading = false;
        state.loaded = true;
        state.success = true;
        state.status = data.status
        state.message = 'This Connection status has been updated successfully!';
    },

    [types.UPDATE_CONNECTION_STATUS_ERROR](state, error) {
        state.loading = false;
        state.loaded = true;
        state.success = false;
        state.message = error;
    },

    [types.CONNECTION_TYPE](state, data){
        state.connectionType = data;
    },
    [types.GET_TRANSFORMER_ID](state, data){
        state.transformerId = data;
    },


};

export default mutations;