import selectAllHeader from '@/directives/selectAllHeader';
import actionsHeader from '@/directives/actionsHeader';

require('./config');
import 'core-js/stable';
import Vue2TouchEvents from 'vue2-touch-events';
import Vue from 'vue';
import App from './App';
import router from './router';
import CoreuiVue from '@coreui/vue';
import { iconsSet as icons } from './assets/icons/icons.js';
import store from './store';

import './plugins/editor';
import './plugins/codeEditor';
import './plugins/select';
import './plugins/vjsoneditor';
import './plugins/rulesEngine';
import './plugins/wysiwyg';
import './config/formBuilderConfig';

import './assets/css/font-awesome.min.css';

//Register Global Components
import './shared/globalComponent';

// THEME PLUGIN
import ThemePlugin from './plugins/theme/src';
import { i18n } from './i18n';

//AUTH
import { config } from './config/keycloakConfig';
import { keycloak } from './helpers/keycloak';

Vue.config.performance = true;
Vue.use(CoreuiVue);
Vue.use(Vue2TouchEvents);
Vue.use(require('vue-cookies'))
// THEME PLUGIN
Vue.use(ThemePlugin, { theme: 'main', store });
Vue.prototype.$log = console.log.bind(console);
Vue.directive('theme-select-all', selectAllHeader);
Vue.directive('theme-header-actions', actionsHeader);

keycloak
  .init({
      onLoad: config.onLoad,
      checkLoginIframe:config.checkLoginIframe
  })
  .then((auth) => {
    if (!auth) {
      window.location.reload();
      Vue.$cookies.remove('ziqni-aapi-token')
      localStorage.removeItem('vue-token');
      localStorage.removeItem('vue-refresh-token');
      localStorage.removeItem('vue-token-parsed');
    } else {
      Vue.$cookies.set('ziqni-aapi-token', keycloak.token, '1h', '/file-objects', '', true, 'Strict');
      localStorage.setItem('vue-token', keycloak.token);
      localStorage.setItem('vue-username', keycloak.idTokenParsed.email);
      localStorage.setItem('vue-refresh-token', keycloak.refreshToken);

      localStorage.setItem('zq-account-id', keycloak.idTokenParsed.account_id);
      localStorage.setItem('zq-space-name', keycloak.idTokenParsed.space_name);
    }

    new Vue({
      el: '#app',
      router,
      store,
      icons,
      template: '<App/>',
      i18n,

      components: {
        App,
      },
    });

    setInterval(() => {
      keycloak
        .updateToken(70)
        .then((refreshed) => {
          if (refreshed) {
            console.log(`Token refreshed ${refreshed}`);
            localStorage.setItem('vue-token', keycloak.token);
            localStorage.setItem('vue-refresh-token', keycloak.refreshToken);
          } else {
            console.log(
              'Token not refreshed, valid for ' +
                Math.round(
                  keycloak.tokenParsed.exp +
                    keycloak.timeSkew -
                    new Date().getTime() / 1000
                ) +
                ' seconds'
            );
          }
        })
        .catch((e) => {
          console.log(`Failed to refresh token ${e}`);
        });
    }, 60000);
  })
  .catch((e) => {
    console.error('Authenticated Failed');
    if (typeof e === 'object' && e.error_description) {
      e.error_description = e.error_description.replaceAll('+', ' ');
    }
    console.error(e);
  });
