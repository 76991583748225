import * as types from './mutation-types';
import * as _ from 'lodash';

const mutations = {
    [types.API_REQUEST](state) {
        state.loading = true;
        state.loaded = false;
    },
    [types.CLEAR_MESSAGE](state){
        state.message = null;
    },

    [types.GET_MESSAGES_SUCCESS](state, messages) {
        state.messages = messages.data.results;
        if (messages.data.results.length > 0) {
            const fields = Object.keys(messages.data.results[0]);
            var finalFields = ["select", "id","status","memberGroup", "members", "messageType", "subject",
                "body",  "scheduling","translations","translatableFields","constraints","actions"]
            state.originalFields = _.cloneDeep(finalFields);
        }
        const _fields = _.difference(state.originalFields, state.hiddenFields);
        state.fields = _fields;
        state.pages = messages.pages;
        state.meta = messages.data.meta;
        state.resultCount = messages.data.meta.resultCount;
        state.loading = false;
        state.loaded = true;
    },

    [types.GET_MESSAGES_ERROR](state) {
        state.loading = false;
        state.loaded = true;
    },

    [types.GET_NOTIFICATION_MESSAGES_SUCCESS](state, messages) {
        state.messages = messages.data.results;
        if (messages.data.results.length > 0) {
            let finalFields = ["select", "id","status","memberGroup", "members", "messageType", "subject",
                "body",  "scheduling","translations","translatableFields","constraints","actions"]
            state.originalFields = _.cloneDeep(finalFields);
        }
        state.fields = _.difference(state.originalFields, state.hiddenFields);
        state.pages = messages.pages;
        state.meta = messages.data.meta;
        state.resultCount = messages.data.meta.resultCount;
        state.loading = false;
        state.loaded = true;
    },

    [types.GET_NOTIFICATION_MESSAGES_ERROR](state) {
        state.loading = false;
        state.loaded = true;
    },

    // GET BY IDS
    [types.GET_MESSAGES_BY_IDS_SUCCESS](state, messages) {
        state.messages = messages.results;
        state.loading = false;
        state.loaded = true;
    },

    [types.GET_MESSAGES_BY_IDS_ERROR](state, error) {
        state.loading = false;
        state.loaded = true;
        state.success = false;
        state.message = error;
    },

    // GET BY ID
    [types.GET_MESSAGE_BY_ID_SUCCESS](state, message) {
        state.singleMessage= message.results[0];
        state.loading = false;
        state.loaded = true;
    },

    [types.GET_MESSAGE_BY_ID_ERROR](state, error) {
        state.loading = false;
        state.loaded = true;
        state.success = false;
        state.message = error;
    },

    [types.GET_NOTIFICATION_MESSAGE_BY_ID_SUCCESS](state, message) {
        state.singleMessage= message.results[0];
        state.loading = false;
        state.loaded = true;
    },

    [types.GET_NOTIFICATION_MESSAGE_BY_ID_ERROR](state, error) {
        state.loading = false;
        state.loaded = true;
        state.success = false;
        state.message = error;
    },

    [types.CREATE_MESSAGE_SUCCESS](state) {
        state.loading = false;
        state.loaded = true;
        state.success = true;
        state.message = 'Message has been added successfully!';
        console.log(state.message);
    },

    [types.CREATE_MESSAGE_ERROR](state, error) {
        state.loading = false;
        state.loaded = true;
        state.success = false;
        state.message = error;
    },

    [types.UPDATE_MESSAGE_SUCCESS](state) {
        state.loading = false;
        state.loaded = true;
        state.success = true;
        state.message = 'This Message has been updated successfully!';
    },

    [types.UPDATE_MESSAGE_ERROR](state, error) {
        state.loading = false;
        state.loaded = true;
        state.success = false;
        state.message = error;
    },

    [types.DELETE_MESSAGE_SUCCESS](state) {
        state.loading = false;
        state.loaded = true;
        state.success = true;
        state.message = 'Message deletion is successful!';
    },

    [types.DELETE_MESSAGE_ERROR](state, error) {
        state.loading = false;
        state.loaded = true;
        state.success = false;
        state.message = error;
    },

    [types.UPDATE_FIELDS](state, data) {
        state.hiddenFields = data._hiddenFields;
        state.fields = _.difference(state.originalFields, state.hiddenFields);
        localStorage.setItem('hiddenCollaboratorsKey', JSON.stringify(state.hiddenFields));
    },

    [types.CLEAR_FILTER](state) {
        state.fields = _.difference(
            state.originalFields,
            state.originHiddenFields
        );
        state.hiddenFields = state.originHiddenFields;
        localStorage.removeItem('hiddenCollaboratorsKey');
    },

    [types.SET_MESSAGE](state, data) {
        state.messages = data;
    },
    // Get API KEYS
    [types.MESSAGES_SUCCESS](state, data){
        state.meta = data.meta
        state.messages = data.results
        state.loading = false;
        state.loaded = true;
        state.resultCount = data.meta.resultCount
    },
    [types.MESSAGES_ERROR](state, error){
        state.error = error;
        state.loading = false;
        state.loaded = true;
    },

};

export default mutations;