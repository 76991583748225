import * as types from './mutation-types';

import {keycloak} from "@/helpers/keycloak";
import updateFields from "@/utils/updateFields";


// Get Rewards (POST method)
export const handleGetUser = async ({commit}) => {
  commit(types.API_REQUEST);

  try {
      const data = await keycloak.loadUserInfo();
      commit(types.GET_USER_SUCCESS, {data});
      return data
  } catch (e) {
      commit(types.GET_USER_ERROR, e);
      return []
  }
};

// Update Fields
export const handleFields = ({commit, state}, data) => {
  updateFields(commit, state, data, 'hiddenRewardsKey');
};

// clear filter
export const handleClearFilter = ({commit}) => {
  commit(types.CLEAR_FILTER);
};

// Clear Events Message
export const handleClearMessage = ({commit}) => {
  commit(types.CLEAR_MESSAGE)
};