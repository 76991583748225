// <<< DO NOT EDIT GENERATED FILES DIRECTLY! model-store-fields-all.mustache >>>


import collaboratorAllOf from './CollaboratorAllOfZq';
import collaboratorRolesAvailable from './CollaboratorRolesAvailableZq';

const collaborator = this;

export default {
  objectType: 'Collaborator',
  allowableValuesKeys: [],
  constraints: [],
  getter: 'collaborators',
  hiddenFields: ['roles',],
  searchableFields: ['email','roles','lastName','firstName',], 
  stringBuilderFields: ['email',],
  operations: ['Create','Update'], 
  searchableAllFields: ['email','roles','lastName','firstName',],
  translatableFields: [],
  sortableFields: [],
  customFieldsFieldName: [],
  metadataFieldName: [],
  tagFieldName: [],
  entityLinksFrom: [],

  route: {
    list: 'ListCollaborator',
    preview: 'PreviewCollaborator',
    create: 'CreateCollaborator',
    update: 'UpdateCollaborator',
  },
  skip: 0,
  limit: 10,
  sortBy: [
    {
      "queryField": "lastName",
      "order": "Asc"
    }
  ],
  fieldList: ['email','roles','lastName','firstName','availableRoles'],
  fields: { 
    email: {
      name: 'email',
      complexType: null,
      displayOrder: 1001,
      allowableValuesKeys: [],
      constraints: ['required','isPrimitiveType','isString',
                     ],
      formType: null,
    },
    roles: {
      name: 'roles',
      complexType: null,
      displayOrder: 1004,
      allowableValuesKeys: [],
      constraints: ['isPrimitiveType','isContainer','isArray',
                    'isString', ],
      formType: null,
    },
    lastName: {
      name: 'lastName',
      complexType: null,
      displayOrder: 1003,
      allowableValuesKeys: [],
      constraints: ['isPrimitiveType','isString',
                     ],
      formType: null,
    },
    firstName: {
      name: 'firstName',
      complexType: null,
      displayOrder: 1002,
      allowableValuesKeys: [],
      constraints: ['isPrimitiveType','isString',
                     ],
      formType: null,
    },
    availableRoles: {
      name: 'availableRoles',
      complexType: collaboratorRolesAvailable,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['isModel',
                     ],
      formType: null,
    }
  }
};
