// <<< DO NOT EDIT GENERATED FILES DIRECTLY! model-store-fields-all.mustache >>>


import actionTypeAdjustmentFactor from './ActionTypeAdjustmentFactorZq';

const productAllOf = this;

export default {
  objectType: 'Product_allOf',
  allowableValuesKeys: [],
  constraints: ['isMap',],
  getter: null,
  hiddenFields: ['description','actionTypeAdjustmentFactors',],
  searchableFields: ['name','description','adjustmentFactor','productRefId',], 
  searchableAllFields: ['name','description','productRefId',],
  translatableFields: ['name','description',],
  sortableFields: ['name','description','adjustmentFactor','productRefId',],
  customFieldsFieldName: [],
  metadataFieldName: [],
  tagFieldName: [],
  entityLinksFrom: [],

  route: {
    
    
  },
  fieldList: ['name','description','adjustmentFactor','productRefId','actionTypeAdjustmentFactors'],
  fields: { 
    name: {
      name: 'name',
      complexType: null,
      displayOrder: 1001,
      allowableValuesKeys: [],
      constraints: ['required','isPrimitiveType','isString',
                     ],
      formType: null,
    },
    description: {
      name: 'description',
      complexType: null,
      displayOrder: 1002,
      allowableValuesKeys: [],
      constraints: ['isPrimitiveType','isString',
                     ],
      formType: 'TEXTAREA',
    },
    adjustmentFactor: {
      name: 'adjustmentFactor',
      complexType: null,
      displayOrder: 1003,
      allowableValuesKeys: [],
      constraints: ['required','isPrimitiveType','isNumeric','isDouble',
                     ],
      formType: null,
    },
    productRefId: {
      name: 'productRefId',
      complexType: null,
      displayOrder: 1000,
      allowableValuesKeys: [],
      constraints: ['required','isPrimitiveType','isString',
                     ],
      formType: null,
    },
    actionTypeAdjustmentFactors: {
      name: 'actionTypeAdjustmentFactors',
      complexType: actionTypeAdjustmentFactor,
      displayOrder: 1004,
      allowableValuesKeys: [],
      constraints: ['isContainer','isArray',
                    'isModel', ],
      formType: null,
    }
  }
};
