// <<< DO NOT EDIT GENERATED FILES DIRECTLY! api-store-actions.mustache >>>
import { delay, cloneDeep } from 'lodash';
import * as types from './mutation-types';
import sdkApiConfig from '@/config/sdkApiConfig';
import updateFields from '@/utils/updateFields';
import { accountMessagesApiZiqni } from '@/generated/ziqni/api/AccountMessagesApiZiqni';
import router from '@/router';

import accountMessageResponseFields from '../../../fields/AccountMessageResponseZq.js';
import apiResponseFields from '../../../fields/ApiResponseZq.js';
import queryRequestFields from '../../../fields/QueryRequestZq.js';
import updateMessageStatusRequestFields from '../../../fields/UpdateMessageStatusRequestZq.js';
import {handleRequest,handleRequestWithBody} from "../../../../../utils/ZiqniAdminApiHandler";

export const handleFields = ({ commit, state }, data) => {
  updateFields(commit, state, data, 'hiddenAccountMessagesKey');
};

// Clear accountMessages Message
export const handleClearMessage = ({ commit }) => {
  commit(types.CLEAR_MESSAGE);
};

// clear filter
export const handleClearFilter = ({ commit }) => {
  commit(types.CLEAR_FILTER);
};

//ACCOUNTMESSAGES - ACCOUNTMESSAGES
export const handleAccountMessagesChanges = ({ commit }, data) => {
  commit('SET_ACCOUNTMESSAGES', data);
};
/**
 * AccountMessageResponse from backend.
 * GET getAccountMessages and return [AccountMessageResponse]
 * vars: [
 *    [[id]] id[ opts, isArray, isQueryParam]
 *    [[limit]] _limit[ opts, isInteger, isQueryParam]
 *    [[skip]] _skip[ opts, isInteger, isQueryParam]]
 */
export const handleGetAccountMessages_item = ({ commit }, idArray, limit, skip) => {
  commit(types.API_REQUEST);
  let opts = { 
    id: idArray, 
    limit: limit, 
    skip: skip
  };

  return accountMessagesApiZiqni
    .getAccountMessages(
      opts
    )
    .then((response) => {
      commit(types.GETACCOUNTMESSAGES_ITEM_SUCCESS, { response });
      return response.results;
    })
    .catch((e) => {
      commit(types.GETACCOUNTMESSAGES_ITEM_ERROR, e);
      return [];
    });
};

/**
 * ApiResponse from backend.
 * DELETE deleteAccountMessages and return [ApiResponse]
 * vars: [
 *    [[id]] id[ opts, isArray, isQueryParam]]
 */
export const handleDeleteAccountMessages = ({ commit }, payload) => {
  const { idArray } = payload;

  commit(types.API_REQUEST);
  let opts = { 
    id: idArray
  };

  return accountMessagesApiZiqni
    .deleteAccountMessages(
      opts
    )
    .then((response) => {
      commit(types.DELETEACCOUNTMESSAGES_SUCCESS, { response });
      return response.results;
    })
    .catch((e) => {
      commit(types.DELETEACCOUNTMESSAGES_ERROR, e);
      return [];
    });
};

/**
 * ApiResponse from backend.
 * POST deleteAccountMessagesByQuery and return [ApiResponse]
 * vars: [
 *    [[body]] QueryRequest[ opts, isBodyParam, ]]
 */
export const handleDeleteAccountMessagesByQuery = ({ commit }, payload) => {
  const { queryRequest } = payload;

  commit(types.API_REQUEST);
  let opts = { 
    body: queryRequest
  };

  return accountMessagesApiZiqni
    .deleteAccountMessagesByQuery(
      opts
    )
    .then((response) => {
      commit(types.DELETEACCOUNTMESSAGESBYQUERY_SUCCESS, { response });
      return response.results;
    })
    .catch((e) => {
      commit(types.DELETEACCOUNTMESSAGESBYQUERY_ERROR, e);
      return [];
    });
};

/**
 * AccountMessageResponse from backend.
 * GET getAccountMessages and return [AccountMessageResponse]
 * vars: [
 *    [[id]] id[ opts, isArray, isQueryParam]
 *    [[limit]] _limit[ opts, isInteger, isQueryParam]
 *    [[skip]] _skip[ opts, isInteger, isQueryParam]]
 */
export const handleGetAccountMessages = ({ commit }, payload) => {
  const { idArray, limit, skip } = payload;

  commit(types.API_REQUEST);
  let opts = { 
    id: idArray, 
    limit: limit, 
    skip: skip
  };

  return accountMessagesApiZiqni
    .getAccountMessages(
      opts
    )
    .then((response) => {
      commit(types.GETACCOUNTMESSAGES_SUCCESS, { response });
      return response.results;
    })
    .catch((e) => {
      commit(types.GETACCOUNTMESSAGES_ERROR, e);
      return [];
    });
};

/**
 * AccountMessageResponse from backend.
 * POST getAccountMessagesByQuery and return [AccountMessageResponse]
 * vars: [
 *    [[body]] QueryRequest[ opts, isBodyParam, ]]
 */
export const handleGetAccountMessagesByQuery = ({ commit }, payload) => {
  const { queryRequest } = payload;

  commit(types.API_REQUEST);
  let opts = { 
    body: queryRequest
  };

  return accountMessagesApiZiqni
    .getAccountMessagesByQuery(
      opts
    )
    .then((response) => {
      commit(types.GETACCOUNTMESSAGESBYQUERY_SUCCESS, { response });
      return response.results;
    })
    .catch((e) => {
      commit(types.GETACCOUNTMESSAGESBYQUERY_ERROR, e);
      return [];
    });
};

/**
 * ApiResponse from backend.
 * PUT updateAccountMessagesStatus and return [ApiResponse]
 * vars: [
 *    [[body]] UpdateMessageStatusRequest[ required, isArray, isBodyParam, ]]
 */
export const handleUpdateAccountMessagesStatus = ({ commit }, payload) => {
  const { updateMessageStatusRequestArray } = payload;

  commit(types.API_REQUEST);
  

  return accountMessagesApiZiqni
    .updateAccountMessagesStatus(
      updateMessageStatusRequestArray
    )
    .then((response) => {
      commit(types.UPDATEACCOUNTMESSAGESSTATUS_SUCCESS, { response });
      return response.results;
    })
    .catch((e) => {
      commit(types.UPDATEACCOUNTMESSAGESSTATUS_ERROR, e);
      return [];
    });
};
