// <<< DO NOT EDIT GENERATED FILES DIRECTLY! api-store-actions.mustache >>>
import { delay, cloneDeep } from 'lodash';
import * as types from './mutation-types';
import sdkApiConfig from '@/config/sdkApiConfig';
import updateFields from '@/utils/updateFields';
import { notificationsApiZiqni } from '@/generated/ziqni/api/NotificationsApiZiqni';
import router from '@/router';

import apiResponseFields from '../../../fields/ApiResponseZq.js';
import createNotificationRequestFields from '../../../fields/CreateNotificationRequestZq.js';
import notificationResponseFields from '../../../fields/NotificationResponseZq.js';
import queryRequestFields from '../../../fields/QueryRequestZq.js';
import updateNotificationStatusRequestFields from '../../../fields/UpdateNotificationStatusRequestZq.js';
import {handleRequest,handleRequestWithBody} from "../../../../../utils/ZiqniAdminApiHandler";

export const handleFields = ({ commit, state }, data) => {
  updateFields(commit, state, data, 'hiddenNotificationsKey');
};

// Clear notifications Message
export const handleClearMessage = ({ commit }) => {
  commit(types.CLEAR_MESSAGE);
};

// clear filter
export const handleClearFilter = ({ commit }) => {
  commit(types.CLEAR_FILTER);
};

//NOTIFICATIONS - NOTIFICATIONS
export const handleNotificationsChanges = ({ commit }, data) => {
  commit('SET_NOTIFICATIONS', data);
};
/**
 * NotificationResponse from backend.
 * GET getNotifications and return [NotificationResponse]
 * vars: [
 *    [[id]] id[ opts, isArray, isQueryParam]
 *    [[limit]] _limit[ opts, isInteger, isQueryParam]
 *    [[skip]] _skip[ opts, isInteger, isQueryParam]]
 */
export const handleGetNotifications_item = ({ commit }, idArray, limit, skip) => {
  commit(types.API_REQUEST);
  let opts = { 
    id: idArray, 
    limit: limit, 
    skip: skip
  };

  return notificationsApiZiqni
    .getNotifications(
      opts
    )
    .then((response) => {
      commit(types.GETNOTIFICATIONS_ITEM_SUCCESS, { response });
      return response.results;
    })
    .catch((e) => {
      commit(types.GETNOTIFICATIONS_ITEM_ERROR, e);
      return [];
    });
};

/**
 * ApiResponse from backend.
 * POST createNotifications and return [ApiResponse]
 * vars: [
 *    [[body]] CreateNotificationRequest[ required, isArray, isBodyParam, ]]
 */
export const handleCreateNotifications = ({ commit }, payload) => {
  const { createNotificationRequestArray } = payload;

  commit(types.API_REQUEST);
  

  return notificationsApiZiqni
    .createNotifications(
      createNotificationRequestArray
    )
    .then((response) => {
      commit(types.CREATENOTIFICATIONS_SUCCESS, { response });
      return response.results;
    })
    .catch((e) => {
      commit(types.CREATENOTIFICATIONS_ERROR, e);
      return [];
    });
};

/**
 * ApiResponse from backend.
 * DELETE deleteNotifications and return [ApiResponse]
 * vars: [
 *    [[id]] id[ opts, isArray, isQueryParam]]
 */
export const handleDeleteNotifications = ({ commit }, payload) => {
  const { idArray } = payload;

  commit(types.API_REQUEST);
  let opts = { 
    id: idArray
  };

  return notificationsApiZiqni
    .deleteNotifications(
      opts
    )
    .then((response) => {
      commit(types.DELETENOTIFICATIONS_SUCCESS, { response });
      return response.results;
    })
    .catch((e) => {
      commit(types.DELETENOTIFICATIONS_ERROR, e);
      return [];
    });
};

/**
 * ApiResponse from backend.
 * POST deleteNotificationsByQuery and return [ApiResponse]
 * vars: [
 *    [[body]] QueryRequest[ opts, isBodyParam, ]]
 */
export const handleDeleteNotificationsByQuery = ({ commit }, payload) => {
  const { queryRequest } = payload;

  commit(types.API_REQUEST);
  let opts = { 
    body: queryRequest
  };

  return notificationsApiZiqni
    .deleteNotificationsByQuery(
      opts
    )
    .then((response) => {
      commit(types.DELETENOTIFICATIONSBYQUERY_SUCCESS, { response });
      return response.results;
    })
    .catch((e) => {
      commit(types.DELETENOTIFICATIONSBYQUERY_ERROR, e);
      return [];
    });
};

/**
 * NotificationResponse from backend.
 * GET getNotifications and return [NotificationResponse]
 * vars: [
 *    [[id]] id[ opts, isArray, isQueryParam]
 *    [[limit]] _limit[ opts, isInteger, isQueryParam]
 *    [[skip]] _skip[ opts, isInteger, isQueryParam]]
 */
export const handleGetNotifications = ({ commit }, payload) => {
  const { idArray, limit, skip } = payload;

  commit(types.API_REQUEST);
  let opts = { 
    id: idArray, 
    limit: limit, 
    skip: skip
  };

  return notificationsApiZiqni
    .getNotifications(
      opts
    )
    .then((response) => {
      commit(types.GETNOTIFICATIONS_SUCCESS, { response });
      return response.results;
    })
    .catch((e) => {
      commit(types.GETNOTIFICATIONS_ERROR, e);
      return [];
    });
};

/**
 * NotificationResponse from backend.
 * POST getNotificationsByQuery and return [NotificationResponse]
 * vars: [
 *    [[body]] QueryRequest[ opts, isBodyParam, ]]
 */
export const handleGetNotificationsByQuery = ({ commit }, payload) => {
  const { queryRequest } = payload;

  commit(types.API_REQUEST);
  let opts = { 
    body: queryRequest
  };

  return notificationsApiZiqni
    .getNotificationsByQuery(
      opts
    )
    .then((response) => {
      commit(types.GETNOTIFICATIONSBYQUERY_SUCCESS, { response });
      return response.results;
    })
    .catch((e) => {
      commit(types.GETNOTIFICATIONSBYQUERY_ERROR, e);
      return [];
    });
};

/**
 * ApiResponse from backend.
 * PUT updateNotificationsStatus and return [ApiResponse]
 * vars: [
 *    [[body]] UpdateNotificationStatusRequest[ required, isArray, isBodyParam, ]]
 */
export const handleUpdateNotificationsStatus = ({ commit }, payload) => {
  const { updateNotificationStatusRequestArray } = payload;

  commit(types.API_REQUEST);
  

  return notificationsApiZiqni
    .updateNotificationsStatus(
      updateNotificationStatusRequestArray
    )
    .then((response) => {
      commit(types.UPDATENOTIFICATIONSSTATUS_SUCCESS, { response });
      return response.results;
    })
    .catch((e) => {
      commit(types.UPDATENOTIFICATIONSSTATUS_ERROR, e);
      return [];
    });
};
