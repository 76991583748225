// <<< DO NOT EDIT GENERATED FILES DIRECTLY! api-store-mutation-types.mustache >>>
export const API_REQUEST = 'API_REQUEST';


export const GETCOMPUTEENGINES_ITEM_REQ = 'GETCOMPUTEENGINES_ITEM_REQ';
export const GETCOMPUTEENGINES_ITEM_ERROR = 'GETCOMPUTEENGINES_ITEM_ERROR';
export const GETCOMPUTEENGINES_ITEM_SUCCESS = 'GETCOMPUTEENGINES_ITEM_SUCCESS'

export const UPDATE_FIELDS = 'UPDATE_FIELDS';
export const CLEAR_FILTER = 'CLEAR_FILTER';
export const CLEAR_MESSAGE = 'CLEAR_MESSAGE';

export const CREATECOMPUTEENGINES_REQ = 'CREATECOMPUTEENGINES_REQ';
export const CREATECOMPUTEENGINES_ERROR = 'CREATECOMPUTEENGINES_ERROR';
export const CREATECOMPUTEENGINES_SUCCESS = 'CREATECOMPUTEENGINES_SUCCESS';

export const DELETECOMPUTEENGINESBYID_REQ = 'DELETECOMPUTEENGINESBYID_REQ';
export const DELETECOMPUTEENGINESBYID_ERROR = 'DELETECOMPUTEENGINESBYID_ERROR';
export const DELETECOMPUTEENGINESBYID_SUCCESS = 'DELETECOMPUTEENGINESBYID_SUCCESS';

export const DELETECOMPUTEENGINESBYQUERY_REQ = 'DELETECOMPUTEENGINESBYQUERY_REQ';
export const DELETECOMPUTEENGINESBYQUERY_ERROR = 'DELETECOMPUTEENGINESBYQUERY_ERROR';
export const DELETECOMPUTEENGINESBYQUERY_SUCCESS = 'DELETECOMPUTEENGINESBYQUERY_SUCCESS';

export const GETCOMPUTEENGINES_REQ = 'GETCOMPUTEENGINES_REQ';
export const GETCOMPUTEENGINES_ERROR = 'GETCOMPUTEENGINES_ERROR';
export const GETCOMPUTEENGINES_SUCCESS = 'GETCOMPUTEENGINES_SUCCESS';

export const GETCOMPUTEENGINESBYID_REQ = 'GETCOMPUTEENGINESBYID_REQ';
export const GETCOMPUTEENGINESBYID_ERROR = 'GETCOMPUTEENGINESBYID_ERROR';
export const GETCOMPUTEENGINESBYID_SUCCESS = 'GETCOMPUTEENGINESBYID_SUCCESS';

export const GETCOMPUTEENGINESBYQUERY_REQ = 'GETCOMPUTEENGINESBYQUERY_REQ';
export const GETCOMPUTEENGINESBYQUERY_ERROR = 'GETCOMPUTEENGINESBYQUERY_ERROR';
export const GETCOMPUTEENGINESBYQUERY_SUCCESS = 'GETCOMPUTEENGINESBYQUERY_SUCCESS';

export const UPDATECOMPUTEENGINES_REQ = 'UPDATECOMPUTEENGINES_REQ';
export const UPDATECOMPUTEENGINES_ERROR = 'UPDATECOMPUTEENGINES_ERROR';
export const UPDATECOMPUTEENGINES_SUCCESS = 'UPDATECOMPUTEENGINES_SUCCESS';

