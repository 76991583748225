import Vue from 'vue';
import Router from 'vue-router';

// Containers
const TheContainer = () => import('@/containers/TheContainer');
// Views
import dashboard from './routes/dashboard';
// Achievements
import achievements from './routes/achievements';
// Missions
import missions from './routes/missions';
// Competitions
import competitions from './routes/competitions';
// Instant wins
import instantWins from './routes/instant-wins';
// PRODUCTS
import products from './routes/products';
// MEMBERS
import members from './routes/members';
//Messaging
import inbox from './routes/messages/inbox';
import draft from './routes/messages/draft';
import sent from './routes/messages/sent';
import deleted from './routes/messages/deleted';
import template from './routes/messages/template';
import accountMessages from './routes/messages/account';
//const EditInboxMessage = () => import('@/views/ziqni/messages/inbox/EditInboxMessage')
// EVENTS
import events from './routes/events';
// SETTINGS
import settings from './routes/settings';
//Repositories
import webAssets from './routes/web-assets';
// HELP
import helpSection from './routes/help-section';
// SPACE
import space from './routes/space';
// REWARDS
import rewards from './routes/rewards';
// AWARDS
import awards from './routes/awards';

// Views - Pages
const Page404 = () => import('@/views/pages/Page404');
const Page500 = () => import('@/views/pages/Page500');
const Login = () => import('@/views/pages/Login');
const Register = () => import('@/views/pages/Register');

Vue.use(Router);

const router = new Router({
  mode: 'hash', // https://router.vuejs.org/api/#mode
  linkActiveClass: 'active',
  scrollBehavior: () => ({ y: 0 }),
  routes: configRoutes(),
});

const spaceName = localStorage.getItem('zq-space-name');
const isSpacesAvailable = spaceName !== null ? spaceName.length : false;

function configRoutes() {
  return [
    {
      path: '/',
      // redirect: '/dashboard',
      name: 'Home',
      component: TheContainer,
      children: [
        ...dashboard,
        achievements,
        missions,
        competitions,
        helpSection,
        instantWins,
        webAssets,
        products,
        members,
        inbox,
        sent,
        deleted,
        draft,
        template,
        accountMessages,
        events,
        settings,
        space,
        rewards,
        awards,
      ],
    },
    {
      path: '/pages',
      redirect: '/pages/404',
      name: 'Pages',
      component: {
        render(c) {
          return c('router-view');
        },
      },
      children: [
        {
          path: '404',
          name: 'Page404',
          component: Page404,
        },
        {
          path: '500',
          name: 'Page500',
          component: Page500,
        },
        {
          path: 'login',
          name: 'Login',
          component: Login,
        },
        {
          path: 'register',
          name: 'Register',
          component: Register,
        },
      ],
    },
  ];
}

router.beforeEach((to, from, next) => {
  if ('Laboratory' === to.name && isSpacesAvailable && !from.name) {
    next({ name: 'Dashboard' });
  } else {
    next();
  }
  // if ('Laboratory' !== to.name && !isSpacesAvailable) {
  //   next({ name: 'Laboratory' });
  // } else if ('Laboratory' === to.name && isSpacesAvailable && !from.name) {
  //   next({ name: 'Dashboard' });
  // } else {
  //   next();
  // }
});

export default router;
