import {SET_THEME, GET_THEME, SET_MAIN_NAV_ITEMS} from './mutation-types';
import StorageService from '@/shared/services/storageService';

const themeService = new StorageService('theme');

export default {
    [SET_THEME](state, theme) {
        state.theme = theme;

        themeService.setToStorage(JSON.stringify(theme));
    },
    [GET_THEME](state) {
        state.theme = themeService.getStorageItem();
    },
    // SET  NAV ITEMS FOR SIDEBAR
    [SET_MAIN_NAV_ITEMS](state, payload) {
        state.navItemsMain = payload;
    }
};
