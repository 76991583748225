// <<< DO NOT EDIT GENERATED FILES DIRECTLY! model-store-fields-all.mustache >>>


import modelDefault from './ModelDefaultZq';
import optParamModels from './OptParamModelsZq';
import unitOfMeasureAllOf from './UnitOfMeasureAllOfZq';
import unitOfMeasureType from './UnitOfMeasureTypeZq';

const unitOfMeasure = this;

export default {
  objectType: 'UnitOfMeasure',
  allowableValuesKeys: [],
  constraints: [],
  getter: 'unitsOfMeasure',
  hiddenFields: ['spaceName','customFields','metadata',],
  searchableFields: ['id','created','name','key','description','isoCode','symbol','multiplier',], 
  stringBuilderFields: ['name','description',],
  operations: ['Create','Update'], 
  searchableAllFields: ['name','description','isoCode',],
  translatableFields: [],
  sortableFields: ['id','spaceName','created','tags','name','key',],
  customFieldsFieldName: ['customFields',],
  metadataFieldName: ['metadata'],
  tagFieldName: ['tags'],
  entityLinksFrom: [],

  route: {
    list: 'ListUnitOfMeasure',
    preview: 'PreviewUnitOfMeasure',
    create: 'CreateUnitOfMeasure',
    update: 'UpdateUnitOfMeasure',
  },
  skip: 0,
  limit: 10,
  sortBy: [
    {
      "queryField": "name",
      "order": "Asc"
    }
  ],
  fieldList: ['id','spaceName','created','customFields','tags','metadata','name','key','description','isoCode','symbol','multiplier','unitOfMeasureType'],
  fields: { 
    id: {
      name: 'id',
      complexType: null,
      displayOrder: 2,
      allowableValuesKeys: [],
      constraints: ['required','isPrimitiveType','isString',
                     ],
      formType: null,
    },
    spaceName: {
      name: 'spaceName',
      complexType: null,
      displayOrder: 9000,
      allowableValuesKeys: [],
      constraints: ['required','isPrimitiveType','isString',
                     ],
      formType: null,
    },
    created: {
      name: 'created',
      complexType: null,
      displayOrder: 9001,
      allowableValuesKeys: [],
      constraints: ['required','isPrimitiveType','isDateTime',
                     ],
      formType: null,
    },
    customFields: {
      name: 'customFields',
      complexType: null,
      displayOrder: 5000,
      allowableValuesKeys: [],
      constraints: ['isCustomFieldsArray','isPrimitiveType','isContainer','isFreeFormObject','isMap',
                    'isFreeFormObject','isNullable', ],
      formType: null,
    },
    tags: {
      name: 'tags',
      complexType: null,
      displayOrder: 1,
      allowableValuesKeys: [],
      constraints: ['isTagsArray','isPrimitiveType','isContainer','isArray',
                    'isString', ],
      formType: null,
    },
    metadata: {
      name: 'metadata',
      complexType: null,
      displayOrder: 10000,
      allowableValuesKeys: [],
      constraints: ['isMetadataMap','isPrimitiveType','isContainer','isMap',
                    'isString', ],
      formType: 'META',
    },
    name: {
      name: 'name',
      complexType: null,
      displayOrder: 1001,
      allowableValuesKeys: [],
      constraints: ['required','isPrimitiveType','isString',
                     ],
      formType: null,
    },
    key: {
      name: 'key',
      complexType: null,
      displayOrder: 1000,
      allowableValuesKeys: [],
      constraints: ['required','isPrimitiveType','isString',
                     ],
      formType: 'NAMEKEY, showKey: true',
    },
    description: {
      name: 'description',
      complexType: null,
      displayOrder: 1005,
      allowableValuesKeys: [],
      constraints: ['isPrimitiveType','isString',
                     ],
      formType: 'TEXTAREA',
    },
    isoCode: {
      name: 'isoCode',
      complexType: null,
      displayOrder: 1002,
      allowableValuesKeys: [],
      constraints: ['isPrimitiveType','isString',
                     ],
      formType: null,
    },
    symbol: {
      name: 'symbol',
      complexType: null,
      displayOrder: 1003,
      allowableValuesKeys: [],
      constraints: ['isPrimitiveType','isString',
                     ],
      formType: null,
    },
    multiplier: {
      name: 'multiplier',
      complexType: null,
      displayOrder: 1005,
      allowableValuesKeys: [],
      constraints: ['required','isPrimitiveType','isNumeric','isDouble',
                     ],
      formType: null,
    },
    unitOfMeasureType: {
      name: 'unitOfMeasureType',
      complexType: unitOfMeasureType,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['required',
                     ],
      formType: null,
    }
  }
};
