// <<< DO NOT EDIT GENERATED FILES DIRECTLY! api-store-actions.mustache >>>
import { delay, cloneDeep } from 'lodash';
import * as types from './mutation-types';
import sdkApiConfig from '@/config/sdkApiConfig';
import updateFields from '@/utils/updateFields';
import { awardsApiZiqni } from '@/generated/ziqni/api/AwardsApiZiqni';
import router from '@/router';

import apiResponseFields from '../../../fields/ApiResponseZq.js';
import awardResponseFields from '../../../fields/AwardResponseZq.js';
import claimAwardRequestFields from '../../../fields/ClaimAwardRequestZq.js';
import queryRequestFields from '../../../fields/QueryRequestZq.js';
import {handleRequest,handleRequestWithBody} from "../../../../../utils/ZiqniAdminApiHandler";

export const handleFields = ({ commit, state }, data) => {
  updateFields(commit, state, data, 'hiddenAwardsKey');
};

// Clear awards Message
export const handleClearMessage = ({ commit }) => {
  commit(types.CLEAR_MESSAGE);
};

// clear filter
export const handleClearFilter = ({ commit }) => {
  commit(types.CLEAR_FILTER);
};

//AWARDS - AWARDS
export const handleAwardsChanges = ({ commit }, data) => {
  commit('SET_AWARDS', data);
};
/**
 * AwardResponse from backend.
 * GET getAwards and return [AwardResponse]
 * vars: [
 *    [[id]] id[ opts, isArray, isQueryParam]
 *    [[limit]] _limit[ opts, isInteger, isQueryParam]
 *    [[skip]] _skip[ opts, isInteger, isQueryParam]]
 */
export const handleGetAwards_item = ({ commit }, idArray, limit, skip) => {
  commit(types.API_REQUEST);
  let opts = { 
    id: idArray, 
    limit: limit, 
    skip: skip
  };

  return awardsApiZiqni
    .getAwards(
      opts
    )
    .then((response) => {
      commit(types.GETAWARDS_ITEM_SUCCESS, { response });
      return response.results;
    })
    .catch((e) => {
      commit(types.GETAWARDS_ITEM_ERROR, e);
      return [];
    });
};

/**
 * ApiResponse from backend.
 * PUT claimAwards and return [ApiResponse]
 * vars: [
 *    [[body]] ClaimAwardRequest[ required, isArray, isBodyParam, ]]
 */
export const handleClaimAwards = ({ commit }, payload) => {
  const { claimAwardRequestArray } = payload;

  commit(types.API_REQUEST);
  

  return awardsApiZiqni
    .claimAwards(
      claimAwardRequestArray
    )
    .then((response) => {
      commit(types.CLAIMAWARDS_SUCCESS, { response });
      return response.results;
    })
    .catch((e) => {
      commit(types.CLAIMAWARDS_ERROR, e);
      return [];
    });
};

/**
 * AwardResponse from backend.
 * GET getAwards and return [AwardResponse]
 * vars: [
 *    [[id]] id[ opts, isArray, isQueryParam]
 *    [[limit]] _limit[ opts, isInteger, isQueryParam]
 *    [[skip]] _skip[ opts, isInteger, isQueryParam]]
 */
export const handleGetAwards = ({ commit }, payload) => {
  const { idArray, limit, skip } = payload;

  commit(types.API_REQUEST);
  let opts = { 
    id: idArray, 
    limit: limit, 
    skip: skip
  };

  return awardsApiZiqni
    .getAwards(
      opts
    )
    .then((response) => {
      commit(types.GETAWARDS_SUCCESS, { response });
      return response.results;
    })
    .catch((e) => {
      commit(types.GETAWARDS_ERROR, e);
      return [];
    });
};

/**
 * AwardResponse from backend.
 * POST getAwardsByQuery and return [AwardResponse]
 * vars: [
 *    [[body]] QueryRequest[ opts, isBodyParam, ]]
 */
export const handleGetAwardsByQuery = ({ commit }, payload) => {
  const { queryRequest } = payload;

  commit(types.API_REQUEST);
  let opts = { 
    body: queryRequest
  };

  return awardsApiZiqni
    .getAwardsByQuery(
      opts
    )
    .then((response) => {
      commit(types.GETAWARDSBYQUERY_SUCCESS, { response });
      return response.results;
    })
    .catch((e) => {
      commit(types.GETAWARDSBYQUERY_ERROR, e);
      return [];
    });
};
