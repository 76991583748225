// <<< DO NOT EDIT GENERATED FILES DIRECTLY! api-store-mutation-types.mustache >>>
export const API_REQUEST = 'API_REQUEST';


export const GETTAGS_ITEM_REQ = 'GETTAGS_ITEM_REQ';
export const GETTAGS_ITEM_ERROR = 'GETTAGS_ITEM_ERROR';
export const GETTAGS_ITEM_SUCCESS = 'GETTAGS_ITEM_SUCCESS'

export const UPDATE_FIELDS = 'UPDATE_FIELDS';
export const CLEAR_FILTER = 'CLEAR_FILTER';
export const CLEAR_MESSAGE = 'CLEAR_MESSAGE';

export const CREATETAGS_REQ = 'CREATETAGS_REQ';
export const CREATETAGS_ERROR = 'CREATETAGS_ERROR';
export const CREATETAGS_SUCCESS = 'CREATETAGS_SUCCESS';

export const DELETETAGS_REQ = 'DELETETAGS_REQ';
export const DELETETAGS_ERROR = 'DELETETAGS_ERROR';
export const DELETETAGS_SUCCESS = 'DELETETAGS_SUCCESS';

export const DELETETAGSBYQUERY_REQ = 'DELETETAGSBYQUERY_REQ';
export const DELETETAGSBYQUERY_ERROR = 'DELETETAGSBYQUERY_ERROR';
export const DELETETAGSBYQUERY_SUCCESS = 'DELETETAGSBYQUERY_SUCCESS';

export const GETTAGS_REQ = 'GETTAGS_REQ';
export const GETTAGS_ERROR = 'GETTAGS_ERROR';
export const GETTAGS_SUCCESS = 'GETTAGS_SUCCESS';

export const GETTAGSBYQUERY_REQ = 'GETTAGSBYQUERY_REQ';
export const GETTAGSBYQUERY_ERROR = 'GETTAGSBYQUERY_ERROR';
export const GETTAGSBYQUERY_SUCCESS = 'GETTAGSBYQUERY_SUCCESS';

export const UPDATETAGS_REQ = 'UPDATETAGS_REQ';
export const UPDATETAGS_ERROR = 'UPDATETAGS_ERROR';
export const UPDATETAGS_SUCCESS = 'UPDATETAGS_SUCCESS';

