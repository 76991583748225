// <<< DO NOT EDIT GENERATED FILES DIRECTLY! api-store-mutation-types.mustache >>>
export const API_REQUEST = 'API_REQUEST';


export const GETEVENTS_ITEM_REQ = 'GETEVENTS_ITEM_REQ';
export const GETEVENTS_ITEM_ERROR = 'GETEVENTS_ITEM_ERROR';
export const GETEVENTS_ITEM_SUCCESS = 'GETEVENTS_ITEM_SUCCESS'

export const UPDATE_FIELDS = 'UPDATE_FIELDS';
export const CLEAR_FILTER = 'CLEAR_FILTER';
export const CLEAR_MESSAGE = 'CLEAR_MESSAGE';

export const CREATEEVENTS_REQ = 'CREATEEVENTS_REQ';
export const CREATEEVENTS_ERROR = 'CREATEEVENTS_ERROR';
export const CREATEEVENTS_SUCCESS = 'CREATEEVENTS_SUCCESS';

export const GETEVENTS_REQ = 'GETEVENTS_REQ';
export const GETEVENTS_ERROR = 'GETEVENTS_ERROR';
export const GETEVENTS_SUCCESS = 'GETEVENTS_SUCCESS';

export const GETEVENTSBYQUERY_REQ = 'GETEVENTSBYQUERY_REQ';
export const GETEVENTSBYQUERY_ERROR = 'GETEVENTSBYQUERY_ERROR';
export const GETEVENTSBYQUERY_SUCCESS = 'GETEVENTSBYQUERY_SUCCESS';

