import Vue from 'vue';
import Vuex from 'vuex';
import events from '@/generated/ziqni/store/modules/events';
import path from './modules/path';
import messages from './modules/messages';
import spaces from './modules/spaces';
import user from './modules/user';
import connections from './modules/settings/connections';
import files from '@/store/modules/fileObjects';
import contests from '@/generated/ziqni/store/modules/contests';
import rules from '@/generated/ziqni/store/modules/rules';
import achievements from '@/generated/ziqni/store/modules/achievements';
import missions from '@/store/modules/missions';
import competitions from '@/generated/ziqni/store/modules/competitions';
import products from '@/generated/ziqni/store/modules/products';
import members from '@/generated/ziqni/store/modules/members';
import collaborators from '@/generated/ziqni/store/modules/collaborators';
import actionTypes from '@/generated/ziqni/store/modules/actionTypes';
import rewardTypes from '@/generated/ziqni/store/modules/rewardTypes';
import customFields from '@/generated/ziqni/store/modules/customFields';
import sqsConnections from '@/store/modules/consumers/sqs';
import kafkaConnections from '@/store/modules/consumers/kafka';
import rabbitMqConnections from '@/store/modules/consumers/rabbitMQ';
import languages from '@/generated/ziqni/store/modules/languages';
import translations from '@/generated/ziqni/store/modules/translations';
import unitsOfMeasure from '@/generated/ziqni/store/modules/unitsOfMeasure';
import rewards from '@/generated/ziqni/store/modules/rewards';
import webhooks from '@/generated/ziqni/store/modules/webhooks';
import transformers from '@/generated/ziqni/store/modules/transformers';
import apiKeys from '@/generated/ziqni/store/modules/apiKeys';
import tags from '@/generated/ziqni/store/modules/tags';
import fileRepositories from "@/generated/ziqni/store/modules/fileRepositories";
import memberMessages from "@/generated/ziqni/store/modules/memberMessages";
import notifications from "@/generated/ziqni/store/modules/notifications";
import accountMessages from "@/generated/ziqni/store/modules/accountMessages";
import awards from "@/generated/ziqni/store/modules/awards";
import computeEngines from "@/generated/ziqni/store/modules/computeEngines";
import instantWins from "@/generated/ziqni/store/modules/instantWins";
import lookups from './modules/lookups';
import theme from './modules/theme';
import leaderboard from './modules/leaderboard';
import logEvents from './modules/logEvents';
import entrants from './modules/entrants'
import currentPage from './modules/currentPage';


Vue.use(Vuex);

const state = {
  sidebarShow: 'responsive',
  sidebarMinimize: false,
};

const mutations = {
  toggleSidebarDesktop(state) {
    const sidebarOpened = [true, 'responsive'].includes(state.sidebarShow);
    state.sidebarShow = sidebarOpened ? false : 'responsive';
  },
  toggleSidebarMobile(state) {
    const sidebarClosed = [false, 'responsive'].includes(state.sidebarShow);
    state.sidebarShow = sidebarClosed ? true : 'responsive';
  },
  set(state, [variable, value]) {
    state[variable] = value;
  },
};

export default new Vuex.Store({
  state,
  mutations,
  strict: true,
  modules: {
    currentPage,
    lookups,
    events,
    products,
    members,
    competitions,
    missions,
    achievements,
    path,
    files,
    actionTypes,
    rewardTypes,
    customFields,
    languages,
    translations,
    unitsOfMeasure,
    webhooks,
    rewards,
    connections,
    sqsConnections,
    kafkaConnections,
    rabbitMqConnections,
    transformers,
    apiKeys,
    collaborators,
    tags,
    contests,
    messages,
    theme,
    spaces,
    user,
    rules,
    fileRepositories,
    memberMessages,
    notifications,
    accountMessages,
    awards,
    computeEngines,
    leaderboard,
    logEvents,
    entrants,
    instantWins
  },
});
