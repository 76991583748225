// <<< DO NOT EDIT GENERATED FILES DIRECTLY! model-store-fields-all.mustache >>>



const awardScheduling = this;

export default {
  objectType: 'AwardScheduling',
  allowableValuesKeys: [],
  constraints: ['isModel',],
  getter: null,
  hiddenFields: [],
  searchableFields: [], 
  searchableAllFields: [],
  translatableFields: [],
  sortableFields: [],
  customFieldsFieldName: [],
  metadataFieldName: [],
  tagFieldName: [],
  entityLinksFrom: [],

  route: {
    
    
  },
  fieldList: ['thenAwardActiveFrom','thenAwardActiveUntil','onStatusChangeTo'],
  fields: { 
    thenAwardActiveFrom: {
      name: 'thenAwardActiveFrom',
      complexType: null,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['isPrimitiveType','isString',
                     ],
      formType: null,
    },
    thenAwardActiveUntil: {
      name: 'thenAwardActiveUntil',
      complexType: null,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['isPrimitiveType','isString',
                     ],
      formType: null,
    },
    onStatusChangeTo: {
      name: 'onStatusChangeTo',
      complexType: null,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['isPrimitiveType','isNumeric','isInteger','isShort',
                     ],
      formType: null,
    }
  }
};
