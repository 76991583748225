export default class StorageService {
    constructor(key) {
        this.storageKey = key;
    }

    setToStorage(item) {
        localStorage.setItem(this.storageKey, item)
    }

    getStorageItem() {
        return JSON.parse(localStorage.getItem(this.storageKey));
    }

    clearStorage() {
        localStorage.removeItem(this.storageKey)
    }
}