// <<< DO NOT EDIT GENERATED FILES DIRECTLY! api-store-mutations.mustache >>>
import * as types from './mutation-types';
import * as _ from 'lodash';
import {
  handleErrorResponse, handleItemErrorResponse,
  handleItemSuccessResponse,
  handleSuccessResponse
} from "../../../../../utils/ZiqniAdminApiHandler";
import apiResponseFields from '../../../fields/ApiResponseZq.js';
import collaboratorResponseFields from '../../../fields/CollaboratorResponseZq.js';
import collaboratorRolesResponseFields from '../../../fields/CollaboratorRolesResponseZq.js';
import createCollaboratorRequestFields from '../../../fields/CreateCollaboratorRequestZq.js';
import queryRequestFields from '../../../fields/QueryRequestZq.js';
import updateCollaboratorRequestFields from '../../../fields/UpdateCollaboratorRequestZq.js';

const mutations = {
    [types.API_REQUEST](state) {
        state.loading = true;
        state.loaded = false;
    },
    [types.CLEAR_MESSAGE](state){
        state.message = null;
    },

    [types.UPDATE_FIELDS](state, data) {
        state.hiddenFields = data._hiddenFields;
        state.fields = _.difference(state.originalFields, state.hiddenFields);
        localStorage.setItem('hiddenCollaboratorsKey', JSON.stringify(state.hiddenFields));
    },

    [types.CLEAR_FILTER](state) {
        state.fields = _.difference(
            state.originalFields,
            state.originHiddenFields
        );
        state.hiddenFields = state.originHiddenFields;
        localStorage.removeItem('hiddenCollaboratorsKey');
    },

    //CollaboratorResponse
    [types.GETCOLLABORATORS_ITEM_SUCCESS](state, response) {
      handleItemSuccessResponse(types.GETCOLLABORATORS_ITEM_SUCCESS,collaboratorResponseFields, state, response, 'collaborator');
    },

    //CollaboratorResponse
    [types.GETCOLLABORATORS_ERROR](state, error) {
      handleItemErrorResponse(types.GETCOLLABORATORS_ITEM_ITEM_ERROR, collaboratorResponseFields, state, error);
    },


    //ApiResponse
    [types.CREATECOLLABORATORS_SUCCESS](state, response) {
      handleSuccessResponse(types.CREATECOLLABORATORS_SUCCESS,apiResponseFields, state, response);
    },

    //ApiResponse
    [types.CREATECOLLABORATORS_ERROR](state, error) {
      handleErrorResponse(types.CREATECOLLABORATORS_ERROR, apiResponseFields, state, error);
    },

    //ApiResponse
    [types.DELETECOLLABORATORS_SUCCESS](state, response) {
      handleSuccessResponse(types.DELETECOLLABORATORS_SUCCESS,apiResponseFields, state, response);
    },

    //ApiResponse
    [types.DELETECOLLABORATORS_ERROR](state, error) {
      handleErrorResponse(types.DELETECOLLABORATORS_ERROR, apiResponseFields, state, error);
    },

    //CollaboratorRolesResponse
    [types.GETAVAILABLEROLESFORCOLLABORATORS_SUCCESS](state, response) {
      handleSuccessResponse(types.GETAVAILABLEROLESFORCOLLABORATORS_SUCCESS,collaboratorRolesResponseFields, state, response);
    },

    //CollaboratorRolesResponse
    [types.GETAVAILABLEROLESFORCOLLABORATORS_ERROR](state, error) {
      handleErrorResponse(types.GETAVAILABLEROLESFORCOLLABORATORS_ERROR, collaboratorRolesResponseFields, state, error);
    },

    //CollaboratorResponse
    [types.GETCOLLABORATORS_SUCCESS](state, response) {
      handleSuccessResponse(types.GETCOLLABORATORS_SUCCESS,collaboratorResponseFields, state, response);
    },

    //CollaboratorResponse
    [types.GETCOLLABORATORS_ERROR](state, error) {
      handleErrorResponse(types.GETCOLLABORATORS_ERROR, collaboratorResponseFields, state, error);
    },

    //CollaboratorResponse
    [types.GETCOLLABORATORSBYQUERY_SUCCESS](state, response) {
      handleSuccessResponse(types.GETCOLLABORATORSBYQUERY_SUCCESS,collaboratorResponseFields, state, response);
    },

    //CollaboratorResponse
    [types.GETCOLLABORATORSBYQUERY_ERROR](state, error) {
      handleErrorResponse(types.GETCOLLABORATORSBYQUERY_ERROR, collaboratorResponseFields, state, error);
    },

    //ApiResponse
    [types.UPDATECOLLABORATORS_SUCCESS](state, response) {
      handleSuccessResponse(types.UPDATECOLLABORATORS_SUCCESS,apiResponseFields, state, response);
    },

    //ApiResponse
    [types.UPDATECOLLABORATORS_ERROR](state, error) {
      handleErrorResponse(types.UPDATECOLLABORATORS_ERROR, apiResponseFields, state, error);
    },
};

export default mutations;
