export const spaces = state => state.spaces;
export const space = state => state.space;
export const appSwitcher = state => state.appSwitcher;
export const fields = state => state.fields;
export const pages = state => state.pages;
export const loading = state => state.loading;
export const resultCount = state => state.resultCount;
export const originalFields = state => state.originalFields;
export const message = state => state.message;
export const success = state => state.success;
