// Genearetd using ziqni-admin-api.mustache
import {ApiClient} from '@ziqni-tech/admin-api-client';
import { ConnectionsApi } from "@ziqni-tech/admin-api-client";

const defaultClient = ApiClient.instance;
defaultClient.basePath = sdkApiConfig.baseApiUrlPath;

import { sdkApiConfig } from '@/config';
import { queryConverter } from '@/utils/query.convert';
import { errorHandler } from '@/utils/errorHandler';
class ConnectionsApiZiqni {

  constructor() {
    this.defaultOptions = {};
    this.ziqniConnectionsApi = new ConnectionsApi();
    this.OAuth2 = this.ziqniConnectionsApi.apiClient.authentications['OAuth2'];
    this.OAuth2.accessToken = localStorage.getItem('vue-token');
  }

  /**
   * createConnections: . Notes: Create a new Consumer Connection in the Ziqni system
   *
   * BaseName: Connections
   * Path: /connections
   * Http Method: POST, Consumes: [{mediaType&#x3D;application/json}], Produces: [{mediaType&#x3D;application/json}]
   * ReturnType: ApiResponse, ReturnBaseType: ApiResponse, ReturnContainer: 
   *  RequiredParams:
   *   - body [body]   >>> Create a RabbitMq Consumer Connection in the Ziqni system
   *      |  dataType: [CreateConnectionRequest], baseType: CreateConnectionRequest, 
   *      |  isBodyParam: true, 
   *      | 
   * 
   *
   * Constraints:
   * hasAuthMethods: true, hasConsumes: true, hasProduces: true, hasParams: true, hasOptionalParams: false,
   * hasRequiredParams: true, returnTypeIsPrimitive: false, returnSimpleType: true, subresourceOperation: false,
   * isMap: false, isArray: false, isMultipart: false, isResponseBinary: false, isResponseFile: false, hasReference: true,
   * hasDefaultResponse: false, hasErrorResponseObject: true, isRestfulIndex: false, isRestfulShow: false,
   * isRestfulCreate: true, isRestfulUpdate: false, isRestfulDestroy: false, isRestful: true, isDeprecated: false,
   * isCallbackRequest: false, uniqueItems: false  hasRequiredParams
   */
  createConnections(body){
    this.OAuth2.accessToken = localStorage.getItem('vue-token');
    return new Promise((resolve, reject) => {
      const callback = (error, data, responseString) => {
        const errorsFromHandler = errorHandler(error, data, responseString);
        if (error) {
          reject(error.message);
        } else if (errorsFromHandler.length) {
          const errors = errorsFromHandler.join(', ');
          reject(errors);
        } else {
          resolve(data);
        }
      };
      this.ziqniConnectionsApi.createConnections(body, callback);
    });
  }

  /**
   * deleteConnections: . Notes: Delete the Consumer Connection for a given identifier specified
   *
   * BaseName: Connections
   * Path: /connections
   * Http Method: DELETE, Consumes: , Produces: [{mediaType&#x3D;application/json}]
   * ReturnType: ApiResponse, ReturnBaseType: ApiResponse, ReturnContainer: 
   *  
   * OptionalParams:
   *   ops { 
   *   - id [id]   >>> The unique identifiers of the resources
   *      |  dataType: [String], baseType: String, 
   *      |  isQueryParam: true, isCollectionFormatMulti: true, isExplode: true, collectionFormat: multi, 
   *      | 
   *   }
   *
   * Constraints:
   * hasAuthMethods: true, hasConsumes: false, hasProduces: true, hasParams: true, hasOptionalParams: true,
   * hasRequiredParams: false, returnTypeIsPrimitive: false, returnSimpleType: true, subresourceOperation: false,
   * isMap: false, isArray: false, isMultipart: false, isResponseBinary: false, isResponseFile: false, hasReference: true,
   * hasDefaultResponse: false, hasErrorResponseObject: true, isRestfulIndex: false, isRestfulShow: false,
   * isRestfulCreate: false, isRestfulUpdate: false, isRestfulDestroy: false, isRestful: false, isDeprecated: false,
   * isCallbackRequest: false, uniqueItems: false hasOptionalParams 
   */
  deleteConnections(opts){
    this.OAuth2.accessToken = localStorage.getItem('vue-token');
    return new Promise((resolve, reject) => {
      const callback = (error, data, responseString) => {
        const errorsFromHandler = errorHandler(error, data, responseString);
        if (error) {
          reject(error.message);
        } else if (errorsFromHandler.length) {
          const errors = errorsFromHandler.join(', ');
          reject(errors);
        } else {
          resolve(data);
        }
      };
      this.ziqniConnectionsApi.deleteConnections(opts, callback);
    });
  }

  /**
   * deleteConnectionsByQuery: . Notes: Delete a RabbitMQ Connection or a list of RabbitMQ Connections from Ziqni by unique Connection ID&#39;s or any other POST body parameters using the POST method
   *
   * BaseName: Connections
   * Path: /connections/delete
   * Http Method: POST, Consumes: [{mediaType&#x3D;application/json}], Produces: [{mediaType&#x3D;application/json}]
   * ReturnType: ApiResponse, ReturnBaseType: ApiResponse, ReturnContainer: 
   *  
   * OptionalParams:
   *   ops { 
   *   - body [body]  isModel: true >>> Delete a RabbitMQ Connection or a list of RabbitMQ Connections from Ziqni by unique Connection ID&#39;s or any other POST body parameters using the POST method
   *      |  dataType: QueryRequest, baseType: QueryRequest, 
   *      |  isBodyParam: true, 
   *      | 
   *   }
   *
   * Constraints:
   * hasAuthMethods: true, hasConsumes: true, hasProduces: true, hasParams: true, hasOptionalParams: true,
   * hasRequiredParams: false, returnTypeIsPrimitive: false, returnSimpleType: true, subresourceOperation: false,
   * isMap: false, isArray: false, isMultipart: false, isResponseBinary: false, isResponseFile: false, hasReference: true,
   * hasDefaultResponse: false, hasErrorResponseObject: true, isRestfulIndex: false, isRestfulShow: false,
   * isRestfulCreate: false, isRestfulUpdate: false, isRestfulDestroy: false, isRestful: false, isDeprecated: false,
   * isCallbackRequest: false, uniqueItems: false hasOptionalParams 
   */
  deleteConnectionsByQuery(opts){
    this.OAuth2.accessToken = localStorage.getItem('vue-token');
    return new Promise((resolve, reject) => {
      const callback = (error, data, responseString) => {
        const errorsFromHandler = errorHandler(error, data, responseString);
        if (error) {
          reject(error.message);
        } else if (errorsFromHandler.length) {
          const errors = errorsFromHandler.join(', ');
          reject(errors);
        } else {
          resolve(data);
        }
      };
      this.ziqniConnectionsApi.deleteConnectionsByQuery(opts, callback);
    });
  }

  /**
   * describeConnectionObject: . Notes: Get all the parameter for the connection based on the connection type
   *
   * BaseName: Connections
   * Path: /connections/_describe
   * Http Method: GET, Consumes: , Produces: 
   * ReturnType: , ReturnBaseType: , ReturnContainer: 
   *  RequiredParams:
   *   - connectionType [connectionType]   >>> The connection type, RabbitMQ, Kafka, or SQS
   *      |  dataType: ConnectionType, 
   *      |  isQueryParam: true, isExplode: true, 
   *      | {enumVars&#x3D;[{name&#x3D;RabbitMq, isString&#x3D;true, value&#x3D;&quot;RabbitMq&quot;}, {name&#x3D;Kafka, isString&#x3D;true, value&#x3D;&quot;Kafka&quot;}, {name&#x3D;SQS, isString&#x3D;true, value&#x3D;&quot;SQS&quot;}], values&#x3D;[RabbitMq, Kafka, SQS]}
   * 
   *
   * Constraints:
   * hasAuthMethods: true, hasConsumes: false, hasProduces: false, hasParams: true, hasOptionalParams: false,
   * hasRequiredParams: true, returnTypeIsPrimitive: false, returnSimpleType: false, subresourceOperation: false,
   * isMap: false, isArray: false, isMultipart: false, isResponseBinary: false, isResponseFile: false, hasReference: false,
   * hasDefaultResponse: false, hasErrorResponseObject: true, isRestfulIndex: false, isRestfulShow: false,
   * isRestfulCreate: false, isRestfulUpdate: false, isRestfulDestroy: false, isRestful: false, isDeprecated: false,
   * isCallbackRequest: false, uniqueItems: false  hasRequiredParams
   */
  describeConnectionObject(connectionType){
    this.OAuth2.accessToken = localStorage.getItem('vue-token');
    return new Promise((resolve, reject) => {
      const callback = (error, data, responseString) => {
        const errorsFromHandler = errorHandler(error, data, responseString);
        if (error) {
          reject(error.message);
        } else if (errorsFromHandler.length) {
          const errors = errorsFromHandler.join(', ');
          reject(errors);
        } else {
          resolve(data);
        }
      };
      this.ziqniConnectionsApi.describeConnectionObject(connectionType, callback);
    });
  }

  /**
   * getConnections: . Notes: Return a list of Consumer Connections
   *
   * BaseName: Connections
   * Path: /connections
   * Http Method: GET, Consumes: , Produces: [{mediaType&#x3D;application/json}]
   * ReturnType: ConnectionResponse, ReturnBaseType: ConnectionResponse, ReturnContainer: 
   *  
   * OptionalParams:
   *   ops { 
   *   - id [id]   >>> The unique identifiers of the resources
   *      |  dataType: [String], baseType: String, 
   *      |  isQueryParam: true, isCollectionFormatMulti: true, isExplode: true, collectionFormat: multi, 
   *      | 
   *  
   *   - limit [_limit] isIntegerisUnboundedInteger isPrimitiveType: true >>> Limit the returned total records found
   *      |  dataType: Number, 
   *      |  isQueryParam: true, isExplode: true, 
   *      | 
   *  
   *   - skip [_skip] isIntegerisUnboundedInteger isPrimitiveType: true >>> Skip the returned records found and return the next batch of records
   *      |  dataType: Number, 
   *      |  isQueryParam: true, isExplode: true, 
   *      | 
   *   }
   *
   * Constraints:
   * hasAuthMethods: true, hasConsumes: false, hasProduces: true, hasParams: true, hasOptionalParams: true,
   * hasRequiredParams: false, returnTypeIsPrimitive: false, returnSimpleType: true, subresourceOperation: false,
   * isMap: false, isArray: false, isMultipart: false, isResponseBinary: false, isResponseFile: false, hasReference: true,
   * hasDefaultResponse: false, hasErrorResponseObject: true, isRestfulIndex: true, isRestfulShow: false,
   * isRestfulCreate: false, isRestfulUpdate: false, isRestfulDestroy: false, isRestful: true, isDeprecated: false,
   * isCallbackRequest: false, uniqueItems: false hasOptionalParams 
   */
  getConnections(opts){
    this.OAuth2.accessToken = localStorage.getItem('vue-token');
    return new Promise((resolve, reject) => {
      const callback = (error, data, responseString) => {
        const errorsFromHandler = errorHandler(error, data, responseString);
        if (error) {
          reject(error.message);
        } else if (errorsFromHandler.length) {
          const errors = errorsFromHandler.join(', ');
          reject(errors);
        } else {
          resolve(data);
        }
      };
      this.ziqniConnectionsApi.getConnections(opts, callback);
    });
  }

  /**
   * getConnectionsByQuery: . Notes: Retrieve a RabbitMQ Connection or a list of RabbitMQ Connections from Ziqni by unique Connection ID&#39;s or any other Post body parameters using the POST method
   *
   * BaseName: Connections
   * Path: /connections/query
   * Http Method: POST, Consumes: [{mediaType&#x3D;application/json}], Produces: [{mediaType&#x3D;application/json}]
   * ReturnType: ConnectionResponse, ReturnBaseType: ConnectionResponse, ReturnContainer: 
   *  
   * OptionalParams:
   *   ops { 
   *   - body [body]  isModel: true >>> Retrieve a RabbitMQ Connection or a list of RabbitMQ Connections from Ziqni by unique Connection ID&#39;s or any other Post body parameters using the POST method
   *      |  dataType: QueryRequest, baseType: QueryRequest, 
   *      |  isBodyParam: true, 
   *      | 
   *   }
   *
   * Constraints:
   * hasAuthMethods: true, hasConsumes: true, hasProduces: true, hasParams: true, hasOptionalParams: true,
   * hasRequiredParams: false, returnTypeIsPrimitive: false, returnSimpleType: true, subresourceOperation: false,
   * isMap: false, isArray: false, isMultipart: false, isResponseBinary: false, isResponseFile: false, hasReference: true,
   * hasDefaultResponse: false, hasErrorResponseObject: true, isRestfulIndex: false, isRestfulShow: false,
   * isRestfulCreate: false, isRestfulUpdate: false, isRestfulDestroy: false, isRestful: false, isDeprecated: false,
   * isCallbackRequest: false, uniqueItems: false hasOptionalParams 
   */
  getConnectionsByQuery(opts){
    this.OAuth2.accessToken = localStorage.getItem('vue-token');
    return new Promise((resolve, reject) => {
      const callback = (error, data, responseString) => {
        const errorsFromHandler = errorHandler(error, data, responseString);
        if (error) {
          reject(error.message);
        } else if (errorsFromHandler.length) {
          const errors = errorsFromHandler.join(', ');
          reject(errors);
        } else {
          resolve(data);
        }
      };
      this.ziqniConnectionsApi.getConnectionsByQuery(opts, callback);
    });
  }

  /**
   * updateConnections: . Notes: Update an existing Consumer Connection in the Ziqni system
   *
   * BaseName: Connections
   * Path: /connections
   * Http Method: PUT, Consumes: [{mediaType&#x3D;application/json}], Produces: [{mediaType&#x3D;application/json}]
   * ReturnType: ApiResponse, ReturnBaseType: ApiResponse, ReturnContainer: 
   *  RequiredParams:
   *   - body [body]   >>> Update the RabbitMq Consumer Connection details in the Ziqni system
   *      |  dataType: [UpdateConnectionRequest], baseType: UpdateConnectionRequest, 
   *      |  isBodyParam: true, 
   *      | 
   * 
   *
   * Constraints:
   * hasAuthMethods: true, hasConsumes: true, hasProduces: true, hasParams: true, hasOptionalParams: false,
   * hasRequiredParams: true, returnTypeIsPrimitive: false, returnSimpleType: true, subresourceOperation: false,
   * isMap: false, isArray: false, isMultipart: false, isResponseBinary: false, isResponseFile: false, hasReference: true,
   * hasDefaultResponse: false, hasErrorResponseObject: true, isRestfulIndex: false, isRestfulShow: false,
   * isRestfulCreate: false, isRestfulUpdate: false, isRestfulDestroy: false, isRestful: false, isDeprecated: false,
   * isCallbackRequest: false, uniqueItems: false  hasRequiredParams
   */
  updateConnections(body){
    this.OAuth2.accessToken = localStorage.getItem('vue-token');
    return new Promise((resolve, reject) => {
      const callback = (error, data, responseString) => {
        const errorsFromHandler = errorHandler(error, data, responseString);
        if (error) {
          reject(error.message);
        } else if (errorsFromHandler.length) {
          const errors = errorsFromHandler.join(', ');
          reject(errors);
        } else {
          resolve(data);
        }
      };
      this.ziqniConnectionsApi.updateConnections(body, callback);
    });
  }

  /**
   * updateConnectionsState: Update connection status. Notes: Update the state of a Consumer using the POST method
   *
   * BaseName: Connections
   * Path: /connections/state
   * Http Method: POST, Consumes: [{mediaType&#x3D;application/json}], Produces: [{mediaType&#x3D;application/json}]
   * ReturnType: ApiResponse, ReturnBaseType: ApiResponse, ReturnContainer: 
   *  
   * OptionalParams:
   *   ops { 
   *   - body [body]   >>> Update the state of a Consumer using the POST method
   *      |  dataType: [UpdateConnectionStateRequest], baseType: UpdateConnectionStateRequest, 
   *      |  isBodyParam: true, 
   *      | 
   *   }
   *
   * Constraints:
   * hasAuthMethods: true, hasConsumes: true, hasProduces: true, hasParams: true, hasOptionalParams: true,
   * hasRequiredParams: false, returnTypeIsPrimitive: false, returnSimpleType: true, subresourceOperation: false,
   * isMap: false, isArray: false, isMultipart: false, isResponseBinary: false, isResponseFile: false, hasReference: true,
   * hasDefaultResponse: false, hasErrorResponseObject: true, isRestfulIndex: false, isRestfulShow: false,
   * isRestfulCreate: false, isRestfulUpdate: false, isRestfulDestroy: false, isRestful: false, isDeprecated: false,
   * isCallbackRequest: false, uniqueItems: false hasOptionalParams 
   */
  updateConnectionsState(opts){
    this.OAuth2.accessToken = localStorage.getItem('vue-token');
    return new Promise((resolve, reject) => {
      const callback = (error, data, responseString) => {
        const errorsFromHandler = errorHandler(error, data, responseString);
        if (error) {
          reject(error.message);
        } else if (errorsFromHandler.length) {
          const errors = errorsFromHandler.join(', ');
          reject(errors);
        } else {
          resolve(data);
        }
      };
      this.ziqniConnectionsApi.updateConnectionsState(opts, callback);
    });
  }

}

export const connectionsApiZiqni = new ConnectionsApiZiqni();
export default ConnectionsApiZiqni;
