// <<< DO NOT EDIT GENERATED FILES DIRECTLY! api-store-mutations.mustache >>>
import * as types from './mutation-types';
import * as _ from 'lodash';
import {
  handleErrorResponse, handleItemErrorResponse,
  handleItemSuccessResponse,
  handleSuccessResponse
} from "../../../../../utils/ZiqniAdminApiHandler";
import apiResponseFields from '../../../fields/ApiResponseZq.js';
import createNotificationRequestFields from '../../../fields/CreateNotificationRequestZq.js';
import notificationResponseFields from '../../../fields/NotificationResponseZq.js';
import queryRequestFields from '../../../fields/QueryRequestZq.js';
import updateNotificationStatusRequestFields from '../../../fields/UpdateNotificationStatusRequestZq.js';

const mutations = {
    [types.API_REQUEST](state) {
        state.loading = true;
        state.loaded = false;
    },
    [types.CLEAR_MESSAGE](state){
        state.message = null;
    },

    [types.UPDATE_FIELDS](state, data) {
        state.hiddenFields = data._hiddenFields;
        state.fields = _.difference(state.originalFields, state.hiddenFields);
        localStorage.setItem('hiddenNotificationsKey', JSON.stringify(state.hiddenFields));
    },

    [types.CLEAR_FILTER](state) {
        state.fields = _.difference(
            state.originalFields,
            state.originHiddenFields
        );
        state.hiddenFields = state.originHiddenFields;
        localStorage.removeItem('hiddenNotificationsKey');
    },

    //NotificationResponse
    [types.GETNOTIFICATIONS_ITEM_SUCCESS](state, response) {
      handleItemSuccessResponse(types.GETNOTIFICATIONS_ITEM_SUCCESS,notificationResponseFields, state, response, 'notification');
    },

    //NotificationResponse
    [types.GETNOTIFICATIONS_ERROR](state, error) {
      handleItemErrorResponse(types.GETNOTIFICATIONS_ITEM_ITEM_ERROR, notificationResponseFields, state, error);
    },


    //ApiResponse
    [types.CREATENOTIFICATIONS_SUCCESS](state, response) {
      handleSuccessResponse(types.CREATENOTIFICATIONS_SUCCESS,apiResponseFields, state, response);
    },

    //ApiResponse
    [types.CREATENOTIFICATIONS_ERROR](state, error) {
      handleErrorResponse(types.CREATENOTIFICATIONS_ERROR, apiResponseFields, state, error);
    },

    //ApiResponse
    [types.DELETENOTIFICATIONS_SUCCESS](state, response) {
      handleSuccessResponse(types.DELETENOTIFICATIONS_SUCCESS,apiResponseFields, state, response);
    },

    //ApiResponse
    [types.DELETENOTIFICATIONS_ERROR](state, error) {
      handleErrorResponse(types.DELETENOTIFICATIONS_ERROR, apiResponseFields, state, error);
    },

    //ApiResponse
    [types.DELETENOTIFICATIONSBYQUERY_SUCCESS](state, response) {
      handleSuccessResponse(types.DELETENOTIFICATIONSBYQUERY_SUCCESS,apiResponseFields, state, response);
    },

    //ApiResponse
    [types.DELETENOTIFICATIONSBYQUERY_ERROR](state, error) {
      handleErrorResponse(types.DELETENOTIFICATIONSBYQUERY_ERROR, apiResponseFields, state, error);
    },

    //NotificationResponse
    [types.GETNOTIFICATIONS_SUCCESS](state, response) {
      handleSuccessResponse(types.GETNOTIFICATIONS_SUCCESS,notificationResponseFields, state, response);
    },

    //NotificationResponse
    [types.GETNOTIFICATIONS_ERROR](state, error) {
      handleErrorResponse(types.GETNOTIFICATIONS_ERROR, notificationResponseFields, state, error);
    },

    //NotificationResponse
    [types.GETNOTIFICATIONSBYQUERY_SUCCESS](state, response) {
      handleSuccessResponse(types.GETNOTIFICATIONSBYQUERY_SUCCESS,notificationResponseFields, state, response);
    },

    //NotificationResponse
    [types.GETNOTIFICATIONSBYQUERY_ERROR](state, error) {
      handleErrorResponse(types.GETNOTIFICATIONSBYQUERY_ERROR, notificationResponseFields, state, error);
    },

    //ApiResponse
    [types.UPDATENOTIFICATIONSSTATUS_SUCCESS](state, response) {
      handleSuccessResponse(types.UPDATENOTIFICATIONSSTATUS_SUCCESS,apiResponseFields, state, response);
    },

    //ApiResponse
    [types.UPDATENOTIFICATIONSSTATUS_ERROR](state, error) {
      handleErrorResponse(types.UPDATENOTIFICATIONSSTATUS_ERROR, apiResponseFields, state, error);
    },
};

export default mutations;
