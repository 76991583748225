// <<< DO NOT EDIT GENERATED FILES DIRECTLY! api-store-mutation-types.mustache >>>
export const API_REQUEST = 'API_REQUEST';


export const GETAPIKEYS_ITEM_REQ = 'GETAPIKEYS_ITEM_REQ';
export const GETAPIKEYS_ITEM_ERROR = 'GETAPIKEYS_ITEM_ERROR';
export const GETAPIKEYS_ITEM_SUCCESS = 'GETAPIKEYS_ITEM_SUCCESS'

export const UPDATE_FIELDS = 'UPDATE_FIELDS';
export const CLEAR_FILTER = 'CLEAR_FILTER';
export const CLEAR_MESSAGE = 'CLEAR_MESSAGE';

export const CREATEAPIKEYS_REQ = 'CREATEAPIKEYS_REQ';
export const CREATEAPIKEYS_ERROR = 'CREATEAPIKEYS_ERROR';
export const CREATEAPIKEYS_SUCCESS = 'CREATEAPIKEYS_SUCCESS';

export const DELETEAPIKEYS_REQ = 'DELETEAPIKEYS_REQ';
export const DELETEAPIKEYS_ERROR = 'DELETEAPIKEYS_ERROR';
export const DELETEAPIKEYS_SUCCESS = 'DELETEAPIKEYS_SUCCESS';

export const DELETEAPIKEYSBYID_REQ = 'DELETEAPIKEYSBYID_REQ';
export const DELETEAPIKEYSBYID_ERROR = 'DELETEAPIKEYSBYID_ERROR';
export const DELETEAPIKEYSBYID_SUCCESS = 'DELETEAPIKEYSBYID_SUCCESS';

export const DELETEAPIKEYSBYQUERY_REQ = 'DELETEAPIKEYSBYQUERY_REQ';
export const DELETEAPIKEYSBYQUERY_ERROR = 'DELETEAPIKEYSBYQUERY_ERROR';
export const DELETEAPIKEYSBYQUERY_SUCCESS = 'DELETEAPIKEYSBYQUERY_SUCCESS';

export const GETAPIKEYS_REQ = 'GETAPIKEYS_REQ';
export const GETAPIKEYS_ERROR = 'GETAPIKEYS_ERROR';
export const GETAPIKEYS_SUCCESS = 'GETAPIKEYS_SUCCESS';

export const GETAPIKEYSBYID_REQ = 'GETAPIKEYSBYID_REQ';
export const GETAPIKEYSBYID_ERROR = 'GETAPIKEYSBYID_ERROR';
export const GETAPIKEYSBYID_SUCCESS = 'GETAPIKEYSBYID_SUCCESS';

export const GETAPIKEYSBYQUERY_REQ = 'GETAPIKEYSBYQUERY_REQ';
export const GETAPIKEYSBYQUERY_ERROR = 'GETAPIKEYSBYQUERY_ERROR';
export const GETAPIKEYSBYQUERY_SUCCESS = 'GETAPIKEYSBYQUERY_SUCCESS';

export const UPDATEAPIKEYS_REQ = 'UPDATEAPIKEYS_REQ';
export const UPDATEAPIKEYS_ERROR = 'UPDATEAPIKEYS_ERROR';
export const UPDATEAPIKEYS_SUCCESS = 'UPDATEAPIKEYS_SUCCESS';

