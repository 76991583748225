// Genearetd using ziqni-admin-api.mustache
import {ApiClient} from '@ziqni-tech/admin-api-client';
import { EventsApi } from "@ziqni-tech/admin-api-client";

const defaultClient = ApiClient.instance;
defaultClient.basePath = sdkApiConfig.baseApiUrlPath;

import { sdkApiConfig } from '@/config';
import { queryConverter } from '@/utils/query.convert';
import { errorHandler } from '@/utils/errorHandler';
class EventsApiZiqni {

  constructor() {
    this.defaultOptions = {};
    this.ziqniEventsApi = new EventsApi();
    this.OAuth2 = this.ziqniEventsApi.apiClient.authentications['OAuth2'];
    this.OAuth2.accessToken = localStorage.getItem('vue-token');
  }

  /**
   * createEvents: . Notes: Create an Event
   *
   * BaseName: Events
   * Path: /events
   * Http Method: POST, Consumes: [{mediaType&#x3D;application/json}], Produces: [{mediaType&#x3D;application/json}]
   * ReturnType: ApiResponse, ReturnBaseType: ApiResponse, ReturnContainer: 
   *  
   * OptionalParams:
   *   ops { 
   *   - body [body]   >>> Create an Event
   *      |  dataType: [CreateEventRequest], baseType: CreateEventRequest, 
   *      |  isBodyParam: true, 
   *      | 
   *   }
   *
   * Constraints:
   * hasAuthMethods: true, hasConsumes: true, hasProduces: true, hasParams: true, hasOptionalParams: true,
   * hasRequiredParams: false, returnTypeIsPrimitive: false, returnSimpleType: true, subresourceOperation: false,
   * isMap: false, isArray: false, isMultipart: false, isResponseBinary: false, isResponseFile: false, hasReference: true,
   * hasDefaultResponse: false, hasErrorResponseObject: true, isRestfulIndex: false, isRestfulShow: false,
   * isRestfulCreate: true, isRestfulUpdate: false, isRestfulDestroy: false, isRestful: true, isDeprecated: false,
   * isCallbackRequest: false, uniqueItems: false hasOptionalParams 
   */
  createEvents(opts){
    this.OAuth2.accessToken = localStorage.getItem('vue-token');
    return new Promise((resolve, reject) => {
      const callback = (error, data, responseString) => {
        const errorsFromHandler = errorHandler(error, data, responseString);
        if (error) {
          reject(error.message);
        } else if (errorsFromHandler.length) {
          const errors = errorsFromHandler.join(', ');
          reject(errors);
        } else {
          resolve(data);
        }
      };
      this.ziqniEventsApi.createEvents(opts, callback);
    });
  }

  /**
   * getEvents: . Notes: Retrieve an Event or a list of Events from Ziqni by unique Event ID&#39;s or any other POST body parameters using the POST method
   *
   * BaseName: Events
   * Path: /events
   * Http Method: GET, Consumes: , Produces: [{mediaType&#x3D;application/json}]
   * ReturnType: EventResponse, ReturnBaseType: EventResponse, ReturnContainer: 
   *  
   * OptionalParams:
   *   ops { 
   *   - id [id]   >>> The unique identifiers of the resources
   *      |  dataType: [String], baseType: String, 
   *      |  isQueryParam: true, isCollectionFormatMulti: true, isExplode: true, collectionFormat: multi, 
   *      | 
   *  
   *   - limit [_limit] isIntegerisUnboundedInteger isPrimitiveType: true >>> Limit the returned total records found
   *      |  dataType: Number, 
   *      |  isQueryParam: true, isExplode: true, 
   *      | 
   *  
   *   - skip [_skip] isIntegerisUnboundedInteger isPrimitiveType: true >>> Skip the returned records found and return the next batch of records
   *      |  dataType: Number, 
   *      |  isQueryParam: true, isExplode: true, 
   *      | 
   *   }
   *
   * Constraints:
   * hasAuthMethods: true, hasConsumes: false, hasProduces: true, hasParams: true, hasOptionalParams: true,
   * hasRequiredParams: false, returnTypeIsPrimitive: false, returnSimpleType: true, subresourceOperation: false,
   * isMap: false, isArray: false, isMultipart: false, isResponseBinary: false, isResponseFile: false, hasReference: true,
   * hasDefaultResponse: false, hasErrorResponseObject: true, isRestfulIndex: true, isRestfulShow: false,
   * isRestfulCreate: false, isRestfulUpdate: false, isRestfulDestroy: false, isRestful: true, isDeprecated: false,
   * isCallbackRequest: false, uniqueItems: false hasOptionalParams 
   */
  getEvents(opts){
    this.OAuth2.accessToken = localStorage.getItem('vue-token');
    return new Promise((resolve, reject) => {
      const callback = (error, data, responseString) => {
        const errorsFromHandler = errorHandler(error, data, responseString);
        if (error) {
          reject(error.message);
        } else if (errorsFromHandler.length) {
          const errors = errorsFromHandler.join(', ');
          reject(errors);
        } else {
          resolve(data);
        }
      };
      this.ziqniEventsApi.getEvents(opts, callback);
    });
  }

  /**
   * getEventsByQuery: . Notes: Retrieve an Event or a list of Events from Ziqni by unique Event ID&#39;s or any other POST body parameters using the POST method
   *
   * BaseName: Events
   * Path: /events/query
   * Http Method: POST, Consumes: [{mediaType&#x3D;application/json}], Produces: [{mediaType&#x3D;application/json}]
   * ReturnType: EventResponse, ReturnBaseType: EventResponse, ReturnContainer: 
   *  
   * OptionalParams:
   *   ops { 
   *   - body [body]  isModel: true >>> Retrieve an Event or a list of Events from Ziqni by unique Event ID&#39;s or any other POST body parameters using the POST method
   *      |  dataType: QueryRequest, baseType: QueryRequest, 
   *      |  isBodyParam: true, 
   *      | 
   *   }
   *
   * Constraints:
   * hasAuthMethods: true, hasConsumes: true, hasProduces: true, hasParams: true, hasOptionalParams: true,
   * hasRequiredParams: false, returnTypeIsPrimitive: false, returnSimpleType: true, subresourceOperation: false,
   * isMap: false, isArray: false, isMultipart: false, isResponseBinary: false, isResponseFile: false, hasReference: true,
   * hasDefaultResponse: false, hasErrorResponseObject: true, isRestfulIndex: false, isRestfulShow: false,
   * isRestfulCreate: false, isRestfulUpdate: false, isRestfulDestroy: false, isRestful: false, isDeprecated: false,
   * isCallbackRequest: false, uniqueItems: false hasOptionalParams 
   */
  getEventsByQuery(opts){
    this.OAuth2.accessToken = localStorage.getItem('vue-token');
    return new Promise((resolve, reject) => {
      const callback = (error, data, responseString) => {
        const errorsFromHandler = errorHandler(error, data, responseString);
        if (error) {
          reject(error.message);
        } else if (errorsFromHandler.length) {
          const errors = errorsFromHandler.join(', ');
          reject(errors);
        } else {
          resolve(data);
        }
      };
      this.ziqniEventsApi.getEventsByQuery(opts, callback);
    });
  }

}

export const eventsApiZiqni = new EventsApiZiqni();
export default EventsApiZiqni;
