// <<< DO NOT EDIT GENERATED FILES DIRECTLY! api-store-mutations.mustache >>>
import * as types from './mutation-types';
import * as _ from 'lodash';
import {
  handleErrorResponse, handleItemErrorResponse,
  handleItemSuccessResponse,
  handleSuccessResponse
} from "../../../../../utils/ZiqniAdminApiHandler";
import apiResponseFields from '../../../fields/ApiResponseZq.js';
import createFileRepositoryRequestFields from '../../../fields/CreateFileRepositoryRequestZq.js';
import fileRepositoryResponseFields from '../../../fields/FileRepositoryResponseZq.js';
import queryRequestFields from '../../../fields/QueryRequestZq.js';
import updateFileRepositoryRequestFields from '../../../fields/UpdateFileRepositoryRequestZq.js';

const mutations = {
    [types.API_REQUEST](state) {
        state.loading = true;
        state.loaded = false;
    },
    [types.CLEAR_MESSAGE](state){
        state.message = null;
    },

    [types.UPDATE_FIELDS](state, data) {
        state.hiddenFields = data._hiddenFields;
        state.fields = _.difference(state.originalFields, state.hiddenFields);
        localStorage.setItem('hiddenFileRepositoriesKey', JSON.stringify(state.hiddenFields));
    },

    [types.CLEAR_FILTER](state) {
        state.fields = _.difference(
            state.originalFields,
            state.originHiddenFields
        );
        state.hiddenFields = state.originHiddenFields;
        localStorage.removeItem('hiddenFileRepositoriesKey');
    },

    //FileRepositoryResponse
    [types.GETFILEREPOSITORIES_ITEM_SUCCESS](state, response) {
      handleItemSuccessResponse(types.GETFILEREPOSITORIES_ITEM_SUCCESS,fileRepositoryResponseFields, state, response, 'fileRepository');
    },

    //FileRepositoryResponse
    [types.GETFILEREPOSITORIES_ERROR](state, error) {
      handleItemErrorResponse(types.GETFILEREPOSITORIES_ITEM_ITEM_ERROR, fileRepositoryResponseFields, state, error);
    },


    //ApiResponse
    [types.CREATEFILEREPOSITORIES_SUCCESS](state, response) {
      handleSuccessResponse(types.CREATEFILEREPOSITORIES_SUCCESS,apiResponseFields, state, response);
    },

    //ApiResponse
    [types.CREATEFILEREPOSITORIES_ERROR](state, error) {
      handleErrorResponse(types.CREATEFILEREPOSITORIES_ERROR, apiResponseFields, state, error);
    },

    //ApiResponse
    [types.DELETEFILEREPOSITORIES_SUCCESS](state, response) {
      handleSuccessResponse(types.DELETEFILEREPOSITORIES_SUCCESS,apiResponseFields, state, response);
    },

    //ApiResponse
    [types.DELETEFILEREPOSITORIES_ERROR](state, error) {
      handleErrorResponse(types.DELETEFILEREPOSITORIES_ERROR, apiResponseFields, state, error);
    },

    //ApiResponse
    [types.DELETEFILEREPOSITORIESBYQUERY_SUCCESS](state, response) {
      handleSuccessResponse(types.DELETEFILEREPOSITORIESBYQUERY_SUCCESS,apiResponseFields, state, response);
    },

    //ApiResponse
    [types.DELETEFILEREPOSITORIESBYQUERY_ERROR](state, error) {
      handleErrorResponse(types.DELETEFILEREPOSITORIESBYQUERY_ERROR, apiResponseFields, state, error);
    },

    //FileRepositoryResponse
    [types.GETFILEREPOSITORIES_SUCCESS](state, response) {
      handleSuccessResponse(types.GETFILEREPOSITORIES_SUCCESS,fileRepositoryResponseFields, state, response);
    },

    //FileRepositoryResponse
    [types.GETFILEREPOSITORIES_ERROR](state, error) {
      handleErrorResponse(types.GETFILEREPOSITORIES_ERROR, fileRepositoryResponseFields, state, error);
    },

    //FileRepositoryResponse
    [types.GETFILEREPOSITORIESBYQUERY_SUCCESS](state, response) {
      handleSuccessResponse(types.GETFILEREPOSITORIESBYQUERY_SUCCESS,fileRepositoryResponseFields, state, response);
    },

    //FileRepositoryResponse
    [types.GETFILEREPOSITORIESBYQUERY_ERROR](state, error) {
      handleErrorResponse(types.GETFILEREPOSITORIESBYQUERY_ERROR, fileRepositoryResponseFields, state, error);
    },

    //ApiResponse
    [types.UPDATEFILEREPOSITORIES_SUCCESS](state, response) {
      handleSuccessResponse(types.UPDATEFILEREPOSITORIES_SUCCESS,apiResponseFields, state, response);
    },

    //ApiResponse
    [types.UPDATEFILEREPOSITORIES_ERROR](state, error) {
      handleErrorResponse(types.UPDATEFILEREPOSITORIES_ERROR, apiResponseFields, state, error);
    },
};

export default mutations;
