// <<< DO NOT EDIT GENERATED FILES DIRECTLY! model-store-fields-all.mustache >>>


import modelDefault from './ModelDefaultZq';
import sourceLanguage from './SourceLanguageZq';
import transformerAllOf from './TransformerAllOfZq';

const transformer = this;

export default {
  objectType: 'Transformer',
  allowableValuesKeys: [],
  constraints: [],
  getter: 'transformers',
  hiddenFields: ['spaceName','source',],
  searchableFields: ['id','created','name','source',], 
  stringBuilderFields: [],
  operations: [], 
  searchableAllFields: ['name','source',],
  translatableFields: [],
  sortableFields: ['id','spaceName','created','name',],
  customFieldsFieldName: [],
  metadataFieldName: [],
  tagFieldName: [],
  entityLinksFrom: [],

  route: {
    list: 'ListTransformer',
    preview: 'PreviewTransformer',
  },
  skip: 0,
  limit: 10,
  sortBy: [
    {
      "queryField": "name",
      "order": "Asc"
    }
  ],
  fieldList: ['id','spaceName','created','name','source','sourceLanguage'],
  fields: { 
    id: {
      name: 'id',
      complexType: null,
      displayOrder: 2,
      allowableValuesKeys: [],
      constraints: ['required','isPrimitiveType','isString',
                     ],
      formType: null,
    },
    spaceName: {
      name: 'spaceName',
      complexType: null,
      displayOrder: 9000,
      allowableValuesKeys: [],
      constraints: ['required','isPrimitiveType','isString',
                     ],
      formType: null,
    },
    created: {
      name: 'created',
      complexType: null,
      displayOrder: 9001,
      allowableValuesKeys: [],
      constraints: ['required','isPrimitiveType','isDateTime',
                     ],
      formType: null,
    },
    name: {
      name: 'name',
      complexType: null,
      displayOrder: 1000,
      allowableValuesKeys: [],
      constraints: ['required','isPrimitiveType','isString',
                     ],
      formType: null,
    },
    source: {
      name: 'source',
      complexType: null,
      displayOrder: 1002,
      allowableValuesKeys: [],
      constraints: ['required','isPrimitiveType','isString',
                     ],
      formType: null,
    },
    sourceLanguage: {
      name: 'sourceLanguage',
      complexType: sourceLanguage,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['required',
                     ],
      formType: null,
    }
  }
};
