// <<< DO NOT EDIT GENERATED FILES DIRECTLY! model-store-fields-all.mustache >>>


import achievementLiveStatus from './AchievementLiveStatusZq';

const achievementLiveStatusRequestAllOf = this;

export default {
  objectType: 'AchievementLiveStatusRequest_allOf',
  allowableValuesKeys: [],
  constraints: ['isMap',],
  getter: null,
  hiddenFields: [],
  searchableFields: [], 
  searchableAllFields: [],
  translatableFields: [],
  sortableFields: [],
  customFieldsFieldName: [],
  metadataFieldName: [],
  tagFieldName: [],
  entityLinksFrom: [],

  route: {
    
    
  },
  fieldList: ['status'],
  fields: { 
    status: {
      name: 'status',
      complexType: achievementLiveStatus,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['required',
                     ],
      formType: null,
    }
  }
};
