// <<< DO NOT EDIT GENERATED FILES DIRECTLY! model-store-fields-all.mustache >>>


import connectionState from './ConnectionStateZq';

const webhookAllOf = this;

export default {
  objectType: 'Webhook_allOf',
  allowableValuesKeys: [],
  constraints: ['isMap',],
  getter: null,
  hiddenFields: ['headers',],
  searchableFields: ['postToUrl','triggers','description','name',], 
  searchableAllFields: ['postToUrl','description','name',],
  translatableFields: [],
  sortableFields: ['name',],
  customFieldsFieldName: [],
  metadataFieldName: [],
  tagFieldName: [],
  entityLinksFrom: [],

  route: {
    
    
  },
  fieldList: ['postToUrl','triggers','description','name','headers','status'],
  fields: { 
    postToUrl: {
      name: 'postToUrl',
      complexType: null,
      displayOrder: 1001,
      allowableValuesKeys: [],
      constraints: ['required','isPrimitiveType','isString',
                     ],
      formType: null,
    },
    triggers: {
      name: 'triggers',
      complexType: null,
      displayOrder: 1007,
      allowableValuesKeys: [],
      constraints: ['required','isPrimitiveType','isContainer','isArray',
                    'isString', ],
      formType: null,
    },
    description: {
      name: 'description',
      complexType: null,
      displayOrder: 1004,
      allowableValuesKeys: [],
      constraints: ['isPrimitiveType','isString',
                     ],
      formType: 'TEXTAREA',
    },
    name: {
      name: 'name',
      complexType: null,
      displayOrder: 1000,
      allowableValuesKeys: [],
      constraints: ['required','isPrimitiveType','isString',
                     ],
      formType: null,
    },
    headers: {
      name: 'headers',
      complexType: null,
      displayOrder: 1005,
      allowableValuesKeys: [],
      constraints: ['isPrimitiveType','isContainer','isMap',
                    'isString', ],
      formType: null,
    },
    status: {
      name: 'status',
      complexType: connectionState,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['required',
                     ],
      formType: null,
    }
  }
};
