import mutations from './mutations';
import * as actions from './actions';
import * as getters from './getters';

const initialState = {
  languagesList: [],
  customFieldsList: [],
  tagsList: [],
  loading: false,
  loaded: true,
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations,
};
