export default {
    /**
     * Maximum allowed decimal places
     * @type {string}
     */
    maxDecimalPlace: '0.0000001',
    /**
     * Minimum allowed entry value
     * @type {string}
     */
    minValue: '0',
    /**
     *  multiselect 'selectLabel'
     * @type {string}
     */
    selectLabel: 'Press to select',
    tagPlaceholder: 'Press to create a tag',
    removeLabel: 'Press to remove'
}