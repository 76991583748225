// <<< DO NOT EDIT GENERATED FILES DIRECTLY! api-store-mutations.mustache >>>
import * as types from './mutation-types';
import * as _ from 'lodash';
import {
  handleErrorResponse, handleItemErrorResponse,
  handleItemSuccessResponse,
  handleSuccessResponse
} from "../../../../../utils/ZiqniAdminApiHandler";
import apiResponseFields from '../../../fields/ApiResponseZq.js';
import createLanguageRequestFields from '../../../fields/CreateLanguageRequestZq.js';
import languageResponseFields from '../../../fields/LanguageResponseZq.js';
import queryRequestFields from '../../../fields/QueryRequestZq.js';
import updateLanguageRequestFields from '../../../fields/UpdateLanguageRequestZq.js';

const mutations = {
    [types.API_REQUEST](state) {
        state.loading = true;
        state.loaded = false;
    },
    [types.CLEAR_MESSAGE](state){
        state.message = null;
    },

    [types.UPDATE_FIELDS](state, data) {
        state.hiddenFields = data._hiddenFields;
        state.fields = _.difference(state.originalFields, state.hiddenFields);
        localStorage.setItem('hiddenLanguagesKey', JSON.stringify(state.hiddenFields));
    },

    [types.CLEAR_FILTER](state) {
        state.fields = _.difference(
            state.originalFields,
            state.originHiddenFields
        );
        state.hiddenFields = state.originHiddenFields;
        localStorage.removeItem('hiddenLanguagesKey');
    },

    //LanguageResponse
    [types.GETLANGUAGES_ITEM_SUCCESS](state, response) {
      handleItemSuccessResponse(types.GETLANGUAGES_ITEM_SUCCESS,languageResponseFields, state, response, 'language');
    },

    //LanguageResponse
    [types.GETLANGUAGES_ERROR](state, error) {
      handleItemErrorResponse(types.GETLANGUAGES_ITEM_ITEM_ERROR, languageResponseFields, state, error);
    },


    //ApiResponse
    [types.CREATELANGUAGES_SUCCESS](state, response) {
      handleSuccessResponse(types.CREATELANGUAGES_SUCCESS,apiResponseFields, state, response);
    },

    //ApiResponse
    [types.CREATELANGUAGES_ERROR](state, error) {
      handleErrorResponse(types.CREATELANGUAGES_ERROR, apiResponseFields, state, error);
    },

    //ApiResponse
    [types.DELETELANGUAGES_SUCCESS](state, response) {
      handleSuccessResponse(types.DELETELANGUAGES_SUCCESS,apiResponseFields, state, response);
    },

    //ApiResponse
    [types.DELETELANGUAGES_ERROR](state, error) {
      handleErrorResponse(types.DELETELANGUAGES_ERROR, apiResponseFields, state, error);
    },

    //ApiResponse
    [types.DELETELANGUAGESBYQUERY_SUCCESS](state, response) {
      handleSuccessResponse(types.DELETELANGUAGESBYQUERY_SUCCESS,apiResponseFields, state, response);
    },

    //ApiResponse
    [types.DELETELANGUAGESBYQUERY_ERROR](state, error) {
      handleErrorResponse(types.DELETELANGUAGESBYQUERY_ERROR, apiResponseFields, state, error);
    },

    //LanguageResponse
    [types.GETLANGUAGES_SUCCESS](state, response) {
      handleSuccessResponse(types.GETLANGUAGES_SUCCESS,languageResponseFields, state, response);
    },

    //LanguageResponse
    [types.GETLANGUAGES_ERROR](state, error) {
      handleErrorResponse(types.GETLANGUAGES_ERROR, languageResponseFields, state, error);
    },

    //LanguageResponse
    [types.GETLANGUAGESBYQUERY_SUCCESS](state, response) {
      handleSuccessResponse(types.GETLANGUAGESBYQUERY_SUCCESS,languageResponseFields, state, response);
    },

    //LanguageResponse
    [types.GETLANGUAGESBYQUERY_ERROR](state, error) {
      handleErrorResponse(types.GETLANGUAGESBYQUERY_ERROR, languageResponseFields, state, error);
    },

    //LanguageResponse
    [types.GETLISTOFALLLANGUAGES_SUCCESS](state, response) {
      handleSuccessResponse(types.GETLISTOFALLLANGUAGES_SUCCESS,languageResponseFields, state, response);
    },

    //LanguageResponse
    [types.GETLISTOFALLLANGUAGES_ERROR](state, error) {
      handleErrorResponse(types.GETLISTOFALLLANGUAGES_ERROR, languageResponseFields, state, error);
    },

    //ApiResponse
    [types.UPDATELANGUAGES_SUCCESS](state, response) {
      handleSuccessResponse(types.UPDATELANGUAGES_SUCCESS,apiResponseFields, state, response);
    },

    //ApiResponse
    [types.UPDATELANGUAGES_ERROR](state, error) {
      handleErrorResponse(types.UPDATELANGUAGES_ERROR, apiResponseFields, state, error);
    },
};

export default mutations;
