// <<< DO NOT EDIT GENERATED FILES DIRECTLY! api-store-mutation-types.mustache >>>
export const API_REQUEST = 'API_REQUEST';


export const GETLANGUAGES_ITEM_REQ = 'GETLANGUAGES_ITEM_REQ';
export const GETLANGUAGES_ITEM_ERROR = 'GETLANGUAGES_ITEM_ERROR';
export const GETLANGUAGES_ITEM_SUCCESS = 'GETLANGUAGES_ITEM_SUCCESS'

export const UPDATE_FIELDS = 'UPDATE_FIELDS';
export const CLEAR_FILTER = 'CLEAR_FILTER';
export const CLEAR_MESSAGE = 'CLEAR_MESSAGE';

export const CREATELANGUAGES_REQ = 'CREATELANGUAGES_REQ';
export const CREATELANGUAGES_ERROR = 'CREATELANGUAGES_ERROR';
export const CREATELANGUAGES_SUCCESS = 'CREATELANGUAGES_SUCCESS';

export const DELETELANGUAGES_REQ = 'DELETELANGUAGES_REQ';
export const DELETELANGUAGES_ERROR = 'DELETELANGUAGES_ERROR';
export const DELETELANGUAGES_SUCCESS = 'DELETELANGUAGES_SUCCESS';

export const DELETELANGUAGESBYQUERY_REQ = 'DELETELANGUAGESBYQUERY_REQ';
export const DELETELANGUAGESBYQUERY_ERROR = 'DELETELANGUAGESBYQUERY_ERROR';
export const DELETELANGUAGESBYQUERY_SUCCESS = 'DELETELANGUAGESBYQUERY_SUCCESS';

export const GETLANGUAGES_REQ = 'GETLANGUAGES_REQ';
export const GETLANGUAGES_ERROR = 'GETLANGUAGES_ERROR';
export const GETLANGUAGES_SUCCESS = 'GETLANGUAGES_SUCCESS';

export const GETLANGUAGESBYQUERY_REQ = 'GETLANGUAGESBYQUERY_REQ';
export const GETLANGUAGESBYQUERY_ERROR = 'GETLANGUAGESBYQUERY_ERROR';
export const GETLANGUAGESBYQUERY_SUCCESS = 'GETLANGUAGESBYQUERY_SUCCESS';

export const GETLISTOFALLLANGUAGES_REQ = 'GETLISTOFALLLANGUAGES_REQ';
export const GETLISTOFALLLANGUAGES_ERROR = 'GETLISTOFALLLANGUAGES_ERROR';
export const GETLISTOFALLLANGUAGES_SUCCESS = 'GETLISTOFALLLANGUAGES_SUCCESS';

export const UPDATELANGUAGES_REQ = 'UPDATELANGUAGES_REQ';
export const UPDATELANGUAGES_ERROR = 'UPDATELANGUAGES_ERROR';
export const UPDATELANGUAGES_SUCCESS = 'UPDATELANGUAGES_SUCCESS';

