// <<< DO NOT EDIT GENERATED FILES DIRECTLY! api-store-actions.mustache >>>
import { delay, cloneDeep } from 'lodash';
import * as types from './mutation-types';
import sdkApiConfig from '@/config/sdkApiConfig';
import updateFields from '@/utils/updateFields';
import { rewardTypesApiZiqni } from '@/generated/ziqni/api/RewardTypesApiZiqni';
import router from '@/router';

import apiResponseFields from '../../../fields/ApiResponseZq.js';
import createRewardTypeRequestFields from '../../../fields/CreateRewardTypeRequestZq.js';
import queryRequestFields from '../../../fields/QueryRequestZq.js';
import rewardTypeResponseFields from '../../../fields/RewardTypeResponseZq.js';
import updateRewardTypeRequestFields from '../../../fields/UpdateRewardTypeRequestZq.js';
import {handleRequest,handleRequestWithBody} from "../../../../../utils/ZiqniAdminApiHandler";

export const handleFields = ({ commit, state }, data) => {
  updateFields(commit, state, data, 'hiddenRewardTypesKey');
};

// Clear rewardTypes Message
export const handleClearMessage = ({ commit }) => {
  commit(types.CLEAR_MESSAGE);
};

// clear filter
export const handleClearFilter = ({ commit }) => {
  commit(types.CLEAR_FILTER);
};

//REWARDTYPES - REWARDTYPES
export const handleRewardTypesChanges = ({ commit }, data) => {
  commit('SET_REWARDTYPES', data);
};
/**
 * RewardTypeResponse from backend.
 * GET getRewardTypes and return [RewardTypeResponse]
 * vars: [
 *    [[id]] id[ opts, isArray, isQueryParam]
 *    [[limit]] _limit[ opts, isInteger, isQueryParam]
 *    [[skip]] _skip[ opts, isInteger, isQueryParam]]
 */
export const handleGetRewardTypes_item = ({ commit }, idArray, limit, skip) => {
  commit(types.API_REQUEST);
  let opts = { 
    id: idArray, 
    limit: limit, 
    skip: skip
  };

  return rewardTypesApiZiqni
    .getRewardTypes(
      opts
    )
    .then((response) => {
      commit(types.GETREWARDTYPES_ITEM_SUCCESS, { response });
      return response.results;
    })
    .catch((e) => {
      commit(types.GETREWARDTYPES_ITEM_ERROR, e);
      return [];
    });
};

/**
 * ApiResponse from backend.
 * POST createRewardTypes and return [ApiResponse]
 * vars: [
 *    [[body]] CreateRewardTypeRequest[ required, isArray, isBodyParam, ]]
 */
export const handleCreateRewardTypes = ({ commit }, payload) => {
  const { createRewardTypeRequestArray } = payload;

  commit(types.API_REQUEST);
  

  return rewardTypesApiZiqni
    .createRewardTypes(
      createRewardTypeRequestArray
    )
    .then((response) => {
      commit(types.CREATEREWARDTYPES_SUCCESS, { response });
      return response.results;
    })
    .catch((e) => {
      commit(types.CREATEREWARDTYPES_ERROR, e);
      return [];
    });
};

/**
 * ApiResponse from backend.
 * DELETE deleteRewardTypes and return [ApiResponse]
 * vars: [
 *    [[id]] id[ opts, isArray, isQueryParam]]
 */
export const handleDeleteRewardTypes = ({ commit }, payload) => {
  const { idArray } = payload;

  commit(types.API_REQUEST);
  let opts = { 
    id: idArray
  };

  return rewardTypesApiZiqni
    .deleteRewardTypes(
      opts
    )
    .then((response) => {
      commit(types.DELETEREWARDTYPES_SUCCESS, { response });
      return response.results;
    })
    .catch((e) => {
      commit(types.DELETEREWARDTYPES_ERROR, e);
      return [];
    });
};

/**
 * ApiResponse from backend.
 * POST deleteRewardTypesByQuery and return [ApiResponse]
 * vars: [
 *    [[body]] QueryRequest[ opts, isBodyParam, ]]
 */
export const handleDeleteRewardTypesByQuery = ({ commit }, payload) => {
  const { queryRequest } = payload;

  commit(types.API_REQUEST);
  let opts = { 
    body: queryRequest
  };

  return rewardTypesApiZiqni
    .deleteRewardTypesByQuery(
      opts
    )
    .then((response) => {
      commit(types.DELETEREWARDTYPESBYQUERY_SUCCESS, { response });
      return response.results;
    })
    .catch((e) => {
      commit(types.DELETEREWARDTYPESBYQUERY_ERROR, e);
      return [];
    });
};

/**
 * RewardTypeResponse from backend.
 * GET getRewardTypes and return [RewardTypeResponse]
 * vars: [
 *    [[id]] id[ opts, isArray, isQueryParam]
 *    [[limit]] _limit[ opts, isInteger, isQueryParam]
 *    [[skip]] _skip[ opts, isInteger, isQueryParam]]
 */
export const handleGetRewardTypes = ({ commit }, payload) => {
  const { idArray, limit, skip } = payload;

  commit(types.API_REQUEST);
  let opts = { 
    id: idArray, 
    limit: limit, 
    skip: skip
  };

  return rewardTypesApiZiqni
    .getRewardTypes(
      opts
    )
    .then((response) => {
      commit(types.GETREWARDTYPES_SUCCESS, { response });
      return response.results;
    })
    .catch((e) => {
      commit(types.GETREWARDTYPES_ERROR, e);
      return [];
    });
};

/**
 * RewardTypeResponse from backend.
 * POST getRewardTypesByQuery and return [RewardTypeResponse]
 * vars: [
 *    [[body]] QueryRequest[ opts, isBodyParam, ]]
 */
export const handleGetRewardTypesByQuery = ({ commit }, payload) => {
  const { queryRequest } = payload;

  commit(types.API_REQUEST);
  let opts = { 
    body: queryRequest
  };

  return rewardTypesApiZiqni
    .getRewardTypesByQuery(
      opts
    )
    .then((response) => {
      commit(types.GETREWARDTYPESBYQUERY_SUCCESS, { response });
      return response.results;
    })
    .catch((e) => {
      commit(types.GETREWARDTYPESBYQUERY_ERROR, e);
      return [];
    });
};

/**
 * ApiResponse from backend.
 * PUT updateRewardTypes and return [ApiResponse]
 * vars: [
 *    [[body]] UpdateRewardTypeRequest[ required, isArray, isBodyParam, ]]
 */
export const handleUpdateRewardTypes = ({ commit }, payload) => {
  const { updateRewardTypeRequestArray } = payload;

  commit(types.API_REQUEST);
  

  return rewardTypesApiZiqni
    .updateRewardTypes(
      updateRewardTypeRequestArray
    )
    .then((response) => {
      commit(types.UPDATEREWARDTYPES_SUCCESS, { response });
      return response.results;
    })
    .catch((e) => {
      commit(types.UPDATEREWARDTYPES_ERROR, e);
      return [];
    });
};
