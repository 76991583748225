import * as types from './mutation-types';
import {cloneDeep, difference} from "lodash";

const mutations = {
  [types.API_REQUEST](state) {
    state.loading = true;
    state.loaded = false;
  },
  // GET (GET METHOD)
  [types.GET_USER_SUCCESS](state, user) {
    // if (Object.keys(user.data).length > 0) {
    //   const fields = Object.keys(user.data.results[0]);
    //   fields.push('actions');
    //   state.originalFields = cloneDeep(fields);
    // }
    // state.fields = difference(state.originalFields, state.hiddenFields);
    state.fields = ["select", "email", "name", "surname", "spaceName", "added","pending","actions"];
    state.user = {
      id: user.data['account_id'],
      spaceName: user.data['space_name'],
      added: '',
      name: user.data['given_name'],
      pending: 'false',
      surname: user.data['family_name'],
      email: user.data['email'],
      role: 'User',
    };
    state.loading = false;
    state.loaded = true;
  },

  [types.GET_USER_ERROR](state, error) {
    state.message = error;
    state.loading = false;
    state.loaded = true;
  },

  // UPDATE FIELDS
  [types.UPDATE_FIELDS](state, data) {
    state.hiddenFields = data._hiddenFields;
    state.fields = difference(state.originalFields, state.hiddenFields);
    localStorage.setItem('hiddenRewardsKey', JSON.stringify(state.hiddenFields));

  },
  // CLEAR FILTER
  [types.CLEAR_FILTER](state) {
    state.fields = difference(
      state.originalFields,
      state.originHiddenFields
    );
    state.hiddenFields = state.originHiddenFields;
    localStorage.removeItem('hiddenRewardsKey');
  },
  // Clear Message
  [types.CLEAR_MESSAGE](state) {
    state.message = null;
  },

};

export default mutations;
