import * as types from './mutation-types';

import updateFields from '@/utils/updateFields';
import { logEventsApiZiqni } from '@/generated/ziqni/api/LogEventsApiZiqni';


export const handleFields = ({ commit, state }, data) => {
  updateFields(commit, state, data, 'hiddenLogEventsKey');
};

// Clear logEvents Message
export const handleClearMessage = ({ commit }) => {
  commit(types.CLEAR_MESSAGE);
};

// clear filter
export const handleClearFilter = ({ commit }) => {
  commit(types.CLEAR_FILTER);
};

//LOGEVENTS - LOGEVENTS
export const handleLogEventsChanges = ({ commit }, data) => {
  commit('SET_LOGEVENTS', data);
};

/**
 * LogEventResponse from backend.
 * POST getLogEventsByQuery and return [LogEventResponse]
 * vars: [
 *    [[body]] QueryRequest[ opts, isBodyParam, ]]
 */
export const handleGetLogEventsByQuery = ({ commit }, payload) => {
  const { queryRequest } = payload;

  commit(types.API_REQUEST);
  let opts = { 
    body: queryRequest
  };

  return logEventsApiZiqni
    .getLogEventsByQuery(
      opts
    )
    .then((response) => {
      commit(types.GETLOGEVENTSBYQUERY_SUCCESS, { response });
      return response.results;
    })
    .catch((e) => {
      commit(types.GETLOGEVENTSBYQUERY_ERROR, e);
      return [];
    });
};
