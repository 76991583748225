// <<< DO NOT EDIT GENERATED FILES DIRECTLY! model-store-fields-all.mustache >>>


import entityType from './EntityTypeZq';

const memberAwardAllOf = this;

export default {
  objectType: 'MemberAward_allOf',
  allowableValuesKeys: [],
  constraints: ['isMap',],
  getter: null,
  hiddenFields: [],
  searchableFields: [], 
  searchableAllFields: [],
  translatableFields: [],
  sortableFields: [],
  customFieldsFieldName: [],
  metadataFieldName: [],
  tagFieldName: [],
  entityLinksFrom: [],

  route: {
    
    
  },
  fieldList: ['rewardId','rewardType','rewardTypeId','rewardValue','delay','entityType','entityId','pointInTime','period','tags','metadata','constraints','claimedTimestamp'],
  fields: { 
    rewardId: {
      name: 'rewardId',
      complexType: null,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['required','isPrimitiveType','isString',
                     ],
      formType: null,
    },
    rewardType: {
      name: 'rewardType',
      complexType: null,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['required','isPrimitiveType','isString',
                     ],
      formType: null,
    },
    rewardTypeId: {
      name: 'rewardTypeId',
      complexType: null,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['required','isPrimitiveType','isString',
                     ],
      formType: null,
    },
    rewardValue: {
      name: 'rewardValue',
      complexType: null,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['required','isPrimitiveType','isNumeric','isDouble',
                     ],
      formType: null,
    },
    delay: {
      name: 'delay',
      complexType: null,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['isPrimitiveType','isNumeric','isInteger','isShort',
                     ],
      formType: null,
    },
    entityType: {
      name: 'entityType',
      complexType: entityType,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['required',
                     ],
      formType: null,
    },
    entityId: {
      name: 'entityId',
      complexType: null,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['required','isPrimitiveType','isString',
                     ],
      formType: null,
    },
    pointInTime: {
      name: 'pointInTime',
      complexType: null,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['isPrimitiveType','isDateTime',
                     ],
      formType: null,
    },
    period: {
      name: 'period',
      complexType: null,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['isPrimitiveType','isNumeric','isInteger','isShort',
                     ],
      formType: null,
    },
    tags: {
      name: 'tags',
      complexType: null,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['isPrimitiveType','isContainer','isArray',
                    'isString', ],
      formType: null,
    },
    metadata: {
      name: 'metadata',
      complexType: null,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['isPrimitiveType','isContainer','isMap',
                    'isString', ],
      formType: null,
    },
    constraints: {
      name: 'constraints',
      complexType: null,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['required','isPrimitiveType','isContainer','isArray',
                    'isString', ],
      formType: null,
    },
    claimedTimestamp: {
      name: 'claimedTimestamp',
      complexType: null,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['isPrimitiveType','isDateTime',
                     ],
      formType: null,
    }
  }
};
