import * as types from './mutation-types';
import { languagesApiZiqni } from '@/generated/ziqni/api/LanguagesApiZiqni';
import { customFieldsApiZiqni } from '@/generated/ziqni/api/CustomFieldsApiZiqni';
import { tagsApiZiqni } from '@/generated/ziqni/api/TagsApiZiqni';
import  customFields_fields from '/src/generated/ziqni/store/modules/customFields/fields';
import  tags_fields from '/src/generated/ziqni/store/modules/tags/fields';

export const handleGetAvailableLanguagesList = ({ commit }, data) => {
  const { skip, limit } = data;
  const body = {
    limit,
    skip,
  };

  commit(types.API_REQUEST);
  return languagesApiZiqni
      .getListOfAllLanguages(body)
      .then((data) => {
        commit(types.GET_LANGUAGE_LIST_SUCCESS, data);
        return data.results;
      })
      .catch((e) => {
        commit(types.GET_LANGUAGE_LIST_ERROR, e);
        return [];
      });
};

export const handleGetCustomFieldsByAppliesTo = ({ commit }, data) => {
    let { queryValue, skip, limit } = data;
    let queryFields = [customFields_fields.baseFields.fields.appliesTo.name];
    const ops = {
        body: {
            limit,
            skip,
            multiFields: [
                {
                    queryFields,
                    queryValue,
                },
            ],
        }
    };
    commit(types.API_REQUEST);

    return customFieldsApiZiqni
        .getCustomFieldsByQuery(ops)
        .then((data) => {
            const pages = Math.ceil(data.meta.totalRecords / data.meta.limit);
            commit(types.GET_CUSTOMFIELDS_APPLIES_TO_SUCCESS, { data, pages });
            return data.results;
        })
        .catch((e) => {
            commit(types.GET_CUSTOMFIELDS_APPLIES_TO_ERROR, e);
            return [];
        });
};

export const handleGetTagsByAppliesTo = ({ commit }, data) => {
    let { queryValue, skip, limit } = data;
    let queryFields = [tags_fields.baseFields.fields.entityTypes.name];
    const ops = {
        body: {
            limit: tags_fields.baseFields.limit,
            skip: tags_fields.baseFields.skip,
            multiFields: [
                {
                    queryFields,
                    queryValue,
                },
            ],
        }
    };
    commit(types.API_REQUEST);

    return tagsApiZiqni
        .getTagsByQuery(ops)
        .then((data) => {
            const pages = Math.ceil(data.meta.totalRecords / data.meta.limit);
            commit(types.GET_TAGS_APPLIES_TO_SUCCESS, { data, pages });
            return data.results;
        })
        .catch((e) => {
            commit(types.GET_TAGS_APPLIES_TO_ERROR, e);
            return [];
        });
};