const Achievements = () => import('@/views/ziqni/achievements');
const PreviewAchievement = () => import('@/views/ziqni/achievements/PreviewAchievement');
const EditAchievement = () => import('@/views/ziqni/achievements/UpdateAchievement');
const CreateAchievement = () => import('@/views/ziqni/achievements/CreateAchievement');

const PreviewAchievementWidget = () => import('@/views/ziqni/achievements/details/Widgets/PreviewWidget');
const CreateWidget = () => import('@/views/ziqni/achievements/details/Widgets/CreateWidget');
const EditWidget = () => import('@/views/ziqni/achievements/details/Widgets/EditWidget');

export default {
  path: 'achievements',
    component: {
  render(c) {
    return c('router-view');
  },
},
  children: [
    {
      path: '',
      name: 'Achievements',
      component: Achievements,
    },
    {
      path: 'detail/:id',
      name: 'PreviewAchievement',
      component: PreviewAchievement,
    },
    {
      path: 'edit-achievement/:id',
      name: 'EditAchievement',
      component: EditAchievement,
    },
    {
      path: 'create-achievement',
      name: 'CreateAchievement',
      component: CreateAchievement,
    },
    {
      path: 'create-achievement-widget',
      name: 'CreateWidget',
      component: CreateWidget,
    },
    {
      path: 'edit-achievement-widget/:id',
      name: 'EditWidget',
      component: EditWidget,
    },
    {
      path: 'detail/preview-widget/:id',
      name: 'PreviewAchievementWidget',
      component: PreviewAchievementWidget,
    },
  ],
}