// <<< DO NOT EDIT GENERATED FILES DIRECTLY! model-store-fields-all.mustache >>>



const scoringStrategy = this;

export default {
  objectType: 'ScoringStrategy',
  allowableValuesKeys: [],
  constraints: ['isMap',],
  getter: null,
  hiddenFields: [],
  searchableFields: [], 
  searchableAllFields: [],
  translatableFields: [],
  sortableFields: [],
  customFieldsFieldName: [],
  metadataFieldName: [],
  tagFieldName: [],
  entityLinksFrom: [],

  route: {
    
    
  },
  fieldList: ['limitUpdatesTo','sumBestXOf','lastUpdateTimeStamp','recordTimeWhenSumReaches'],
  fields: { 
    limitUpdatesTo: {
      name: 'limitUpdatesTo',
      complexType: null,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['required','isPrimitiveType','isNumeric','isInteger','isShort',
                     ],
      formType: null,
    },
    sumBestXOf: {
      name: 'sumBestXOf',
      complexType: null,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['required','isPrimitiveType','isNumeric','isInteger','isShort',
                     ],
      formType: null,
    },
    lastUpdateTimeStamp: {
      name: 'lastUpdateTimeStamp',
      complexType: null,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['required','isPrimitiveType','isNumeric','isLong',
                     ],
      formType: null,
    },
    recordTimeWhenSumReaches: {
      name: 'recordTimeWhenSumReaches',
      complexType: null,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['required','isPrimitiveType','isNumeric','isDouble',
                     ],
      formType: null,
    }
  }
};
