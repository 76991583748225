// <<< DO NOT EDIT GENERATED FILES DIRECTLY! api-store-mutations.mustache >>>
import * as types from './mutation-types';
import * as _ from 'lodash';
import {
  handleErrorResponse, handleItemErrorResponse,
  handleItemSuccessResponse,
  handleSuccessResponse
} from "../../../../../utils/ZiqniAdminApiHandler";
import apiResponseFields from '../../../fields/ApiResponseZq.js';
import createTranslationRequestFields from '../../../fields/CreateTranslationRequestZq.js';
import queryRequestFields from '../../../fields/QueryRequestZq.js';
import translationResponseFields from '../../../fields/TranslationResponseZq.js';
import updateTranslationRequestFields from '../../../fields/UpdateTranslationRequestZq.js';

const mutations = {
    [types.API_REQUEST](state) {
        state.loading = true;
        state.loaded = false;
    },
    [types.CLEAR_MESSAGE](state){
        state.message = null;
    },

    [types.UPDATE_FIELDS](state, data) {
        state.hiddenFields = data._hiddenFields;
        state.fields = _.difference(state.originalFields, state.hiddenFields);
        localStorage.setItem('hiddenTranslationsKey', JSON.stringify(state.hiddenFields));
    },

    [types.CLEAR_FILTER](state) {
        state.fields = _.difference(
            state.originalFields,
            state.originHiddenFields
        );
        state.hiddenFields = state.originHiddenFields;
        localStorage.removeItem('hiddenTranslationsKey');
    },

    //TranslationResponse
    [types.GETTRANSLATIONS_ITEM_SUCCESS](state, response) {
      handleItemSuccessResponse(types.GETTRANSLATIONS_ITEM_SUCCESS,translationResponseFields, state, response, 'translation');
    },

    //TranslationResponse
    [types.GETTRANSLATIONS_ERROR](state, error) {
      handleItemErrorResponse(types.GETTRANSLATIONS_ITEM_ITEM_ERROR, translationResponseFields, state, error);
    },


    //ApiResponse
    [types.CREATETRANSLATIONS_SUCCESS](state, response) {
      handleSuccessResponse(types.CREATETRANSLATIONS_SUCCESS,apiResponseFields, state, response);
    },

    //ApiResponse
    [types.CREATETRANSLATIONS_ERROR](state, error) {
      handleErrorResponse(types.CREATETRANSLATIONS_ERROR, apiResponseFields, state, error);
    },

    //ApiResponse
    [types.DELETETRANSLATIONS_SUCCESS](state, response) {
      handleSuccessResponse(types.DELETETRANSLATIONS_SUCCESS,apiResponseFields, state, response);
    },

    //ApiResponse
    [types.DELETETRANSLATIONS_ERROR](state, error) {
      handleErrorResponse(types.DELETETRANSLATIONS_ERROR, apiResponseFields, state, error);
    },

    //ApiResponse
    [types.DELETETRANSLATIONSBYQUERY_SUCCESS](state, response) {
      handleSuccessResponse(types.DELETETRANSLATIONSBYQUERY_SUCCESS,apiResponseFields, state, response);
    },

    //ApiResponse
    [types.DELETETRANSLATIONSBYQUERY_ERROR](state, error) {
      handleErrorResponse(types.DELETETRANSLATIONSBYQUERY_ERROR, apiResponseFields, state, error);
    },

    //TranslationResponse
    [types.GETTRANSLATIONS_SUCCESS](state, response) {
      handleSuccessResponse(types.GETTRANSLATIONS_SUCCESS,translationResponseFields, state, response);
    },

    //TranslationResponse
    [types.GETTRANSLATIONS_ERROR](state, error) {
      handleErrorResponse(types.GETTRANSLATIONS_ERROR, translationResponseFields, state, error);
    },

    //TranslationResponse
    [types.GETTRANSLATIONSBYENTITYID_SUCCESS](state, response) {
      handleSuccessResponse(types.GETTRANSLATIONSBYENTITYID_SUCCESS,translationResponseFields, state, response);
    },

    //TranslationResponse
    [types.GETTRANSLATIONSBYENTITYID_ERROR](state, error) {
      handleErrorResponse(types.GETTRANSLATIONSBYENTITYID_ERROR, translationResponseFields, state, error);
    },

    //TranslationResponse
    [types.GETTRANSLATIONSBYQUERY_SUCCESS](state, response) {
      handleSuccessResponse(types.GETTRANSLATIONSBYQUERY_SUCCESS,translationResponseFields, state, response);
    },

    //TranslationResponse
    [types.GETTRANSLATIONSBYQUERY_ERROR](state, error) {
      handleErrorResponse(types.GETTRANSLATIONSBYQUERY_ERROR, translationResponseFields, state, error);
    },

    //ApiResponse
    [types.UPDATETRANSLATIONS_SUCCESS](state, response) {
      handleSuccessResponse(types.UPDATETRANSLATIONS_SUCCESS,apiResponseFields, state, response);
    },

    //ApiResponse
    [types.UPDATETRANSLATIONS_ERROR](state, error) {
      handleErrorResponse(types.UPDATETRANSLATIONS_ERROR, apiResponseFields, state, error);
    },
};

export default mutations;
