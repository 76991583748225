const Deleted = () => import('@/views/ziqni/messages/deleted')
const PreviewDeletedMessage = () => import('@/views/ziqni/messages/deleted/PreviewDeletedMessage')

export default {
  path: 'deleted',
  meta: {
    label: 'Messages'
  },
  component: {
    render(c) {
      return c('router-view')
    }
  },
  children: [
    {
      path: '/',
      name: 'Deleted',
      component: Deleted,

      meta: {
        label: 'Deleted',
      },
    },

    {
      path: ':id',
      name: 'PreviewDeletedMessage',
      component: PreviewDeletedMessage,
      meta: {
        label: 'Preview Deleted Message',
      },
    },
  ],
}