// <<< DO NOT EDIT GENERATED FILES DIRECTLY! model-store-fields-all.mustache >>>


import achievementLiveStatus from './AchievementLiveStatusZq';
import achievementStrategies from './AchievementStrategiesZq';
import dependantOn from './DependantOnZq';
import scheduling from './SchedulingZq';
import updateAchievementRequestAllOf from './UpdateAchievementRequestAllOfZq';
import updateModelDefault from './UpdateModelDefaultZq';
import updateOptParamModels from './UpdateOptParamModelsZq';

const updateAchievementRequest = this;

export default {
  objectType: 'Achievement',
  allowableValuesKeys: [],
  constraints: [],
  getter: null,
  hiddenFields: [],
  searchableFields: [], 
  searchableAllFields: [],
  translatableFields: [],
  sortableFields: [],
  customFieldsFieldName: ['customFields',],
  metadataFieldName: ['metadata'],
  tagFieldName: ['tags'],
  entityLinksFrom: [],

  route: {
    
    
  },
  fieldList: ['id','customFields','tags','metadata','name','description','termsAndConditions','icon','banner','bannerLowResolution','bannerHighResolution','scheduling','achievementLiveStatus','maxNumberOfIssues','constraints','achievementDependencies','memberTagsFilter','productTagsFilter','addConstraints','removeConstraints','strategies'],
  fields: { 
    id: {
      name: 'id',
      complexType: null,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['required','isPrimitiveType','isString',
                     ],
      formType: null,
    },
    customFields: {
      name: 'customFields',
      complexType: null,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['isCustomFieldsArray','isPrimitiveType','isContainer','isFreeFormObject','isMap',
                    'isFreeFormObject','isNullable', ],
      formType: 'CUSTOM',
    },
    tags: {
      name: 'tags',
      complexType: null,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['isTagsArray','isPrimitiveType','isContainer','isArray',
                    'isString', ],
      formType: 'TAG',
    },
    metadata: {
      name: 'metadata',
      complexType: null,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['isMetadataMap','isPrimitiveType','isContainer','isMap',
                    'isString', ],
      formType: 'META',
    },
    name: {
      name: 'name',
      complexType: null,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['isPrimitiveType','isString',
                     ],
      formType: null,
    },
    description: {
      name: 'description',
      complexType: null,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['isPrimitiveType','isString',
                     ],
      formType: null,
    },
    termsAndConditions: {
      name: 'termsAndConditions',
      complexType: null,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['isPrimitiveType','isString',
                     ],
      formType: null,
    },
    icon: {
      name: 'icon',
      complexType: null,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['isPrimitiveType','isString',
                     ],
      formType: null,
    },
    banner: {
      name: 'banner',
      complexType: null,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['isPrimitiveType','isString',
                     ],
      formType: null,
    },
    bannerLowResolution: {
      name: 'bannerLowResolution',
      complexType: null,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['isPrimitiveType','isString',
                     ],
      formType: null,
    },
    bannerHighResolution: {
      name: 'bannerHighResolution',
      complexType: null,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['isPrimitiveType','isString',
                     ],
      formType: null,
    },
    scheduling: {
      name: 'scheduling',
      complexType: scheduling,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['isModel',
                     ],
      formType: null,
    },
    achievementLiveStatus: {
      name: 'achievementLiveStatus',
      complexType: achievementLiveStatus,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: [
                     ],
      formType: null,
    },
    maxNumberOfIssues: {
      name: 'maxNumberOfIssues',
      complexType: null,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['isPrimitiveType','isNumeric','isInteger','isShort',
                     ],
      formType: null,
    },
    constraints: {
      name: 'constraints',
      complexType: null,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['deprecated','isPrimitiveType','isContainer','isArray',
                    'isString', ],
      formType: null,
    },
    achievementDependencies: {
      name: 'achievementDependencies',
      complexType: dependantOn,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['isModel',
                     ],
      formType: null,
    },
    memberTagsFilter: {
      name: 'memberTagsFilter',
      complexType: dependantOn,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['isModel',
                     ],
      formType: null,
    },
    productTagsFilter: {
      name: 'productTagsFilter',
      complexType: dependantOn,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['isModel',
                     ],
      formType: null,
    },
    addConstraints: {
      name: 'addConstraints',
      complexType: null,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['isPrimitiveType','isContainer','isArray',
                    'isString', ],
      formType: null,
    },
    removeConstraints: {
      name: 'removeConstraints',
      complexType: null,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['isPrimitiveType','isContainer','isArray',
                    'isString', ],
      formType: null,
    },
    strategies: {
      name: 'strategies',
      complexType: achievementStrategies,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['isModel',
                     ],
      formType: null,
    }
  }
};
