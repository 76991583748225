import * as types from './mutation-types';
import * as _ from 'lodash';
import {tagsList} from "./getters";

const mutations = {
    [types.API_REQUEST](state) {
        state.loading = true;
        state.loaded = false;
    },

    [types.GET_LANGUAGE_LIST_SUCCESS](state, languagesList) {
        state.languagesList = languagesList.results;
        state.loading = false;
        state.loaded = true;
    },
    [types.GET_LANGUAGE_LIST_ERROR](state, error) {
        state.loading = false;
        state.loaded = true;
        state.success = false;
        state.message = error;
    },

    [types.GET_CUSTOMFIELDS_APPLIES_TO_SUCCESS](state, customFields) {
        state.customFieldsList = customFields.data.results;
        state.loading = false;
        state.loaded = true;
    },

    [types.GET_CUSTOMFIELDS_APPLIES_TO_ERROR](state) {
        state.loading = false;
        state.loaded = true;
    },

    [types.GET_TAGS_APPLIES_TO_SUCCESS](state, tagsList) {
        state.tagsList = tagsList.data.results;
        state.loading = false;
        state.loaded = true;
    },

    [types.GET_TAGS_APPLIES_TO_ERROR](state) {
        state.loading = false;
        state.loaded = true;
    }
};

export default mutations;
