// <<< DO NOT EDIT GENERATED FILES DIRECTLY! api-store-mutation-types.mustache >>>
export const API_REQUEST = 'API_REQUEST';


export const GETREWARDTYPES_ITEM_REQ = 'GETREWARDTYPES_ITEM_REQ';
export const GETREWARDTYPES_ITEM_ERROR = 'GETREWARDTYPES_ITEM_ERROR';
export const GETREWARDTYPES_ITEM_SUCCESS = 'GETREWARDTYPES_ITEM_SUCCESS'

export const UPDATE_FIELDS = 'UPDATE_FIELDS';
export const CLEAR_FILTER = 'CLEAR_FILTER';
export const CLEAR_MESSAGE = 'CLEAR_MESSAGE';

export const CREATEREWARDTYPES_REQ = 'CREATEREWARDTYPES_REQ';
export const CREATEREWARDTYPES_ERROR = 'CREATEREWARDTYPES_ERROR';
export const CREATEREWARDTYPES_SUCCESS = 'CREATEREWARDTYPES_SUCCESS';

export const DELETEREWARDTYPES_REQ = 'DELETEREWARDTYPES_REQ';
export const DELETEREWARDTYPES_ERROR = 'DELETEREWARDTYPES_ERROR';
export const DELETEREWARDTYPES_SUCCESS = 'DELETEREWARDTYPES_SUCCESS';

export const DELETEREWARDTYPESBYQUERY_REQ = 'DELETEREWARDTYPESBYQUERY_REQ';
export const DELETEREWARDTYPESBYQUERY_ERROR = 'DELETEREWARDTYPESBYQUERY_ERROR';
export const DELETEREWARDTYPESBYQUERY_SUCCESS = 'DELETEREWARDTYPESBYQUERY_SUCCESS';

export const GETREWARDTYPES_REQ = 'GETREWARDTYPES_REQ';
export const GETREWARDTYPES_ERROR = 'GETREWARDTYPES_ERROR';
export const GETREWARDTYPES_SUCCESS = 'GETREWARDTYPES_SUCCESS';

export const GETREWARDTYPESBYQUERY_REQ = 'GETREWARDTYPESBYQUERY_REQ';
export const GETREWARDTYPESBYQUERY_ERROR = 'GETREWARDTYPESBYQUERY_ERROR';
export const GETREWARDTYPESBYQUERY_SUCCESS = 'GETREWARDTYPESBYQUERY_SUCCESS';

export const UPDATEREWARDTYPES_REQ = 'UPDATEREWARDTYPES_REQ';
export const UPDATEREWARDTYPES_ERROR = 'UPDATEREWARDTYPES_ERROR';
export const UPDATEREWARDTYPES_SUCCESS = 'UPDATEREWARDTYPES_SUCCESS';

