import * as types from './mutation-types';
import * as _ from 'lodash';
import {
  handleErrorResponse, handleItemErrorResponse,
  handleItemSuccessResponse,
  handleSuccessResponse
} from "@/utils/ZiqniAdminApiHandler";
import apiResponseFields from '@/generated/ziqni/fields/ApiResponseZq';
import createFileObjectRequestFields from '@/generated/ziqni/fields/CreateFileObjectRequestZq';
import fileObjectsResponseFields from '@/generated/ziqni/fields/FileObjectsResponseZq';
import queryRequestFields from '@/generated/ziqni/fields/QueryRequestZq';
import updateFileObjectRequestFields from '@/generated/ziqni/fields/UpdateFileObjectRequestZq';

const mutations = {
    [types.API_REQUEST](state) {
        state.loading = true;
        state.loaded = false;
    },
    [types.CLEAR_MESSAGE](state){
        state.message = null;
    },

    [types.UPDATE_FIELDS](state, data) {
        state.hiddenFields = data._hiddenFields;
        state.fields = _.difference(state.originalFields, state.hiddenFields);
        localStorage.setItem('hiddenFileObjectsKey', JSON.stringify(state.hiddenFields));
    },

    [types.CLEAR_FILTER](state) {
        state.fields = _.difference(
            state.originalFields,
            state.originHiddenFields
        );
        state.hiddenFields = state.originHiddenFields;
        localStorage.removeItem('hiddenFileObjectsKey');
    },

    //FileObjectsResponse
    [types.GETFILEOBJECTS_ITEM_SUCCESS](state, response) {
      handleItemSuccessResponse(types.GETFILEOBJECTS_ITEM_SUCCESS,fileObjectsResponseFields, state, response, 'fileObject');
    },

    //FileObjectsResponse
    [types.GETFILEOBJECTS_ERROR](state, error) {
      handleItemErrorResponse(types.GETFILEOBJECTS_ITEM_ITEM_ERROR, fileObjectsResponseFields, state, error);
    },

    //ApiResponse
    [types.COPYFILEOBJECTS_SUCCESS](state, response) {
      handleSuccessResponse(types.COPYFILEOBJECTS_SUCCESS,apiResponseFields, state, response);
    },

    //ApiResponse
    [types.COPYFILEOBJECTS_ERROR](state, error) {
      handleErrorResponse(types.COPYFILEOBJECTS_ERROR, apiResponseFields, state, error);
    },

    //ApiResponse
    [types.CREATEFILEOBJECT_SUCCESS](state, response) {
      handleSuccessResponse(types.CREATEFILEOBJECT_SUCCESS,apiResponseFields, state, response);
    },

    //ApiResponse
    [types.CREATEFILEOBJECT_ERROR](state, error) {
      handleErrorResponse(types.CREATEFILEOBJECT_ERROR, apiResponseFields, state, error);
    },

    //ApiResponse
    [types.DELETEFILEOBJECTBYID_SUCCESS](state, response) {
      handleSuccessResponse(types.DELETEFILEOBJECTBYID_SUCCESS,apiResponseFields, state, response);
    },

    //ApiResponse
    [types.DELETEFILEOBJECTBYID_ERROR](state, error) {
      handleErrorResponse(types.DELETEFILEOBJECTBYID_ERROR, apiResponseFields, state, error);
    },

    //ApiResponse
    [types.DELETEFILEOBJECTSBYQUERY_SUCCESS](state, response) {
      handleSuccessResponse(types.DELETEFILEOBJECTSBYQUERY_SUCCESS,apiResponseFields, state, response);
    },

    //ApiResponse
    [types.DELETEFILEOBJECTSBYQUERY_ERROR](state, error) {
      handleErrorResponse(types.DELETEFILEOBJECTSBYQUERY_ERROR, apiResponseFields, state, error);
    },

    //File
    [types.DOWNLOADFILEOBJECTS_SUCCESS](state, response) {
      handleSuccessResponse(types.DOWNLOADFILEOBJECTS_SUCCESS,apiResponseFields, state, response);
    },

    //File
    [types.DOWNLOADFILEOBJECTS_ERROR](state, error) {
      handleErrorResponse(types.DOWNLOADFILEOBJECTS_ERROR, apiResponseFields, state, error);
    },

    //FileObjectsResponse
    [types.GETFILEOBJECTS_SUCCESS](state, response) {
      handleSuccessResponse(types.GETFILEOBJECTS_SUCCESS,fileObjectsResponseFields, state, response);
    },

    //FileObjectsResponse
    [types.GETFILEOBJECTS_ERROR](state, error) {
      handleErrorResponse(types.GETFILEOBJECTS_ERROR, fileObjectsResponseFields, state, error);
    },

    //FileObjectsResponse
    [types.GETFILEOBJECTSBYQUERY_SUCCESS](state, response) {
      handleSuccessResponse(types.GETFILEOBJECTSBYQUERY_SUCCESS,fileObjectsResponseFields, state, response);
    },

    //FileObjectsResponse
    [types.GETFILEOBJECTSBYQUERY_ERROR](state, error) {
      handleErrorResponse(types.GETFILEOBJECTSBYQUERY_ERROR, fileObjectsResponseFields, state, error);
    },

    //ApiResponse
    [types.SAVETEMPLATES_SUCCESS](state, response) {
        handleSuccessResponse(types.SAVETEMPLATES_SUCCESS,apiResponseFields, state, response);
    },

    //ApiResponse
    [types.SAVETEMPLATES_ERROR](state, error) {
        handleErrorResponse(types.SAVETEMPLATES_ERROR, apiResponseFields, state, error);
    },

    //ApiResponse
    [types.UPDATEFILEOBJECTS_SUCCESS](state, response) {
      handleSuccessResponse(types.UPDATEFILEOBJECTS_SUCCESS,apiResponseFields, state, response);
    },

    //ApiResponse
    [types.UPDATEFILEOBJECTS_ERROR](state, error) {
      handleErrorResponse(types.UPDATEFILEOBJECTS_ERROR, apiResponseFields, state, error);
    },

    //ApiResponse
    [types.UPLOADFILEOBJECTS_SUCCESS](state, response) {
      handleSuccessResponse(types.UPLOADFILEOBJECTS_SUCCESS,apiResponseFields, state, response);
    },

    //ApiResponse
    [types.UPLOADFILEOBJECTS_ERROR](state, error) {
      handleErrorResponse(types.UPLOADFILEOBJECTS_ERROR, apiResponseFields, state, error);
    },
};

export default mutations;
