// <<< DO NOT EDIT GENERATED FILES DIRECTLY! >>>


















export const achievementsReduced = state => state.achievementsReduced; 
export const achievementReduced = state => state.achievementReduced; 




export const competitionsReduced = state => state.competitionsReduced; 
export const competitionReduced = state => state.competitionReduced; 




export const contestsReduced = state => state.contestsReduced; 
export const contestReduced = state => state.contestReduced; 




export const products = state => state.products; 
export const product = state => state.product; 





















export const sortableFields = state => state.sortableFields;
export const fields = state => state.fields;
export const pages = state => state.pages;
export const loading = state => state.loading;
export const resultCount = state => state.resultCount;
export const totalRecords = state => state.totalRecords;
export const originalFields = state => state.originalFields;
export const searchableAllFields = state => state.searchableAllFields;
export const message = state => state.message;
export const success = state => state.success;
