// <<< DO NOT EDIT GENERATED FILES DIRECTLY! model-store-fields-all.mustache >>>



const entrantAction = this;

export default {
  objectType: 'EntrantAction',
  allowableValuesKeys: ['DropScore','ResetScore'],
  constraints: ['isString','isEnum',],
  getter: null,
  hiddenFields: [],
  searchableFields: [], 
  searchableAllFields: [],
  translatableFields: [],
  sortableFields: [],
  customFieldsFieldName: [],
  metadataFieldName: [],
  tagFieldName: [],
  entityLinksFrom: [],

  route: {
    
    
  },
  fieldList: [],
  fields: { 
  }
};
