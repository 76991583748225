// <<< DO NOT EDIT GENERATED FILES DIRECTLY! api-store-actions.mustache >>>
import { delay, cloneDeep } from 'lodash';
import * as types from './mutation-types';
import sdkApiConfig from '@/config/sdkApiConfig';
import updateFields from '@/utils/updateFields';
import { competitionsApiZiqni } from '@/generated/ziqni/api/CompetitionsApiZiqni';
import router from '@/router';

import apiResponseFields from '../../../fields/ApiResponseZq.js';
import bracketResponseFields from '../../../fields/BracketResponseZq.js';
import competitionLeaderboardResponseFields from '../../../fields/CompetitionLeaderboardResponseZq.js';
import competitionResponseFields from '../../../fields/CompetitionResponseZq.js';
import contestReducedResponseFields from '../../../fields/ContestReducedResponseZq.js';
import createCompetitionRequestFields from '../../../fields/CreateCompetitionRequestZq.js';
import createSimpleCompetitionRequestFields from '../../../fields/CreateSimpleCompetitionRequestZq.js';
import entrantRequestFields from '../../../fields/EntrantRequestZq.js';
import entrantResponseFields from '../../../fields/EntrantResponseZq.js';
import queryRequestFields from '../../../fields/QueryRequestZq.js';
import updateCompetitionRequestFields from '../../../fields/UpdateCompetitionRequestZq.js';
import updateCompetitionStatusRequestFields from '../../../fields/UpdateCompetitionStatusRequestZq.js';
import {handleRequest,handleRequestWithBody} from "../../../../../utils/ZiqniAdminApiHandler";

export const handleFields = ({ commit, state }, data) => {
  updateFields(commit, state, data, 'hiddenCompetitionsKey');
};

// Clear competitions Message
export const handleClearMessage = ({ commit }) => {
  commit(types.CLEAR_MESSAGE);
};

// clear filter
export const handleClearFilter = ({ commit }) => {
  commit(types.CLEAR_FILTER);
};

//COMPETITIONS - COMPETITIONS
export const handleCompetitionsChanges = ({ commit }, data) => {
  commit('SET_COMPETITIONS', data);
};
/**
 * CompetitionResponse from backend.
 * GET getCompetitions and return [CompetitionResponse]
 * vars: [
 *    [[id]] id[ opts, isArray, isQueryParam]
 *    [[limit]] _limit[ opts, isInteger, isQueryParam]
 *    [[skip]] _skip[ opts, isInteger, isQueryParam]]
 */
export const handleGetCompetitions_item = ({ commit }, idArray, limit, skip) => {
  commit(types.API_REQUEST);
  let opts = { 
    id: idArray, 
    limit: limit, 
    skip: skip
  };

  return competitionsApiZiqni
    .getCompetitions(
      opts
    )
    .then((response) => {
      commit(types.GETCOMPETITIONS_ITEM_SUCCESS, { response });
      return response.results;
    })
    .catch((e) => {
      commit(types.GETCOMPETITIONS_ITEM_ERROR, e);
      return [];
    });
};

/**
 * ApiResponse from backend.
 * POST createCompetitions and return [ApiResponse]
 * vars: [
 *    [[body]] CreateCompetitionRequest[ required, isBodyParam, ]]
 */
export const handleCreateCompetitions = ({ commit }, payload) => {
  const { createCompetitionRequest } = payload;

  commit(types.API_REQUEST);
  

  return competitionsApiZiqni
    .createCompetitions(
      createCompetitionRequest
    )
    .then((response) => {
      commit(types.CREATECOMPETITIONS_SUCCESS, { response });
      return response.results;
    })
    .catch((e) => {
      commit(types.CREATECOMPETITIONS_ERROR, e);
      return [];
    });
};

/**
 * ApiResponse from backend.
 * POST createSimpleCompetitions and return [ApiResponse]
 * vars: [
 *    [[body]] CreateSimpleCompetitionRequest[ required, isBodyParam, ]]
 */
export const handleCreateSimpleCompetitions = ({ commit }, payload) => {
  const { createSimpleCompetitionRequest } = payload;

  commit(types.API_REQUEST);
  

  return competitionsApiZiqni
    .createSimpleCompetitions(
      createSimpleCompetitionRequest
    )
    .then((response) => {
      commit(types.CREATESIMPLECOMPETITIONS_SUCCESS, { response });
      return response.results;
    })
    .catch((e) => {
      commit(types.CREATESIMPLECOMPETITIONS_ERROR, e);
      return [];
    });
};

/**
 * CompetitionResponse from backend.
 * GET getCompetitions and return [CompetitionResponse]
 * vars: [
 *    [[id]] id[ opts, isArray, isQueryParam]
 *    [[limit]] _limit[ opts, isInteger, isQueryParam]
 *    [[skip]] _skip[ opts, isInteger, isQueryParam]]
 */
export const handleGetCompetitions = ({ commit }, payload) => {
  const { idArray, limit, skip } = payload;

  commit(types.API_REQUEST);
  let opts = { 
    id: idArray, 
    limit: limit, 
    skip: skip
  };

  return competitionsApiZiqni
    .getCompetitions(
      opts
    )
    .then((response) => {
      commit(types.GETCOMPETITIONS_SUCCESS, { response });
      return response.results;
    })
    .catch((e) => {
      commit(types.GETCOMPETITIONS_ERROR, e);
      return [];
    });
};

/**
 * BracketResponse from backend.
 * GET getCompetitionsBrackets and return [BracketResponse]
 * vars: [
 *    [[id]] id[ required, isString, isPathParam, ]
 *    [[limit]] _limit[ opts, isInteger, isQueryParam]
 *    [[skip]] _skip[ opts, isInteger, isQueryParam]]
 */
export const handleGetCompetitionsBrackets = ({ commit }, payload) => {
  const { id, limit, skip } = payload;

  commit(types.API_REQUEST);
  let opts = { 
    limit: limit, 
    skip: skip
  };

  return competitionsApiZiqni
    .getCompetitionsBrackets(
      id,opts
    )
    .then((response) => {
      commit(types.GETCOMPETITIONSBRACKETS_SUCCESS, { response });
      return response.results;
    })
    .catch((e) => {
      commit(types.GETCOMPETITIONSBRACKETS_ERROR, e);
      return [];
    });
};

/**
 * CompetitionResponse from backend.
 * POST getCompetitionsByQuery and return [CompetitionResponse]
 * vars: [
 *    [[body]] QueryRequest[ opts, isBodyParam, ]]
 */
export const handleGetCompetitionsByQuery = ({ commit }, payload) => {
  const { queryRequest } = payload;

  commit(types.API_REQUEST);
  let opts = { 
    body: queryRequest
  };

  return competitionsApiZiqni
    .getCompetitionsByQuery(
      opts
    )
    .then((response) => {
      commit(types.GETCOMPETITIONSBYQUERY_SUCCESS, { response });
      return response.results;
    })
    .catch((e) => {
      commit(types.GETCOMPETITIONSBYQUERY_ERROR, e);
      return [];
    });
};

/**
 * CloneCompetitionResponse from backend.
 * GET getCompetitionsToClone and return [CloneCompetitionResponse]
 * vars: [
 *    [[id]] id[ required, isString, isPathParam, ]]
 */
export const handleGetCompetitionsToClone = ({ commit }, payload) => {
  const { id } = payload;

  commit(types.API_REQUEST);
  

  return competitionsApiZiqni
    .getCompetitionsToClone(
      id
    )
    .then((response) => {
      commit(types.GETCOMPETITIONSTOCLONE_SUCCESS, { response });
      return response.results;
    })
    .catch((e) => {
      commit(types.GETCOMPETITIONSTOCLONE_ERROR, e);
      return [];
    });
};

/**
 * ContestReducedResponse from backend.
 * GET getContestsForCompetitions and return [ContestReducedResponse]
 * vars: [
 *    [[id]] id[ required, isString, isPathParam, ]
 *    [[limit]] _limit[ opts, isInteger, isQueryParam]
 *    [[skip]] _skip[ opts, isInteger, isQueryParam]]
 */
export const handleGetContestsForCompetitions = ({ commit }, payload) => {
  const { id, limit, skip } = payload;

  commit(types.API_REQUEST);
  let opts = { 
    limit: limit, 
    skip: skip
  };

  return competitionsApiZiqni
    .getContestsForCompetitions(
      id,opts
    )
    .then((response) => {
      commit(types.GETCONTESTSFORCOMPETITIONS_SUCCESS, { response });
      return response.results;
    })
    .catch((e) => {
      commit(types.GETCONTESTSFORCOMPETITIONS_ERROR, e);
      return [];
    });
};

/**
 * CompetitionLeaderboardResponse from backend.
 * GET getLeaderboardByCompetitionId and return [CompetitionLeaderboardResponse]
 * vars: [
 *    [[id]] id[ required, isString, isPathParam, ]
 *    [[limit]] _limit[ opts, isInteger, isQueryParam]
 *    [[skip]] _skip[ opts, isInteger, isQueryParam]]
 */
export const handleGetLeaderboardByCompetitionId = ({ commit }, payload) => {
  const { id, limit, skip } = payload;

  commit(types.API_REQUEST);
  let opts = { 
    limit: limit, 
    skip: skip
  };

  return competitionsApiZiqni
    .getLeaderboardByCompetitionId(
      id,opts
    )
    .then((response) => {
      commit(types.GETLEADERBOARDBYCOMPETITIONID_SUCCESS, { response });
      return response.results;
    })
    .catch((e) => {
      commit(types.GETLEADERBOARDBYCOMPETITIONID_ERROR, e);
      return [];
    });
};

/**
 * EntrantResponse from backend.
 * GET getListOfEntrants and return [EntrantResponse]
 * vars: [
 *    [[id]] id[ required, isString, isPathParam, ]
 *    [[limit]] _limit[ opts, isInteger, isQueryParam]
 *    [[skip]] _skip[ opts, isInteger, isQueryParam]]
 */
export const handleGetListOfEntrants = ({ commit }, payload) => {
  const { id, limit, skip } = payload;

  commit(types.API_REQUEST);
  let opts = { 
    limit: limit, 
    skip: skip
  };

  return competitionsApiZiqni
    .getListOfEntrants(
      id,opts
    )
    .then((response) => {
      commit(types.GETLISTOFENTRANTS_SUCCESS, { response });
      return response.results;
    })
    .catch((e) => {
      commit(types.GETLISTOFENTRANTS_ERROR, e);
      return [];
    });
};

/**
 * ApiResponse from backend.
 * PUT updateCompetitions and return [ApiResponse]
 * vars: [
 *    [[body]] UpdateCompetitionRequest[ required, isBodyParam, ]]
 */
export const handleUpdateCompetitions = ({ commit }, payload) => {
  const { updateCompetitionRequest } = payload;

  commit(types.API_REQUEST);
  

  return competitionsApiZiqni
    .updateCompetitions(
      updateCompetitionRequest
    )
    .then((response) => {
      commit(types.UPDATECOMPETITIONS_SUCCESS, { response });
      return response.results;
    })
    .catch((e) => {
      commit(types.UPDATECOMPETITIONS_ERROR, e);
      return [];
    });
};

/**
 * ApiResponse from backend.
 * PUT updateCompetitionsEntries and return [ApiResponse]
 * vars: [
 *    [[body]] EntrantRequest[ opts, isArray, isBodyParam, ]]
 */
export const handleUpdateCompetitionsEntries = ({ commit }, payload) => {
  const { entrantRequestArray } = payload;

  commit(types.API_REQUEST);
  let opts = { 
    body: entrantRequestArray
  };

  return competitionsApiZiqni
    .updateCompetitionsEntries(
      opts
    )
    .then((response) => {
      commit(types.UPDATECOMPETITIONSENTRIES_SUCCESS, { response });
      return response.results;
    })
    .catch((e) => {
      commit(types.UPDATECOMPETITIONSENTRIES_ERROR, e);
      return [];
    });
};

/**
 * ApiResponse from backend.
 * PUT updateCompetitionsState and return [ApiResponse]
 * vars: [
 *    [[body]] UpdateCompetitionStatusRequest[ opts, isArray, isBodyParam, ]]
 */
export const handleUpdateCompetitionsState = ({ commit }, payload) => {
  const { updateCompetitionStatusRequestArray } = payload;

  commit(types.API_REQUEST);
  let opts = { 
    body: updateCompetitionStatusRequestArray
  };

  return competitionsApiZiqni
    .updateCompetitionsState(
      opts
    )
    .then((response) => {
      commit(types.UPDATECOMPETITIONSSTATE_SUCCESS, { response });
      return response.results;
    })
    .catch((e) => {
      commit(types.UPDATECOMPETITIONSSTATE_ERROR, e);
      return [];
    });
};
