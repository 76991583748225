const HelpSection = () => import('@/views/ziqni/help');
const TermsOfService = () => import('@/views/ziqni/help/TermsOfService');

export  default {
  path: 'help-section/',
  component: {
    render(c) {
      return c('router-view');
    },
  },
  children: [
    {
      path: '',
      name: 'HelpSection',
      component: HelpSection,
    },
    {
      path: 'terms-of-service/',
      name: 'TermsOfService',
      component: TermsOfService,
    },
  ]
}