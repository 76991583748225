// <<< DO NOT EDIT GENERATED FILES DIRECTLY! api-store-actions.mustache >>>
import { delay, cloneDeep } from 'lodash';
import * as types from './mutation-types';
import sdkApiConfig from '@/config/sdkApiConfig';
import updateFields from '@/utils/updateFields';
import { tagsApiZiqni } from '@/generated/ziqni/api/TagsApiZiqni';
import router from '@/router';

import apiResponseFields from '../../../fields/ApiResponseZq.js';
import createTagRequestFields from '../../../fields/CreateTagRequestZq.js';
import queryRequestFields from '../../../fields/QueryRequestZq.js';
import tagResponseFields from '../../../fields/TagResponseZq.js';
import updateTagRequestFields from '../../../fields/UpdateTagRequestZq.js';
import {handleRequest,handleRequestWithBody} from "../../../../../utils/ZiqniAdminApiHandler";

export const handleFields = ({ commit, state }, data) => {
  updateFields(commit, state, data, 'hiddenTagsKey');
};

// Clear tags Message
export const handleClearMessage = ({ commit }) => {
  commit(types.CLEAR_MESSAGE);
};

// clear filter
export const handleClearFilter = ({ commit }) => {
  commit(types.CLEAR_FILTER);
};

//TAGS - TAGS
export const handleTagsChanges = ({ commit }, data) => {
  commit('SET_TAGS', data);
};
/**
 * TagResponse from backend.
 * GET getTags and return [TagResponse]
 * vars: [
 *    [[id]] id[ opts, isArray, isQueryParam]
 *    [[limit]] _limit[ opts, isInteger, isQueryParam]
 *    [[skip]] _skip[ opts, isInteger, isQueryParam]]
 */
export const handleGetTags_item = ({ commit }, idArray, limit, skip) => {
  commit(types.API_REQUEST);
  let opts = { 
    id: idArray, 
    limit: limit, 
    skip: skip
  };

  return tagsApiZiqni
    .getTags(
      opts
    )
    .then((response) => {
      commit(types.GETTAGS_ITEM_SUCCESS, { response });
      return response.results;
    })
    .catch((e) => {
      commit(types.GETTAGS_ITEM_ERROR, e);
      return [];
    });
};

/**
 * ApiResponse from backend.
 * POST createTags and return [ApiResponse]
 * vars: [
 *    [[body]] CreateTagRequest[ required, isArray, isBodyParam, ]]
 */
export const handleCreateTags = ({ commit }, payload) => {
  const { createTagRequestArray } = payload;

  commit(types.API_REQUEST);
  

  return tagsApiZiqni
    .createTags(
      createTagRequestArray
    )
    .then((response) => {
      commit(types.CREATETAGS_SUCCESS, { response });
      return response.results;
    })
    .catch((e) => {
      commit(types.CREATETAGS_ERROR, e);
      return [];
    });
};

/**
 * ApiResponse from backend.
 * DELETE deleteTags and return [ApiResponse]
 * vars: [
 *    [[id]] id[ opts, isArray, isQueryParam]]
 */
export const handleDeleteTags = ({ commit }, payload) => {
  const { idArray } = payload;

  commit(types.API_REQUEST);
  let opts = { 
    id: idArray
  };

  return tagsApiZiqni
    .deleteTags(
      opts
    )
    .then((response) => {
      commit(types.DELETETAGS_SUCCESS, { response });
      return response.results;
    })
    .catch((e) => {
      commit(types.DELETETAGS_ERROR, e);
      return [];
    });
};

/**
 * ApiResponse from backend.
 * POST deleteTagsByQuery and return [ApiResponse]
 * vars: [
 *    [[body]] QueryRequest[ opts, isBodyParam, ]]
 */
export const handleDeleteTagsByQuery = ({ commit }, payload) => {
  const { queryRequest } = payload;

  commit(types.API_REQUEST);
  let opts = { 
    body: queryRequest
  };

  return tagsApiZiqni
    .deleteTagsByQuery(
      opts
    )
    .then((response) => {
      commit(types.DELETETAGSBYQUERY_SUCCESS, { response });
      return response.results;
    })
    .catch((e) => {
      commit(types.DELETETAGSBYQUERY_ERROR, e);
      return [];
    });
};

/**
 * TagResponse from backend.
 * GET getTags and return [TagResponse]
 * vars: [
 *    [[id]] id[ opts, isArray, isQueryParam]
 *    [[limit]] _limit[ opts, isInteger, isQueryParam]
 *    [[skip]] _skip[ opts, isInteger, isQueryParam]]
 */
export const handleGetTags = ({ commit }, payload) => {
  const { idArray, limit, skip } = payload;

  commit(types.API_REQUEST);
  let opts = { 
    id: idArray, 
    limit: limit, 
    skip: skip
  };

  return tagsApiZiqni
    .getTags(
      opts
    )
    .then((response) => {
      commit(types.GETTAGS_SUCCESS, { response });
      return response.results;
    })
    .catch((e) => {
      commit(types.GETTAGS_ERROR, e);
      return [];
    });
};

/**
 * TagResponse from backend.
 * POST getTagsByQuery and return [TagResponse]
 * vars: [
 *    [[body]] QueryRequest[ opts, isBodyParam, ]]
 */
export const handleGetTagsByQuery = ({ commit }, payload) => {
  const { queryRequest } = payload;

  commit(types.API_REQUEST);
  let opts = { 
    body: queryRequest
  };

  return tagsApiZiqni
    .getTagsByQuery(
      opts
    )
    .then((response) => {
      commit(types.GETTAGSBYQUERY_SUCCESS, { response });
      return response.results;
    })
    .catch((e) => {
      commit(types.GETTAGSBYQUERY_ERROR, e);
      return [];
    });
};

/**
 * ApiResponse from backend.
 * PUT updateTags and return [ApiResponse]
 * vars: [
 *    [[body]] UpdateTagRequest[ required, isArray, isBodyParam, ]]
 */
export const handleUpdateTags = ({ commit }, payload) => {
  const { updateTagRequestArray } = payload;

  commit(types.API_REQUEST);
  

  return tagsApiZiqni
    .updateTags(
      updateTagRequestArray
    )
    .then((response) => {
      commit(types.UPDATETAGS_SUCCESS, { response });
      return response.results;
    })
    .catch((e) => {
      commit(types.UPDATETAGS_ERROR, e);
      return [];
    });
};
