// <<< DO NOT EDIT GENERATED FILES DIRECTLY! model-store-fields-all.mustache >>>


import dependantOn from './DependantOnZq';

const dependancy = this;

export default {
  objectType: 'Dependancy',
  allowableValuesKeys: [],
  constraints: ['isMap',],
  getter: null,
  hiddenFields: [],
  searchableFields: [], 
  searchableAllFields: [],
  translatableFields: [],
  sortableFields: [],
  customFieldsFieldName: [],
  metadataFieldName: [],
  tagFieldName: [],
  entityLinksFrom: [],

  route: {
    
    
  },
  fieldList: ['entityType','dependantOn'],
  fields: { 
    entityType: {
      name: 'entityType',
      complexType: null,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['isPrimitiveType','isString',
                     ],
      formType: null,
    },
    dependantOn: {
      name: 'dependantOn',
      complexType: dependantOn,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['isModel',
                     ],
      formType: null,
    }
  }
};
