// <<< DO NOT EDIT GENERATED FILES DIRECTLY! api-store-mutations.mustache >>>
import * as types from './mutation-types';
import * as _ from 'lodash';
import {
  handleErrorResponse, handleItemErrorResponse,
  handleItemSuccessResponse,
  handleSuccessResponse
} from "../../../../../utils/ZiqniAdminApiHandler";
import apiResponseFields from '../../../fields/ApiResponseZq.js';
import createEntityRewardRequestFields from '../../../fields/CreateEntityRewardRequestZq.js';
import queryRequestFields from '../../../fields/QueryRequestZq.js';
import rewardResponseFields from '../../../fields/RewardResponseZq.js';
import updateEntityRewardRequestFields from '../../../fields/UpdateEntityRewardRequestZq.js';

const mutations = {
    [types.API_REQUEST](state) {
        state.loading = true;
        state.loaded = false;
    },
    [types.CLEAR_MESSAGE](state){
        state.message = null;
    },

    [types.UPDATE_FIELDS](state, data) {
        state.hiddenFields = data._hiddenFields;
        state.fields = _.difference(state.originalFields, state.hiddenFields);
        localStorage.setItem('hiddenRewardsKey', JSON.stringify(state.hiddenFields));
    },

    [types.CLEAR_FILTER](state) {
        state.fields = _.difference(
            state.originalFields,
            state.originHiddenFields
        );
        state.hiddenFields = state.originHiddenFields;
        localStorage.removeItem('hiddenRewardsKey');
    },

    //RewardResponse
    [types.GETREWARDS_ITEM_SUCCESS](state, response) {
      handleItemSuccessResponse(types.GETREWARDS_ITEM_SUCCESS,rewardResponseFields, state, response, 'reward');
    },

    //RewardResponse
    [types.GETREWARDS_ERROR](state, error) {
      handleItemErrorResponse(types.GETREWARDS_ITEM_ITEM_ERROR, rewardResponseFields, state, error);
    },


    //ApiResponse
    [types.CREATEREWARDS_SUCCESS](state, response) {
      handleSuccessResponse(types.CREATEREWARDS_SUCCESS,apiResponseFields, state, response);
    },

    //ApiResponse
    [types.CREATEREWARDS_ERROR](state, error) {
      handleErrorResponse(types.CREATEREWARDS_ERROR, apiResponseFields, state, error);
    },

    //ApiResponse
    [types.DELETEREWARDS_SUCCESS](state, response) {
      handleSuccessResponse(types.DELETEREWARDS_SUCCESS,apiResponseFields, state, response);
    },

    //ApiResponse
    [types.DELETEREWARDS_ERROR](state, error) {
      handleErrorResponse(types.DELETEREWARDS_ERROR, apiResponseFields, state, error);
    },

    //ApiResponse
    [types.DELETEREWARDSBYQUERY_SUCCESS](state, response) {
      handleSuccessResponse(types.DELETEREWARDSBYQUERY_SUCCESS,apiResponseFields, state, response);
    },

    //ApiResponse
    [types.DELETEREWARDSBYQUERY_ERROR](state, error) {
      handleErrorResponse(types.DELETEREWARDSBYQUERY_ERROR, apiResponseFields, state, error);
    },

    //RewardResponse
    [types.GETREWARDS_SUCCESS](state, response) {
      handleSuccessResponse(types.GETREWARDS_SUCCESS,rewardResponseFields, state, response);
    },

    //RewardResponse
    [types.GETREWARDS_ERROR](state, error) {
      handleErrorResponse(types.GETREWARDS_ERROR, rewardResponseFields, state, error);
    },

    //RewardResponse
    [types.GETREWARDSBYACHIEVEMENTID_SUCCESS](state, response) {
      handleSuccessResponse(types.GETREWARDSBYACHIEVEMENTID_SUCCESS,rewardResponseFields, state, response);
    },

    //RewardResponse
    [types.GETREWARDSBYACHIEVEMENTID_ERROR](state, error) {
      handleErrorResponse(types.GETREWARDSBYACHIEVEMENTID_ERROR, rewardResponseFields, state, error);
    },

    //RewardResponse
    [types.GETREWARDSBYCONTESTID_SUCCESS](state, response) {
      handleSuccessResponse(types.GETREWARDSBYCONTESTID_SUCCESS,rewardResponseFields, state, response);
    },

    //RewardResponse
    [types.GETREWARDSBYCONTESTID_ERROR](state, error) {
      handleErrorResponse(types.GETREWARDSBYCONTESTID_ERROR, rewardResponseFields, state, error);
    },

    //RewardResponse
    [types.GETREWARDSBYQUERY_SUCCESS](state, response) {
      handleSuccessResponse(types.GETREWARDSBYQUERY_SUCCESS,rewardResponseFields, state, response);
    },

    //RewardResponse
    [types.GETREWARDSBYQUERY_ERROR](state, error) {
      handleErrorResponse(types.GETREWARDSBYQUERY_ERROR, rewardResponseFields, state, error);
    },

    //ApiResponse
    [types.UPDATEREWARDS_SUCCESS](state, response) {
      handleSuccessResponse(types.UPDATEREWARDS_SUCCESS,apiResponseFields, state, response);
    },

    //ApiResponse
    [types.UPDATEREWARDS_ERROR](state, error) {
      handleErrorResponse(types.UPDATEREWARDS_ERROR, apiResponseFields, state, error);
    },
};

export default mutations;
