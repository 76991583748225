// <<< DO NOT EDIT GENERATED FILES DIRECTLY! api-store-actions.mustache >>>
import { delay, cloneDeep } from 'lodash';
import * as types from './mutation-types';
import sdkApiConfig from '@/config/sdkApiConfig';
import updateFields from '@/utils/updateFields';
import { transformersApiZiqni } from '@/generated/ziqni/api/TransformersApiZiqni';
import router from '@/router';

import apiResponseFields from '../../../fields/ApiResponseZq.js';
import createTransformerRequestFields from '../../../fields/CreateTransformerRequestZq.js';
import queryRequestFields from '../../../fields/QueryRequestZq.js';
import transformerResponseFields from '../../../fields/TransformerResponseZq.js';
import updateTransformerRequestFields from '../../../fields/UpdateTransformerRequestZq.js';
import {handleRequest,handleRequestWithBody} from "../../../../../utils/ZiqniAdminApiHandler";

export const handleFields = ({ commit, state }, data) => {
  updateFields(commit, state, data, 'hiddenTransformersKey');
};

// Clear transformers Message
export const handleClearMessage = ({ commit }) => {
  commit(types.CLEAR_MESSAGE);
};

// clear filter
export const handleClearFilter = ({ commit }) => {
  commit(types.CLEAR_FILTER);
};

//TRANSFORMERS - TRANSFORMERS
export const handleTransformersChanges = ({ commit }, data) => {
  commit('SET_TRANSFORMERS', data);
};
/**
 * TransformerResponse from backend.
 * GET getTransformers and return [TransformerResponse]
 * vars: [
 *    [[id]] id[ opts, isArray, isQueryParam]
 *    [[limit]] _limit[ opts, isInteger, isQueryParam]
 *    [[skip]] _skip[ opts, isInteger, isQueryParam]]
 */
export const handleGetTransformers_item = ({ commit }, idArray, limit, skip) => {
  commit(types.API_REQUEST);
  let opts = { 
    id: idArray, 
    limit: limit, 
    skip: skip
  };

  return transformersApiZiqni
    .getTransformers(
      opts
    )
    .then((response) => {
      commit(types.GETTRANSFORMERS_ITEM_SUCCESS, { response });
      return response.results;
    })
    .catch((e) => {
      commit(types.GETTRANSFORMERS_ITEM_ERROR, e);
      return [];
    });
};

/**
 * ApiResponse from backend.
 * POST createTransformers and return [ApiResponse]
 * vars: [
 *    [[body]] CreateTransformerRequest[ required, isArray, isBodyParam, ]]
 */
export const handleCreateTransformers = ({ commit }, payload) => {
  const { createTransformerRequestArray } = payload;

  commit(types.API_REQUEST);
  

  return transformersApiZiqni
    .createTransformers(
      createTransformerRequestArray
    )
    .then((response) => {
      commit(types.CREATETRANSFORMERS_SUCCESS, { response });
      return response.results;
    })
    .catch((e) => {
      commit(types.CREATETRANSFORMERS_ERROR, e);
      return [];
    });
};

/**
 * ApiResponse from backend.
 * DELETE deleteTransformers and return [ApiResponse]
 * vars: [
 *    [[id]] id[ opts, isArray, isQueryParam]]
 */
export const handleDeleteTransformers = ({ commit }, payload) => {
  const { idArray } = payload;

  commit(types.API_REQUEST);
  let opts = { 
    id: idArray
  };

  return transformersApiZiqni
    .deleteTransformers(
      opts
    )
    .then((response) => {
      commit(types.DELETETRANSFORMERS_SUCCESS, { response });
      return response.results;
    })
    .catch((e) => {
      commit(types.DELETETRANSFORMERS_ERROR, e);
      return [];
    });
};

/**
 * ApiResponse from backend.
 * POST deleteTransformersByQuery and return [ApiResponse]
 * vars: [
 *    [[body]] QueryRequest[ opts, isBodyParam, ]]
 */
export const handleDeleteTransformersByQuery = ({ commit }, payload) => {
  const { queryRequest } = payload;

  commit(types.API_REQUEST);
  let opts = { 
    body: queryRequest
  };

  return transformersApiZiqni
    .deleteTransformersByQuery(
      opts
    )
    .then((response) => {
      commit(types.DELETETRANSFORMERSBYQUERY_SUCCESS, { response });
      return response.results;
    })
    .catch((e) => {
      commit(types.DELETETRANSFORMERSBYQUERY_ERROR, e);
      return [];
    });
};

/**
 * TransformerResponse from backend.
 * GET getTransformers and return [TransformerResponse]
 * vars: [
 *    [[id]] id[ opts, isArray, isQueryParam]
 *    [[limit]] _limit[ opts, isInteger, isQueryParam]
 *    [[skip]] _skip[ opts, isInteger, isQueryParam]]
 */
export const handleGetTransformers = ({ commit }, payload) => {
  const { idArray, limit, skip } = payload;

  commit(types.API_REQUEST);
  let opts = { 
    id: idArray, 
    limit: limit, 
    skip: skip
  };

  return transformersApiZiqni
    .getTransformers(
      opts
    )
    .then((response) => {
      commit(types.GETTRANSFORMERS_SUCCESS, { response });
      return response.results;
    })
    .catch((e) => {
      commit(types.GETTRANSFORMERS_ERROR, e);
      return [];
    });
};

/**
 * TransformerResponse from backend.
 * POST getTransformersByQuery and return [TransformerResponse]
 * vars: [
 *    [[body]] QueryRequest[ opts, isBodyParam, ]]
 */
export const handleGetTransformersByQuery = ({ commit }, payload) => {
  const { queryRequest } = payload;

  commit(types.API_REQUEST);
  let opts = { 
    body: queryRequest
  };

  return transformersApiZiqni
    .getTransformersByQuery(
      opts
    )
    .then((response) => {
      commit(types.GETTRANSFORMERSBYQUERY_SUCCESS, { response });
      return response.results;
    })
    .catch((e) => {
      commit(types.GETTRANSFORMERSBYQUERY_ERROR, e);
      return [];
    });
};

/**
 * ApiResponse from backend.
 * PUT updateTransformers and return [ApiResponse]
 * vars: [
 *    [[body]] UpdateTransformerRequest[ required, isArray, isBodyParam, ]]
 */
export const handleUpdateTransformers = ({ commit }, payload) => {
  const { updateTransformerRequestArray } = payload;

  commit(types.API_REQUEST);
  

  return transformersApiZiqni
    .updateTransformers(
      updateTransformerRequestArray
    )
    .then((response) => {
      commit(types.UPDATETRANSFORMERS_SUCCESS, { response });
      return response.results;
    })
    .catch((e) => {
      commit(types.UPDATETRANSFORMERS_ERROR, e);
      return [];
    });
};
