export const API_REQUEST = 'API_REQUEST';

export const GET_LANGUAGE_LIST_SUCCESS = 'GET_LANGUAGE_LIST_SUCCESS';
export const GET_LANGUAGE_LIST_ERROR = 'GET_LANGUAGE_LIST_ERROR';

export const GET_CUSTOMFIELDS_APPLIES_TO_SUCCESS = 'GET_CUSTOMFIELDS_APPLIES_TO_SUCCESS';
export const GET_CUSTOMFIELDS_APPLIES_TO_ERROR = 'GET_CUSTOMFIELDS_APPLIES_TO_ERROR';

export const GET_TAGS_APPLIES_TO_SUCCESS = 'GET_TAGS_APPLIES_TO_SUCCESS';
export const GET_TAGS_APPLIES_TO_ERROR = 'GET_TAGS_APPLIES_TO_ERROR';
