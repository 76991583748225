import mutations from './mutations';
import * as actions from './actions';
import * as getters from './getters';

const initialState = {
  spaces: [],
  space: {},
  pages: 0,
  appSwitcher: false,
  originalFields: [],
  fields: [
    'icon',
    'spaceName',
    'id',
    'accountType',
    'masterSpace',
    'actions',
  ],
  hiddenFields: [
    'id',
    'spaceName',
    'created',
    'memberID',
    'batchId',
    'relatesTo',
    'relatesToExternal',
    'metadata',
  ],
  meta: {},
  loading: false,
  loaded: true,
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations,
};
