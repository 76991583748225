// <<< DO NOT EDIT GENERATED FILES DIRECTLY! model-store-fields-all.mustache >>>


import competitionStatus from './CompetitionStatusZq';
import competitionType from './CompetitionTypeZq';
import dependantOn from './DependantOnZq';
import dependancy from './DependancyZq';
import productReduced from './ProductReducedZq';

const competitionAllOf = this;

export default {
  objectType: 'Competition_allOf',
  allowableValuesKeys: [],
  constraints: ['isMap',],
  getter: null,
  hiddenFields: [],
  searchableFields: ['numberOfRounds','numberOfGroupStages','name','description','termsAndConditions','maxNumberOfEntrants','minNumberOfEntrants','scheduledStartDate','scheduledEndDate','actualStartDate','actualEndDate','statusCode',], 
  searchableAllFields: ['name','description','termsAndConditions',],
  translatableFields: ['name','description','termsAndConditions',],
  sortableFields: [],
  customFieldsFieldName: [],
  metadataFieldName: [],
  tagFieldName: [],
  entityLinksFrom: [],

  route: {
    
    
  },
  fieldList: ['competitionType','numberOfRounds','numberOfGroupStages','name','description','termsAndConditions','maxNumberOfEntrants','minNumberOfEntrants','entrantMemberType','dependantOn','scheduledStartDate','scheduledEndDate','actualStartDate','actualEndDate','status','statusCode','constraints','products','entrantMemberTagsFilter','productTagsFilter','icon','banner','bannerLowResolution','bannerHighResolution'],
  fields: { 
    competitionType: {
      name: 'competitionType',
      complexType: competitionType,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['required',
                     ],
      formType: null,
    },
    numberOfRounds: {
      name: 'numberOfRounds',
      complexType: null,
      displayOrder: 1006,
      allowableValuesKeys: [],
      constraints: ['required','isPrimitiveType','isNumeric','isInteger','isShort',
                     ],
      formType: null,
    },
    numberOfGroupStages: {
      name: 'numberOfGroupStages',
      complexType: null,
      displayOrder: 1006,
      allowableValuesKeys: [],
      constraints: ['isPrimitiveType','isNumeric','isInteger','isUnboundedInteger',
                     ],
      formType: null,
    },
    name: {
      name: 'name',
      complexType: null,
      displayOrder: 1001,
      allowableValuesKeys: [],
      constraints: ['required','isPrimitiveType','isString',
                     ],
      formType: null,
    },
    description: {
      name: 'description',
      complexType: null,
      displayOrder: 1002,
      allowableValuesKeys: [],
      constraints: ['isPrimitiveType','isString',
                     ],
      formType: 'TEXTAREA',
    },
    termsAndConditions: {
      name: 'termsAndConditions',
      complexType: null,
      displayOrder: 1003,
      allowableValuesKeys: [],
      constraints: ['isPrimitiveType','isString',
                     ],
      formType: 'TEXTAREA',
    },
    maxNumberOfEntrants: {
      name: 'maxNumberOfEntrants',
      complexType: null,
      displayOrder: 1006,
      allowableValuesKeys: [],
      constraints: ['isPrimitiveType','isNumeric','isInteger','isShort',
                     ],
      formType: null,
    },
    minNumberOfEntrants: {
      name: 'minNumberOfEntrants',
      complexType: null,
      displayOrder: 1006,
      allowableValuesKeys: [],
      constraints: ['required','isPrimitiveType','isNumeric','isInteger','isShort',
                     ],
      formType: null,
    },
    entrantMemberType: {
      name: 'entrantMemberType',
      complexType: null,
      displayOrder: 1005,
      allowableValuesKeys: [],
      constraints: ['isPrimitiveType','isString',
                     ],
      formType: null,
    },
    dependantOn: {
      name: 'dependantOn',
      complexType: dependancy,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['required','isModel',
                     ],
      formType: null,
    },
    scheduledStartDate: {
      name: 'scheduledStartDate',
      complexType: null,
      displayOrder: 1006,
      allowableValuesKeys: [],
      constraints: ['required','isPrimitiveType','isDateTime',
                     ],
      formType: null,
    },
    scheduledEndDate: {
      name: 'scheduledEndDate',
      complexType: null,
      displayOrder: 1006,
      allowableValuesKeys: [],
      constraints: ['required','isPrimitiveType','isDateTime',
                     ],
      formType: null,
    },
    actualStartDate: {
      name: 'actualStartDate',
      complexType: null,
      displayOrder: 1006,
      allowableValuesKeys: [],
      constraints: ['isPrimitiveType','isDateTime','isReadOnly',
                     ],
      formType: null,
    },
    actualEndDate: {
      name: 'actualEndDate',
      complexType: null,
      displayOrder: 1006,
      allowableValuesKeys: [],
      constraints: ['isPrimitiveType','isDateTime','isReadOnly',
                     ],
      formType: null,
    },
    status: {
      name: 'status',
      complexType: competitionStatus,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['required',
                     ],
      formType: null,
    },
    statusCode: {
      name: 'statusCode',
      complexType: null,
      displayOrder: 1006,
      allowableValuesKeys: [],
      constraints: ['isPrimitiveType','isNumeric','isInteger','isUnboundedInteger','isReadOnly',
                     ],
      formType: null,
    },
    constraints: {
      name: 'constraints',
      complexType: null,
      displayOrder: 1006,
      allowableValuesKeys: ['notifyMember','memberAcknowledgmentRequired','isPublic','autoStart','autoStop','optinRequiredForEntrants',],
      constraints: ['isConstraintsArray','required','isPrimitiveType','isContainer','isArray',
                    'isString', ],
      formType: null,
    },
    products: {
      name: 'products',
      complexType: productReduced,
      displayOrder: 1006,
      allowableValuesKeys: [],
      constraints: ['required','isContainer','isArray',
                    'isModel', ],
      formType: null,
    },
    entrantMemberTagsFilter: {
      name: 'entrantMemberTagsFilter',
      complexType: dependantOn,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['isModel',
                     ],
      formType: null,
    },
    productTagsFilter: {
      name: 'productTagsFilter',
      complexType: dependantOn,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['isModel',
                     ],
      formType: null,
    },
    icon: {
      name: 'icon',
      complexType: null,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['isPrimitiveType','isString',
                     ],
      formType: null,
    },
    banner: {
      name: 'banner',
      complexType: null,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['isPrimitiveType','isString',
                     ],
      formType: null,
    },
    bannerLowResolution: {
      name: 'bannerLowResolution',
      complexType: null,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['isPrimitiveType','isString',
                     ],
      formType: null,
    },
    bannerHighResolution: {
      name: 'bannerHighResolution',
      complexType: null,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['isPrimitiveType','isString',
                     ],
      formType: null,
    }
  }
};
