// <<< DO NOT EDIT GENERATED FILES DIRECTLY! model-store-fields-all.mustache >>>


import contestStatus from './ContestStatusZq';
import rewardReduced from './RewardReducedZq';

const contestReducedAllOf = this;

export default {
  objectType: 'ContestReduced_allOf',
  allowableValuesKeys: [],
  constraints: ['isMap',],
  getter: null,
  hiddenFields: [],
  searchableFields: [], 
  searchableAllFields: [],
  translatableFields: [],
  sortableFields: [],
  customFieldsFieldName: [],
  metadataFieldName: [],
  tagFieldName: [],
  entityLinksFrom: [],

  route: {
    
    
  },
  fieldList: ['competitionId','row','name','round','groupStage','entrantsFromContest','status','statusCode','scheduledStartDate','scheduledEndDate','actualStartDate','actualEndDate','tags','rewards'],
  fields: { 
    competitionId: {
      name: 'competitionId',
      complexType: null,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['required','isPrimitiveType','isString',
                     ],
      formType: null,
    },
    row: {
      name: 'row',
      complexType: null,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['required','isPrimitiveType','isNumeric','isInteger','isShort',
                     ],
      formType: null,
    },
    name: {
      name: 'name',
      complexType: null,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['required','isPrimitiveType','isString',
                     ],
      formType: null,
    },
    round: {
      name: 'round',
      complexType: null,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['required','isPrimitiveType','isNumeric','isInteger','isShort',
                     ],
      formType: null,
    },
    groupStage: {
      name: 'groupStage',
      complexType: null,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['required','isPrimitiveType','isNumeric','isInteger','isShort',
                     ],
      formType: null,
    },
    entrantsFromContest: {
      name: 'entrantsFromContest',
      complexType: null,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['required','isPrimitiveType','isContainer','isArray',
                    'isString', ],
      formType: null,
    },
    status: {
      name: 'status',
      complexType: contestStatus,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['required',
                     ],
      formType: null,
    },
    statusCode: {
      name: 'statusCode',
      complexType: null,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['required','isPrimitiveType','isNumeric','isInteger','isUnboundedInteger','isReadOnly',
                     ],
      formType: null,
    },
    scheduledStartDate: {
      name: 'scheduledStartDate',
      complexType: null,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['required','isPrimitiveType','isDateTime',
                     ],
      formType: null,
    },
    scheduledEndDate: {
      name: 'scheduledEndDate',
      complexType: null,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['required','isPrimitiveType','isDateTime',
                     ],
      formType: null,
    },
    actualStartDate: {
      name: 'actualStartDate',
      complexType: null,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['isPrimitiveType','isDateTime','isReadOnly',
                     ],
      formType: null,
    },
    actualEndDate: {
      name: 'actualEndDate',
      complexType: null,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['isPrimitiveType','isDateTime','isReadOnly',
                     ],
      formType: null,
    },
    tags: {
      name: 'tags',
      complexType: null,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['isPrimitiveType','isContainer','isArray',
                    'isString', ],
      formType: null,
    },
    rewards: {
      name: 'rewards',
      complexType: rewardReduced,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['isContainer','isArray',
                    'isModel', ],
      formType: null,
    }
  }
};
