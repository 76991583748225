// <<< DO NOT EDIT GENERATED FILES DIRECTLY! api-store-actions.mustache >>>
import { delay, cloneDeep } from 'lodash';
import * as types from './mutation-types';
import sdkApiConfig from '@/config/sdkApiConfig';
import updateFields from '@/utils/updateFields';
import { productsApiZiqni } from '@/generated/ziqni/api/ProductsApiZiqni';
import router from '@/router';

import achievementReducedResponseFields from '../../../fields/AchievementReducedResponseZq.js';
import apiResponseFields from '../../../fields/ApiResponseZq.js';
import competitionReducedResponseFields from '../../../fields/CompetitionReducedResponseZq.js';
import contestReducedResponseFields from '../../../fields/ContestReducedResponseZq.js';
import createProductRequestFields from '../../../fields/CreateProductRequestZq.js';
import productResponseFields from '../../../fields/ProductResponseZq.js';
import queryRequestFields from '../../../fields/QueryRequestZq.js';
import updateProductRequestFields from '../../../fields/UpdateProductRequestZq.js';
import {handleRequest,handleRequestWithBody} from "../../../../../utils/ZiqniAdminApiHandler";

export const handleFields = ({ commit, state }, data) => {
  updateFields(commit, state, data, 'hiddenProductsKey');
};

// Clear products Message
export const handleClearMessage = ({ commit }) => {
  commit(types.CLEAR_MESSAGE);
};

// clear filter
export const handleClearFilter = ({ commit }) => {
  commit(types.CLEAR_FILTER);
};

//PRODUCTS - PRODUCTS
export const handleProductsChanges = ({ commit }, data) => {
  commit('SET_PRODUCTS', data);
};
/**
 * AchievementReducedResponse from backend.
 * GET getAchievementsForProducts and return [AchievementReducedResponse]
 * vars: [
 *    [[id]] id[ required, isString, isPathParam, ]
 *    [[achievementId]] achievementId[ opts, isString, isQueryParam]
 *    [[limit]] _limit[ opts, isInteger, isQueryParam]
 *    [[skip]] _skip[ opts, isInteger, isQueryParam]]
 */
export const handleGetAchievementsForProducts_item = ({ commit }, id, achievementId, limit, skip) => {
  commit(types.API_REQUEST);
  let opts = { 
    achievementId: achievementId, 
    limit: limit, 
    skip: skip
  };

  return productsApiZiqni
    .getAchievementsForProducts(
      id,opts
    )
    .then((response) => {
      commit(types.GETACHIEVEMENTSFORPRODUCTS_ITEM_SUCCESS, { response });
      return response.results;
    })
    .catch((e) => {
      commit(types.GETACHIEVEMENTSFORPRODUCTS_ITEM_ERROR, e);
      return [];
    });
};
/**
 * CompetitionReducedResponse from backend.
 * GET getCompetitionsForProducts and return [CompetitionReducedResponse]
 * vars: [
 *    [[id]] id[ required, isString, isPathParam, ]
 *    [[competitionId]] competitionId[ opts, isString, isQueryParam]
 *    [[limit]] _limit[ opts, isInteger, isQueryParam]
 *    [[skip]] _skip[ opts, isInteger, isQueryParam]]
 */
export const handleGetCompetitionsForProducts_item = ({ commit }, id, competitionId, limit, skip) => {
  commit(types.API_REQUEST);
  let opts = { 
    competitionId: competitionId, 
    limit: limit, 
    skip: skip
  };

  return productsApiZiqni
    .getCompetitionsForProducts(
      id,opts
    )
    .then((response) => {
      commit(types.GETCOMPETITIONSFORPRODUCTS_ITEM_SUCCESS, { response });
      return response.results;
    })
    .catch((e) => {
      commit(types.GETCOMPETITIONSFORPRODUCTS_ITEM_ERROR, e);
      return [];
    });
};
/**
 * ContestReducedResponse from backend.
 * GET getContestsForProducts and return [ContestReducedResponse]
 * vars: [
 *    [[id]] id[ required, isString, isPathParam, ]
 *    [[contestId]] contestId[ opts, isString, isQueryParam]
 *    [[limit]] _limit[ opts, isInteger, isQueryParam]
 *    [[skip]] _skip[ opts, isInteger, isQueryParam]]
 */
export const handleGetContestsForProducts_item = ({ commit }, id, contestId, limit, skip) => {
  commit(types.API_REQUEST);
  let opts = { 
    contestId: contestId, 
    limit: limit, 
    skip: skip
  };

  return productsApiZiqni
    .getContestsForProducts(
      id,opts
    )
    .then((response) => {
      commit(types.GETCONTESTSFORPRODUCTS_ITEM_SUCCESS, { response });
      return response.results;
    })
    .catch((e) => {
      commit(types.GETCONTESTSFORPRODUCTS_ITEM_ERROR, e);
      return [];
    });
};
/**
 * ProductResponse from backend.
 * GET getProducts and return [ProductResponse]
 * vars: [
 *    [[id]] id[ opts, isArray, isQueryParam]
 *    [[limit]] _limit[ opts, isInteger, isQueryParam]
 *    [[skip]] _skip[ opts, isInteger, isQueryParam]]
 */
export const handleGetProducts_item = ({ commit }, idArray, limit, skip) => {
  commit(types.API_REQUEST);
  let opts = { 
    id: idArray, 
    limit: limit, 
    skip: skip
  };

  return productsApiZiqni
    .getProducts(
      opts
    )
    .then((response) => {
      commit(types.GETPRODUCTS_ITEM_SUCCESS, { response });
      return response.results;
    })
    .catch((e) => {
      commit(types.GETPRODUCTS_ITEM_ERROR, e);
      return [];
    });
};

/**
 * ApiResponse from backend.
 * POST createProducts and return [ApiResponse]
 * vars: [
 *    [[body]] CreateProductRequest[ required, isArray, isBodyParam, ]]
 */
export const handleCreateProducts = ({ commit }, payload) => {
  const { createProductRequestArray } = payload;

  commit(types.API_REQUEST);
  

  return productsApiZiqni
    .createProducts(
      createProductRequestArray
    )
    .then((response) => {
      commit(types.CREATEPRODUCTS_SUCCESS, { response });
      return response.results;
    })
    .catch((e) => {
      commit(types.CREATEPRODUCTS_ERROR, e);
      return [];
    });
};

/**
 * ApiResponse from backend.
 * DELETE deleteProducts and return [ApiResponse]
 * vars: [
 *    [[id]] id[ opts, isArray, isQueryParam]]
 */
export const handleDeleteProducts = ({ commit }, payload) => {
  const { idArray } = payload;

  commit(types.API_REQUEST);
  let opts = { 
    id: idArray
  };

  return productsApiZiqni
    .deleteProducts(
      opts
    )
    .then((response) => {
      commit(types.DELETEPRODUCTS_SUCCESS, { response });
      return response.results;
    })
    .catch((e) => {
      commit(types.DELETEPRODUCTS_ERROR, e);
      return [];
    });
};

/**
 * ApiResponse from backend.
 * POST deleteProductsByQuery and return [ApiResponse]
 * vars: [
 *    [[body]] QueryRequest[ opts, isBodyParam, ]]
 */
export const handleDeleteProductsByQuery = ({ commit }, payload) => {
  const { queryRequest } = payload;

  commit(types.API_REQUEST);
  let opts = { 
    body: queryRequest
  };

  return productsApiZiqni
    .deleteProductsByQuery(
      opts
    )
    .then((response) => {
      commit(types.DELETEPRODUCTSBYQUERY_SUCCESS, { response });
      return response.results;
    })
    .catch((e) => {
      commit(types.DELETEPRODUCTSBYQUERY_ERROR, e);
      return [];
    });
};

/**
 * AchievementReducedResponse from backend.
 * GET getAchievementsForProducts and return [AchievementReducedResponse]
 * vars: [
 *    [[id]] id[ required, isString, isPathParam, ]
 *    [[achievementId]] achievementId[ opts, isString, isQueryParam]
 *    [[limit]] _limit[ opts, isInteger, isQueryParam]
 *    [[skip]] _skip[ opts, isInteger, isQueryParam]]
 */
export const handleGetAchievementsForProducts = ({ commit }, payload) => {
  const { id, achievementId, limit, skip } = payload;

  commit(types.API_REQUEST);
  let opts = { 
    achievementId: achievementId, 
    limit: limit, 
    skip: skip
  };

  return productsApiZiqni
    .getAchievementsForProducts(
      id,opts
    )
    .then((response) => {
      commit(types.GETACHIEVEMENTSFORPRODUCTS_SUCCESS, { response });
      return response.results;
    })
    .catch((e) => {
      commit(types.GETACHIEVEMENTSFORPRODUCTS_ERROR, e);
      return [];
    });
};

/**
 * CompetitionReducedResponse from backend.
 * GET getCompetitionsForProducts and return [CompetitionReducedResponse]
 * vars: [
 *    [[id]] id[ required, isString, isPathParam, ]
 *    [[competitionId]] competitionId[ opts, isString, isQueryParam]
 *    [[limit]] _limit[ opts, isInteger, isQueryParam]
 *    [[skip]] _skip[ opts, isInteger, isQueryParam]]
 */
export const handleGetCompetitionsForProducts = ({ commit }, payload) => {
  const { id, competitionId, limit, skip } = payload;

  commit(types.API_REQUEST);
  let opts = { 
    competitionId: competitionId, 
    limit: limit, 
    skip: skip
  };

  return productsApiZiqni
    .getCompetitionsForProducts(
      id,opts
    )
    .then((response) => {
      commit(types.GETCOMPETITIONSFORPRODUCTS_SUCCESS, { response });
      return response.results;
    })
    .catch((e) => {
      commit(types.GETCOMPETITIONSFORPRODUCTS_ERROR, e);
      return [];
    });
};

/**
 * ContestReducedResponse from backend.
 * GET getContestsForProducts and return [ContestReducedResponse]
 * vars: [
 *    [[id]] id[ required, isString, isPathParam, ]
 *    [[contestId]] contestId[ opts, isString, isQueryParam]
 *    [[limit]] _limit[ opts, isInteger, isQueryParam]
 *    [[skip]] _skip[ opts, isInteger, isQueryParam]]
 */
export const handleGetContestsForProducts = ({ commit }, payload) => {
  const { id, contestId, limit, skip } = payload;

  commit(types.API_REQUEST);
  let opts = { 
    contestId: contestId, 
    limit: limit, 
    skip: skip
  };

  return productsApiZiqni
    .getContestsForProducts(
      id,opts
    )
    .then((response) => {
      commit(types.GETCONTESTSFORPRODUCTS_SUCCESS, { response });
      return response.results;
    })
    .catch((e) => {
      commit(types.GETCONTESTSFORPRODUCTS_ERROR, e);
      return [];
    });
};

/**
 * ProductResponse from backend.
 * GET getProducts and return [ProductResponse]
 * vars: [
 *    [[id]] id[ opts, isArray, isQueryParam]
 *    [[limit]] _limit[ opts, isInteger, isQueryParam]
 *    [[skip]] _skip[ opts, isInteger, isQueryParam]]
 */
export const handleGetProducts = ({ commit }, payload) => {
  const { idArray, limit, skip } = payload;

  commit(types.API_REQUEST);
  let opts = { 
    id: idArray, 
    limit: limit, 
    skip: skip
  };

  return productsApiZiqni
    .getProducts(
      opts
    )
    .then((response) => {
      commit(types.GETPRODUCTS_SUCCESS, { response });
      return response.results;
    })
    .catch((e) => {
      commit(types.GETPRODUCTS_ERROR, e);
      return [];
    });
};

/**
 * ProductResponse from backend.
 * POST getProductsByQuery and return [ProductResponse]
 * vars: [
 *    [[body]] QueryRequest[ opts, isBodyParam, ]]
 */
export const handleGetProductsByQuery = ({ commit }, payload) => {
  const { queryRequest } = payload;

  commit(types.API_REQUEST);
  let opts = { 
    body: queryRequest
  };

  return productsApiZiqni
    .getProductsByQuery(
      opts
    )
    .then((response) => {
      commit(types.GETPRODUCTSBYQUERY_SUCCESS, { response });
      return response.results;
    })
    .catch((e) => {
      commit(types.GETPRODUCTSBYQUERY_ERROR, e);
      return [];
    });
};

/**
 * ProductResponse from backend.
 * GET getProductsByRefId and return [ProductResponse]
 * vars: [
 *    [[refIds]] refIds[ opts, isArray, isQueryParam]
 *    [[limit]] _limit[ opts, isInteger, isQueryParam]
 *    [[skip]] _skip[ opts, isInteger, isQueryParam]]
 */
export const handleGetProductsByRefId = ({ commit }, payload) => {
  const { refIdsArray, limit, skip } = payload;

  commit(types.API_REQUEST);
  let opts = { 
    refIds: refIdsArray, 
    limit: limit, 
    skip: skip
  };

  return productsApiZiqni
    .getProductsByRefId(
      opts
    )
    .then((response) => {
      commit(types.GETPRODUCTSBYREFID_SUCCESS, { response });
      return response.results;
    })
    .catch((e) => {
      commit(types.GETPRODUCTSBYREFID_ERROR, e);
      return [];
    });
};

/**
 * ApiResponse from backend.
 * PUT updateProducts and return [ApiResponse]
 * vars: [
 *    [[body]] UpdateProductRequest[ required, isArray, isBodyParam, ]]
 */
export const handleUpdateProducts = ({ commit }, payload) => {
  const { updateProductRequestArray } = payload;

  commit(types.API_REQUEST);
  

  return productsApiZiqni
    .updateProducts(
      updateProductRequestArray
    )
    .then((response) => {
      commit(types.UPDATEPRODUCTS_SUCCESS, { response });
      return response.results;
    })
    .catch((e) => {
      commit(types.UPDATEPRODUCTS_ERROR, e);
      return [];
    });
};
