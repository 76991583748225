import mutations from './mutations';
import * as actions from './actions';
import * as getters from './getters';

// by default theme : 'default';

const initialState = {
  theme: 'default',
  navItemsMain: {
    _name: 'CSidebarNav',
    _children: [
      {
        _name: 'CSidebarNavItem',
        name: 'Dashboard',
        to: '/dashboard',
        icon: 'cil-speedometer',
        _attrs: {class: 'c-sidebar-nav-item'}
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Achievements',
        to: '/achievements',
        icon: 'cil-star',
        _attrs: {class: 'c-sidebar-nav-item'}
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Missions',
        to: '/missions',
        icon: 'cil-star',
        _attrs: {class: 'c-sidebar-nav-item'}
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Competitions',
        to: '/competitions',
        icon: 'cil-layers',
        _attrs: {class: 'c-sidebar-nav-item'}
      },
      {
        _name: 'CSidebarNavItem',
        name: 'InstantWins',
        to: '/instant-wins',
        icon: 'cil-layers',
        _attrs: {class: 'c-sidebar-nav-item'}
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Rewards',
        to: '/rewards',
        icon: 'cil-star',
        _attrs: {class: 'c-sidebar-nav-item'}
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Members',
        to: '/members',
        icon: 'cil-user',
        _attrs: {class: 'c-sidebar-nav-item'}
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Products',
        to: '/products',
        icon: 'cil-cursor',
        _attrs: {class: 'c-sidebar-nav-item'}
      },
      {
        _name: 'CSidebarNavItem',
        name: 'FileRepositorys',
        to: '/cms',
        icon: 'cil-cloud-download',
        _attrs: {class: 'c-sidebar-nav-item'}
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Messaging',
        to: '/inbox',
        icon: 'cil-envelope-closed',
        _attrs: {class: 'c-sidebar-nav-item'}
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Events',
        to: '/events',
        icon: 'cil-globe-alt',
        _attrs: {class: 'c-sidebar-nav-item'}
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Settings',
        to: '/settings',
        icon: 'cil-settings',
        _attrs: {class: 'c-sidebar-nav-item'}
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Help',
        to: 'https://ziqni.myfreshworks.com/login/auth/1643103936268?client_id=78368727158800390&redirect_uri=https%3A%2F%2Fhelp.ziqni.com%2Ffreshid%2Fcustomer_authorize_callback',
        icon: 'cil-settings',
        _attrs: {class: 'c-sidebar-nav-item'}
      },
    ],
  },

};

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations,
};
