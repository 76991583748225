// <<< DO NOT EDIT GENERATED FILES DIRECTLY! model-store-fields-all.mustache >>>


import rankingStrategy from './RankingStrategyZq';
import scoringStrategy from './ScoringStrategyZq';
import strategyType from './StrategyTypeZq';

const tournamentStrategies = this;

export default {
  objectType: 'TournamentStrategies',
  allowableValuesKeys: [],
  constraints: ['isMap',],
  getter: null,
  hiddenFields: [],
  searchableFields: [], 
  searchableAllFields: [],
  translatableFields: [],
  sortableFields: [],
  customFieldsFieldName: [],
  metadataFieldName: [],
  tagFieldName: [],
  entityLinksFrom: [],

  route: {
    
    
  },
  fieldList: ['strategyType','rankingStrategy','scoringStrategy'],
  fields: { 
    strategyType: {
      name: 'strategyType',
      complexType: strategyType,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['required',
                     ],
      formType: null,
    },
    rankingStrategy: {
      name: 'rankingStrategy',
      complexType: rankingStrategy,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['required','isModel',
                     ],
      formType: null,
    },
    scoringStrategy: {
      name: 'scoringStrategy',
      complexType: scoringStrategy,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['required','isModel',
                     ],
      formType: null,
    }
  }
};
