// <<< DO NOT EDIT GENERATED FILES DIRECTLY! api-store-actions.mustache >>>
import { delay, cloneDeep } from 'lodash';
import * as types from './mutation-types';
import sdkApiConfig from '@/config/sdkApiConfig';
import updateFields from '@/utils/updateFields';
import { achievementsApiZiqni } from '@/generated/ziqni/api/AchievementsApiZiqni';
import router from '@/router';

import achievementIssuedResponseFields from '../../../fields/AchievementIssuedResponseZq.js';
import achievementLiveStatusRequestFields from '../../../fields/AchievementLiveStatusRequestZq.js';
import achievementResponseFields from '../../../fields/AchievementResponseZq.js';
import apiResponseFields from '../../../fields/ApiResponseZq.js';
import cloneAchievementResponseFields from '../../../fields/CloneAchievementResponseZq.js';
import createAchievementRequestFields from '../../../fields/CreateAchievementRequestZq.js';
import memberAchievementIssuedResponseFields from '../../../fields/MemberAchievementIssuedResponseZq.js';
import queryRequestFields from '../../../fields/QueryRequestZq.js';
import updateAchievementRequestFields from '../../../fields/UpdateAchievementRequestZq.js';
import {handleRequest,handleRequestWithBody} from "../../../../../utils/ZiqniAdminApiHandler";

export const handleFields = ({ commit, state }, data) => {
  updateFields(commit, state, data, 'hiddenAchievementsKey');
};

// Clear achievements Message
export const handleClearMessage = ({ commit }) => {
  commit(types.CLEAR_MESSAGE);
};

// clear filter
export const handleClearFilter = ({ commit }) => {
  commit(types.CLEAR_FILTER);
};

//ACHIEVEMENTS - ACHIEVEMENTS
export const handleAchievementsChanges = ({ commit }, data) => {
  commit('SET_ACHIEVEMENTS', data);
};
/**
 * AchievementResponse from backend.
 * GET getAchievements and return [AchievementResponse]
 * vars: [
 *    [[id]] id[ opts, isArray, isQueryParam]
 *    [[limit]] _limit[ opts, isInteger, isQueryParam]
 *    [[skip]] _skip[ opts, isInteger, isQueryParam]]
 */
export const handleGetAchievements_item = ({ commit }, idArray, limit, skip) => {
  commit(types.API_REQUEST);
  let opts = { 
    id: idArray, 
    limit: limit, 
    skip: skip
  };

  return achievementsApiZiqni
    .getAchievements(
      opts
    )
    .then((response) => {
      commit(types.GETACHIEVEMENTS_ITEM_SUCCESS, { response });
      return response.results;
    })
    .catch((e) => {
      commit(types.GETACHIEVEMENTS_ITEM_ERROR, e);
      return [];
    });
};

/**
 * ApiResponse from backend.
 * POST createAchievements and return [ApiResponse]
 * vars: [
 *    [[body]] CreateAchievementRequest[ required, isArray, isBodyParam, ]]
 */
export const handleCreateAchievements = ({ commit }, payload) => {
  const { createAchievementRequestArray } = payload;

  commit(types.API_REQUEST);
  

  return achievementsApiZiqni
    .createAchievements(
      createAchievementRequestArray
    )
    .then((response) => {
      commit(types.CREATEACHIEVEMENTS_SUCCESS, { response });
      return response.results;
    })
    .catch((e) => {
      commit(types.CREATEACHIEVEMENTS_ERROR, e);
      return [];
    });
};

/**
 * ApiResponse from backend.
 * DELETE deleteAchievements and return [ApiResponse]
 * vars: [
 *    [[id]] id[ opts, isArray, isQueryParam]]
 */
export const handleDeleteAchievements = ({ commit }, payload) => {
  const { idArray } = payload;

  commit(types.API_REQUEST);
  let opts = { 
    id: idArray
  };

  return achievementsApiZiqni
    .deleteAchievements(
      opts
    )
    .then((response) => {
      commit(types.DELETEACHIEVEMENTS_SUCCESS, { response });
      return response.results;
    })
    .catch((e) => {
      commit(types.DELETEACHIEVEMENTS_ERROR, e);
      return [];
    });
};

/**
 * ApiResponse from backend.
 * POST deleteAchievementsByQuery and return [ApiResponse]
 * vars: [
 *    [[body]] QueryRequest[ opts, isBodyParam, ]]
 */
export const handleDeleteAchievementsByQuery = ({ commit }, payload) => {
  const { queryRequest } = payload;

  commit(types.API_REQUEST);
  let opts = { 
    body: queryRequest
  };

  return achievementsApiZiqni
    .deleteAchievementsByQuery(
      opts
    )
    .then((response) => {
      commit(types.DELETEACHIEVEMENTSBYQUERY_SUCCESS, { response });
      return response.results;
    })
    .catch((e) => {
      commit(types.DELETEACHIEVEMENTSBYQUERY_ERROR, e);
      return [];
    });
};

/**
 * CloneAchievementResponse from backend.
 * GET getAchievementToClone and return [CloneAchievementResponse]
 * vars: [
 *    [[id]] id[ required, isString, isPathParam, ]]
 */
export const handleGetAchievementToClone = ({ commit }, payload) => {
  const { id } = payload;

  commit(types.API_REQUEST);
  

  return achievementsApiZiqni
    .getAchievementToClone(
      id
    )
    .then((response) => {
      commit(types.GETACHIEVEMENTTOCLONE_SUCCESS, { response });
      return response.results;
    })
    .catch((e) => {
      commit(types.GETACHIEVEMENTTOCLONE_ERROR, e);
      return [];
    });
};

/**
 * AchievementResponse from backend.
 * GET getAchievements and return [AchievementResponse]
 * vars: [
 *    [[id]] id[ opts, isArray, isQueryParam]
 *    [[limit]] _limit[ opts, isInteger, isQueryParam]
 *    [[skip]] _skip[ opts, isInteger, isQueryParam]]
 */
export const handleGetAchievements = ({ commit }, payload) => {
  const { idArray, limit, skip } = payload;

  commit(types.API_REQUEST);
  let opts = { 
    id: idArray, 
    limit: limit, 
    skip: skip
  };

  return achievementsApiZiqni
    .getAchievements(
      opts
    )
    .then((response) => {
      commit(types.GETACHIEVEMENTS_SUCCESS, { response });
      return response.results;
    })
    .catch((e) => {
      commit(types.GETACHIEVEMENTS_ERROR, e);
      return [];
    });
};

/**
 * AchievementResponse from backend.
 * POST getAchievementsByQuery and return [AchievementResponse]
 * vars: [
 *    [[body]] QueryRequest[ opts, isBodyParam, ]]
 */
export const handleGetAchievementsByQuery = ({ commit }, payload) => {
  const { queryRequest } = payload;

  commit(types.API_REQUEST);
  let opts = { 
    body: queryRequest
  };

  return achievementsApiZiqni
    .getAchievementsByQuery(
      opts
    )
    .then((response) => {
      commit(types.GETACHIEVEMENTSBYQUERY_SUCCESS, { response });
      return response.results;
    })
    .catch((e) => {
      commit(types.GETACHIEVEMENTSBYQUERY_ERROR, e);
      return [];
    });
};

/**
 * AchievementResponse from backend.
 * GET getAntecendentsForAchievement and return [AchievementResponse]
 * vars: [
 *    [[id]] id[ required, isString, isPathParam, ]
 *    [[limit]] _limit[ opts, isInteger, isQueryParam]
 *    [[skip]] _skip[ opts, isInteger, isQueryParam]]
 */
export const handleGetAntecendentsForAchievement = ({ commit }, payload) => {
  const { id, limit, skip } = payload;

  commit(types.API_REQUEST);
  let opts = { 
    limit: limit, 
    skip: skip
  };

  return achievementsApiZiqni
    .getAntecendentsForAchievement(
      id,opts
    )
    .then((response) => {
      commit(types.GETANTECENDENTSFORACHIEVEMENT_SUCCESS, { response });
      return response.results;
    })
    .catch((e) => {
      commit(types.GETANTECENDENTSFORACHIEVEMENT_ERROR, e);
      return [];
    });
};

/**
 * AchievementIssuedResponse from backend.
 * GET getIssuedAchievementsCountById and return [AchievementIssuedResponse]
 * vars: [
 *    [[id]] id[ required, isString, isPathParam, ]]
 */
export const handleGetIssuedAchievementsCountById = ({ commit }, payload) => {
  const { id } = payload;

  commit(types.API_REQUEST);
  

  return achievementsApiZiqni
    .getIssuedAchievementsCountById(
      id
    )
    .then((response) => {
      commit(types.GETISSUEDACHIEVEMENTSCOUNTBYID_SUCCESS, { response });
      return response.results;
    })
    .catch((e) => {
      commit(types.GETISSUEDACHIEVEMENTSCOUNTBYID_ERROR, e);
      return [];
    });
};

/**
 * MemberAchievementIssuedResponse from backend.
 * GET getMembersAchievementsDetails and return [MemberAchievementIssuedResponse]
 * vars: [
 *    [[id]] id[ required, isString, isPathParam, ]
 *    [[memberId]] memberId[ opts, isString, isQueryParam]
 *    [[limit]] _limit[ opts, isInteger, isQueryParam]
 *    [[skip]] _skip[ opts, isInteger, isQueryParam]]
 */
export const handleGetMembersAchievementsDetails = ({ commit }, payload) => {
  const { id, memberId, limit, skip } = payload;

  commit(types.API_REQUEST);
  let opts = { 
    memberId: memberId, 
    limit: limit, 
    skip: skip
  };

  return achievementsApiZiqni
    .getMembersAchievementsDetails(
      id,opts
    )
    .then((response) => {
      commit(types.GETMEMBERSACHIEVEMENTSDETAILS_SUCCESS, { response });
      return response.results;
    })
    .catch((e) => {
      commit(types.GETMEMBERSACHIEVEMENTSDETAILS_ERROR, e);
      return [];
    });
};

/**
 * ApiResponse from backend.
 * PUT updateAchievements and return [ApiResponse]
 * vars: [
 *    [[body]] UpdateAchievementRequest[ required, isArray, isBodyParam, ]]
 */
export const handleUpdateAchievements = ({ commit }, payload) => {
  const { updateAchievementRequestArray } = payload;

  commit(types.API_REQUEST);
  

  return achievementsApiZiqni
    .updateAchievements(
      updateAchievementRequestArray
    )
    .then((response) => {
      commit(types.UPDATEACHIEVEMENTS_SUCCESS, { response });
      return response.results;
    })
    .catch((e) => {
      commit(types.UPDATEACHIEVEMENTS_ERROR, e);
      return [];
    });
};

/**
 * ApiResponse from backend.
 * PUT updateAchievementsLiveStatus and return [ApiResponse]
 * vars: [
 *    [[body]] AchievementLiveStatusRequest[ opts, isArray, isBodyParam, ]]
 */
export const handleUpdateAchievementsLiveStatus = ({ commit }, payload) => {
  const { achievementLiveStatusRequestArray } = payload;

  commit(types.API_REQUEST);
  let opts = { 
    body: achievementLiveStatusRequestArray
  };

  return achievementsApiZiqni
    .updateAchievementsLiveStatus(
      opts
    )
    .then((response) => {
      commit(types.UPDATEACHIEVEMENTSLIVESTATUS_SUCCESS, { response });
      return response.results;
    })
    .catch((e) => {
      commit(types.UPDATEACHIEVEMENTSLIVESTATUS_ERROR, e);
      return [];
    });
};
