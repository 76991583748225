// import {delay, cloneDeep} from 'lodash';
import * as types from './mutation-types';
// import {memberMessagesApiZiqni} from '@/generated/ziqni/api/MemberMessagesApiZiqni';
import {notificationsApiZiqni} from '@/generated/ziqni/api/NotificationsApiZiqni';
// import router from '../../../router';
// import {logo} from "@/assets/icons/logo";

export const handleGetNotificationMessages = ({commit}, data) => {
    const {queryData, sortBy, skip, limit} = data;
    const body = {
        limit,
        skip,
        sortBy,
        query: queryData,
    };
    commit(types.API_REQUEST);
    return notificationsApiZiqni.getNotifications(body)
        .then(data => {
            const pages = Math.ceil(data.meta.totalRecords / data.meta.limit);
            commit(types.GET_NOTIFICATION_MESSAGES_SUCCESS, {data, pages});
            return data.results;
        })
        .catch(e => {
            commit(types.GET_NOTIFICATION_MESSAGES_ERROR, e);
            return [];
        })
};

// export const handleMessageChanges = ({commit}, data) => {
//     commit('SET_APIKEY', data)
// }
//
// /*
// // Get Messages from backend
// export const handleGetDeletedMessages = ({commit}, data) => {
//     const {queryData, sortBy, skip, limit} = data;
//
//     /!* sortBy[0].order =
//          sortBy[0].order.charAt(0).toUpperCase() +
//          sortBy[0].order.slice(1).toLowerCase();*!/
//
//     const body = {
//         limit,
//         skip,
//         query: queryData,
//     };
//     commit(types.API_REQUEST);
//
//     //Temp code
//     const data1 = {
//         "meta": {"objectType": "MetaExtended", "resultCount": 3, "errorCount": 0, "skip": 0, "limit": 10},
//         "results": [
//             {
//                 "id": "dAWsA3UBen7u-SEkZ6Fi",
//                 "status": "New",
//                 "spaceName": "alex",
//                 "added": "2020-10-07T15:27:11Z",
//                 "memberGroup": ["group1", "group2"],
//                 "members": ["Dc4swmQBVd51K6gPQqFx"],
//                 "messageType": "Achievement",
//                 "body": "This is Test Message",
//                 "subject": "Congratulations!",
//                 "scheduling": "",
//                 "translations": [],
//                 "translatableFields": ["body"],
//                 "constraints": ""
//             },
//
//             {
//                 "id": "z_zuMm8BhoCA-ASXKnuw",
//                 "status": "Read",
//                 "spaceName": "alex",
//                 "added": "2020-10-07T15:27:11Z",
//                 "memberGroup": ["group1", "group2"],
//                 "members": ["SHvkX24BQudJDGCuEtOH"],
//                 "messageType": "Achievement",
//                 "body": "This is Test Message",
//                 "subject": "Congratulations!",
//                 "scheduling": "",
//                 "translations": [],
//                 "translatableFields": ["body"],
//                 "constraints": ""
//             },
//
//             {
//                 "id": "yfzuMm8BhoCA-ASXJ3ts",
//                 "status": "Actioned",
//                 "spaceName": "alex",
//                 "added": "2020-10-07T15:27:11Z",
//                 "memberGroup": ["group1", "group2"],
//                 "members": ["Dc4swmQBVd51K6gPQqFx"],
//                 "messageType": "Achievement",
//                 "body": "This is Test Message",
//                 "subject": "Congratulations!",
//                 "scheduling": "",
//                 "translations": [],
//                 "translatableFields": ["body"],
//                 "constraints": "deprecated"
//             },
//             {
//                 "id": "yfzuMm8BhoCA-ASXJ3ts",
//                 "status": "Expired",
//                 "spaceName": "alex",
//                 "added": "2020-10-07T15:27:11Z",
//                 "memberGroup": ["group1", "group2"],
//                 "members": ["Dc4swmQBVd51K6gPQqFx"],
//                 "messageType": "Achievement",
//                 "body": "This is Test Message",
//                 "subject": "Congratulations!",
//                 "scheduling": "",
//                 "translations": [],
//                 "translatableFields": ["body"],
//                 "constraints": ""
//             },
//             {
//                 "id": "yfzuMm8BhoCA-ASXJ3ts",
//                 "status": "Canceled",
//                 "spaceName": "alex",
//                 "added": "2020-10-07T15:27:11Z",
//                 "memberGroup": ["group1", "group2"],
//                 "members": ["Dc4swmQBVd51K6gPQqFx"],
//                 "messageType": "Achievement",
//                 "body": "This is Test Message",
//                 "subject": "Congratulations!",
//                 "scheduling": "",
//                 "translations": [],
//                 "translatableFields": ["body"],
//                 "constraints": ""
//             },
//             {
//                 "id": "yfzuMm8BhoCA-ASXJ3ts",
//                 "status": "Deprecated",
//                 "spaceName": "alex",
//                 "added": "2020-10-07T15:27:11Z",
//                 "memberGroup": ["group1", "group2"],
//                 "members": ["Dc4swmQBVd51K6gPQqFx"],
//                 "messageType": "Achievement",
//                 "body": "This is Test Message",
//                 "subject": "Congratulations!",
//                 "scheduling": "",
//                 "translations": [],
//                 "translatableFields": ["body"],
//                 "constraints": ""
//             },
//             {
//                 "id": "yfzuMm8BhoCA-ASXJ3ts",
//                 "status": "CollaborationRequest",
//                 "spaceName": "alex",
//                 "added": "2020-10-07T15:27:11Z",
//                 "memberGroup": ["group1", "group2"],
//                 "members": ["Dc4swmQBVd51K6gPQqFx"],
//                 "messageType": "Achievement",
//                 "body": "This is Test Message",
//                 "subject": "Congratulations!",
//                 "scheduling": "",
//                 "translations": [],
//                 "translatableFields": ["body"],
//                 "constraints": ""
//             },
//             {
//                 "id": "yfzuMm8BhoCA-ASXJ3ts",
//                 "status": "Claimed",
//                 "spaceName": "alex",
//                 "added": "2020-10-07T15:27:11Z",
//                 "memberGroup": ["group1", "group2"],
//                 "members": ["Dc4swmQBVd51K6gPQqFx"],
//                 "messageType": "Achievement",
//                 "body": "This is Test Message",
//                 "subject": "Congratulations!",
//                 "scheduling": "",
//                 "translations": [],
//                 "translatableFields": ["body"],
//                 "constraints": ""
//             }
//         ]
//     };
//     const pages = Math.ceil(data1.meta.resultCount / data1.meta.limit);
//     commit(types.GET_DELETED_MESSAGES_SUCCESS, {data1, pages});
//     return data1.results;
// };
// */
//
// export const handleGetMessages = ({commit}, data) => {
//     const {queryData, sortBy, skip, limit} = data;
//     const body = {
//         limit,
//         skip,
//         sortBy,
//         query: queryData,
//     };
//     commit(types.API_REQUEST);
//     return memberMessagesApiZiqni.getMessages(body)
//         .then(data => {
//             const pages = Math.ceil(data.meta.totalRecords / data.meta.limit);
//             commit(types.GET_MESSAGES_SUCCESS, {data, pages});
//             return data.results;
//         })
//         .catch(e => {
//             commit(types.GET_MESSAGES_ERROR, e);
//             return [];
//         })
// };
//
// export const handleGetMemberMessages = ({commit}, data) => {
//     const {queryData, sortBy, skip, limit} = data;
//     const body = {
//         limit,
//         skip,
//         sortBy,
//         query: queryData,
//     };
//     commit(types.API_REQUEST);
//
//     return memberMessagesApiZiqni.getMemberMessages(body)
//         .then(data => {
//             const pages = Math.ceil(data.meta.totalRecords / data.meta.limit);
//             commit(types.GET_MESSAGES_SUCCESS, {data, pages});
//             return data.results;
//         })
//         .catch(e => {
//             commit(types.GET_MESSAGES_ERROR, e);
//             return [];
//         })
// };
//
// export const handleGetMemberMessageById = ({commit}, id) => {
//     const body = {
//         id: [id]
//     };
//     commit(types.API_REQUEST);
//
//     return memberMessagesApiZiqni.getMemberMessages(body)
//         .then(data => {
//             commit(types.GET_MESSAGE_BY_ID_SUCCESS, data);
//             return data.results;
//         })
//         .catch(e => {
//             commit(types.GET_MESSAGE_BY_ID_ERROR, e);
//             return [];
//         })
// };
//
// export const handleGetNotificationMessages = ({commit}, data) => {
//     const {queryData, sortBy, skip, limit} = data;
//     const body = {
//         limit,
//         skip,
//         sortBy,
//         query: queryData,
//     };
//     commit(types.API_REQUEST);
//
//     return memberMessagesApiZiqni.getNotificationMessages(body)
//         .then(data => {
//             const pages = Math.ceil(data.meta.totalRecords / data.meta.limit);
//             commit(types.GET_NOTIFICATION_MESSAGES_SUCCESS, {data, pages});
//             return data.results;
//         })
//         .catch(e => {
//             commit(types.GET_NOTIFICATION_MESSAGES_ERROR, e);
//             return [];
//         })
// };
//
// export const handleGetNotificationMessageById = ({commit}, id) => {
//     const body = {
//         id: [id]
//     };
//     commit(types.API_REQUEST);
//
//     return memberMessagesApiZiqni.getNotificationMessages(body)
//         .then(data => {
//             commit(types.GET_NOTIFICATION_MESSAGE_BY_ID_SUCCESS, data);
//             return data.results;
//         })
//         .catch(e => {
//             commit(types.GET_NOTIFICATION_MESSAGE_BY_ID_ERROR, e);
//             return [];
//         })
// };
//
// export const handleGetAccountMessages = ({commit}, data) => {
//     const {queryData, sortBy, skip, limit} = data;
//     const body = {
//         limit,
//         skip,
//         sortBy,
//         query: queryData,
//     };
//     commit(types.API_REQUEST);
//
//     return memberMessagesApiZiqni.getAccountMessages(body)
//         .then(data => {
//             const pages = Math.ceil(data.meta.totalRecords / data.meta.limit);
//             commit(types.GET_MESSAGES_SUCCESS, {data, pages});
//             return data.results;
//         })
//         .catch(e => {
//             commit(types.GET_MESSAGES_ERROR, e);
//             return [];
//         })
//
//     // Temp code
//     // const data1 = {
//     //     "meta": {"objectType": "MetaExtended", "resultCount": 3, "errorCount": 0, "skip": 0, "limit": 10},
//     //     "results": [
//     //         {
//     //             "id": "dAWsA3UBen7u-SEkZ6Fi",
//     //             "status": "New",
//     //             "spaceName": "alex",
//     //             "added": "2020-10-07T15:27:11Z",
//     //             "memberGroup": ["group1", "group2"],
//     //             "members": ["Dc4swmQBVd51K6gPQqFx"],
//     //             "messageType": "Achievement",
//     //             "body": "This is Test Message",
//     //             "subject": "Congratulations!",
//     //             "scheduling": "",
//     //             "translations": [],
//     //             "translatableFields": ["body"],
//     //             "constraints": ""
//     //         },
//     //
//     //         {
//     //             "id": "z_zuMm8BhoCA-ASXKnuw",
//     //             "status": "Read",
//     //             "spaceName": "alex",
//     //             "added": "2020-10-07T15:27:11Z",
//     //             "memberGroup": ["group1", "group2"],
//     //             "members": ["SHvkX24BQudJDGCuEtOH"],
//     //             "messageType": "Achievement",
//     //             "body": "This is Test Message",
//     //             "subject": "Congratulations!",
//     //             "scheduling": "",
//     //             "translations": [],
//     //             "translatableFields": ["body"],
//     //             "constraints": ""
//     //         },
//     //
//     //         {
//     //             "id": "yfzuMm8BhoCA-ASXJ3ts",
//     //             "status": "Actioned",
//     //             "spaceName": "alex",
//     //             "added": "2020-10-07T15:27:11Z",
//     //             "memberGroup": ["group1", "group2"],
//     //             "members": ["Dc4swmQBVd51K6gPQqFx"],
//     //             "messageType": "Achievement",
//     //             "body": "This is Test Message",
//     //             "subject": "Congratulations!",
//     //             "scheduling": "",
//     //             "translations": [],
//     //             "translatableFields": ["body"],
//     //             "constraints": "deprecated"
//     //         },
//     //         {
//     //             "id": "yfzuMm8BhoCA-ASXJ3ts",
//     //             "status": "Expired",
//     //             "spaceName": "alex",
//     //             "added": "2020-10-07T15:27:11Z",
//     //             "memberGroup": ["group1", "group2"],
//     //             "members": ["Dc4swmQBVd51K6gPQqFx"],
//     //             "messageType": "Achievement",
//     //             "body": "This is Test Message",
//     //             "subject": "Congratulations!",
//     //             "scheduling": "",
//     //             "translations": [],
//     //             "translatableFields": ["body"],
//     //             "constraints": ""
//     //         },
//     //         {
//     //             "id": "yfzuMm8BhoCA-ASXJ3ts",
//     //             "status": "Canceled",
//     //             "spaceName": "alex",
//     //             "added": "2020-10-07T15:27:11Z",
//     //             "memberGroup": ["group1", "group2"],
//     //             "members": ["Dc4swmQBVd51K6gPQqFx"],
//     //             "messageType": "Achievement",
//     //             "body": "This is Test Message",
//     //             "subject": "Congratulations!",
//     //             "scheduling": "",
//     //             "translations": [],
//     //             "translatableFields": ["body"],
//     //             "constraints": ""
//     //         },
//     //         {
//     //             "id": "yfzuMm8BhoCA-ASXJ3ts",
//     //             "status": "Deprecated",
//     //             "spaceName": "alex",
//     //             "added": "2020-10-07T15:27:11Z",
//     //             "memberGroup": ["group1", "group2"],
//     //             "members": ["Dc4swmQBVd51K6gPQqFx"],
//     //             "messageType": "Achievement",
//     //             "body": "This is Test Message",
//     //             "subject": "Congratulations!",
//     //             "scheduling": "",
//     //             "translations": [],
//     //             "translatableFields": ["body"],
//     //             "constraints": ""
//     //         },
//     //         {
//     //             "id": "yfzuMm8BhoCA-ASXJ3ts",
//     //             "status": "CollaborationRequest",
//     //             "spaceName": "alex",
//     //             "added": "2020-10-07T15:27:11Z",
//     //             "memberGroup": ["group1", "group2"],
//     //             "members": ["Dc4swmQBVd51K6gPQqFx"],
//     //             "messageType": "Achievement",
//     //             "body": "This is Test Message",
//     //             "subject": "Congratulations!",
//     //             "scheduling": "",
//     //             "translations": [],
//     //             "translatableFields": ["body"],
//     //             "constraints": ""
//     //         },
//     //         {
//     //             "id": "yfzuMm8BhoCA-ASXJ3ts",
//     //             "status": "Claimed",
//     //             "spaceName": "alex",
//     //             "added": "2020-10-07T15:27:11Z",
//     //             "memberGroup": ["group1", "group2"],
//     //             "members": ["Dc4swmQBVd51K6gPQqFx"],
//     //             "messageType": "Achievement",
//     //             "body": "This is Test Message",
//     //             "subject": "Congratulations!",
//     //             "scheduling": "",
//     //             "translations": [],
//     //             "translatableFields": ["body"],
//     //             "constraints": ""
//     //         }
//     //     ]
//     // };
// };
//
// export const handleGetAccountMessagesById = ({commit}, id) => {
//     const body = {
//         id: [id]
//     };
//     commit(types.API_REQUEST);
//     return memberMessagesApiZiqni.getAccountMessages(body)
//         .then(data => {
//             commit(types.GET_MESSAGE_BY_ID_SUCCESS, data);
//             return data.results;
//         })
//         .catch(e => {
//             commit(types.GET_MESSAGE_BY_ID_ERROR, e);
//             return []
//         })
// };
//
// // Get Messages from backend by IDS
// export const handleGetMessagesByIds = ({commit}, data) => {
//     const {queryData, sortBy, skip, limit} = data;
//
//     const body = {
//         limit,
//         skip,
//         sortBy,
//         query: queryData,
//     };
//     commit(types.API_REQUEST);
//
//     return memberMessagesApiZiqni.getMessagesByIds(body)
//         .then(data => {
//             commit(types.GET_MESSAGES_BY_IDS_SUCCESS, data);
//             return data.results
//         })
//         .catch(e => {
//             commit(types.GET_MESSAGES_BY_IDS_ERROR, e);
//             return []
//         })
// };
//
// export const handleDeleteMemberMessages = async ({commit, dispatch}, data) => {
//     commit(types.API_REQUEST);
//     const {id, queryData, sortBy, skip, limit} = data;
//     try {
//         await memberMessagesApiZiqni.deleteMemberMessages(id);
//         delay(() => {
//             dispatch('handleGetMemberMessages', {
//                 queryData,
//                 sortBy,
//                 skip,
//                 limit,
//             });
//             commit(types.DELETE_MESSAGE_SUCCESS,data)
//         }, 1000)
//     } catch (e) {
//         commit(types.DELETE_MESSAGE_ERROR, e);
//     }
// };
//
// export const handleDeleteNotificationMessages = async ({commit, dispatch}, data) => {
//     commit(types.API_REQUEST);
//     const {id, queryData, sortBy, skip, limit} = data;
//     try {
//         await memberMessagesApiZiqni.deleteNotificationMessages(id);
//         delay(() => {
//             dispatch('handleGetNotificationMessages', {
//                 queryData,
//                 sortBy,
//                 skip,
//                 limit,
//             });
//             commit(types.DELETE_MESSAGE_SUCCESS,data)
//         }, 1000)
//     } catch (e) {
//         commit(types.DELETE_MESSAGE_ERROR, e);
//     }
// };
//
// export const handleDeleteAccountMessages = async ({commit, dispatch}, data) => {
//     commit(types.API_REQUEST);
//     const {id, queryData, sortBy, skip, limit} = data;
//
//     try {
//         await memberMessagesApiZiqni.deleteAccountMessages(id);
//         delay(() => {
//             dispatch('handleGetAccountMessages', {
//                 queryData,
//                 sortBy,
//                 skip,
//                 limit,
//             });
//             commit(types.DELETE_MESSAGE_SUCCESS,data)
//         }, 1000)
//     } catch (e) {
//         commit(types.DELETE_MESSAGE_ERROR, e);
//     }
// };
//
// export const handleUpdateNotificationMessage = async ({commit}, formData) => {
//     commit(types.API_REQUEST);
//     memberMessagesApiZiqni.updateNotificationMessages(formData)
//         .then((res) => {
//             commit(types.UPDATE_MESSAGE_SUCCESS);
//             return res;
//             // delay(() => {
//             //     router.push({
//             //         name: 'PreviewNotificationMessage',
//             //         id: data.results[0].id,
//             //     });
//             // }, 1000);
//            /* router.push({
//                 name: 'PreviewNotificationMessage',
//                 params: {
//                     id: formData.id,
//                 }
//             });*/
//         })
//         .catch(e => {
//             commit(types.UPDATE_MESSAGE_ERROR, e);
//         })
// };
//
// export const handleUpdateMemberMessage = ({commit}, formData) => {
//     commit(types.API_REQUEST);
//     memberMessagesApiZiqni.updateMemberMessages(formData)
//         .then(data => {
//             commit(types.UPDATE_MESSAGE_SUCCESS, data);
//             router.push({
//                 name: 'PreviewMemberMessage',
//                 params: {
//                     id: formData.id,
//                 }
//             });
//         })
//         .catch(e => {
//             commit(types.UPDATE_MESSAGE_ERROR, e);
//         })
// };
//
// // delete selected
// export const handleDeleteMessages = async ({commit, dispatch}, data) => {
//     commit(types.API_REQUEST);
//     const {id, queryData, sortBy, skip, limit} = data;
//
//     const body = {
//         "should": [
//             {
//                 "queryField": "id",
//                 "queryValues": id
//             }
//         ]
//     }
//
//     try {
//         await memberMessagesApiZiqni.deleteMessagesByQuery(body);
//         delay(() => {
//             dispatch('handleGetMessages', {
//                 queryData,
//                 sortBy,
//                 skip,
//                 limit,
//             });
//             commit(types.DELETE_MESSAGE_SUCCESS,data)
//         }, 1000)
//     } catch (e) {
//         commit(types.DELETE_MESSAGE_ERROR, e.message);
//     }
// };
// // Update selected Messages
// export const handleUpdateMessages = ({commit}, formData) => {
//     const data1={"meta":{"objectType":"MetaExtended","resultCount":3,"errorCount":0,"skip":0,"limit":10},
//         "results":[{"id":"dAWsA3UBen7u-SEkZ6Fi","spaceName":"alex","added":"2020-10-07T15:27:11Z","memberGroup":["group1","group2"],
//             "members":[ "Dc4swmQBVd51K6gPQqFx", "SHvkX24BQudJDGCuEtOH" ],"messageType":"Achievement","body":"This is Test Message",
//             "subject":"Congratulations!","status":"New","scheduling":{},"translations":[],"translatableFields":"body","constraint":""}]};
//
//
//
//     delay(() => {
//         commit(types.UPDATE_MESSAGE_SUCCESS,data1)
//
//         router.push({
//             name: 'PreviewDraftMessage',
//             params: {
//                 id: data1.results[0].id,
//             }
//         });
//     }, 1000);
//
//     commit(types.API_REQUEST);
// };
//
// // Create Member Messages
// export const handleCreateNotificationMessages = async ({commit}, formData) => {
//     commit(types.API_REQUEST);
//     try {
//         const data = await memberMessagesApiZiqni.createNotificationMessages(formData);
//         delay(() => {
//             commit(types.CREATE_MESSAGE_SUCCESS);
//             router.push({
//                 name: 'PreviewNotificationMessage',
//                 params: {
//                     id: data.results[0].id
//                 }
//             })
//         }, 1000)
//
//     } catch (e) {
//         commit(types.CREATE_MESSAGE_ERROR, e)
//     }
//     // commit(types.API_REQUEST);
//     // memberMessagesApiZiqni.createNotificationMessages(formData)
//     //     .then(data => {
//     //         delay(() => {
//     //             commit(types.CREATE_MESSAGE_SUCCESS, data);
//     //             router.push({
//     //                 name: 'PreviewNotificationMessage',
//     //                 params: {
//     //                     id: data.results[0].id
//     //                 }
//     //             });
//     //         }, 1000);
//     //
//     //     })
//     //     .catch(e => {
//     //         commit(types.CREATE_MESSAGE_ERROR, e);
//     //     })
// };
//
// // CreateMember Messages
// export const handleCreateMemberMessages = ({commit}, formData) => {
//     commit(types.API_REQUEST);
//     memberMessagesApiZiqni.createMemberMessages(formData)
//         .then(data => {
//             delay(() => {
//                 commit(types.CREATE_MESSAGE_SUCCESS, data);
//                 router.push({
//                     name: 'PreviewMemberMessage',
//                     params: {
//                         id: data.results[0].id
//                     }
//                 });
//             }, 1000);
//
//         })
//         .catch(e => {
//             commit(types.CREATE_MESSAGE_ERROR, e);
//         })
// };
//
// export const handleCreateMessage = ({commit}, formData) => {
//     //Temp Code
//     const data1={"meta":{"objectType":"MetaExtended","resultCount":3,"errorCount":0,"skip":0,"limit":10},
//         "results":[{"id":"dAWsA3UBen7u-SEkZ6Fi","spaceName":"alex","added":"2020-10-07T15:27:11Z","memberGroup":["group1","group2"],
//                 "members":[ "Dc4swmQBVd51K6gPQqFx", "SHvkX24BQudJDGCuEtOH" ],"messageType":"Achievement","body":"This is Test Message",
//                 "subject":"Congratulations!","status":"New","scheduling":{},"translations":[],"translatableFields":"body","constraint":""}]};
//
//
//
//         delay(() => {
//             commit(types.CREATE_MESSAGE_SUCCESS,data1)
//
//             router.push({
//                 name: 'PreviewDraftMessage',
//                 params: {
//                     id: data1.results[0].id,
//                 }
//             });
//         }, 1000);
//
//     commit(types.API_REQUEST);
//     /*MESSAGESsService.createMESSAGESs(formData)
//         .then(data => {
//             delay(() => {
//                 commit(types.CREATE_MESSAGES_SUCCESS, data);
//                 router.push({
//                     name: 'Preview MESSAGES',
//                     params: {
//                         id: data.results[0].id,
//                         name: formData[0].name
//                     }
//                 });
//             }, 1000);
//
//         })
//         .catch(e => {
//             commit(types.CREATE_MESSAGES_ERROR, e);
//         })*/
// };
//
// // show/hide header of table
// export const handleFields = ({commit, state}, data) => {
//     if (data) {
//         const {hiddenFields} = state;
//         const _hiddenFields = cloneDeep(hiddenFields);
//         const hiddenIndex = _hiddenFields.indexOf(data);
//         if (hiddenIndex > -1) {
//             _hiddenFields.splice(hiddenIndex, 1);
//         } else {
//             _hiddenFields.push(data);
//         }
//
//         commit(types.UPDATE_FIELDS, {_hiddenFields});
//     } else {
//         const preHiddenFields =
//             localStorage.getItem('hiddenMessagesHelpersKey') &&
//             JSON.parse(localStorage.getItem('hiddenMessagesHelpersKey'));
//         preHiddenFields &&
//         commit(types.UPDATE_FIELDS, {
//             _hiddenFields: preHiddenFields,
//         });
//     }
// };
//
// // Clear Message
// export const handleClearMessage = ({commit}) => {
//     commit(types.CLEAR_MESSAGE)
// }
//
// // clear filter
// export const handleClearFilter = ({commit}) => {
//     commit(types.CLEAR_FILTER);
// };
