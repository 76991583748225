// <<< DO NOT EDIT GENERATED FILES DIRECTLY! api-store-actions.mustache >>>
import { delay, cloneDeep } from 'lodash';
import * as types from './mutation-types';
import sdkApiConfig from '@/config/sdkApiConfig';
import updateFields from '@/utils/updateFields';
import { membersApiZiqni } from '@/generated/ziqni/api/MembersApiZiqni';
import router from '@/router';

import achievementReducedResponseFields from '../../../fields/AchievementReducedResponseZq.js';
import apiResponseFields from '../../../fields/ApiResponseZq.js';
import competitionReducedResponseFields from '../../../fields/CompetitionReducedResponseZq.js';
import contestReducedResponseFields from '../../../fields/ContestReducedResponseZq.js';
import createMemberRequestFields from '../../../fields/CreateMemberRequestZq.js';
import memberAwardResponseFields from '../../../fields/MemberAwardResponseZq.js';
import memberMessageResponseFields from '../../../fields/MemberMessageResponseZq.js';
import memberResponseFields from '../../../fields/MemberResponseZq.js';
import queryRequestFields from '../../../fields/QueryRequestZq.js';
import updateMemberRequestFields from '../../../fields/UpdateMemberRequestZq.js';
import {handleRequest,handleRequestWithBody} from "../../../../../utils/ZiqniAdminApiHandler";

export const handleFields = ({ commit, state }, data) => {
  updateFields(commit, state, data, 'hiddenMembersKey');
};

// Clear members Message
export const handleClearMessage = ({ commit }) => {
  commit(types.CLEAR_MESSAGE);
};

// clear filter
export const handleClearFilter = ({ commit }) => {
  commit(types.CLEAR_FILTER);
};

//MEMBERS - MEMBERS
export const handleMembersChanges = ({ commit }, data) => {
  commit('SET_MEMBERS', data);
};
/**
 * MemberResponse from backend.
 * GET getMembers and return [MemberResponse]
 * vars: [
 *    [[id]] id[ opts, isArray, isQueryParam]
 *    [[limit]] _limit[ opts, isInteger, isQueryParam]
 *    [[skip]] _skip[ opts, isInteger, isQueryParam]]
 */
export const handleGetMembers_item = ({ commit }, idArray, limit, skip) => {
  commit(types.API_REQUEST);
  let opts = { 
    id: idArray, 
    limit: limit, 
    skip: skip
  };

  return membersApiZiqni
    .getMembers(
      opts
    )
    .then((response) => {
      commit(types.GETMEMBERS_ITEM_SUCCESS, { response });
      return response.results;
    })
    .catch((e) => {
      commit(types.GETMEMBERS_ITEM_ERROR, e);
      return [];
    });
};

/**
 * ApiResponse from backend.
 * POST createMembers and return [ApiResponse]
 * vars: [
 *    [[body]] CreateMemberRequest[ required, isArray, isBodyParam, ]]
 */
export const handleCreateMembers = ({ commit }, payload) => {
  const { createMemberRequestArray } = payload;

  commit(types.API_REQUEST);
  

  return membersApiZiqni
    .createMembers(
      createMemberRequestArray
    )
    .then((response) => {
      commit(types.CREATEMEMBERS_SUCCESS, { response });
      return response.results;
    })
    .catch((e) => {
      commit(types.CREATEMEMBERS_ERROR, e);
      return [];
    });
};

/**
 * ApiResponse from backend.
 * DELETE deleteMembers and return [ApiResponse]
 * vars: [
 *    [[id]] id[ opts, isArray, isQueryParam]]
 */
export const handleDeleteMembers = ({ commit }, payload) => {
  const { idArray } = payload;

  commit(types.API_REQUEST);
  let opts = { 
    id: idArray
  };

  return membersApiZiqni
    .deleteMembers(
      opts
    )
    .then((response) => {
      commit(types.DELETEMEMBERS_SUCCESS, { response });
      return response.results;
    })
    .catch((e) => {
      commit(types.DELETEMEMBERS_ERROR, e);
      return [];
    });
};

/**
 * ApiResponse from backend.
 * POST deleteMembersByQuery and return [ApiResponse]
 * vars: [
 *    [[body]] QueryRequest[ opts, isBodyParam, ]]
 */
export const handleDeleteMembersByQuery = ({ commit }, payload) => {
  const { queryRequest } = payload;

  commit(types.API_REQUEST);
  let opts = { 
    body: queryRequest
  };

  return membersApiZiqni
    .deleteMembersByQuery(
      opts
    )
    .then((response) => {
      commit(types.DELETEMEMBERSBYQUERY_SUCCESS, { response });
      return response.results;
    })
    .catch((e) => {
      commit(types.DELETEMEMBERSBYQUERY_ERROR, e);
      return [];
    });
};

/**
 * AchievementReducedResponse from backend.
 * GET getAchievementsForMembers and return [AchievementReducedResponse]
 * vars: [
 *    [[id]] id[ required, isString, isPathParam, ]
 *    [[achievementId]] achievementId[ opts, isString, isQueryParam]
 *    [[limit]] _limit[ opts, isInteger, isQueryParam]
 *    [[skip]] _skip[ opts, isInteger, isQueryParam]]
 */
export const handleGetAchievementsForMembers = ({ commit }, payload) => {
  const { id, achievementId, limit, skip } = payload;

  commit(types.API_REQUEST);
  let opts = { 
    achievementId: achievementId, 
    limit: limit, 
    skip: skip
  };

  return membersApiZiqni
    .getAchievementsForMembers(
      id,opts
    )
    .then((response) => {
      commit(types.GETACHIEVEMENTSFORMEMBERS_SUCCESS, { response });
      return response.results;
    })
    .catch((e) => {
      commit(types.GETACHIEVEMENTSFORMEMBERS_ERROR, e);
      return [];
    });
};

/**
 * MemberAwardResponse from backend.
 * GET getAwardsForMembers and return [MemberAwardResponse]
 * vars: [
 *    [[id]] id[ required, isString, isPathParam, ]
 *    [[awardId]] awardId[ opts, isString, isQueryParam]
 *    [[limit]] _limit[ opts, isInteger, isQueryParam]
 *    [[skip]] _skip[ opts, isInteger, isQueryParam]]
 */
export const handleGetAwardsForMembers = ({ commit }, payload) => {
  const { id, awardId, limit, skip } = payload;

  commit(types.API_REQUEST);
  let opts = { 
    awardId: awardId, 
    limit: limit, 
    skip: skip
  };

  return membersApiZiqni
    .getAwardsForMembers(
      id,opts
    )
    .then((response) => {
      commit(types.GETAWARDSFORMEMBERS_SUCCESS, { response });
      return response.results;
    })
    .catch((e) => {
      commit(types.GETAWARDSFORMEMBERS_ERROR, e);
      return [];
    });
};

/**
 * CompetitionReducedResponse from backend.
 * GET getCompetitionsForMembers and return [CompetitionReducedResponse]
 * vars: [
 *    [[id]] id[ required, isString, isPathParam, ]
 *    [[competitionId]] competitionId[ opts, isString, isQueryParam]
 *    [[limit]] _limit[ opts, isInteger, isQueryParam]
 *    [[skip]] _skip[ opts, isInteger, isQueryParam]]
 */
export const handleGetCompetitionsForMembers = ({ commit }, payload) => {
  const { id, competitionId, limit, skip } = payload;

  commit(types.API_REQUEST);
  let opts = { 
    competitionId: competitionId, 
    limit: limit, 
    skip: skip
  };

  return membersApiZiqni
    .getCompetitionsForMembers(
      id,opts
    )
    .then((response) => {
      commit(types.GETCOMPETITIONSFORMEMBERS_SUCCESS, { response });
      return response.results;
    })
    .catch((e) => {
      commit(types.GETCOMPETITIONSFORMEMBERS_ERROR, e);
      return [];
    });
};

/**
 * ContestReducedResponse from backend.
 * GET getContestsForMembers and return [ContestReducedResponse]
 * vars: [
 *    [[id]] id[ required, isString, isPathParam, ]
 *    [[contestId]] contestId[ opts, isString, isQueryParam]
 *    [[limit]] _limit[ opts, isInteger, isQueryParam]
 *    [[skip]] _skip[ opts, isInteger, isQueryParam]]
 */
export const handleGetContestsForMembers = ({ commit }, payload) => {
  const { id, contestId, limit, skip } = payload;

  commit(types.API_REQUEST);
  let opts = { 
    contestId: contestId, 
    limit: limit, 
    skip: skip
  };

  return membersApiZiqni
    .getContestsForMembers(
      id,opts
    )
    .then((response) => {
      commit(types.GETCONTESTSFORMEMBERS_SUCCESS, { response });
      return response.results;
    })
    .catch((e) => {
      commit(types.GETCONTESTSFORMEMBERS_ERROR, e);
      return [];
    });
};

/**
 * MemberResponse from backend.
 * GET getMembers and return [MemberResponse]
 * vars: [
 *    [[id]] id[ opts, isArray, isQueryParam]
 *    [[limit]] _limit[ opts, isInteger, isQueryParam]
 *    [[skip]] _skip[ opts, isInteger, isQueryParam]]
 */
export const handleGetMembers = ({ commit }, payload) => {
  const { idArray, limit, skip } = payload;

  commit(types.API_REQUEST);
  let opts = { 
    id: idArray, 
    limit: limit, 
    skip: skip
  };

  return membersApiZiqni
    .getMembers(
      opts
    )
    .then((response) => {
      commit(types.GETMEMBERS_SUCCESS, { response });
      return response.results;
    })
    .catch((e) => {
      commit(types.GETMEMBERS_ERROR, e);
      return [];
    });
};

/**
 * MemberResponse from backend.
 * POST getMembersByQuery and return [MemberResponse]
 * vars: [
 *    [[body]] QueryRequest[ opts, isBodyParam, ]]
 */
export const handleGetMembersByQuery = ({ commit }, payload) => {
  const { queryRequest } = payload;

  commit(types.API_REQUEST);
  let opts = { 
    body: queryRequest
  };

  return membersApiZiqni
    .getMembersByQuery(
      opts
    )
    .then((response) => {
      commit(types.GETMEMBERSBYQUERY_SUCCESS, { response });
      return response.results;
    })
    .catch((e) => {
      commit(types.GETMEMBERSBYQUERY_ERROR, e);
      return [];
    });
};

/**
 * MemberResponse from backend.
 * GET getMembersByRefId and return [MemberResponse]
 * vars: [
 *    [[refIds]] refIds[ opts, isArray, isQueryParam]
 *    [[limit]] _limit[ opts, isInteger, isQueryParam]
 *    [[skip]] _skip[ opts, isInteger, isQueryParam]]
 */
export const handleGetMembersByRefId = ({ commit }, payload) => {
  const { refIdsArray, limit, skip } = payload;

  commit(types.API_REQUEST);
  let opts = { 
    refIds: refIdsArray, 
    limit: limit, 
    skip: skip
  };

  return membersApiZiqni
    .getMembersByRefId(
      opts
    )
    .then((response) => {
      commit(types.GETMEMBERSBYREFID_SUCCESS, { response });
      return response.results;
    })
    .catch((e) => {
      commit(types.GETMEMBERSBYREFID_ERROR, e);
      return [];
    });
};

/**
 * MemberMessageResponse from backend.
 * GET getMessagesForMembers and return [MemberMessageResponse]
 * vars: [
 *    [[id]] id[ required, isString, isPathParam, ]
 *    [[messageId]] messageId[ opts, isString, isQueryParam]
 *    [[limit]] _limit[ opts, isInteger, isQueryParam]
 *    [[skip]] _skip[ opts, isInteger, isQueryParam]]
 */
export const handleGetMessagesForMembers = ({ commit }, payload) => {
  const { id, messageId, limit, skip } = payload;

  commit(types.API_REQUEST);
  let opts = { 
    messageId: messageId, 
    limit: limit, 
    skip: skip
  };

  return membersApiZiqni
    .getMessagesForMembers(
      id,opts
    )
    .then((response) => {
      commit(types.GETMESSAGESFORMEMBERS_SUCCESS, { response });
      return response.results;
    })
    .catch((e) => {
      commit(types.GETMESSAGESFORMEMBERS_ERROR, e);
      return [];
    });
};

/**
 * ApiResponse from backend.
 * PUT updateMembers and return [ApiResponse]
 * vars: [
 *    [[body]] UpdateMemberRequest[ required, isArray, isBodyParam, ]]
 */
export const handleUpdateMembers = ({ commit }, payload) => {
  const { updateMemberRequestArray } = payload;

  commit(types.API_REQUEST);
  

  return membersApiZiqni
    .updateMembers(
      updateMemberRequestArray
    )
    .then((response) => {
      commit(types.UPDATEMEMBERS_SUCCESS, { response });
      return response.results;
    })
    .catch((e) => {
      commit(types.UPDATEMEMBERS_ERROR, e);
      return [];
    });
};
