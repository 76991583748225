// <<< DO NOT EDIT GENERATED FILES DIRECTLY! api-store-actions.mustache >>>
import { delay, cloneDeep } from 'lodash';
import * as types from './mutation-types';
import sdkApiConfig from '@/config/sdkApiConfig';
import updateFields from '@/utils/updateFields';
import { webhooksApiZiqni } from '@/generated/ziqni/api/WebhooksApiZiqni';
import router from '@/router';

import apiResponseFields from '../../../fields/ApiResponseZq.js';
import createWebhookRequestFields from '../../../fields/CreateWebhookRequestZq.js';
import queryRequestFields from '../../../fields/QueryRequestZq.js';
import updateStateRequestFields from '../../../fields/UpdateStateRequestZq.js';
import updateWebhookRequestFields from '../../../fields/UpdateWebhookRequestZq.js';
import webhookResponseFields from '../../../fields/WebhookResponseZq.js';
import webhookTriggersResponseFields from '../../../fields/WebhookTriggersResponseZq.js';
import {handleRequest,handleRequestWithBody} from "../../../../../utils/ZiqniAdminApiHandler";

export const handleFields = ({ commit, state }, data) => {
  updateFields(commit, state, data, 'hiddenWebhooksKey');
};

// Clear webhooks Message
export const handleClearMessage = ({ commit }) => {
  commit(types.CLEAR_MESSAGE);
};

// clear filter
export const handleClearFilter = ({ commit }) => {
  commit(types.CLEAR_FILTER);
};

//WEBHOOKS - WEBHOOKS
export const handleWebhooksChanges = ({ commit }, data) => {
  commit('SET_WEBHOOKS', data);
};
/**
 * WebhookResponse from backend.
 * GET getWebhooks and return [WebhookResponse]
 * vars: [
 *    [[id]] id[ opts, isArray, isQueryParam]
 *    [[limit]] _limit[ opts, isInteger, isQueryParam]
 *    [[skip]] _skip[ opts, isInteger, isQueryParam]]
 */
export const handleGetWebhooks_item = ({ commit }, idArray, limit, skip) => {
  commit(types.API_REQUEST);
  let opts = { 
    id: idArray, 
    limit: limit, 
    skip: skip
  };

  return webhooksApiZiqni
    .getWebhooks(
      opts
    )
    .then((response) => {
      commit(types.GETWEBHOOKS_ITEM_SUCCESS, { response });
      return response.results;
    })
    .catch((e) => {
      commit(types.GETWEBHOOKS_ITEM_ERROR, e);
      return [];
    });
};

/**
 * ApiResponse from backend.
 * POST createWebhooks and return [ApiResponse]
 * vars: [
 *    [[body]] CreateWebhookRequest[ required, isBodyParam, ]]
 */
export const handleCreateWebhooks = ({ commit }, payload) => {
  const { createWebhookRequest } = payload;

  commit(types.API_REQUEST);
  

  return webhooksApiZiqni
    .createWebhooks(
      createWebhookRequest
    )
    .then((response) => {
      commit(types.CREATEWEBHOOKS_SUCCESS, { response });
      return response.results;
    })
    .catch((e) => {
      commit(types.CREATEWEBHOOKS_ERROR, e);
      return [];
    });
};

/**
 * ApiResponse from backend.
 * DELETE deleteWebhooks and return [ApiResponse]
 * vars: [
 *    [[id]] id[ opts, isArray, isQueryParam]]
 */
export const handleDeleteWebhooks = ({ commit }, payload) => {
  const { idArray } = payload;

  commit(types.API_REQUEST);
  let opts = { 
    id: idArray
  };

  return webhooksApiZiqni
    .deleteWebhooks(
      opts
    )
    .then((response) => {
      commit(types.DELETEWEBHOOKS_SUCCESS, { response });
      return response.results;
    })
    .catch((e) => {
      commit(types.DELETEWEBHOOKS_ERROR, e);
      return [];
    });
};

/**
 * ApiResponse from backend.
 * POST deleteWebhooksByQuery and return [ApiResponse]
 * vars: [
 *    [[body]] QueryRequest[ opts, isBodyParam, ]]
 */
export const handleDeleteWebhooksByQuery = ({ commit }, payload) => {
  const { queryRequest } = payload;

  commit(types.API_REQUEST);
  let opts = { 
    body: queryRequest
  };

  return webhooksApiZiqni
    .deleteWebhooksByQuery(
      opts
    )
    .then((response) => {
      commit(types.DELETEWEBHOOKSBYQUERY_SUCCESS, { response });
      return response.results;
    })
    .catch((e) => {
      commit(types.DELETEWEBHOOKSBYQUERY_ERROR, e);
      return [];
    });
};

/**
 * WebhookResponse from backend.
 * GET getWebhooks and return [WebhookResponse]
 * vars: [
 *    [[id]] id[ opts, isArray, isQueryParam]
 *    [[limit]] _limit[ opts, isInteger, isQueryParam]
 *    [[skip]] _skip[ opts, isInteger, isQueryParam]]
 */
export const handleGetWebhooks = ({ commit }, payload) => {
  const { idArray, limit, skip } = payload;

  commit(types.API_REQUEST);
  let opts = { 
    id: idArray, 
    limit: limit, 
    skip: skip
  };

  return webhooksApiZiqni
    .getWebhooks(
      opts
    )
    .then((response) => {
      commit(types.GETWEBHOOKS_SUCCESS, { response });
      return response.results;
    })
    .catch((e) => {
      commit(types.GETWEBHOOKS_ERROR, e);
      return [];
    });
};

/**
 * WebhookResponse from backend.
 * POST getWebhooksByQuery and return [WebhookResponse]
 * vars: [
 *    [[body]] QueryRequest[ opts, isBodyParam, ]]
 */
export const handleGetWebhooksByQuery = ({ commit }, payload) => {
  const { queryRequest } = payload;

  commit(types.API_REQUEST);
  let opts = { 
    body: queryRequest
  };

  return webhooksApiZiqni
    .getWebhooksByQuery(
      opts
    )
    .then((response) => {
      commit(types.GETWEBHOOKSBYQUERY_SUCCESS, { response });
      return response.results;
    })
    .catch((e) => {
      commit(types.GETWEBHOOKSBYQUERY_ERROR, e);
      return [];
    });
};

/**
 * WebhookTriggersResponse from backend.
 * GET getWebhooksTriggers and return [WebhookTriggersResponse]
 * vars: []
 */
export const handleGetWebhooksTriggers = ({ commit }, payload) => {

  commit(types.API_REQUEST);
  

  return webhooksApiZiqni
    .getWebhooksTriggers(
      
    )
    .then((response) => {
      commit(types.GETWEBHOOKSTRIGGERS_SUCCESS, { response });
      return response.results;
    })
    .catch((e) => {
      commit(types.GETWEBHOOKSTRIGGERS_ERROR, e);
      return [];
    });
};

/**
 * ApiResponse from backend.
 * PUT updateWebhooks and return [ApiResponse]
 * vars: [
 *    [[body]] UpdateWebhookRequest[ required, isBodyParam, ]]
 */
export const handleUpdateWebhooks = ({ commit }, payload) => {
  const { updateWebhookRequest } = payload;

  commit(types.API_REQUEST);
  

  return webhooksApiZiqni
    .updateWebhooks(
      updateWebhookRequest
    )
    .then((response) => {
      commit(types.UPDATEWEBHOOKS_SUCCESS, { response });
      return response.results;
    })
    .catch((e) => {
      commit(types.UPDATEWEBHOOKS_ERROR, e);
      return [];
    });
};

/**
 * ApiResponse from backend.
 * POST updateWebhooksState and return [ApiResponse]
 * vars: [
 *    [[body]] UpdateStateRequest[ opts, isBodyParam, ]]
 */
export const handleUpdateWebhooksState = ({ commit }, payload) => {
  const { updateStateRequest } = payload;

  commit(types.API_REQUEST);
  let opts = { 
    body: updateStateRequest
  };

  return webhooksApiZiqni
    .updateWebhooksState(
      opts
    )
    .then((response) => {
      commit(types.UPDATEWEBHOOKSSTATE_SUCCESS, { response });
      return response.results;
    })
    .catch((e) => {
      commit(types.UPDATEWEBHOOKSSTATE_ERROR, e);
      return [];
    });
};
