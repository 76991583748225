import * as types from './mutation-types';

export const setCurrentPageNumber = ({ commit }, data) => {
  commit(types.SET_CURRENT_PAGE_NUMBER, data);
};

export const setItemsPerPage = ({ commit }, data) => {
  commit(types.SET_ITEMS_PER_PAGE, data);
};

export const setTableModel = ({ commit }, data) => {
  commit(types.SET_TABLE_MODEL, data);
};

export const setCurrentStatus = ({ commit }, data) => {
  commit(types.SET_CURRENT_STATUS, data);
};
