// <<< DO NOT EDIT GENERATED FILES DIRECTLY! model-store-fields-all.mustache >>>


import achievementLiveStatus from './AchievementLiveStatusZq';
import achievementReducedAllOf from './AchievementReducedAllOfZq';
import dependancy from './DependancyZq';
import modelDefault from './ModelDefaultZq';

const achievementReduced = this;

export default {
  objectType: 'AchievementReduced',
  allowableValuesKeys: [],
  constraints: [],
  getter: null,
  hiddenFields: ['spaceName',],
  searchableFields: ['id','created',], 
  searchableAllFields: [],
  translatableFields: [],
  sortableFields: ['id','spaceName','created',],
  customFieldsFieldName: [],
  metadataFieldName: [],
  tagFieldName: [],
  entityLinksFrom: [],

  route: {
    
    
  },
  fieldList: ['id','spaceName','created','name','achievementLiveStatus','tagsId','dependantOn'],
  fields: { 
    id: {
      name: 'id',
      complexType: null,
      displayOrder: 2,
      allowableValuesKeys: [],
      constraints: ['required','isPrimitiveType','isString',
                     ],
      formType: null,
    },
    spaceName: {
      name: 'spaceName',
      complexType: null,
      displayOrder: 9000,
      allowableValuesKeys: [],
      constraints: ['required','isPrimitiveType','isString',
                     ],
      formType: null,
    },
    created: {
      name: 'created',
      complexType: null,
      displayOrder: 9001,
      allowableValuesKeys: [],
      constraints: ['required','isPrimitiveType','isDateTime',
                     ],
      formType: null,
    },
    name: {
      name: 'name',
      complexType: null,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['required','isPrimitiveType','isString',
                     ],
      formType: null,
    },
    achievementLiveStatus: {
      name: 'achievementLiveStatus',
      complexType: achievementLiveStatus,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['required',
                     ],
      formType: null,
    },
    tagsId: {
      name: 'tagsId',
      complexType: null,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['isPrimitiveType','isContainer','isArray',
                    'isString', ],
      formType: null,
    },
    dependantOn: {
      name: 'dependantOn',
      complexType: dependancy,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['isContainer','isArray',
                    'isModel', ],
      formType: null,
    }
  }
};
