// <<< DO NOT EDIT GENERATED FILES DIRECTLY! api-store-mutation-types.mustache >>>
export const API_REQUEST = 'API_REQUEST';


export const GETFILEREPOSITORIES_ITEM_REQ = 'GETFILEREPOSITORIES_ITEM_REQ';
export const GETFILEREPOSITORIES_ITEM_ERROR = 'GETFILEREPOSITORIES_ITEM_ERROR';
export const GETFILEREPOSITORIES_ITEM_SUCCESS = 'GETFILEREPOSITORIES_ITEM_SUCCESS'

export const UPDATE_FIELDS = 'UPDATE_FIELDS';
export const CLEAR_FILTER = 'CLEAR_FILTER';
export const CLEAR_MESSAGE = 'CLEAR_MESSAGE';

export const CREATEFILEREPOSITORIES_REQ = 'CREATEFILEREPOSITORIES_REQ';
export const CREATEFILEREPOSITORIES_ERROR = 'CREATEFILEREPOSITORIES_ERROR';
export const CREATEFILEREPOSITORIES_SUCCESS = 'CREATEFILEREPOSITORIES_SUCCESS';

export const DELETEFILEREPOSITORIES_REQ = 'DELETEFILEREPOSITORIES_REQ';
export const DELETEFILEREPOSITORIES_ERROR = 'DELETEFILEREPOSITORIES_ERROR';
export const DELETEFILEREPOSITORIES_SUCCESS = 'DELETEFILEREPOSITORIES_SUCCESS';

export const DELETEFILEREPOSITORIESBYQUERY_REQ = 'DELETEFILEREPOSITORIESBYQUERY_REQ';
export const DELETEFILEREPOSITORIESBYQUERY_ERROR = 'DELETEFILEREPOSITORIESBYQUERY_ERROR';
export const DELETEFILEREPOSITORIESBYQUERY_SUCCESS = 'DELETEFILEREPOSITORIESBYQUERY_SUCCESS';

export const GETFILEREPOSITORIES_REQ = 'GETFILEREPOSITORIES_REQ';
export const GETFILEREPOSITORIES_ERROR = 'GETFILEREPOSITORIES_ERROR';
export const GETFILEREPOSITORIES_SUCCESS = 'GETFILEREPOSITORIES_SUCCESS';

export const GETFILEREPOSITORIESBYQUERY_REQ = 'GETFILEREPOSITORIESBYQUERY_REQ';
export const GETFILEREPOSITORIESBYQUERY_ERROR = 'GETFILEREPOSITORIESBYQUERY_ERROR';
export const GETFILEREPOSITORIESBYQUERY_SUCCESS = 'GETFILEREPOSITORIESBYQUERY_SUCCESS';

export const UPDATEFILEREPOSITORIES_REQ = 'UPDATEFILEREPOSITORIES_REQ';
export const UPDATEFILEREPOSITORIES_ERROR = 'UPDATEFILEREPOSITORIES_ERROR';
export const UPDATEFILEREPOSITORIES_SUCCESS = 'UPDATEFILEREPOSITORIES_SUCCESS';

