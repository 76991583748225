// <<< DO NOT EDIT GENERATED FILES DIRECTLY! api-store-mutation-types.mustache >>>
export const API_REQUEST = 'API_REQUEST';


export const GETNOTIFICATIONS_ITEM_REQ = 'GETNOTIFICATIONS_ITEM_REQ';
export const GETNOTIFICATIONS_ITEM_ERROR = 'GETNOTIFICATIONS_ITEM_ERROR';
export const GETNOTIFICATIONS_ITEM_SUCCESS = 'GETNOTIFICATIONS_ITEM_SUCCESS'

export const UPDATE_FIELDS = 'UPDATE_FIELDS';
export const CLEAR_FILTER = 'CLEAR_FILTER';
export const CLEAR_MESSAGE = 'CLEAR_MESSAGE';

export const CREATENOTIFICATIONS_REQ = 'CREATENOTIFICATIONS_REQ';
export const CREATENOTIFICATIONS_ERROR = 'CREATENOTIFICATIONS_ERROR';
export const CREATENOTIFICATIONS_SUCCESS = 'CREATENOTIFICATIONS_SUCCESS';

export const DELETENOTIFICATIONS_REQ = 'DELETENOTIFICATIONS_REQ';
export const DELETENOTIFICATIONS_ERROR = 'DELETENOTIFICATIONS_ERROR';
export const DELETENOTIFICATIONS_SUCCESS = 'DELETENOTIFICATIONS_SUCCESS';

export const DELETENOTIFICATIONSBYQUERY_REQ = 'DELETENOTIFICATIONSBYQUERY_REQ';
export const DELETENOTIFICATIONSBYQUERY_ERROR = 'DELETENOTIFICATIONSBYQUERY_ERROR';
export const DELETENOTIFICATIONSBYQUERY_SUCCESS = 'DELETENOTIFICATIONSBYQUERY_SUCCESS';

export const GETNOTIFICATIONS_REQ = 'GETNOTIFICATIONS_REQ';
export const GETNOTIFICATIONS_ERROR = 'GETNOTIFICATIONS_ERROR';
export const GETNOTIFICATIONS_SUCCESS = 'GETNOTIFICATIONS_SUCCESS';

export const GETNOTIFICATIONSBYQUERY_REQ = 'GETNOTIFICATIONSBYQUERY_REQ';
export const GETNOTIFICATIONSBYQUERY_ERROR = 'GETNOTIFICATIONSBYQUERY_ERROR';
export const GETNOTIFICATIONSBYQUERY_SUCCESS = 'GETNOTIFICATIONSBYQUERY_SUCCESS';

export const UPDATENOTIFICATIONSSTATUS_REQ = 'UPDATENOTIFICATIONSSTATUS_REQ';
export const UPDATENOTIFICATIONSSTATUS_ERROR = 'UPDATENOTIFICATIONSSTATUS_ERROR';
export const UPDATENOTIFICATIONSSTATUS_SUCCESS = 'UPDATENOTIFICATIONSSTATUS_SUCCESS';

