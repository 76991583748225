import mutations from './mutations';
import * as actions from './actions';
import * as getters from './getters';

const initialState = {
  messages: [],
  singleMessage: {},
  pages: 0,
  resultCount: 0,
  hiddenFields: ['spaceName', 'metadata','scheduling','translations','constraints'],
  originHiddenFields: ['spaceName', 'metadata','scheduling','translations','constraints'],
  fields: [],
  originalFields: [],
  meta: {},
  loading: false,
  loaded: true,
  success: false,
  message: null,
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations,
};
