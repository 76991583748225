export const API_REQUEST = 'API_REQUEST';
export const GET_MESSAGES_SUCCESS = 'GET_MESSAGES_SUCCESS';
export const GET_MESSAGES_ERROR = 'GET_MESSAGES_ERROR';
export const GET_MESSAGE_BY_ID_SUCCESS = 'GET_MESSAGES_BY_ID_SUCCESS';
export const GET_MESSAGE_BY_ID_ERROR = 'GET_MESSAGES_BY_ID_ERROR';
export const SET_MESSAGE = 'SET_MESSAGE';
export const CREATE_MESSAGE_SUCCESS = 'CREATE_MESSAGE_SUCCESS';
export const CREATE_MESSAGE_ERROR = 'CREATE_MESSAGE_ERROR';
export const UPDATE_MESSAGE_SUCCESS = 'UPDATE_MESSAGE_SUCCESS';
export const UPDATE_MESSAGE_ERROR = 'UPDATE_MESSAGE_ERROR';
export const DELETE_MESSAGE_ERROR = 'DELETE_MESSAGE_ERROR';
export const DELETE_MESSAGE_SUCCESS = 'DELETE_MESSAGE_SUCCESS';
export const UPDATE_FIELDS = 'UPDATE_FIELDS';
export const CLEAR_FILTER = 'CLEAR_FILTER';
export const CLEAR_MESSAGE = 'CLEAR_MESSAGE';

// GET BY IDS
export const GET_MESSAGES_BY_IDS_SUCCESS = 'GET_MESSAGES_BY_IDS_SUCCESS';
export const GET_MESSAGES_BY_IDS_ERROR = 'GET_MESSAGES_BY_IDS_ERROR';
export const GET_NOTIFICATION_MESSAGE_BY_ID_SUCCESS = 'GET_NOTIFICATION_MESSAGE_BY_ID_SUCCESS';
export const GET_NOTIFICATION_MESSAGE_BY_ID_ERROR = 'GET_NOTIFICATION_MESSAGE_BY_ID_ERROR';

export const GET_NOTIFICATION_MESSAGES_SUCCESS = 'GET_NOTIFICATION_MESSAGES_SUCCESS';
export const GET_NOTIFICATION_MESSAGES_ERROR = 'GET_NOTIFICATION_MESSAGES_ERROR';

// Action Types (Method GET)
export const MESSAGES_SUCCESS = 'MESSAGES_SUCCESS';
export const MESSAGES_ERROR = 'MESSAGES_ERROR';

