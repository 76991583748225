// <<< DO NOT EDIT GENERATED FILES DIRECTLY! api-store-mutations.mustache >>>
import * as types from './mutation-types';
import * as _ from 'lodash';
import {
  handleErrorResponse, handleItemErrorResponse,
  handleItemSuccessResponse,
  handleSuccessResponse
} from "../../../../../utils/ZiqniAdminApiHandler";
import apiResponseFields from '../../../fields/ApiResponseZq.js';
import createTransformerRequestFields from '../../../fields/CreateTransformerRequestZq.js';
import queryRequestFields from '../../../fields/QueryRequestZq.js';
import transformerResponseFields from '../../../fields/TransformerResponseZq.js';
import updateTransformerRequestFields from '../../../fields/UpdateTransformerRequestZq.js';

const mutations = {
    [types.API_REQUEST](state) {
        state.loading = true;
        state.loaded = false;
    },
    [types.CLEAR_MESSAGE](state){
        state.message = null;
    },

    [types.UPDATE_FIELDS](state, data) {
        state.hiddenFields = data._hiddenFields;
        state.fields = _.difference(state.originalFields, state.hiddenFields);
        localStorage.setItem('hiddenTransformersKey', JSON.stringify(state.hiddenFields));
    },

    [types.CLEAR_FILTER](state) {
        state.fields = _.difference(
            state.originalFields,
            state.originHiddenFields
        );
        state.hiddenFields = state.originHiddenFields;
        localStorage.removeItem('hiddenTransformersKey');
    },

    //TransformerResponse
    [types.GETTRANSFORMERS_ITEM_SUCCESS](state, response) {
      handleItemSuccessResponse(types.GETTRANSFORMERS_ITEM_SUCCESS,transformerResponseFields, state, response, 'transformer');
    },

    //TransformerResponse
    [types.GETTRANSFORMERS_ERROR](state, error) {
      handleItemErrorResponse(types.GETTRANSFORMERS_ITEM_ITEM_ERROR, transformerResponseFields, state, error);
    },


    //ApiResponse
    [types.CREATETRANSFORMERS_SUCCESS](state, response) {
      handleSuccessResponse(types.CREATETRANSFORMERS_SUCCESS,apiResponseFields, state, response);
    },

    //ApiResponse
    [types.CREATETRANSFORMERS_ERROR](state, error) {
      handleErrorResponse(types.CREATETRANSFORMERS_ERROR, apiResponseFields, state, error);
    },

    //ApiResponse
    [types.DELETETRANSFORMERS_SUCCESS](state, response) {
      handleSuccessResponse(types.DELETETRANSFORMERS_SUCCESS,apiResponseFields, state, response);
    },

    //ApiResponse
    [types.DELETETRANSFORMERS_ERROR](state, error) {
      handleErrorResponse(types.DELETETRANSFORMERS_ERROR, apiResponseFields, state, error);
    },

    //ApiResponse
    [types.DELETETRANSFORMERSBYQUERY_SUCCESS](state, response) {
      handleSuccessResponse(types.DELETETRANSFORMERSBYQUERY_SUCCESS,apiResponseFields, state, response);
    },

    //ApiResponse
    [types.DELETETRANSFORMERSBYQUERY_ERROR](state, error) {
      handleErrorResponse(types.DELETETRANSFORMERSBYQUERY_ERROR, apiResponseFields, state, error);
    },

    //TransformerResponse
    [types.GETTRANSFORMERS_SUCCESS](state, response) {
      handleSuccessResponse(types.GETTRANSFORMERS_SUCCESS,transformerResponseFields, state, response);
    },

    //TransformerResponse
    [types.GETTRANSFORMERS_ERROR](state, error) {
      handleErrorResponse(types.GETTRANSFORMERS_ERROR, transformerResponseFields, state, error);
    },

    //TransformerResponse
    [types.GETTRANSFORMERSBYQUERY_SUCCESS](state, response) {
      handleSuccessResponse(types.GETTRANSFORMERSBYQUERY_SUCCESS,transformerResponseFields, state, response);
    },

    //TransformerResponse
    [types.GETTRANSFORMERSBYQUERY_ERROR](state, error) {
      handleErrorResponse(types.GETTRANSFORMERSBYQUERY_ERROR, transformerResponseFields, state, error);
    },

    //ApiResponse
    [types.UPDATETRANSFORMERS_SUCCESS](state, response) {
      handleSuccessResponse(types.UPDATETRANSFORMERS_SUCCESS,apiResponseFields, state, response);
    },

    //ApiResponse
    [types.UPDATETRANSFORMERS_ERROR](state, error) {
      handleErrorResponse(types.UPDATETRANSFORMERS_ERROR, apiResponseFields, state, error);
    },
};

export default mutations;
