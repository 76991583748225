// <<< DO NOT EDIT GENERATED FILES DIRECTLY! model-store-fields-all.mustache >>>



const strategyType = this;

export default {
  objectType: 'StrategyType',
  allowableValuesKeys: ['TotalCumulative','SumBest','LimitedTo','FirstTo'],
  constraints: ['isString','isEnum',],
  getter: null,
  hiddenFields: [],
  searchableFields: [], 
  searchableAllFields: [],
  translatableFields: [],
  sortableFields: [],
  customFieldsFieldName: [],
  metadataFieldName: [],
  tagFieldName: [],
  entityLinksFrom: [],

  route: {
    
    
  },
  fieldList: [],
  fields: { 
  }
};
