const Events = () => import('@/views/ziqni/events');
const PreviewEvent = () => import('@/views/ziqni/events/PreviewEvent');
const CreateEvent = () => import('@/views/ziqni/events/CreateEvent');

export default {
  path: 'events',
  component: {
    render(c) {
      return c('router-view');
    },
  },
  children: [
    {
      path: '',
      name: 'Events',
      component: Events,
    },
    {
      path: 'detail/:id',
      name: 'PreviewEvent',
      component: PreviewEvent,
    },
    {
      path: 'create-event',
      name: 'CreateEvent',
      component: CreateEvent,
    },
  ]
}