// <<< DO NOT EDIT GENERATED FILES DIRECTLY! api-store-actions.mustache >>>
import { delay, cloneDeep } from 'lodash';
import * as types from './mutation-types';
import sdkApiConfig from '@/config/sdkApiConfig';
import updateFields from '@/utils/updateFields';
import { customFieldsApiZiqni } from '@/generated/ziqni/api/CustomFieldsApiZiqni';
import router from '@/router';

import apiResponseFields from '../../../fields/ApiResponseZq.js';
import createCustomFieldRequestFields from '../../../fields/CreateCustomFieldRequestZq.js';
import customFieldResponseFields from '../../../fields/CustomFieldResponseZq.js';
import queryRequestFields from '../../../fields/QueryRequestZq.js';
import updateCustomFieldRequestFields from '../../../fields/UpdateCustomFieldRequestZq.js';
import {handleRequest,handleRequestWithBody} from "../../../../../utils/ZiqniAdminApiHandler";

export const handleFields = ({ commit, state }, data) => {
  updateFields(commit, state, data, 'hiddenCustomFieldsKey');
};

// Clear customFields Message
export const handleClearMessage = ({ commit }) => {
  commit(types.CLEAR_MESSAGE);
};

// clear filter
export const handleClearFilter = ({ commit }) => {
  commit(types.CLEAR_FILTER);
};

//CUSTOMFIELDS - CUSTOMFIELDS
export const handleCustomFieldsChanges = ({ commit }, data) => {
  commit('SET_CUSTOMFIELDS', data);
};
/**
 * CustomFieldResponse from backend.
 * GET getCustomFields and return [CustomFieldResponse]
 * vars: [
 *    [[id]] id[ opts, isArray, isQueryParam]
 *    [[limit]] _limit[ opts, isInteger, isQueryParam]
 *    [[skip]] _skip[ opts, isInteger, isQueryParam]]
 */
export const handleGetCustomFields_item = ({ commit }, idArray, limit, skip) => {
  commit(types.API_REQUEST);
  let opts = { 
    id: idArray, 
    limit: limit, 
    skip: skip
  };

  return customFieldsApiZiqni
    .getCustomFields(
      opts
    )
    .then((response) => {
      commit(types.GETCUSTOMFIELDS_ITEM_SUCCESS, { response });
      return response.results;
    })
    .catch((e) => {
      commit(types.GETCUSTOMFIELDS_ITEM_ERROR, e);
      return [];
    });
};

/**
 * ApiResponse from backend.
 * POST createCustomFields and return [ApiResponse]
 * vars: [
 *    [[body]] CreateCustomFieldRequest[ required, isArray, isBodyParam, ]]
 */
export const handleCreateCustomFields = ({ commit }, payload) => {
  const { createCustomFieldRequestArray } = payload;

  commit(types.API_REQUEST);
  

  return customFieldsApiZiqni
    .createCustomFields(
      createCustomFieldRequestArray
    )
    .then((response) => {
      commit(types.CREATECUSTOMFIELDS_SUCCESS, { response });
      return response.results;
    })
    .catch((e) => {
      commit(types.CREATECUSTOMFIELDS_ERROR, e);
      return [];
    });
};

/**
 * ApiResponse from backend.
 * DELETE deleteCustomFields and return [ApiResponse]
 * vars: [
 *    [[id]] id[ opts, isArray, isQueryParam]]
 */
export const handleDeleteCustomFields = ({ commit }, payload) => {
  const { idArray } = payload;

  commit(types.API_REQUEST);
  let opts = { 
    id: idArray
  };

  return customFieldsApiZiqni
    .deleteCustomFields(
      opts
    )
    .then((response) => {
      commit(types.DELETECUSTOMFIELDS_SUCCESS, { response });
      return response.results;
    })
    .catch((e) => {
      commit(types.DELETECUSTOMFIELDS_ERROR, e);
      return [];
    });
};

/**
 * ApiResponse from backend.
 * POST deleteCustomFieldsByQuery and return [ApiResponse]
 * vars: [
 *    [[body]] QueryRequest[ opts, isBodyParam, ]]
 */
export const handleDeleteCustomFieldsByQuery = ({ commit }, payload) => {
  const { queryRequest } = payload;

  commit(types.API_REQUEST);
  let opts = { 
    body: queryRequest
  };

  return customFieldsApiZiqni
    .deleteCustomFieldsByQuery(
      opts
    )
    .then((response) => {
      commit(types.DELETECUSTOMFIELDSBYQUERY_SUCCESS, { response });
      return response.results;
    })
    .catch((e) => {
      commit(types.DELETECUSTOMFIELDSBYQUERY_ERROR, e);
      return [];
    });
};

/**
 * CustomFieldResponse from backend.
 * GET getCustomFields and return [CustomFieldResponse]
 * vars: [
 *    [[id]] id[ opts, isArray, isQueryParam]
 *    [[limit]] _limit[ opts, isInteger, isQueryParam]
 *    [[skip]] _skip[ opts, isInteger, isQueryParam]]
 */
export const handleGetCustomFields = ({ commit }, payload) => {
  const { idArray, limit, skip } = payload;

  commit(types.API_REQUEST);
  let opts = { 
    id: idArray, 
    limit: limit, 
    skip: skip
  };

  return customFieldsApiZiqni
    .getCustomFields(
      opts
    )
    .then((response) => {
      commit(types.GETCUSTOMFIELDS_SUCCESS, { response });
      return response.results;
    })
    .catch((e) => {
      commit(types.GETCUSTOMFIELDS_ERROR, e);
      return [];
    });
};

/**
 * CustomFieldResponse from backend.
 * POST getCustomFieldsByQuery and return [CustomFieldResponse]
 * vars: [
 *    [[body]] QueryRequest[ opts, isBodyParam, ]]
 */
export const handleGetCustomFieldsByQuery = ({ commit }, payload) => {
  const { queryRequest } = payload;

  commit(types.API_REQUEST);
  let opts = { 
    body: queryRequest
  };

  return customFieldsApiZiqni
    .getCustomFieldsByQuery(
      opts
    )
    .then((response) => {
      commit(types.GETCUSTOMFIELDSBYQUERY_SUCCESS, { response });
      return response.results;
    })
    .catch((e) => {
      commit(types.GETCUSTOMFIELDSBYQUERY_ERROR, e);
      return [];
    });
};

/**
 * ApiResponse from backend.
 * PUT updateCustomFields and return [ApiResponse]
 * vars: [
 *    [[body]] UpdateCustomFieldRequest[ required, isArray, isBodyParam, ]]
 */
export const handleUpdateCustomFields = ({ commit }, payload) => {
  const { updateCustomFieldRequestArray } = payload;

  commit(types.API_REQUEST);
  

  return customFieldsApiZiqni
    .updateCustomFields(
      updateCustomFieldRequestArray
    )
    .then((response) => {
      commit(types.UPDATECUSTOMFIELDS_SUCCESS, { response });
      return response.results;
    })
    .catch((e) => {
      commit(types.UPDATECUSTOMFIELDS_ERROR, e);
      return [];
    });
};
