// <<< DO NOT EDIT GENERATED FILES DIRECTLY! model-store-fields-all.mustache >>>


import competitionType from './CompetitionTypeZq';
import createContestForCompetitionRequest from './CreateContestForCompetitionRequestZq';
import dependancy from './DependancyZq';
import dependantOn from './DependantOnZq';
import updateCompetitionRequestAllOf from './UpdateCompetitionRequestAllOfZq';
import updateContestRequest from './UpdateContestRequestZq';
import updateModelDefault from './UpdateModelDefaultZq';
import updateOptParamModels from './UpdateOptParamModelsZq';

const updateCompetitionRequest = this;

export default {
  objectType: 'UpdateCompetitionRequest',
  allowableValuesKeys: [],
  constraints: [],
  getter: null,
  hiddenFields: [],
  searchableFields: [], 
  searchableAllFields: [],
  translatableFields: [],
  sortableFields: [],
  customFieldsFieldName: ['customFields',],
  metadataFieldName: ['metadata'],
  tagFieldName: ['tags'],
  entityLinksFrom: [],

  route: {
    
    
  },
  fieldList: ['id','customFields','tags','metadata','competitionType','numberOfRounds','numberOfGroupStages','name','description','termsAndConditions','entrantMemberType','maxNumberOfEntrants','minNumberOfEntrants','scheduledStartDate','scheduledEndDate','dependantOn','constraints','addConstraints','removeConstraints','productIds','createContests','updateContests','entrantMemberTagsFilter','productTagsFilter','icon','banner','bannerLowResolution','bannerHighResolution'],
  fields: { 
    id: {
      name: 'id',
      complexType: null,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['required','isPrimitiveType','isString',
                     ],
      formType: null,
    },
    customFields: {
      name: 'customFields',
      complexType: null,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['isCustomFieldsArray','isPrimitiveType','isContainer','isFreeFormObject','isMap',
                    'isFreeFormObject','isNullable', ],
      formType: 'CUSTOM',
    },
    tags: {
      name: 'tags',
      complexType: null,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['isTagsArray','isPrimitiveType','isContainer','isArray',
                    'isString', ],
      formType: 'TAG',
    },
    metadata: {
      name: 'metadata',
      complexType: null,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['isMetadataMap','isPrimitiveType','isContainer','isMap',
                    'isString', ],
      formType: 'META',
    },
    competitionType: {
      name: 'competitionType',
      complexType: competitionType,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: [
                     ],
      formType: null,
    },
    numberOfRounds: {
      name: 'numberOfRounds',
      complexType: null,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['isPrimitiveType','isNumeric','isInteger','isShort',
                     ],
      formType: null,
    },
    numberOfGroupStages: {
      name: 'numberOfGroupStages',
      complexType: null,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['isPrimitiveType','isNumeric','isInteger','isUnboundedInteger',
                     ],
      formType: null,
    },
    name: {
      name: 'name',
      complexType: null,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['isPrimitiveType','isString',
                     ],
      formType: null,
    },
    description: {
      name: 'description',
      complexType: null,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['isPrimitiveType','isString',
                     ],
      formType: null,
    },
    termsAndConditions: {
      name: 'termsAndConditions',
      complexType: null,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['isPrimitiveType','isString',
                     ],
      formType: null,
    },
    entrantMemberType: {
      name: 'entrantMemberType',
      complexType: null,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['isPrimitiveType','isString',
                     ],
      formType: null,
    },
    maxNumberOfEntrants: {
      name: 'maxNumberOfEntrants',
      complexType: null,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['isPrimitiveType','isNumeric','isInteger','isShort',
                     ],
      formType: null,
    },
    minNumberOfEntrants: {
      name: 'minNumberOfEntrants',
      complexType: null,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['isPrimitiveType','isNumeric','isInteger','isShort',
                     ],
      formType: null,
    },
    scheduledStartDate: {
      name: 'scheduledStartDate',
      complexType: null,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['isPrimitiveType','isDateTime',
                     ],
      formType: null,
    },
    scheduledEndDate: {
      name: 'scheduledEndDate',
      complexType: null,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['isPrimitiveType','isDateTime',
                     ],
      formType: null,
    },
    dependantOn: {
      name: 'dependantOn',
      complexType: dependancy,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['required','isModel',
      ],
      formType: null,
    },
    constraints: {
      name: 'constraints',
      complexType: null,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['deprecated','isPrimitiveType','isContainer','isArray',
                    'isString', ],
      formType: null,
    },
    addConstraints: {
      name: 'addConstraints',
      complexType: null,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['isPrimitiveType','isContainer','isArray',
                    'isString', ],
      formType: null,
    },
    removeConstraints: {
      name: 'removeConstraints',
      complexType: null,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['isPrimitiveType','isContainer','isArray',
                    'isString', ],
      formType: null,
    },
    productIds: {
      name: 'productIds',
      complexType: null,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['isPrimitiveType','isContainer','isArray',
                    'isString', ],
      formType: null,
    },
    createContests: {
      name: 'createContests',
      complexType: createContestForCompetitionRequest,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['isModel',
                     ],
      formType: null,
    },
    updateContests: {
      name: 'updateContests',
      complexType: updateContestRequest,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['isModel',
                     ],
      formType: null,
    },
    entrantMemberTagsFilter: {
      name: 'entrantMemberTagsFilter',
      complexType: dependantOn,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['isModel',
                     ],
      formType: null,
    },
    productTagsFilter: {
      name: 'productTagsFilter',
      complexType: dependantOn,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['isModel',
                     ],
      formType: null,
    },
    icon: {
      name: 'icon',
      complexType: null,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['isPrimitiveType','isString',
                     ],
      formType: null,
    },
    banner: {
      name: 'banner',
      complexType: null,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['isPrimitiveType','isString',
                     ],
      formType: null,
    },
    bannerLowResolution: {
      name: 'bannerLowResolution',
      complexType: null,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['isPrimitiveType','isString',
                     ],
      formType: null,
    },
    bannerHighResolution: {
      name: 'bannerHighResolution',
      complexType: null,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['isPrimitiveType','isString',
                     ],
      formType: null,
    }
  }
};
