// <<< DO NOT EDIT GENERATED FILES DIRECTLY! api-store-mutation-types.mustache >>>
export const API_REQUEST = 'API_REQUEST';


export const GETCOLLABORATORS_ITEM_REQ = 'GETCOLLABORATORS_ITEM_REQ';
export const GETCOLLABORATORS_ITEM_ERROR = 'GETCOLLABORATORS_ITEM_ERROR';
export const GETCOLLABORATORS_ITEM_SUCCESS = 'GETCOLLABORATORS_ITEM_SUCCESS'

export const UPDATE_FIELDS = 'UPDATE_FIELDS';
export const CLEAR_FILTER = 'CLEAR_FILTER';
export const CLEAR_MESSAGE = 'CLEAR_MESSAGE';

export const CREATECOLLABORATORS_REQ = 'CREATECOLLABORATORS_REQ';
export const CREATECOLLABORATORS_ERROR = 'CREATECOLLABORATORS_ERROR';
export const CREATECOLLABORATORS_SUCCESS = 'CREATECOLLABORATORS_SUCCESS';

export const DELETECOLLABORATORS_REQ = 'DELETECOLLABORATORS_REQ';
export const DELETECOLLABORATORS_ERROR = 'DELETECOLLABORATORS_ERROR';
export const DELETECOLLABORATORS_SUCCESS = 'DELETECOLLABORATORS_SUCCESS';

export const GETAVAILABLEROLESFORCOLLABORATORS_REQ = 'GETAVAILABLEROLESFORCOLLABORATORS_REQ';
export const GETAVAILABLEROLESFORCOLLABORATORS_ERROR = 'GETAVAILABLEROLESFORCOLLABORATORS_ERROR';
export const GETAVAILABLEROLESFORCOLLABORATORS_SUCCESS = 'GETAVAILABLEROLESFORCOLLABORATORS_SUCCESS';

export const GETCOLLABORATORS_REQ = 'GETCOLLABORATORS_REQ';
export const GETCOLLABORATORS_ERROR = 'GETCOLLABORATORS_ERROR';
export const GETCOLLABORATORS_SUCCESS = 'GETCOLLABORATORS_SUCCESS';

export const GETCOLLABORATORSBYQUERY_REQ = 'GETCOLLABORATORSBYQUERY_REQ';
export const GETCOLLABORATORSBYQUERY_ERROR = 'GETCOLLABORATORSBYQUERY_ERROR';
export const GETCOLLABORATORSBYQUERY_SUCCESS = 'GETCOLLABORATORSBYQUERY_SUCCESS';

export const UPDATECOLLABORATORS_REQ = 'UPDATECOLLABORATORS_REQ';
export const UPDATECOLLABORATORS_ERROR = 'UPDATECOLLABORATORS_ERROR';
export const UPDATECOLLABORATORS_SUCCESS = 'UPDATECOLLABORATORS_SUCCESS';

