const Awards = () => import('@/views/ziqni/awards');
const PreviewAward = () => import('@/views/ziqni/awards/AwardDetails');

export default {
  path: 'awards',
  component: {
    render(c) {
      return c('router-view');
    },
  },
  children: [
    {
      path: '',
      name: 'Awards',
      component: Awards,
    },
    {
      path: 'detail/:id',
      name: 'PreviewAward',
      component: PreviewAward,
    },
  ]
}