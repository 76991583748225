import {SET_THEME, GET_THEME, SET_MAIN_NAV_ITEMS} from './mutation-types';

export const setTheme = ({commit}, theme) => {
    commit(SET_THEME, theme);
};

export const getTheme = ({commit}, theme) => {
    commit(GET_THEME, theme);
};

// SET MAIN NAV ITEMS FOR SIDEBAR
export const setMainNavItems = ({commit}, payload) => {
    commit(SET_MAIN_NAV_ITEMS, payload);
}