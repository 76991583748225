// <<< DO NOT EDIT GENERATED FILES DIRECTLY! api-store-mutation-types.mustache >>>
export const API_REQUEST = 'API_REQUEST';


export const GETWEBHOOKS_ITEM_REQ = 'GETWEBHOOKS_ITEM_REQ';
export const GETWEBHOOKS_ITEM_ERROR = 'GETWEBHOOKS_ITEM_ERROR';
export const GETWEBHOOKS_ITEM_SUCCESS = 'GETWEBHOOKS_ITEM_SUCCESS'

export const UPDATE_FIELDS = 'UPDATE_FIELDS';
export const CLEAR_FILTER = 'CLEAR_FILTER';
export const CLEAR_MESSAGE = 'CLEAR_MESSAGE';

export const CREATEWEBHOOKS_REQ = 'CREATEWEBHOOKS_REQ';
export const CREATEWEBHOOKS_ERROR = 'CREATEWEBHOOKS_ERROR';
export const CREATEWEBHOOKS_SUCCESS = 'CREATEWEBHOOKS_SUCCESS';

export const DELETEWEBHOOKS_REQ = 'DELETEWEBHOOKS_REQ';
export const DELETEWEBHOOKS_ERROR = 'DELETEWEBHOOKS_ERROR';
export const DELETEWEBHOOKS_SUCCESS = 'DELETEWEBHOOKS_SUCCESS';

export const DELETEWEBHOOKSBYQUERY_REQ = 'DELETEWEBHOOKSBYQUERY_REQ';
export const DELETEWEBHOOKSBYQUERY_ERROR = 'DELETEWEBHOOKSBYQUERY_ERROR';
export const DELETEWEBHOOKSBYQUERY_SUCCESS = 'DELETEWEBHOOKSBYQUERY_SUCCESS';

export const GETWEBHOOKS_REQ = 'GETWEBHOOKS_REQ';
export const GETWEBHOOKS_ERROR = 'GETWEBHOOKS_ERROR';
export const GETWEBHOOKS_SUCCESS = 'GETWEBHOOKS_SUCCESS';

export const GETWEBHOOKSBYQUERY_REQ = 'GETWEBHOOKSBYQUERY_REQ';
export const GETWEBHOOKSBYQUERY_ERROR = 'GETWEBHOOKSBYQUERY_ERROR';
export const GETWEBHOOKSBYQUERY_SUCCESS = 'GETWEBHOOKSBYQUERY_SUCCESS';

export const GETWEBHOOKSTRIGGERS_REQ = 'GETWEBHOOKSTRIGGERS_REQ';
export const GETWEBHOOKSTRIGGERS_ERROR = 'GETWEBHOOKSTRIGGERS_ERROR';
export const GETWEBHOOKSTRIGGERS_SUCCESS = 'GETWEBHOOKSTRIGGERS_SUCCESS';

export const UPDATEWEBHOOKS_REQ = 'UPDATEWEBHOOKS_REQ';
export const UPDATEWEBHOOKS_ERROR = 'UPDATEWEBHOOKS_ERROR';
export const UPDATEWEBHOOKS_SUCCESS = 'UPDATEWEBHOOKS_SUCCESS';

export const UPDATEWEBHOOKSSTATE_REQ = 'UPDATEWEBHOOKSSTATE_REQ';
export const UPDATEWEBHOOKSSTATE_ERROR = 'UPDATEWEBHOOKSSTATE_ERROR';
export const UPDATEWEBHOOKSSTATE_SUCCESS = 'UPDATEWEBHOOKSSTATE_SUCCESS';

