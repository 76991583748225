import * as types from './mutation-types';
import * as _ from 'lodash';
import {
  handleErrorResponse, handleItemErrorResponse,
  handleItemSuccessResponse,
  handleSuccessResponse
} from "../../../utils/ZiqniAdminApiHandler";
import apiResponseFields from '@/generated/ziqni/fields/ApiResponseZq.js';
import kafkaConnectionResponseFields from '@/generated/ziqni/fields/KafkaConnectionResponseZq.js';
import rabbitMqConnectionResponseFields from '@/generated/ziqni/fields/RabbitMqConnectionResponseZq.js';
import sqsConnectionResponseFields from '@/generated/ziqni/fields/SqsConnectionResponseZq.js';
import connectionResponseFields from '@/generated/ziqni/fields/ConnectionResponseZq';

const mutations = {
    [types.API_REQUEST](state) {
        state.loading = true;
        state.loaded = false;
    },
    [types.CLEAR_MESSAGE](state){
        state.message = null;
    },

    [types.UPDATE_FIELDS](state, data) {
        state.hiddenFields = data._hiddenFields;
        state.fields = _.difference(state.originalFields, state.hiddenFields);
        localStorage.setItem('hiddenConsumersKey', JSON.stringify(state.hiddenFields));
    },

    [types.CLEAR_FILTER](state) {
        state.fields = _.difference(
            state.originalFields,
            state.originHiddenFields
        );
        state.hiddenFields = state.originHiddenFields;
        localStorage.removeItem('hiddenConsumersKey');
    },

    //KafkaConnectionResponse
    [types.GETKAFKACONNECTIONS_ITEM_SUCCESS](state, response) {
        handleItemSuccessResponse(types.GETKAFKACONNECTIONS_ITEM_SUCCESS,kafkaConnectionResponseFields, state, response, 'kafkaConnection');
    },

    //KafkaConnectionResponse
    [types.GETKAFKACONNECTIONS_ERROR](state, error) {
        handleItemErrorResponse(types.GETKAFKACONNECTIONS_ITEM_ERROR, kafkaConnectionResponseFields, state, error);
    },

    //RabbitMqConnectionResponse
    [types.GETRABBITMQCONNECTIONS_ITEM_SUCCESS](state, response) {
        handleItemSuccessResponse(types.GETRABBITMQCONNECTIONS_ITEM_SUCCESS,rabbitMqConnectionResponseFields, state, response, 'rabbitMqConnection');
    },

    //RabbitMqConnectionResponse
    [types.GETRABBITMQCONNECTIONS_ERROR](state, error) {
        handleItemErrorResponse(types.GETRABBITMQCONNECTIONS_ITEM_ERROR, rabbitMqConnectionResponseFields, state, error);
    },

    //SqsConnectionResponse
    [types.GETSQSCONNECTIONS_ITEM_SUCCESS](state, response) {
        handleItemSuccessResponse(types.GETSQSCONNECTIONS_ITEM_SUCCESS,sqsConnectionResponseFields, state, response, 'sqsConnection');
    },

    //SqsConnectionResponse
    [types.GETSQSCONNECTIONS_ERROR](state, error) {
        handleItemErrorResponse(types.GETSQSCONNECTIONS_ITEM_ERROR, sqsConnectionResponseFields, state, error);
    },


    //ApiResponse
    [types.CREATEKAFKACONNECTIONS_SUCCESS](state, response) {
        handleSuccessResponse(types.CREATEKAFKACONNECTIONS_SUCCESS,apiResponseFields, state, response);
    },

    //ApiResponse
    [types.CREATEKAFKACONNECTIONS_ERROR](state, error) {
        handleErrorResponse(types.CREATEKAFKACONNECTIONS_ERROR, apiResponseFields, state, error);
    },

    //ApiResponse
    [types.CREATERABBITMQCONNECTIONS_SUCCESS](state, response) {
        handleSuccessResponse(types.CREATERABBITMQCONNECTIONS_SUCCESS,apiResponseFields, state, response);
    },

    //ApiResponse
    [types.CREATERABBITMQCONNECTIONS_ERROR](state, error) {
        handleErrorResponse(types.CREATERABBITMQCONNECTIONS_ERROR, apiResponseFields, state, error);
    },

    //ApiResponse
    [types.CREATESQSCONNECTIONS_SUCCESS](state, response) {
        handleSuccessResponse(types.CREATESQSCONNECTIONS_SUCCESS,apiResponseFields, state, response);
    },

    //ApiResponse
    [types.CREATESQSCONNECTIONS_ERROR](state, error) {
        handleErrorResponse(types.CREATESQSCONNECTIONS_ERROR, apiResponseFields, state, error);
    },

    //ApiResponse
    [types.DELETEKAFKACONNECTIONS_SUCCESS](state, response) {
        handleSuccessResponse(types.DELETEKAFKACONNECTIONS_SUCCESS,apiResponseFields, state, response);
    },

    //ApiResponse
    [types.DELETEKAFKACONNECTIONS_ERROR](state, error) {
        handleErrorResponse(types.DELETEKAFKACONNECTIONS_ERROR, apiResponseFields, state, error);
    },

    //ApiResponse
    [types.DELETEKAFKACONNECTIONSBYQUERY_SUCCESS](state, response) {
        handleSuccessResponse(types.DELETEKAFKACONNECTIONSBYQUERY_SUCCESS,apiResponseFields, state, response);
    },

    //ApiResponse
    [types.DELETEKAFKACONNECTIONSBYQUERY_ERROR](state, error) {
        handleErrorResponse(types.DELETEKAFKACONNECTIONSBYQUERY_ERROR, apiResponseFields, state, error);
    },

    //ApiResponse
    [types.DELETERABBITMQCONNECTIONS_SUCCESS](state, response) {
        handleSuccessResponse(types.DELETERABBITMQCONNECTIONS_SUCCESS,apiResponseFields, state, response);
    },

    //ApiResponse
    [types.DELETERABBITMQCONNECTIONS_ERROR](state, error) {
        handleErrorResponse(types.DELETERABBITMQCONNECTIONS_ERROR, apiResponseFields, state, error);
    },

    //ApiResponse
    [types.DELETERABBITMQCONNECTIONSBYQUERY_SUCCESS](state, response) {
        handleSuccessResponse(types.DELETERABBITMQCONNECTIONSBYQUERY_SUCCESS,apiResponseFields, state, response);
    },

    //ApiResponse
    [types.DELETERABBITMQCONNECTIONSBYQUERY_ERROR](state, error) {
        handleErrorResponse(types.DELETERABBITMQCONNECTIONSBYQUERY_ERROR, apiResponseFields, state, error);
    },

    //ApiResponse
    [types.DELETESQSCONNECTIONS_SUCCESS](state, response) {
        handleSuccessResponse(types.DELETESQSCONNECTIONS_SUCCESS,apiResponseFields, state, response);
    },

    //ApiResponse
    [types.DELETESQSCONNECTIONS_ERROR](state, error) {
        handleErrorResponse(types.DELETESQSCONNECTIONS_ERROR, apiResponseFields, state, error);
    },

    //ApiResponse
    [types.DELETESQSCONNECTIONSBYQUERY_SUCCESS](state, response) {
        handleSuccessResponse(types.DELETESQSCONNECTIONSBYQUERY_SUCCESS,apiResponseFields, state, response);
    },

    //ApiResponse
    [types.DELETESQSCONNECTIONSBYQUERY_ERROR](state, error) {
        handleErrorResponse(types.DELETESQSCONNECTIONSBYQUERY_ERROR, apiResponseFields, state, error);
    },

    //KafkaConnectionResponse
    [types.GETKAFKACONNECTIONS_SUCCESS](state, response) {
        handleSuccessResponse(types.GETKAFKACONNECTIONS_SUCCESS,kafkaConnectionResponseFields, state, response);
    },

    //KafkaConnectionResponse
    [types.GETKAFKACONNECTIONS_ERROR](state, error) {
        handleErrorResponse(types.GETKAFKACONNECTIONS_ERROR, kafkaConnectionResponseFields, state, error);
    },

    //KafkaConnectionResponse
    [types.GETKAFKACONNECTIONSBYQUERY_SUCCESS](state, response) {
        handleSuccessResponse(types.GETKAFKACONNECTIONSBYQUERY_SUCCESS,kafkaConnectionResponseFields, state, response);
    },

    //KafkaConnectionResponse
    [types.GETKAFKACONNECTIONSBYQUERY_ERROR](state, error) {
        handleErrorResponse(types.GETKAFKACONNECTIONSBYQUERY_ERROR, kafkaConnectionResponseFields, state, error);
    },

    //RabbitMqConnectionResponse
    [types.GETRABBITMQCONNECTIONS_SUCCESS](state, response) {
        handleSuccessResponse(types.GETRABBITMQCONNECTIONS_SUCCESS,rabbitMqConnectionResponseFields, state, response);
    },

    //RabbitMqConnectionResponse
    [types.GETRABBITMQCONNECTIONS_ERROR](state, error) {
        handleErrorResponse(types.GETRABBITMQCONNECTIONS_ERROR, rabbitMqConnectionResponseFields, state, error);
    },

    //RabbitMqConnectionResponse
    [types.GETRABBITMQCONNECTIONSBYQUERY_SUCCESS](state, response) {
        handleSuccessResponse(types.GETRABBITMQCONNECTIONSBYQUERY_SUCCESS,rabbitMqConnectionResponseFields, state, response);
    },

    //RabbitMqConnectionResponse
    [types.GETRABBITMQCONNECTIONSBYQUERY_ERROR](state, error) {
        handleErrorResponse(types.GETRABBITMQCONNECTIONSBYQUERY_ERROR, rabbitMqConnectionResponseFields, state, error);
    },

    //SqsConnectionResponse
    [types.GETSQSCONNECTIONS_SUCCESS](state, response) {
        handleSuccessResponse(types.GETSQSCONNECTIONS_SUCCESS,sqsConnectionResponseFields, state, response);
    },

    //SqsConnectionResponse
    [types.GETSQSCONNECTIONS_ERROR](state, error) {
        handleErrorResponse(types.GETSQSCONNECTIONS_ERROR, sqsConnectionResponseFields, state, error);
    },

    //SqsConnectionResponse
    [types.GETSQSCONNECTIONSBYQUERY_SUCCESS](state, response) {
        handleSuccessResponse(types.GETSQSCONNECTIONSBYQUERY_SUCCESS,sqsConnectionResponseFields, state, response);
    },

    //SqsConnectionResponse
    [types.GETSQSCONNECTIONSBYQUERY_ERROR](state, error) {
        handleErrorResponse(types.GETSQSCONNECTIONSBYQUERY_ERROR, sqsConnectionResponseFields, state, error);
    },

    //ApiResponse
    [types.UPDATECONNECTIONSSTATE_SUCCESS](state, response) {
        handleSuccessResponse(types.UPDATECONNECTIONSSTATE_SUCCESS,apiResponseFields, state, response);
    },

    //ApiResponse
    [types.UPDATECONNECTIONSSTATE_ERROR](state, error) {
        handleErrorResponse(types.UPDATECONNECTIONSSTATE_ERROR, apiResponseFields, state, error);
    },

    //ApiResponse
    [types.UPDATEKAFKACONNECTIONS_SUCCESS](state, response) {
        handleSuccessResponse(types.UPDATEKAFKACONNECTIONS_SUCCESS,apiResponseFields, state, response);
    },

    //ApiResponse
    [types.UPDATEKAFKACONNECTIONS_ERROR](state, error) {
        handleErrorResponse(types.UPDATEKAFKACONNECTIONS_ERROR, apiResponseFields, state, error);
    },

    //ApiResponse
    [types.UPDATEKAFKACONNECTIONSSTATE_SUCCESS](state, response) {
        handleSuccessResponse(types.UPDATEKAFKACONNECTIONSSTATE_SUCCESS,apiResponseFields, state, response);
    },

    //ApiResponse
    [types.UPDATEKAFKACONNECTIONSSTATE_ERROR](state, error) {
        handleErrorResponse(types.UPDATEKAFKACONNECTIONSSTATE_ERROR, apiResponseFields, state, error);
    },

    //ApiResponse
    [types.UPDATERABBITMQCONNECTIONS_SUCCESS](state, response) {
        handleSuccessResponse(types.UPDATERABBITMQCONNECTIONS_SUCCESS,apiResponseFields, state, response);
    },

    //ApiResponse
    [types.UPDATERABBITMQCONNECTIONS_ERROR](state, error) {
        handleErrorResponse(types.UPDATERABBITMQCONNECTIONS_ERROR, apiResponseFields, state, error);
    },

    //ApiResponse
    [types.UPDATERABBITMQCONNECTIONSSTATE_SUCCESS](state, response) {
        handleSuccessResponse(types.UPDATERABBITMQCONNECTIONSSTATE_SUCCESS,apiResponseFields, state, response);
    },

    //ApiResponse
    [types.UPDATERABBITMQCONNECTIONSSTATE_ERROR](state, error) {
        handleErrorResponse(types.UPDATERABBITMQCONNECTIONSSTATE_ERROR, apiResponseFields, state, error);
    },

    //ApiResponse
    [types.UPDATESQSCONNECTIONS_SUCCESS](state, response) {
        handleSuccessResponse(types.UPDATESQSCONNECTIONS_SUCCESS,apiResponseFields, state, response);
    },

    //ApiResponse
    [types.UPDATESQSCONNECTIONS_ERROR](state, error) {
        handleErrorResponse(types.UPDATESQSCONNECTIONS_ERROR, apiResponseFields, state, error);
    },

    //ApiResponse
    [types.UPDATESQSCONNECTIONSSTATE_SUCCESS](state, response) {
        handleSuccessResponse(types.UPDATESQSCONNECTIONSSTATE_SUCCESS,apiResponseFields, state, response);
    },

    //ApiResponse
    [types.UPDATESQSCONNECTIONSSTATE_ERROR](state, error) {
        handleErrorResponse(types.UPDATESQSCONNECTIONSSTATE_ERROR, apiResponseFields, state, error);
    },
};

export default mutations;
