export const API_REQUEST = 'API_REQUEST';

// GET
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
export const GET_USER_ERROR = 'GET_USER_ERROR';

export const UPDATE_FIELDS = 'UPDATE_FIELDS';
export const CLEAR_FILTER = 'CLEAR_FILTER';

// Clear Message
export const CLEAR_MESSAGE = 'CLEAR_MESSAGE';