import * as types from './mutation-types';

const mutations = {
  [types.SET_CURRENT_PAGE_NUMBER](state, data) {
    state.currentPageNumber = data;
  },
  [types.SET_ITEMS_PER_PAGE](state, data) {
    state.itemsPerPageData = data;
  },
  [types.SET_TABLE_MODEL](state, data) {
    state.tableModel = data;
  },
  [types.SET_CURRENT_STATUS](state, data) {
    state.currentStatus = data;
  },
};

export default mutations;
