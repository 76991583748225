// <<< DO NOT EDIT GENERATED FILES DIRECTLY! model-store-fields-all.mustache >>>



const appliesTo = this;

export default {
  objectType: 'AppliesTo',
  allowableValuesKeys: ['Member','Product','Achievement','Repository','FileObject','Language','MemberMessage','Reward','MemberAward','Award','Message','RewardType','ActionType','UnitOfMeasure','Competition','Contest','SqsConnection','KafkaConnection','RabbitMqConnection','Event','Webhook','Attachment','Rule','APIKey','Connection', 'InstantWin'],
  constraints: ['isString','isEnum',],
  getter: null,
  hiddenFields: [],
  searchableFields: [],
  searchableAllFields: [],
  translatableFields: [],
  sortableFields: [],
  customFieldsFieldName: [],
  metadataFieldName: [],
  tagFieldName: [],
  entityLinksFrom: [],

  route: {


  },
  fieldList: [],
  fields: {
  }
};
