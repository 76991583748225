const Space = () => import('@/views/ziqni/space');

export  default {
    path: 'space',
    component: {
        render(c) {
            return c('router-view');
        },
    },
    children: [
        {
            path: '',
            name: 'Space',
            component: Space,
        },
    ]
}
