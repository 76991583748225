import * as types from './mutation-types';
import { entrantsApiZiqni } from '@/generated/ziqni/api/EntrantsApiZiqni';
import updateFields from '@/utils/updateFields';

export const handleFields = ({ commit, state }, data) => {
  updateFields(commit, state, data, 'hiddenEntrantsKey');
};

// Clear entrants Message
export const handleClearMessage = ({ commit }) => {
  commit(types.CLEAR_MESSAGE);
};

// clear filter
export const handleClearFilter = ({ commit }) => {
  commit(types.CLEAR_FILTER);
};

//ENTRANTS - ENTRANTS
export const handleEntrantsChanges = ({ commit }, data) => {
  commit('SET_ENTRANTS', data);
};

/**
 * EntrantResponse from backend.
 * POST exportEntrantsByQuery and return [EntrantResponse]
 * vars: [
 *    [[queryRequest]] QueryRequest[ opts, isBodyParam, ]]
 */
export const handleExportEntrantsByQuery = ({ commit }, payload) => {
  const { queryRequest } = payload;

  commit(types.API_REQUEST);
  let opts = {
    queryRequest: queryRequest
  };

  return entrantsApiZiqni
    .exportEntrantsByQuery(
      opts
    )
    .then((response) => {
      commit(types.EXPORTENTRANTSBYQUERY_SUCCESS, { response });
      return response;
    })
    .catch((e) => {
      commit(types.EXPORTENTRANTSBYQUERY_ERROR, e);
      return [];
    });
};

/**
 * EntrantResponse from backend.
 * POST getEntrantsByQuery and return [EntrantResponse]
 * vars: [
 *    [[queryRequest]] QueryRequest[ opts, isBodyParam, ]]
 */
export const handleGetEntrantsByQuery = ({ commit }, payload) => {
  const { queryRequest } = payload;

  commit(types.API_REQUEST);
  let opts = {
    queryRequest: queryRequest
  };

  return entrantsApiZiqni
    .getEntrantsByQuery(
      opts
    )
    .then((response) => {
      commit(types.GETENTRANTSBYQUERY_SUCCESS, { response });
      return response.results;
    })
    .catch((e) => {
      commit(types.GETENTRANTSBYQUERY_ERROR, e);
      return [];
    });
};

export const handleExportEntrantsMetaByQuery = ({ commit }, payload) => {
  const { queryRequest } = payload;

  commit(types.API_REQUEST);
  let opts = {
    queryRequest: queryRequest
  };

  return entrantsApiZiqni
    .exportEntrantsByQuery(
      opts
    )
    .then((response) => {
      return response.meta;
    })
    .catch((e) => {
      commit(types.EXPORTENTRANTSBYQUERY_ERROR, e);
      return [];
    });
};