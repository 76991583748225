// Genearetd using ziqni-admin-api.mustache
import {ApiClient} from '@ziqni-tech/admin-api-client';
import { MembersApi } from "@ziqni-tech/admin-api-client";

const defaultClient = ApiClient.instance;
defaultClient.basePath = sdkApiConfig.baseApiUrlPath;

import { sdkApiConfig } from '@/config';
import { queryConverter } from '@/utils/query.convert';
import { errorHandler } from '@/utils/errorHandler';
class MembersApiZiqni {

  constructor() {
    this.defaultOptions = {};
    this.ziqniMembersApi = new MembersApi();
    this.OAuth2 = this.ziqniMembersApi.apiClient.authentications['OAuth2'];
    this.OAuth2.accessToken = localStorage.getItem('vue-token');
  }

  /**
   * createMembers: Create members. Notes: Create new Members in the Ziqni database
   *
   * BaseName: Members
   * Path: /members
   * Http Method: POST, Consumes: [{mediaType&#x3D;application/json}, {mediaType&#x3D;file/csv}], Produces: [{mediaType&#x3D;application/json}]
   * ReturnType: ApiResponse, ReturnBaseType: ApiResponse, ReturnContainer: 
   *  RequiredParams:
   *   - body [body]   >>> Create Members in the Ziqni database. An array can contain a maximum of 10,000 members in one request
   *      |  dataType: [CreateMemberRequest], baseType: CreateMemberRequest, 
   *      |  isBodyParam: true, 
   *      | 
   * 
   *
   * Constraints:
   * hasAuthMethods: true, hasConsumes: true, hasProduces: true, hasParams: true, hasOptionalParams: false,
   * hasRequiredParams: true, returnTypeIsPrimitive: false, returnSimpleType: true, subresourceOperation: false,
   * isMap: false, isArray: false, isMultipart: false, isResponseBinary: false, isResponseFile: false, hasReference: true,
   * hasDefaultResponse: false, hasErrorResponseObject: true, isRestfulIndex: false, isRestfulShow: false,
   * isRestfulCreate: true, isRestfulUpdate: false, isRestfulDestroy: false, isRestful: true, isDeprecated: false,
   * isCallbackRequest: false, uniqueItems: false  hasRequiredParams
   */
  createMembers(body){
    this.OAuth2.accessToken = localStorage.getItem('vue-token');
    return new Promise((resolve, reject) => {
      const callback = (error, data, responseString) => {
        const errorsFromHandler = errorHandler(error, data, responseString);
        if (error) {
          reject(error.message);
        } else if (errorsFromHandler.length) {
          const errors = errorsFromHandler.join(', ');
          reject(errors);
        } else {
          resolve(data);
        }
      };
      this.ziqniMembersApi.createMembers(body, callback);
    });
  }

  /**
   * deleteMembers: Delete members. Notes: Delete Members for a given identifier specified
   *
   * BaseName: Members
   * Path: /members
   * Http Method: DELETE, Consumes: , Produces: [{mediaType&#x3D;application/json}]
   * ReturnType: ApiResponse, ReturnBaseType: ApiResponse, ReturnContainer: 
   *  
   * OptionalParams:
   *   ops { 
   *   - id [id]   >>> The unique identifiers of the resources
   *      |  dataType: [String], baseType: String, 
   *      |  isQueryParam: true, isCollectionFormatMulti: true, isExplode: true, collectionFormat: multi, 
   *      | 
   *   }
   *
   * Constraints:
   * hasAuthMethods: true, hasConsumes: false, hasProduces: true, hasParams: true, hasOptionalParams: true,
   * hasRequiredParams: false, returnTypeIsPrimitive: false, returnSimpleType: true, subresourceOperation: false,
   * isMap: false, isArray: false, isMultipart: false, isResponseBinary: false, isResponseFile: false, hasReference: true,
   * hasDefaultResponse: false, hasErrorResponseObject: true, isRestfulIndex: false, isRestfulShow: false,
   * isRestfulCreate: false, isRestfulUpdate: false, isRestfulDestroy: false, isRestful: false, isDeprecated: false,
   * isCallbackRequest: false, uniqueItems: false hasOptionalParams 
   */
  deleteMembers(opts){
    this.OAuth2.accessToken = localStorage.getItem('vue-token');
    return new Promise((resolve, reject) => {
      const callback = (error, data, responseString) => {
        const errorsFromHandler = errorHandler(error, data, responseString);
        if (error) {
          reject(error.message);
        } else if (errorsFromHandler.length) {
          const errors = errorsFromHandler.join(', ');
          reject(errors);
        } else {
          resolve(data);
        }
      };
      this.ziqniMembersApi.deleteMembers(opts, callback);
    });
  }

  /**
   * deleteMembersByQuery: Delete members by query. Notes: Delete Members from Ziqni database by unique Members ID&#39;s or any other POST body parameters using the POST method
   *
   * BaseName: Members
   * Path: /members/delete
   * Http Method: POST, Consumes: [{mediaType&#x3D;application/json}], Produces: [{mediaType&#x3D;application/json}]
   * ReturnType: ApiResponse, ReturnBaseType: ApiResponse, ReturnContainer: 
   *  
   * OptionalParams:
   *   ops { 
   *   - body [body]  isModel: true >>> Delete Members from Ziqni database by unique Members ID&#39;s or any other POST body parameters using the POST method
   *      |  dataType: QueryRequest, baseType: QueryRequest, 
   *      |  isBodyParam: true, 
   *      | 
   *   }
   *
   * Constraints:
   * hasAuthMethods: true, hasConsumes: true, hasProduces: true, hasParams: true, hasOptionalParams: true,
   * hasRequiredParams: false, returnTypeIsPrimitive: false, returnSimpleType: true, subresourceOperation: false,
   * isMap: false, isArray: false, isMultipart: false, isResponseBinary: false, isResponseFile: false, hasReference: true,
   * hasDefaultResponse: false, hasErrorResponseObject: true, isRestfulIndex: false, isRestfulShow: false,
   * isRestfulCreate: false, isRestfulUpdate: false, isRestfulDestroy: false, isRestful: false, isDeprecated: false,
   * isCallbackRequest: false, uniqueItems: false hasOptionalParams 
   */
  deleteMembersByQuery(opts){
    this.OAuth2.accessToken = localStorage.getItem('vue-token');
    return new Promise((resolve, reject) => {
      const callback = (error, data, responseString) => {
        const errorsFromHandler = errorHandler(error, data, responseString);
        if (error) {
          reject(error.message);
        } else if (errorsFromHandler.length) {
          const errors = errorsFromHandler.join(', ');
          reject(errors);
        } else {
          resolve(data);
        }
      };
      this.ziqniMembersApi.deleteMembersByQuery(opts, callback);
    });
  }

  /**
   * getAchievementsForMembers: NOT AVAILABLE IN CURRENT RELEASE. Notes: Returns a list of Achievements for the Member id provided
   *
   * BaseName: Members
   * Path: /members/{id}/achievements
   * Http Method: GET, Consumes: , Produces: [{mediaType&#x3D;application/json}]
   * ReturnType: AchievementReducedResponse, ReturnBaseType: AchievementReducedResponse, ReturnContainer: 
   *  RequiredParams:
   *   - id [id] isString isPrimitiveType: true >>> Unique identifier of the resource
   *      |  dataType: String, 
   *      |  isPathParam: true, 
   *      | 
   * OptionalParams:
   *   ops { 
   *   - achievementId [achievementId] isString isPrimitiveType: true >>> Id of an Achievement
   *      |  dataType: String, 
   *      |  isQueryParam: true, 
   *      | 
   *  
   *   - limit [_limit] isIntegerisUnboundedInteger isPrimitiveType: true >>> Limit the returned total records found
   *      |  dataType: Number, 
   *      |  isQueryParam: true, isExplode: true, 
   *      | 
   *  
   *   - skip [_skip] isIntegerisUnboundedInteger isPrimitiveType: true >>> Skip the returned records found and return the next batch of records
   *      |  dataType: Number, 
   *      |  isQueryParam: true, isExplode: true, 
   *      | 
   *   }
   *
   * Constraints:
   * hasAuthMethods: true, hasConsumes: false, hasProduces: true, hasParams: true, hasOptionalParams: true,
   * hasRequiredParams: true, returnTypeIsPrimitive: false, returnSimpleType: true, subresourceOperation: false,
   * isMap: false, isArray: false, isMultipart: false, isResponseBinary: false, isResponseFile: false, hasReference: true,
   * hasDefaultResponse: false, hasErrorResponseObject: true, isRestfulIndex: false, isRestfulShow: false,
   * isRestfulCreate: false, isRestfulUpdate: false, isRestfulDestroy: false, isRestful: false, isDeprecated: false,
   * isCallbackRequest: false, uniqueItems: false hasOptionalParams hasRequiredParams
   */
  getAchievementsForMembers(id,opts){
    this.OAuth2.accessToken = localStorage.getItem('vue-token');
    return new Promise((resolve, reject) => {
      const callback = (error, data, responseString) => {
        const errorsFromHandler = errorHandler(error, data, responseString);
        if (error) {
          reject(error.message);
        } else if (errorsFromHandler.length) {
          const errors = errorsFromHandler.join(', ');
          reject(errors);
        } else {
          resolve(data);
        }
      };
      this.ziqniMembersApi.getAchievementsForMembers(id, opts, callback);
    });
  }

  /**
   * getAwardsForMembers: NOT AVAILABLE IN CURRENT RELEASE. Notes: Returns a list of Awards for the Member id provided
   *
   * BaseName: Members
   * Path: /members/{id}/awards
   * Http Method: GET, Consumes: , Produces: [{mediaType&#x3D;application/json}]
   * ReturnType: MemberAwardResponse, ReturnBaseType: MemberAwardResponse, ReturnContainer: 
   *  RequiredParams:
   *   - id [id] isString isPrimitiveType: true >>> Unique identifier of the resource
   *      |  dataType: String, 
   *      |  isPathParam: true, 
   *      | 
   * OptionalParams:
   *   ops { 
   *   - awardId [awardId] isString isPrimitiveType: true >>> Id of an Award
   *      |  dataType: String, 
   *      |  isQueryParam: true, 
   *      | 
   *  
   *   - limit [_limit] isIntegerisUnboundedInteger isPrimitiveType: true >>> Limit the returned total records found
   *      |  dataType: Number, 
   *      |  isQueryParam: true, isExplode: true, 
   *      | 
   *  
   *   - skip [_skip] isIntegerisUnboundedInteger isPrimitiveType: true >>> Skip the returned records found and return the next batch of records
   *      |  dataType: Number, 
   *      |  isQueryParam: true, isExplode: true, 
   *      | 
   *   }
   *
   * Constraints:
   * hasAuthMethods: true, hasConsumes: false, hasProduces: true, hasParams: true, hasOptionalParams: true,
   * hasRequiredParams: true, returnTypeIsPrimitive: false, returnSimpleType: true, subresourceOperation: false,
   * isMap: false, isArray: false, isMultipart: false, isResponseBinary: false, isResponseFile: false, hasReference: true,
   * hasDefaultResponse: false, hasErrorResponseObject: true, isRestfulIndex: false, isRestfulShow: false,
   * isRestfulCreate: false, isRestfulUpdate: false, isRestfulDestroy: false, isRestful: false, isDeprecated: false,
   * isCallbackRequest: false, uniqueItems: false hasOptionalParams hasRequiredParams
   */
  getAwardsForMembers(id,opts){
    this.OAuth2.accessToken = localStorage.getItem('vue-token');
    return new Promise((resolve, reject) => {
      const callback = (error, data, responseString) => {
        const errorsFromHandler = errorHandler(error, data, responseString);
        if (error) {
          reject(error.message);
        } else if (errorsFromHandler.length) {
          const errors = errorsFromHandler.join(', ');
          reject(errors);
        } else {
          resolve(data);
        }
      };
      this.ziqniMembersApi.getAwardsForMembers(id, opts, callback);
    });
  }

  /**
   * getCompetitionsForMembers: NOT AVAILABLE IN CURRENT RELEASE. Notes: Returns a list Competitions for the Member id provided
   *
   * BaseName: Members
   * Path: /members/{id}/competitions
   * Http Method: GET, Consumes: , Produces: [{mediaType&#x3D;application/json}]
   * ReturnType: CompetitionReducedResponse, ReturnBaseType: CompetitionReducedResponse, ReturnContainer: 
   *  RequiredParams:
   *   - id [id] isString isPrimitiveType: true >>> Unique identifier of the resource
   *      |  dataType: String, 
   *      |  isPathParam: true, 
   *      | 
   * OptionalParams:
   *   ops { 
   *   - competitionId [competitionId] isString isPrimitiveType: true >>> Id of a Competition
   *      |  dataType: String, 
   *      |  isQueryParam: true, 
   *      | 
   *  
   *   - limit [_limit] isIntegerisUnboundedInteger isPrimitiveType: true >>> Limit the returned total records found
   *      |  dataType: Number, 
   *      |  isQueryParam: true, isExplode: true, 
   *      | 
   *  
   *   - skip [_skip] isIntegerisUnboundedInteger isPrimitiveType: true >>> Skip the returned records found and return the next batch of records
   *      |  dataType: Number, 
   *      |  isQueryParam: true, isExplode: true, 
   *      | 
   *   }
   *
   * Constraints:
   * hasAuthMethods: true, hasConsumes: false, hasProduces: true, hasParams: true, hasOptionalParams: true,
   * hasRequiredParams: true, returnTypeIsPrimitive: false, returnSimpleType: true, subresourceOperation: false,
   * isMap: false, isArray: false, isMultipart: false, isResponseBinary: false, isResponseFile: false, hasReference: true,
   * hasDefaultResponse: false, hasErrorResponseObject: true, isRestfulIndex: false, isRestfulShow: false,
   * isRestfulCreate: false, isRestfulUpdate: false, isRestfulDestroy: false, isRestful: false, isDeprecated: false,
   * isCallbackRequest: false, uniqueItems: false hasOptionalParams hasRequiredParams
   */
  getCompetitionsForMembers(id,opts){
    this.OAuth2.accessToken = localStorage.getItem('vue-token');
    return new Promise((resolve, reject) => {
      const callback = (error, data, responseString) => {
        const errorsFromHandler = errorHandler(error, data, responseString);
        if (error) {
          reject(error.message);
        } else if (errorsFromHandler.length) {
          const errors = errorsFromHandler.join(', ');
          reject(errors);
        } else {
          resolve(data);
        }
      };
      this.ziqniMembersApi.getCompetitionsForMembers(id, opts, callback);
    });
  }

  /**
   * getContestsForMembers: NOT AVAILABLE IN CURRENT RELEASE. Notes: Returns a list of Contests for the Member id provided
   *
   * BaseName: Members
   * Path: /members/{id}/contests
   * Http Method: GET, Consumes: , Produces: [{mediaType&#x3D;application/json}]
   * ReturnType: ContestReducedResponse, ReturnBaseType: ContestReducedResponse, ReturnContainer: 
   *  RequiredParams:
   *   - id [id] isString isPrimitiveType: true >>> Unique identifier of the resource
   *      |  dataType: String, 
   *      |  isPathParam: true, 
   *      | 
   * OptionalParams:
   *   ops { 
   *   - contestId [contestId] isString isPrimitiveType: true >>> Id of a Contest
   *      |  dataType: String, 
   *      |  isQueryParam: true, 
   *      | 
   *  
   *   - limit [_limit] isIntegerisUnboundedInteger isPrimitiveType: true >>> Limit the returned total records found
   *      |  dataType: Number, 
   *      |  isQueryParam: true, isExplode: true, 
   *      | 
   *  
   *   - skip [_skip] isIntegerisUnboundedInteger isPrimitiveType: true >>> Skip the returned records found and return the next batch of records
   *      |  dataType: Number, 
   *      |  isQueryParam: true, isExplode: true, 
   *      | 
   *   }
   *
   * Constraints:
   * hasAuthMethods: true, hasConsumes: false, hasProduces: true, hasParams: true, hasOptionalParams: true,
   * hasRequiredParams: true, returnTypeIsPrimitive: false, returnSimpleType: true, subresourceOperation: false,
   * isMap: false, isArray: false, isMultipart: false, isResponseBinary: false, isResponseFile: false, hasReference: true,
   * hasDefaultResponse: false, hasErrorResponseObject: true, isRestfulIndex: false, isRestfulShow: false,
   * isRestfulCreate: false, isRestfulUpdate: false, isRestfulDestroy: false, isRestful: false, isDeprecated: false,
   * isCallbackRequest: false, uniqueItems: false hasOptionalParams hasRequiredParams
   */
  getContestsForMembers(id,opts){
    this.OAuth2.accessToken = localStorage.getItem('vue-token');
    return new Promise((resolve, reject) => {
      const callback = (error, data, responseString) => {
        const errorsFromHandler = errorHandler(error, data, responseString);
        if (error) {
          reject(error.message);
        } else if (errorsFromHandler.length) {
          const errors = errorsFromHandler.join(', ');
          reject(errors);
        } else {
          resolve(data);
        }
      };
      this.ziqniMembersApi.getContestsForMembers(id, opts, callback);
    });
  }

  /**
   * getMembers: Get members. Notes: Returns a list of Members. This assumes that members have first been uploaded via a POST request or web console
   *
   * BaseName: Members
   * Path: /members
   * Http Method: GET, Consumes: , Produces: [{mediaType&#x3D;application/json}]
   * ReturnType: MemberResponse, ReturnBaseType: MemberResponse, ReturnContainer: 
   *  
   * OptionalParams:
   *   ops { 
   *   - id [id]   >>> The unique identifiers of the resources
   *      |  dataType: [String], baseType: String, 
   *      |  isQueryParam: true, isCollectionFormatMulti: true, isExplode: true, collectionFormat: multi, 
   *      | 
   *  
   *   - limit [_limit] isIntegerisUnboundedInteger isPrimitiveType: true >>> Limit the returned total records found
   *      |  dataType: Number, 
   *      |  isQueryParam: true, isExplode: true, 
   *      | 
   *  
   *   - skip [_skip] isIntegerisUnboundedInteger isPrimitiveType: true >>> Skip the returned records found and return the next batch of records
   *      |  dataType: Number, 
   *      |  isQueryParam: true, isExplode: true, 
   *      | 
   *   }
   *
   * Constraints:
   * hasAuthMethods: true, hasConsumes: false, hasProduces: true, hasParams: true, hasOptionalParams: true,
   * hasRequiredParams: false, returnTypeIsPrimitive: false, returnSimpleType: true, subresourceOperation: false,
   * isMap: false, isArray: false, isMultipart: false, isResponseBinary: false, isResponseFile: false, hasReference: true,
   * hasDefaultResponse: false, hasErrorResponseObject: true, isRestfulIndex: true, isRestfulShow: false,
   * isRestfulCreate: false, isRestfulUpdate: false, isRestfulDestroy: false, isRestful: true, isDeprecated: false,
   * isCallbackRequest: false, uniqueItems: false hasOptionalParams 
   */
  getMembers(opts){
    this.OAuth2.accessToken = localStorage.getItem('vue-token');
    return new Promise((resolve, reject) => {
      const callback = (error, data, responseString) => {
        const errorsFromHandler = errorHandler(error, data, responseString);
        if (error) {
          reject(error.message);
        } else if (errorsFromHandler.length) {
          const errors = errorsFromHandler.join(', ');
          reject(errors);
        } else {
          resolve(data);
        }
      };
      this.ziqniMembersApi.getMembers(opts, callback);
    });
  }

  /**
   * getMembersByQuery: Get members by query. Notes: Retrieve Members from Ziqni database by unique Member ID&#39;s or any other POST body parameters using the POST method
   *
   * BaseName: Members
   * Path: /members/query
   * Http Method: POST, Consumes: [{mediaType&#x3D;application/json}], Produces: [{mediaType&#x3D;application/json}]
   * ReturnType: MemberResponse, ReturnBaseType: MemberResponse, ReturnContainer: 
   *  
   * OptionalParams:
   *   ops { 
   *   - body [body]  isModel: true >>> Retrieve Products from Ziqni database by unique Product ID&#39;s or any other POST body parameters using the POST method
   *      |  dataType: QueryRequest, baseType: QueryRequest, 
   *      |  isBodyParam: true, 
   *      | 
   *   }
   *
   * Constraints:
   * hasAuthMethods: true, hasConsumes: true, hasProduces: true, hasParams: true, hasOptionalParams: true,
   * hasRequiredParams: false, returnTypeIsPrimitive: false, returnSimpleType: true, subresourceOperation: false,
   * isMap: false, isArray: false, isMultipart: false, isResponseBinary: false, isResponseFile: false, hasReference: true,
   * hasDefaultResponse: false, hasErrorResponseObject: true, isRestfulIndex: false, isRestfulShow: false,
   * isRestfulCreate: false, isRestfulUpdate: false, isRestfulDestroy: false, isRestful: false, isDeprecated: false,
   * isCallbackRequest: false, uniqueItems: false hasOptionalParams 
   */
  getMembersByQuery(opts){
    this.OAuth2.accessToken = localStorage.getItem('vue-token');
    return new Promise((resolve, reject) => {
      const callback = (error, data, responseString) => {
        const errorsFromHandler = errorHandler(error, data, responseString);
        if (error) {
          reject(error.message);
        } else if (errorsFromHandler.length) {
          const errors = errorsFromHandler.join(', ');
          reject(errors);
        } else {
          resolve(data);
        }
      };
      this.ziqniMembersApi.getMembersByQuery(opts, callback);
    });
  }

  /**
   * getMembersByRefId: Get members by reference ids. Notes: Returns a list of Members. This assumes that members have first been uploaded via a POST request or web console
   *
   * BaseName: Members
   * Path: /members/find-by-ref-identifiers
   * Http Method: GET, Consumes: , Produces: [{mediaType&#x3D;application/json}]
   * ReturnType: MemberResponse, ReturnBaseType: MemberResponse, ReturnContainer: 
   *  
   * OptionalParams:
   *   ops { 
   *   - refIds [refIds]   >>> The reference ids  of the resources
   *      |  dataType: [String], baseType: String, 
   *      |  isQueryParam: true, isCollectionFormatMulti: true, isExplode: true, collectionFormat: multi, 
   *      | 
   *  
   *   - limit [_limit] isIntegerisUnboundedInteger isPrimitiveType: true >>> Limit the returned total records found
   *      |  dataType: Number, 
   *      |  isQueryParam: true, isExplode: true, 
   *      | 
   *  
   *   - skip [_skip] isIntegerisUnboundedInteger isPrimitiveType: true >>> Skip the returned records found and return the next batch of records
   *      |  dataType: Number, 
   *      |  isQueryParam: true, isExplode: true, 
   *      | 
   *   }
   *
   * Constraints:
   * hasAuthMethods: true, hasConsumes: false, hasProduces: true, hasParams: true, hasOptionalParams: true,
   * hasRequiredParams: false, returnTypeIsPrimitive: false, returnSimpleType: true, subresourceOperation: false,
   * isMap: false, isArray: false, isMultipart: false, isResponseBinary: false, isResponseFile: false, hasReference: true,
   * hasDefaultResponse: false, hasErrorResponseObject: true, isRestfulIndex: false, isRestfulShow: false,
   * isRestfulCreate: false, isRestfulUpdate: false, isRestfulDestroy: false, isRestful: false, isDeprecated: false,
   * isCallbackRequest: false, uniqueItems: false hasOptionalParams 
   */
  getMembersByRefId(opts){
    this.OAuth2.accessToken = localStorage.getItem('vue-token');
    return new Promise((resolve, reject) => {
      const callback = (error, data, responseString) => {
        const errorsFromHandler = errorHandler(error, data, responseString);
        if (error) {
          reject(error.message);
        } else if (errorsFromHandler.length) {
          const errors = errorsFromHandler.join(', ');
          reject(errors);
        } else {
          resolve(data);
        }
      };
      this.ziqniMembersApi.getMembersByRefId(opts, callback);
    });
  }

  /**
   * getMessagesForMembers: NOT AVAILABLE IN CURRENT RELEASE. Notes: Returns a list of Messages for the Member id provided
   *
   * BaseName: Members
   * Path: /members/{id}/inbox
   * Http Method: GET, Consumes: , Produces: [{mediaType&#x3D;application/json}]
   * ReturnType: MemberMessageResponse, ReturnBaseType: MemberMessageResponse, ReturnContainer: 
   *  RequiredParams:
   *   - id [id] isString isPrimitiveType: true >>> Unique identifier of the resource
   *      |  dataType: String, 
   *      |  isPathParam: true, 
   *      | 
   * OptionalParams:
   *   ops { 
   *   - messageId [messageId] isString isPrimitiveType: true >>> Id of a Message
   *      |  dataType: String, 
   *      |  isQueryParam: true, 
   *      | 
   *  
   *   - limit [_limit] isIntegerisUnboundedInteger isPrimitiveType: true >>> Limit the returned total records found
   *      |  dataType: Number, 
   *      |  isQueryParam: true, isExplode: true, 
   *      | 
   *  
   *   - skip [_skip] isIntegerisUnboundedInteger isPrimitiveType: true >>> Skip the returned records found and return the next batch of records
   *      |  dataType: Number, 
   *      |  isQueryParam: true, isExplode: true, 
   *      | 
   *   }
   *
   * Constraints:
   * hasAuthMethods: true, hasConsumes: false, hasProduces: true, hasParams: true, hasOptionalParams: true,
   * hasRequiredParams: true, returnTypeIsPrimitive: false, returnSimpleType: true, subresourceOperation: false,
   * isMap: false, isArray: false, isMultipart: false, isResponseBinary: false, isResponseFile: false, hasReference: true,
   * hasDefaultResponse: false, hasErrorResponseObject: true, isRestfulIndex: false, isRestfulShow: false,
   * isRestfulCreate: false, isRestfulUpdate: false, isRestfulDestroy: false, isRestful: false, isDeprecated: false,
   * isCallbackRequest: false, uniqueItems: false hasOptionalParams hasRequiredParams
   */
  getMessagesForMembers(id,opts){
    this.OAuth2.accessToken = localStorage.getItem('vue-token');
    return new Promise((resolve, reject) => {
      const callback = (error, data, responseString) => {
        const errorsFromHandler = errorHandler(error, data, responseString);
        if (error) {
          reject(error.message);
        } else if (errorsFromHandler.length) {
          const errors = errorsFromHandler.join(', ');
          reject(errors);
        } else {
          resolve(data);
        }
      };
      this.ziqniMembersApi.getMessagesForMembers(id, opts, callback);
    });
  }

  /**
   * updateMembers: Update members. Notes: Update existing Members in the Ziqni database
   *
   * BaseName: Members
   * Path: /members
   * Http Method: PUT, Consumes: [{mediaType&#x3D;application/json}, {mediaType&#x3D;file/csv}], Produces: [{mediaType&#x3D;application/json}]
   * ReturnType: ApiResponse, ReturnBaseType: ApiResponse, ReturnContainer: 
   *  RequiredParams:
   *   - body [body]   >>> Update member details in the Ziqni database for a given Member identifier. * Member Ref Id can not be changed after creation * A member Id must exist in the Ziqni database to update the member
   *      |  dataType: [UpdateMemberRequest], baseType: UpdateMemberRequest, 
   *      |  isBodyParam: true, 
   *      | 
   * 
   *
   * Constraints:
   * hasAuthMethods: true, hasConsumes: true, hasProduces: true, hasParams: true, hasOptionalParams: false,
   * hasRequiredParams: true, returnTypeIsPrimitive: false, returnSimpleType: true, subresourceOperation: false,
   * isMap: false, isArray: false, isMultipart: false, isResponseBinary: false, isResponseFile: false, hasReference: true,
   * hasDefaultResponse: false, hasErrorResponseObject: true, isRestfulIndex: false, isRestfulShow: false,
   * isRestfulCreate: false, isRestfulUpdate: false, isRestfulDestroy: false, isRestful: false, isDeprecated: false,
   * isCallbackRequest: false, uniqueItems: false  hasRequiredParams
   */
  updateMembers(body){
    this.OAuth2.accessToken = localStorage.getItem('vue-token');
    return new Promise((resolve, reject) => {
      const callback = (error, data, responseString) => {
        const errorsFromHandler = errorHandler(error, data, responseString);
        if (error) {
          reject(error.message);
        } else if (errorsFromHandler.length) {
          const errors = errorsFromHandler.join(', ');
          reject(errors);
        } else {
          resolve(data);
        }
      };
      this.ziqniMembersApi.updateMembers(body, callback);
    });
  }

}

export const membersApiZiqni = new MembersApiZiqni();
export default MembersApiZiqni;
