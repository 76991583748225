const Members = () => import('@/views/ziqni/members');
const CreateMember = () => import('@/generated/ziqni/views/members/CreateMember');
const EditMember = () => import('@/generated/ziqni/views/members/UpdateMember');
const PreviewMember = () => import('@/views/ziqni/members/PreviewMember');

export default {
  path: 'members',
  component: {
    render(c) {
      return c('router-view');
    },
  },
  children: [
    {
      path: '/',
      name: 'Members',
      component: Members,

      meta: {
        label: 'Members',
      },
    },
    {
      path: 'edit-member/:id',
      name: 'EditMember',
      component: EditMember,
      meta: {
        label: 'Edit Member',
      },
      props: true,
    },
    {
      path: 'detail/:id',
      name: 'PreviewMember',
      component: PreviewMember,
      props: true,
    },
    {
      path: 'create-member',
      name: 'CreateMember',
      component: CreateMember,
      meta: {
        label: 'Create Member',
      },
    },
  ],
}