const Rewards = () => import('@/views/ziqni/rewards');
const CreateReward = () => import('@/views/ziqni/rewards/CreateReward');
const EditReward = () => import('@/views/ziqni/rewards/UpdateReward');
const PreviewReward = () => import('@/views/ziqni/rewards/PreviewReward');

export default {
  path: 'rewards',
  component: {
    render(c) {
      return c('router-view');
    },
  },
  children: [
    {
      path: '',
      name: 'Rewards',
      component: Rewards,
    },
    {
      path: 'detail/:id',
      name: 'PreviewReward',
      component: PreviewReward,
    },
    {
      path: 'create-reward',
      name: 'CreateReward',
      component: CreateReward,
    },
    {
      path: 'edit-reward/:id',
      name: 'EditReward',
      component: EditReward,
    },
  ]
}