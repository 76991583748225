// <<< DO NOT EDIT GENERATED FILES DIRECTLY! api-store-mutations.mustache >>>
import * as types from './mutation-types';
import * as _ from 'lodash';
import {
  handleErrorResponse, handleItemErrorResponse,
  handleItemSuccessResponse,
  handleSuccessResponse
} from "../../../../../utils/ZiqniAdminApiHandler";
import achievementIssuedResponseFields from '../../../fields/AchievementIssuedResponseZq.js';
import achievementLiveStatusRequestFields from '../../../fields/AchievementLiveStatusRequestZq.js';
import achievementResponseFields from '../../../fields/AchievementResponseZq.js';
import apiResponseFields from '../../../fields/ApiResponseZq.js';
import cloneAchievementResponseFields from '../../../fields/CloneAchievementResponseZq.js';
import createAchievementRequestFields from '../../../fields/CreateAchievementRequestZq.js';
import memberAchievementIssuedResponseFields from '../../../fields/MemberAchievementIssuedResponseZq.js';
import queryRequestFields from '../../../fields/QueryRequestZq.js';
import updateAchievementRequestFields from '../../../fields/UpdateAchievementRequestZq.js';

const mutations = {
    [types.API_REQUEST](state) {
        state.loading = true;
        state.loaded = false;
    },
    [types.CLEAR_MESSAGE](state){
        state.message = null;
    },

    [types.UPDATE_FIELDS](state, data) {
        state.hiddenFields = data._hiddenFields;
        state.fields = _.difference(state.originalFields, state.hiddenFields);
        localStorage.setItem('hiddenAchievementsKey', JSON.stringify(state.hiddenFields));
    },

    [types.CLEAR_FILTER](state) {
        state.fields = _.difference(
            state.originalFields,
            state.originHiddenFields
        );
        state.hiddenFields = state.originHiddenFields;
        localStorage.removeItem('hiddenAchievementsKey');
    },

    //AchievementResponse
    [types.GETACHIEVEMENTS_ITEM_SUCCESS](state, response) {
      handleItemSuccessResponse(types.GETACHIEVEMENTS_ITEM_SUCCESS,achievementResponseFields, state, response, 'achievement');
    },

    //AchievementResponse
    [types.GETACHIEVEMENTS_ERROR](state, error) {
      handleItemErrorResponse(types.GETACHIEVEMENTS_ITEM_ITEM_ERROR, achievementResponseFields, state, error);
    },


    //ApiResponse
    [types.CREATEACHIEVEMENTS_SUCCESS](state, response) {
      handleSuccessResponse(types.CREATEACHIEVEMENTS_SUCCESS,apiResponseFields, state, response);
    },

    //ApiResponse
    [types.CREATEACHIEVEMENTS_ERROR](state, error) {
      handleErrorResponse(types.CREATEACHIEVEMENTS_ERROR, apiResponseFields, state, error);
    },

    //ApiResponse
    [types.DELETEACHIEVEMENTS_SUCCESS](state, response) {
      handleSuccessResponse(types.DELETEACHIEVEMENTS_SUCCESS,apiResponseFields, state, response);
    },

    //ApiResponse
    [types.DELETEACHIEVEMENTS_ERROR](state, error) {
      handleErrorResponse(types.DELETEACHIEVEMENTS_ERROR, apiResponseFields, state, error);
    },

    //ApiResponse
    [types.DELETEACHIEVEMENTSBYQUERY_SUCCESS](state, response) {
      handleSuccessResponse(types.DELETEACHIEVEMENTSBYQUERY_SUCCESS,apiResponseFields, state, response);
    },

    //ApiResponse
    [types.DELETEACHIEVEMENTSBYQUERY_ERROR](state, error) {
      handleErrorResponse(types.DELETEACHIEVEMENTSBYQUERY_ERROR, apiResponseFields, state, error);
    },

    //CloneAchievementResponse
    [types.GETACHIEVEMENTTOCLONE_SUCCESS](state, response) {
      handleSuccessResponse(types.GETACHIEVEMENTTOCLONE_SUCCESS,cloneAchievementResponseFields, state, response);
    },

    //CloneAchievementResponse
    [types.GETACHIEVEMENTTOCLONE_ERROR](state, error) {
      handleErrorResponse(types.GETACHIEVEMENTTOCLONE_ERROR, cloneAchievementResponseFields, state, error);
    },

    //AchievementResponse
    [types.GETACHIEVEMENTS_SUCCESS](state, response) {
      handleSuccessResponse(types.GETACHIEVEMENTS_SUCCESS,achievementResponseFields, state, response);
    },

    //AchievementResponse
    [types.GETACHIEVEMENTS_ERROR](state, error) {
      handleErrorResponse(types.GETACHIEVEMENTS_ERROR, achievementResponseFields, state, error);
    },

    //AchievementResponse
    [types.GETACHIEVEMENTSBYQUERY_SUCCESS](state, response) {
      handleSuccessResponse(types.GETACHIEVEMENTSBYQUERY_SUCCESS,achievementResponseFields, state, response);
    },

    //AchievementResponse
    [types.GETACHIEVEMENTSBYQUERY_ERROR](state, error) {
      handleErrorResponse(types.GETACHIEVEMENTSBYQUERY_ERROR, achievementResponseFields, state, error);
    },

    //AchievementResponse
    [types.GETANTECENDENTSFORACHIEVEMENT_SUCCESS](state, response) {
      handleSuccessResponse(types.GETANTECENDENTSFORACHIEVEMENT_SUCCESS,achievementResponseFields, state, response);
    },

    //AchievementResponse
    [types.GETANTECENDENTSFORACHIEVEMENT_ERROR](state, error) {
      handleErrorResponse(types.GETANTECENDENTSFORACHIEVEMENT_ERROR, achievementResponseFields, state, error);
    },

    //AchievementIssuedResponse
    [types.GETISSUEDACHIEVEMENTSCOUNTBYID_SUCCESS](state, response) {
      handleSuccessResponse(types.GETISSUEDACHIEVEMENTSCOUNTBYID_SUCCESS,achievementIssuedResponseFields, state, response);
    },

    //AchievementIssuedResponse
    [types.GETISSUEDACHIEVEMENTSCOUNTBYID_ERROR](state, error) {
      handleErrorResponse(types.GETISSUEDACHIEVEMENTSCOUNTBYID_ERROR, achievementIssuedResponseFields, state, error);
    },

    //MemberAchievementIssuedResponse
    [types.GETMEMBERSACHIEVEMENTSDETAILS_SUCCESS](state, response) {
      handleSuccessResponse(types.GETMEMBERSACHIEVEMENTSDETAILS_SUCCESS,memberAchievementIssuedResponseFields, state, response);
    },

    //MemberAchievementIssuedResponse
    [types.GETMEMBERSACHIEVEMENTSDETAILS_ERROR](state, error) {
      handleErrorResponse(types.GETMEMBERSACHIEVEMENTSDETAILS_ERROR, memberAchievementIssuedResponseFields, state, error);
    },

    //ApiResponse
    [types.UPDATEACHIEVEMENTS_SUCCESS](state, response) {
      handleSuccessResponse(types.UPDATEACHIEVEMENTS_SUCCESS,apiResponseFields, state, response);
    },

    //ApiResponse
    [types.UPDATEACHIEVEMENTS_ERROR](state, error) {
      handleErrorResponse(types.UPDATEACHIEVEMENTS_ERROR, apiResponseFields, state, error);
    },

    //ApiResponse
    [types.UPDATEACHIEVEMENTSLIVESTATUS_SUCCESS](state, response) {
      handleSuccessResponse(types.UPDATEACHIEVEMENTSLIVESTATUS_SUCCESS,apiResponseFields, state, response);
    },

    //ApiResponse
    [types.UPDATEACHIEVEMENTSLIVESTATUS_ERROR](state, error) {
      handleErrorResponse(types.UPDATEACHIEVEMENTSLIVESTATUS_ERROR, apiResponseFields, state, error);
    },
};

export default mutations;
