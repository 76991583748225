// <<< DO NOT EDIT GENERATED FILES DIRECTLY! api-store-mutation-types.mustache >>>
export const API_REQUEST = 'API_REQUEST';


export const GETACHIEVEMENTS_ITEM_REQ = 'GETACHIEVEMENTS_ITEM_REQ';
export const GETACHIEVEMENTS_ITEM_ERROR = 'GETACHIEVEMENTS_ITEM_ERROR';
export const GETACHIEVEMENTS_ITEM_SUCCESS = 'GETACHIEVEMENTS_ITEM_SUCCESS'

export const UPDATE_FIELDS = 'UPDATE_FIELDS';
export const CLEAR_FILTER = 'CLEAR_FILTER';
export const CLEAR_MESSAGE = 'CLEAR_MESSAGE';

export const CREATEACHIEVEMENTS_REQ = 'CREATEACHIEVEMENTS_REQ';
export const CREATEACHIEVEMENTS_ERROR = 'CREATEACHIEVEMENTS_ERROR';
export const CREATEACHIEVEMENTS_SUCCESS = 'CREATEACHIEVEMENTS_SUCCESS';

export const DELETEACHIEVEMENTS_REQ = 'DELETEACHIEVEMENTS_REQ';
export const DELETEACHIEVEMENTS_ERROR = 'DELETEACHIEVEMENTS_ERROR';
export const DELETEACHIEVEMENTS_SUCCESS = 'DELETEACHIEVEMENTS_SUCCESS';

export const DELETEACHIEVEMENTSBYQUERY_REQ = 'DELETEACHIEVEMENTSBYQUERY_REQ';
export const DELETEACHIEVEMENTSBYQUERY_ERROR = 'DELETEACHIEVEMENTSBYQUERY_ERROR';
export const DELETEACHIEVEMENTSBYQUERY_SUCCESS = 'DELETEACHIEVEMENTSBYQUERY_SUCCESS';

export const GETACHIEVEMENTTOCLONE_REQ = 'GETACHIEVEMENTTOCLONE_REQ';
export const GETACHIEVEMENTTOCLONE_ERROR = 'GETACHIEVEMENTTOCLONE_ERROR';
export const GETACHIEVEMENTTOCLONE_SUCCESS = 'GETACHIEVEMENTTOCLONE_SUCCESS';

export const GETACHIEVEMENTS_REQ = 'GETACHIEVEMENTS_REQ';
export const GETACHIEVEMENTS_ERROR = 'GETACHIEVEMENTS_ERROR';
export const GETACHIEVEMENTS_SUCCESS = 'GETACHIEVEMENTS_SUCCESS';

export const GETACHIEVEMENTSBYQUERY_REQ = 'GETACHIEVEMENTSBYQUERY_REQ';
export const GETACHIEVEMENTSBYQUERY_ERROR = 'GETACHIEVEMENTSBYQUERY_ERROR';
export const GETACHIEVEMENTSBYQUERY_SUCCESS = 'GETACHIEVEMENTSBYQUERY_SUCCESS';

export const GETANTECENDENTSFORACHIEVEMENT_REQ = 'GETANTECENDENTSFORACHIEVEMENT_REQ';
export const GETANTECENDENTSFORACHIEVEMENT_ERROR = 'GETANTECENDENTSFORACHIEVEMENT_ERROR';
export const GETANTECENDENTSFORACHIEVEMENT_SUCCESS = 'GETANTECENDENTSFORACHIEVEMENT_SUCCESS';

export const GETDESENDENTSFORACHIEVEMENT_REQ = 'GETDESENDENTSFORACHIEVEMENT_REQ';
export const GETDESENDENTSFORACHIEVEMENT_ERROR = 'GETDESENDENTSFORACHIEVEMENT_ERROR';
export const GETDESENDENTSFORACHIEVEMENT_SUCCESS = 'GETDESENDENTSFORACHIEVEMENT_SUCCESS';

export const GETISSUEDACHIEVEMENTSCOUNTBYID_REQ = 'GETISSUEDACHIEVEMENTSCOUNTBYID_REQ';
export const GETISSUEDACHIEVEMENTSCOUNTBYID_ERROR = 'GETISSUEDACHIEVEMENTSCOUNTBYID_ERROR';
export const GETISSUEDACHIEVEMENTSCOUNTBYID_SUCCESS = 'GETISSUEDACHIEVEMENTSCOUNTBYID_SUCCESS';

export const GETMEMBERSACHIEVEMENTSDETAILS_REQ = 'GETMEMBERSACHIEVEMENTSDETAILS_REQ';
export const GETMEMBERSACHIEVEMENTSDETAILS_ERROR = 'GETMEMBERSACHIEVEMENTSDETAILS_ERROR';
export const GETMEMBERSACHIEVEMENTSDETAILS_SUCCESS = 'GETMEMBERSACHIEVEMENTSDETAILS_SUCCESS';

export const UPDATEACHIEVEMENTS_REQ = 'UPDATEACHIEVEMENTS_REQ';
export const UPDATEACHIEVEMENTS_ERROR = 'UPDATEACHIEVEMENTS_ERROR';
export const UPDATEACHIEVEMENTS_SUCCESS = 'UPDATEACHIEVEMENTS_SUCCESS';

export const UPDATEACHIEVEMENTSLIVESTATUS_REQ = 'UPDATEACHIEVEMENTSLIVESTATUS_REQ';
export const UPDATEACHIEVEMENTSLIVESTATUS_ERROR = 'UPDATEACHIEVEMENTSLIVESTATUS_ERROR';
export const UPDATEACHIEVEMENTSLIVESTATUS_SUCCESS = 'UPDATEACHIEVEMENTSLIVESTATUS_SUCCESS';

