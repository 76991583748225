// <<< DO NOT EDIT GENERATED FILES DIRECTLY! api-store-mutations.mustache >>>
import * as types from './mutation-types';
import * as _ from 'lodash';
import {
  handleErrorResponse, handleItemErrorResponse,
  handleItemSuccessResponse,
  handleSuccessResponse
} from "../../../../../utils/ZiqniAdminApiHandler";
import apiResponseFields from '../../../fields/ApiResponseZq.js';
import bracketResponseFields from '../../../fields/BracketResponseZq.js';
import cloneCompetitionResponseFields from '../../../fields/CloneCompetitionResponseZq.js';
import competitionLeaderboardResponseFields from '../../../fields/CompetitionLeaderboardResponseZq.js';
import competitionResponseFields from '../../../fields/CompetitionResponseZq.js';
import contestReducedResponseFields from '../../../fields/ContestReducedResponseZq.js';
import createCompetitionRequestFields from '../../../fields/CreateCompetitionRequestZq.js';
import createSimpleCompetitionRequestFields from '../../../fields/CreateSimpleCompetitionRequestZq.js';
import entrantRequestFields from '../../../fields/EntrantRequestZq.js';
import entrantResponseFields from '../../../fields/EntrantResponseZq.js';
import queryRequestFields from '../../../fields/QueryRequestZq.js';
import updateCompetitionRequestFields from '../../../fields/UpdateCompetitionRequestZq.js';
import updateCompetitionStatusRequestFields from '../../../fields/UpdateCompetitionStatusRequestZq.js';

const mutations = {
    [types.API_REQUEST](state) {
        state.loading = true;
        state.loaded = false;
    },
    [types.CLEAR_MESSAGE](state){
        state.message = null;
    },

    [types.UPDATE_FIELDS](state, data) {
        state.hiddenFields = data._hiddenFields;
        state.fields = _.difference(state.originalFields, state.hiddenFields);
        localStorage.setItem('hiddenCompetitionsKey', JSON.stringify(state.hiddenFields));
    },

    [types.CLEAR_FILTER](state) {
        state.fields = _.difference(
            state.originalFields,
            state.originHiddenFields
        );
        state.hiddenFields = state.originHiddenFields;
        localStorage.removeItem('hiddenCompetitionsKey');
    },

    //CompetitionResponse
    [types.GETCOMPETITIONS_ITEM_SUCCESS](state, response) {
      handleItemSuccessResponse(types.GETCOMPETITIONS_ITEM_SUCCESS,competitionResponseFields, state, response, 'competition');
    },

    //CompetitionResponse
    [types.GETCOMPETITIONS_ERROR](state, error) {
      handleItemErrorResponse(types.GETCOMPETITIONS_ITEM_ITEM_ERROR, competitionResponseFields, state, error);
    },


    //ApiResponse
    [types.CREATECOMPETITIONS_SUCCESS](state, response) {
      handleSuccessResponse(types.CREATECOMPETITIONS_SUCCESS,apiResponseFields, state, response);
    },

    //ApiResponse
    [types.CREATECOMPETITIONS_ERROR](state, error) {
      handleErrorResponse(types.CREATECOMPETITIONS_ERROR, apiResponseFields, state, error);
    },

    //ApiResponse
    [types.CREATESIMPLECOMPETITIONS_SUCCESS](state, response) {
      handleSuccessResponse(types.CREATESIMPLECOMPETITIONS_SUCCESS,apiResponseFields, state, response);
    },

    //ApiResponse
    [types.CREATESIMPLECOMPETITIONS_ERROR](state, error) {
      handleErrorResponse(types.CREATESIMPLECOMPETITIONS_ERROR, apiResponseFields, state, error);
    },

    //CompetitionResponse
    [types.GETCOMPETITIONS_SUCCESS](state, response) {
      handleSuccessResponse(types.GETCOMPETITIONS_SUCCESS,competitionResponseFields, state, response);
    },

    //CompetitionResponse
    [types.GETCOMPETITIONS_ERROR](state, error) {
      handleErrorResponse(types.GETCOMPETITIONS_ERROR, competitionResponseFields, state, error);
    },

    //BracketResponse
    [types.GETCOMPETITIONSBRACKETS_SUCCESS](state, response) {
      handleSuccessResponse(types.GETCOMPETITIONSBRACKETS_SUCCESS,bracketResponseFields, state, response);
    },

    //BracketResponse
    [types.GETCOMPETITIONSBRACKETS_ERROR](state, error) {
      handleErrorResponse(types.GETCOMPETITIONSBRACKETS_ERROR, bracketResponseFields, state, error);
    },

    //CompetitionResponse
    [types.GETCOMPETITIONSBYQUERY_SUCCESS](state, response) {
      handleSuccessResponse(types.GETCOMPETITIONSBYQUERY_SUCCESS,competitionResponseFields, state, response);
    },

    //CompetitionResponse
    [types.GETCOMPETITIONSBYQUERY_ERROR](state, error) {
      handleErrorResponse(types.GETCOMPETITIONSBYQUERY_ERROR, competitionResponseFields, state, error);
    },

    //CloneCompetitionResponse
    [types.GETCOMPETITIONSTOCLONE_SUCCESS](state, response) {
      handleSuccessResponse(types.GETCOMPETITIONSTOCLONE_SUCCESS,cloneCompetitionResponseFields, state, response);
    },

    //CloneCompetitionResponse
    [types.GETCOMPETITIONSTOCLONE_ERROR](state, error) {
      handleErrorResponse(types.GETCOMPETITIONSTOCLONE_ERROR, cloneCompetitionResponseFields, state, error);
    },

    //CompetitionLeaderboardResponse
    [types.GETLEADERBOARDBYCOMPETITIONID_SUCCESS](state, response) {
      handleSuccessResponse(types.GETLEADERBOARDBYCOMPETITIONID_SUCCESS,competitionLeaderboardResponseFields, state, response);
    },

    //CompetitionLeaderboardResponse
    [types.GETLEADERBOARDBYCOMPETITIONID_ERROR](state, error) {
      handleErrorResponse(types.GETLEADERBOARDBYCOMPETITIONID_ERROR, competitionLeaderboardResponseFields, state, error);
    },

    //EntrantResponse
    [types.GETLISTOFENTRANTS_SUCCESS](state, response) {
      handleSuccessResponse(types.GETLISTOFENTRANTS_SUCCESS,entrantResponseFields, state, response);
    },

    //EntrantResponse
    [types.GETLISTOFENTRANTS_ERROR](state, error) {
      handleErrorResponse(types.GETLISTOFENTRANTS_ERROR, entrantResponseFields, state, error);
    },

    //ApiResponse
    [types.UPDATECOMPETITIONS_SUCCESS](state, response) {
      handleSuccessResponse(types.UPDATECOMPETITIONS_SUCCESS,apiResponseFields, state, response);
    },

    //ApiResponse
    [types.UPDATECOMPETITIONS_ERROR](state, error) {
      handleErrorResponse(types.UPDATECOMPETITIONS_ERROR, apiResponseFields, state, error);
    },

    //ApiResponse
    [types.UPDATECOMPETITIONSENTRIES_SUCCESS](state, response) {
      handleSuccessResponse(types.UPDATECOMPETITIONSENTRIES_SUCCESS,apiResponseFields, state, response);
    },

    //ApiResponse
    [types.UPDATECOMPETITIONSENTRIES_ERROR](state, error) {
      handleErrorResponse(types.UPDATECOMPETITIONSENTRIES_ERROR, apiResponseFields, state, error);
    },

    //ApiResponse
    [types.UPDATECOMPETITIONSSTATE_SUCCESS](state, response) {
      handleSuccessResponse(types.UPDATECOMPETITIONSSTATE_SUCCESS,apiResponseFields, state, response);
    },

    //ApiResponse
    [types.UPDATECOMPETITIONSSTATE_ERROR](state, error) {
      handleErrorResponse(types.UPDATECOMPETITIONSSTATE_ERROR, apiResponseFields, state, error);
    },
};

export default mutations;
