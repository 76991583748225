export const API_REQUEST = 'API_REQUEST';

export const GETENTRANTSBYQUERY_REQ = 'GETENTRANTSBYQUERY_REQ';
export const GETENTRANTSBYQUERY_SUCCESS = 'GETENTRANTSBYQUERY_SUCCESS';
export const GETENTRANTSBYQUERY_ERROR = 'GETENTRANTSBYQUERY_ERROR';

export const EXPORTENTRANTSBYQUERY_REQ = 'EXPORTENTRANTSBYQUERY_REQ';
export const EXPORTENTRANTSBYQUERY_SUCCESS = 'EXPORTENTRANTSBYQUERY_SUCCESS';
export const EXPORTENTRANTSBYQUERY_ERROR = 'EXPORTENTRANTSBYQUERY_ERROR';


