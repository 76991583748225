export const API_REQUEST = 'API_REQUEST';
export const GET_CONNECTIONS_SUCCESS = 'GET_CONNECTIONS_SUCCESS';
export const GET_CONNECTIONS_ERROR = 'GET_CONNECTIONS_ERROR';
export const GET_CONNECTIONS_BY_ID_SUCCESS = 'GET_CONNECTIONS_BY_ID_SUCCESS';
export const GET_CONNECTIONS_BY_ID_ERROR = 'GET_CONNECTIONS_BY_ID_ERROR';
export const SET_CONNECTION = 'SET_CONNECTION';
export const CREATE_CONNECTION_SUCCESS = 'CREATE_CONNECTION_SUCCESS';
export const CREATE_CONNECTION_ERROR = 'CREATE_CONNECTION_ERROR';
export const UPDATE_CONNECTION_SUCCESS = 'UPDATE_CONNECTION_SUCCESS';
export const UPDATE_CONNECTION_ERROR = 'UPDATE_CONNECTION_ERROR';
export const DELETE_CONNECTION_ERROR = 'DELETE_CONNECTION_ERROR';
export const DELETE_CONNECTION_SUCCESS = 'DELETE_CONNECTION_SUCCESS';
export const UPDATE_FIELDS = 'UPDATE_FIELDS';
export const CLEAR_FILTER = 'CLEAR_FILTER';
export const CLEAR_MESSAGE = 'CLEAR_MESSAGE';

// GET BY IDS
export const GET_CONNECTIONS_BY_IDS_SUCCESS = 'GET_CONNECTIONS_BY_IDS_SUCCESS';
export const GET_CONNECTIONS_BY_IDS_ERROR = 'GET_CONNECTIONS_BY_IDS_ERROR';

// Action Types (Method GET)
export const CONNECTIONS_SUCCESS = 'CONNECTIONS_SUCCESS';
export const CONNECTIONS_ERROR = 'CONNECTIONS_ERROR';

export const UPDATE_CONNECTION_STATUS_SUCCESS = 'UPDATE_CONNECTION_STATUS_SUCCESS'
export const UPDATE_CONNECTION_STATUS_ERROR = 'UPDATE_CONNECTION_STATUS_ERROR'

export const CONNECTION_TYPE = 'CONNECTION_TYPE'

export const GET_TRANSFORMERS_SUCCESS = 'GET_TRANSFORMERS_SUCCESS'
export const GET_TRANSFORMERS_ERROR = 'GET_TRANSFORMERS_ERROR'
