// <<< DO NOT EDIT GENERATED FILES DIRECTLY! api-store-mutation-types.mustache >>>
export const API_REQUEST = 'API_REQUEST';


export const GETCUSTOMFIELDS_ITEM_REQ = 'GETCUSTOMFIELDS_ITEM_REQ';
export const GETCUSTOMFIELDS_ITEM_ERROR = 'GETCUSTOMFIELDS_ITEM_ERROR';
export const GETCUSTOMFIELDS_ITEM_SUCCESS = 'GETCUSTOMFIELDS_ITEM_SUCCESS'

export const UPDATE_FIELDS = 'UPDATE_FIELDS';
export const CLEAR_FILTER = 'CLEAR_FILTER';
export const CLEAR_MESSAGE = 'CLEAR_MESSAGE';

export const CREATECUSTOMFIELDS_REQ = 'CREATECUSTOMFIELDS_REQ';
export const CREATECUSTOMFIELDS_ERROR = 'CREATECUSTOMFIELDS_ERROR';
export const CREATECUSTOMFIELDS_SUCCESS = 'CREATECUSTOMFIELDS_SUCCESS';

export const DELETECUSTOMFIELDS_REQ = 'DELETECUSTOMFIELDS_REQ';
export const DELETECUSTOMFIELDS_ERROR = 'DELETECUSTOMFIELDS_ERROR';
export const DELETECUSTOMFIELDS_SUCCESS = 'DELETECUSTOMFIELDS_SUCCESS';

export const DELETECUSTOMFIELDSBYQUERY_REQ = 'DELETECUSTOMFIELDSBYQUERY_REQ';
export const DELETECUSTOMFIELDSBYQUERY_ERROR = 'DELETECUSTOMFIELDSBYQUERY_ERROR';
export const DELETECUSTOMFIELDSBYQUERY_SUCCESS = 'DELETECUSTOMFIELDSBYQUERY_SUCCESS';

export const GETCUSTOMFIELDS_REQ = 'GETCUSTOMFIELDS_REQ';
export const GETCUSTOMFIELDS_ERROR = 'GETCUSTOMFIELDS_ERROR';
export const GETCUSTOMFIELDS_SUCCESS = 'GETCUSTOMFIELDS_SUCCESS';

export const GETCUSTOMFIELDSBYQUERY_REQ = 'GETCUSTOMFIELDSBYQUERY_REQ';
export const GETCUSTOMFIELDSBYQUERY_ERROR = 'GETCUSTOMFIELDSBYQUERY_ERROR';
export const GETCUSTOMFIELDSBYQUERY_SUCCESS = 'GETCUSTOMFIELDSBYQUERY_SUCCESS';

export const UPDATECUSTOMFIELDS_REQ = 'UPDATECUSTOMFIELDS_REQ';
export const UPDATECUSTOMFIELDS_ERROR = 'UPDATECUSTOMFIELDS_ERROR';
export const UPDATECUSTOMFIELDS_SUCCESS = 'UPDATECUSTOMFIELDS_SUCCESS';

