// <<< DO NOT EDIT GENERATED FILES DIRECTLY! api-store-mutations.mustache >>>
import * as types from './mutation-types';
import * as _ from 'lodash';
import {
  handleErrorResponse, handleItemErrorResponse,
  handleItemSuccessResponse,
  handleSuccessResponse
} from "../../../../../utils/ZiqniAdminApiHandler";
import accountMessageResponseFields from '../../../fields/AccountMessageResponseZq.js';
import apiResponseFields from '../../../fields/ApiResponseZq.js';
import queryRequestFields from '../../../fields/QueryRequestZq.js';
import updateMessageStatusRequestFields from '../../../fields/UpdateMessageStatusRequestZq.js';

const mutations = {
    [types.API_REQUEST](state) {
        state.loading = true;
        state.loaded = false;
    },
    [types.CLEAR_MESSAGE](state){
        state.message = null;
    },

    [types.UPDATE_FIELDS](state, data) {
        state.hiddenFields = data._hiddenFields;
        state.fields = _.difference(state.originalFields, state.hiddenFields);
        localStorage.setItem('hiddenAccountMessagesKey', JSON.stringify(state.hiddenFields));
    },

    [types.CLEAR_FILTER](state) {
        state.fields = _.difference(
            state.originalFields,
            state.originHiddenFields
        );
        state.hiddenFields = state.originHiddenFields;
        localStorage.removeItem('hiddenAccountMessagesKey');
    },

    //AccountMessageResponse
    [types.GETACCOUNTMESSAGES_ITEM_SUCCESS](state, response) {
      handleItemSuccessResponse(types.GETACCOUNTMESSAGES_ITEM_SUCCESS,accountMessageResponseFields, state, response, 'accountMessage');
    },

    //AccountMessageResponse
    [types.GETACCOUNTMESSAGES_ERROR](state, error) {
      handleItemErrorResponse(types.GETACCOUNTMESSAGES_ITEM_ITEM_ERROR, accountMessageResponseFields, state, error);
    },


    //ApiResponse
    [types.DELETEACCOUNTMESSAGES_SUCCESS](state, response) {
      handleSuccessResponse(types.DELETEACCOUNTMESSAGES_SUCCESS,apiResponseFields, state, response);
    },

    //ApiResponse
    [types.DELETEACCOUNTMESSAGES_ERROR](state, error) {
      handleErrorResponse(types.DELETEACCOUNTMESSAGES_ERROR, apiResponseFields, state, error);
    },

    //ApiResponse
    [types.DELETEACCOUNTMESSAGESBYQUERY_SUCCESS](state, response) {
      handleSuccessResponse(types.DELETEACCOUNTMESSAGESBYQUERY_SUCCESS,apiResponseFields, state, response);
    },

    //ApiResponse
    [types.DELETEACCOUNTMESSAGESBYQUERY_ERROR](state, error) {
      handleErrorResponse(types.DELETEACCOUNTMESSAGESBYQUERY_ERROR, apiResponseFields, state, error);
    },

    //AccountMessageResponse
    [types.GETACCOUNTMESSAGES_SUCCESS](state, response) {
      handleSuccessResponse(types.GETACCOUNTMESSAGES_SUCCESS,accountMessageResponseFields, state, response);
    },

    //AccountMessageResponse
    [types.GETACCOUNTMESSAGES_ERROR](state, error) {
      handleErrorResponse(types.GETACCOUNTMESSAGES_ERROR, accountMessageResponseFields, state, error);
    },

    //AccountMessageResponse
    [types.GETACCOUNTMESSAGESBYQUERY_SUCCESS](state, response) {
      handleSuccessResponse(types.GETACCOUNTMESSAGESBYQUERY_SUCCESS,accountMessageResponseFields, state, response);
    },

    //AccountMessageResponse
    [types.GETACCOUNTMESSAGESBYQUERY_ERROR](state, error) {
      handleErrorResponse(types.GETACCOUNTMESSAGESBYQUERY_ERROR, accountMessageResponseFields, state, error);
    },

    //ApiResponse
    [types.UPDATEACCOUNTMESSAGESSTATUS_SUCCESS](state, response) {
      handleSuccessResponse(types.UPDATEACCOUNTMESSAGESSTATUS_SUCCESS,apiResponseFields, state, response);
    },

    //ApiResponse
    [types.UPDATEACCOUNTMESSAGESSTATUS_ERROR](state, error) {
      handleErrorResponse(types.UPDATEACCOUNTMESSAGESSTATUS_ERROR, apiResponseFields, state, error);
    },
};

export default mutations;
