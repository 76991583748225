// <<< DO NOT EDIT GENERATED FILES DIRECTLY! api-store-mutation-types.mustache >>>
export const API_REQUEST = 'API_REQUEST';


export const GETREWARDS_ITEM_REQ = 'GETREWARDS_ITEM_REQ';
export const GETREWARDS_ITEM_ERROR = 'GETREWARDS_ITEM_ERROR';
export const GETREWARDS_ITEM_SUCCESS = 'GETREWARDS_ITEM_SUCCESS'

export const UPDATE_FIELDS = 'UPDATE_FIELDS';
export const CLEAR_FILTER = 'CLEAR_FILTER';
export const CLEAR_MESSAGE = 'CLEAR_MESSAGE';

export const CREATEREWARDS_REQ = 'CREATEREWARDS_REQ';
export const CREATEREWARDS_ERROR = 'CREATEREWARDS_ERROR';
export const CREATEREWARDS_SUCCESS = 'CREATEREWARDS_SUCCESS';

export const DELETEREWARDS_REQ = 'DELETEREWARDS_REQ';
export const DELETEREWARDS_ERROR = 'DELETEREWARDS_ERROR';
export const DELETEREWARDS_SUCCESS = 'DELETEREWARDS_SUCCESS';

export const DELETEREWARDSBYQUERY_REQ = 'DELETEREWARDSBYQUERY_REQ';
export const DELETEREWARDSBYQUERY_ERROR = 'DELETEREWARDSBYQUERY_ERROR';
export const DELETEREWARDSBYQUERY_SUCCESS = 'DELETEREWARDSBYQUERY_SUCCESS';

export const GETREWARDS_REQ = 'GETREWARDS_REQ';
export const GETREWARDS_ERROR = 'GETREWARDS_ERROR';
export const GETREWARDS_SUCCESS = 'GETREWARDS_SUCCESS';

export const GETREWARDSBYACHIEVEMENTID_REQ = 'GETREWARDSBYACHIEVEMENTID_REQ';
export const GETREWARDSBYACHIEVEMENTID_ERROR = 'GETREWARDSBYACHIEVEMENTID_ERROR';
export const GETREWARDSBYACHIEVEMENTID_SUCCESS = 'GETREWARDSBYACHIEVEMENTID_SUCCESS';

export const GETREWARDSBYCONTESTID_REQ = 'GETREWARDSBYCONTESTID_REQ';
export const GETREWARDSBYCONTESTID_ERROR = 'GETREWARDSBYCONTESTID_ERROR';
export const GETREWARDSBYCONTESTID_SUCCESS = 'GETREWARDSBYCONTESTID_SUCCESS';

export const GETREWARDSBYQUERY_REQ = 'GETREWARDSBYQUERY_REQ';
export const GETREWARDSBYQUERY_ERROR = 'GETREWARDSBYQUERY_ERROR';
export const GETREWARDSBYQUERY_SUCCESS = 'GETREWARDSBYQUERY_SUCCESS';

export const UPDATEREWARDS_REQ = 'UPDATEREWARDS_REQ';
export const UPDATEREWARDS_ERROR = 'UPDATEREWARDS_ERROR';
export const UPDATEREWARDS_SUCCESS = 'UPDATEREWARDS_SUCCESS';

