// <<< DO NOT EDIT GENERATED FILES DIRECTLY! api-store-mutation-types.mustache >>>
export const API_REQUEST = 'API_REQUEST';


export const GETUNITSOFMEASURE_ITEM_REQ = 'GETUNITSOFMEASURE_ITEM_REQ';
export const GETUNITSOFMEASURE_ITEM_ERROR = 'GETUNITSOFMEASURE_ITEM_ERROR';
export const GETUNITSOFMEASURE_ITEM_SUCCESS = 'GETUNITSOFMEASURE_ITEM_SUCCESS'

export const UPDATE_FIELDS = 'UPDATE_FIELDS';
export const CLEAR_FILTER = 'CLEAR_FILTER';
export const CLEAR_MESSAGE = 'CLEAR_MESSAGE';

export const CREATEUNITSOFMEASURE_REQ = 'CREATEUNITSOFMEASURE_REQ';
export const CREATEUNITSOFMEASURE_ERROR = 'CREATEUNITSOFMEASURE_ERROR';
export const CREATEUNITSOFMEASURE_SUCCESS = 'CREATEUNITSOFMEASURE_SUCCESS';

export const DELETECOLLABORATORSBYQUERY_REQ = 'DELETECOLLABORATORSBYQUERY_REQ';
export const DELETECOLLABORATORSBYQUERY_ERROR = 'DELETECOLLABORATORSBYQUERY_ERROR';
export const DELETECOLLABORATORSBYQUERY_SUCCESS = 'DELETECOLLABORATORSBYQUERY_SUCCESS';

export const DELETEUNITSOFMEASURE_REQ = 'DELETEUNITSOFMEASURE_REQ';
export const DELETEUNITSOFMEASURE_ERROR = 'DELETEUNITSOFMEASURE_ERROR';
export const DELETEUNITSOFMEASURE_SUCCESS = 'DELETEUNITSOFMEASURE_SUCCESS';

export const DELETEUNITSOFMEASUREBYQUERY_REQ = 'DELETEUNITSOFMEASUREBYQUERY_REQ';
export const DELETEUNITSOFMEASUREBYQUERY_ERROR = 'DELETEUNITSOFMEASUREBYQUERY_ERROR';
export const DELETEUNITSOFMEASUREBYQUERY_SUCCESS = 'DELETEUNITSOFMEASUREBYQUERY_SUCCESS';

export const GETUNITSOFMEASURE_REQ = 'GETUNITSOFMEASURE_REQ';
export const GETUNITSOFMEASURE_ERROR = 'GETUNITSOFMEASURE_ERROR';
export const GETUNITSOFMEASURE_SUCCESS = 'GETUNITSOFMEASURE_SUCCESS';

export const GETUNITSOFMEASUREBYQUERY_REQ = 'GETUNITSOFMEASUREBYQUERY_REQ';
export const GETUNITSOFMEASUREBYQUERY_ERROR = 'GETUNITSOFMEASUREBYQUERY_ERROR';
export const GETUNITSOFMEASUREBYQUERY_SUCCESS = 'GETUNITSOFMEASUREBYQUERY_SUCCESS';

export const UPDATEUNITSOFMEASURE_REQ = 'UPDATEUNITSOFMEASURE_REQ';
export const UPDATEUNITSOFMEASURE_ERROR = 'UPDATEUNITSOFMEASURE_ERROR';
export const UPDATEUNITSOFMEASURE_SUCCESS = 'UPDATEUNITSOFMEASURE_SUCCESS';

