// <<< DO NOT EDIT GENERATED FILES DIRECTLY! model-store-fields-all.mustache >>>


import achievementStatus from './AchievementStatusZq';
import achievementStrategies from './AchievementStrategiesZq';
import dependantOn from './DependantOnZq';
// import productReduced from './ProductReducedZq';
import productReduced from "@/generated/ziqni/fields/ProductReducedZq";
import scheduling from './SchedulingZq';

const achievementAllOf = this;

export default {
  objectType: 'Achievement_allOf',
  allowableValuesKeys: [],
  constraints: ['isMap',],
  getter: null,
  hiddenFields: ['icon',],
  searchableFields: ['name','description','termsAndConditions','maxNumberOfIssues','statusCode','constraints',], 
  searchableAllFields: ['name','description','termsAndConditions',],
  translatableFields: ['name','description','termsAndConditions',],
  sortableFields: [],
  customFieldsFieldName: [],
  metadataFieldName: [],
  tagFieldName: [],
  entityLinksFrom: [],

  route: {
    
    
  },
  fieldList: ['name','description','termsAndConditions','icon','banner','bannerLowResolution','bannerHighResolution','scheduling','maxNumberOfIssues','status','statusCode','constraints','achievementDependencies','memberTagsFilter','products','productTagsFilter','strategies'],
  fields: { 
    name: {
      name: 'name',
      complexType: null,
      displayOrder: 1001,
      allowableValuesKeys: [],
      constraints: ['required','isPrimitiveType','isString',
                     ],
      formType: null,
    },
    description: {
      name: 'description',
      complexType: null,
      displayOrder: 1002,
      allowableValuesKeys: [],
      constraints: ['isPrimitiveType','isString',
                     ],
      formType: 'TEXTAREA',
    },
    termsAndConditions: {
      name: 'termsAndConditions',
      complexType: null,
      displayOrder: 1003,
      allowableValuesKeys: [],
      constraints: ['isPrimitiveType','isString',
                     ],
      formType: null,
    },
    icon: {
      name: 'icon',
      complexType: null,
      displayOrder: 1011,
      allowableValuesKeys: [],
      constraints: ['required','isPrimitiveType','isString',
                     ],
      formType: null,
    },
    banner: {
      name: 'banner',
      complexType: null,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['isPrimitiveType','isString',
                     ],
      formType: null,
    },
    bannerLowResolution: {
      name: 'bannerLowResolution',
      complexType: null,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['isPrimitiveType','isString',
                     ],
      formType: null,
    },
    bannerHighResolution: {
      name: 'bannerHighResolution',
      complexType: null,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['isPrimitiveType','isString',
                     ],
      formType: null,
    },
    scheduling: {
      name: 'scheduling',
      complexType: scheduling,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['isModel',
                     ],
      formType: null,
    },
    maxNumberOfIssues: {
      name: 'maxNumberOfIssues',
      complexType: null,
      displayOrder: 1006,
      allowableValuesKeys: [],
      constraints: ['isPrimitiveType','isNumeric','isInteger','isShort',
                     ],
      formType: null,
    },
    status: {
      name: 'status',
      complexType: achievementStatus,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: [
                     ],
      formType: null,
    },
    statusCode: {
      name: 'statusCode',
      complexType: null,
      displayOrder: 1006,
      allowableValuesKeys: [],
      constraints: ['isPrimitiveType','isNumeric','isInteger','isUnboundedInteger','isReadOnly',
                     ],
      formType: null,
    },
    constraints: {
      name: 'constraints',
      complexType: null,
      displayOrder: 1100,
      allowableValuesKeys: [],
      constraints: ['isConstraintsArray','required','isPrimitiveType','isContainer','isArray',
                    'isString', ],
      formType: null,
    },
    achievementDependencies: {
      name: 'achievementDependencies',
      complexType: dependantOn,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['isModel',
                     ],
      formType: null,
    },
    memberTagsFilter: {
      name: 'memberTagsFilter',
      complexType: dependantOn,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['isModel',
                     ],
      formType: null,
    },
    products: {
      name: 'products',
      complexType: productReduced,
      displayOrder: 1006,
      allowableValuesKeys: [],
      constraints: ['isContainer','isArray',
                    'isModel', ],
      formType: null,
    },
    productTagsFilter: {
      name: 'productTagsFilter',
      complexType: dependantOn,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['isModel',
                     ],
      formType: null,
    },
    strategies: {
      name: 'strategies',
      complexType: achievementStrategies,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['isModel',
                     ],
      formType: null,
    }
  }
};
