// Genearetd using ziqni-admin-api.mustache
import {ApiClient} from '@ziqni-tech/admin-api-client';
import { ProductsApi } from "@ziqni-tech/admin-api-client";

const defaultClient = ApiClient.instance;
defaultClient.basePath = sdkApiConfig.baseApiUrlPath;

import { sdkApiConfig } from '@/config';
import { queryConverter } from '@/utils/query.convert';
import { errorHandler } from '@/utils/errorHandler';
class ProductsApiZiqni {

  constructor() {
    this.defaultOptions = {};
    this.ziqniProductsApi = new ProductsApi();
    this.OAuth2 = this.ziqniProductsApi.apiClient.authentications['OAuth2'];
    this.OAuth2.accessToken = localStorage.getItem('vue-token');
  }

  /**
   * createProducts: Create products. Notes: Create new Products in the Ziqni database
   *
   * BaseName: Products
   * Path: /products
   * Http Method: POST, Consumes: [{mediaType&#x3D;application/json}, {mediaType&#x3D;file/csv}], Produces: [{mediaType&#x3D;application/json}]
   * ReturnType: ApiResponse, ReturnBaseType: ApiResponse, ReturnContainer: 
   *  RequiredParams:
   *   - body [body]   >>> Create Products in the Ziqni database. An array can contain a maximum of 10,000 products in one request
   *      |  dataType: [CreateProductRequest], baseType: CreateProductRequest, 
   *      |  isBodyParam: true, 
   *      | 
   * 
   *
   * Constraints:
   * hasAuthMethods: true, hasConsumes: true, hasProduces: true, hasParams: true, hasOptionalParams: false,
   * hasRequiredParams: true, returnTypeIsPrimitive: false, returnSimpleType: true, subresourceOperation: false,
   * isMap: false, isArray: false, isMultipart: false, isResponseBinary: false, isResponseFile: false, hasReference: true,
   * hasDefaultResponse: false, hasErrorResponseObject: true, isRestfulIndex: false, isRestfulShow: false,
   * isRestfulCreate: true, isRestfulUpdate: false, isRestfulDestroy: false, isRestful: true, isDeprecated: false,
   * isCallbackRequest: false, uniqueItems: false  hasRequiredParams
   */
  createProducts(body){
    this.OAuth2.accessToken = localStorage.getItem('vue-token');
    return new Promise((resolve, reject) => {
      const callback = (error, data, responseString) => {
        const errorsFromHandler = errorHandler(error, data, responseString);
        if (error) {
          reject(error.message);
        } else if (errorsFromHandler.length) {
          const errors = errorsFromHandler.join(', ');
          reject(errors);
        } else {
          resolve(data);
        }
      };
      this.ziqniProductsApi.createProducts(body, callback);
    });
  }

  /**
   * deleteProducts: Delete products. Notes: Delete Products for a given identifier specified
   *
   * BaseName: Products
   * Path: /products
   * Http Method: DELETE, Consumes: , Produces: [{mediaType&#x3D;application/json}]
   * ReturnType: ApiResponse, ReturnBaseType: ApiResponse, ReturnContainer: 
   *  
   * OptionalParams:
   *   ops { 
   *   - id [id]   >>> The unique identifiers of the resources
   *      |  dataType: [String], baseType: String, 
   *      |  isQueryParam: true, isCollectionFormatMulti: true, isExplode: true, collectionFormat: multi, 
   *      | 
   *   }
   *
   * Constraints:
   * hasAuthMethods: true, hasConsumes: false, hasProduces: true, hasParams: true, hasOptionalParams: true,
   * hasRequiredParams: false, returnTypeIsPrimitive: false, returnSimpleType: true, subresourceOperation: false,
   * isMap: false, isArray: false, isMultipart: false, isResponseBinary: false, isResponseFile: false, hasReference: true,
   * hasDefaultResponse: false, hasErrorResponseObject: true, isRestfulIndex: false, isRestfulShow: false,
   * isRestfulCreate: false, isRestfulUpdate: false, isRestfulDestroy: false, isRestful: false, isDeprecated: false,
   * isCallbackRequest: false, uniqueItems: false hasOptionalParams 
   */
  deleteProducts(opts){
    this.OAuth2.accessToken = localStorage.getItem('vue-token');
    return new Promise((resolve, reject) => {
      const callback = (error, data, responseString) => {
        const errorsFromHandler = errorHandler(error, data, responseString);
        if (error) {
          reject(error.message);
        } else if (errorsFromHandler.length) {
          const errors = errorsFromHandler.join(', ');
          reject(errors);
        } else {
          resolve(data);
        }
      };
      this.ziqniProductsApi.deleteProducts(opts, callback);
    });
  }

  /**
   * deleteProductsByQuery: Delete products by query. Notes: Delete Products from Ziqni database by unique Product ID&#39;s or any other POST body parameters using the POST method
   *
   * BaseName: Products
   * Path: /products/delete
   * Http Method: POST, Consumes: [{mediaType&#x3D;application/json}], Produces: [{mediaType&#x3D;application/json}]
   * ReturnType: ApiResponse, ReturnBaseType: ApiResponse, ReturnContainer: 
   *  
   * OptionalParams:
   *   ops { 
   *   - body [body]  isModel: true >>> Delete Products from Ziqni database by unique Product ID&#39;s or any other POST body parameters using the POST method
   *      |  dataType: QueryRequest, baseType: QueryRequest, 
   *      |  isBodyParam: true, 
   *      | 
   *   }
   *
   * Constraints:
   * hasAuthMethods: true, hasConsumes: true, hasProduces: true, hasParams: true, hasOptionalParams: true,
   * hasRequiredParams: false, returnTypeIsPrimitive: false, returnSimpleType: true, subresourceOperation: false,
   * isMap: false, isArray: false, isMultipart: false, isResponseBinary: false, isResponseFile: false, hasReference: true,
   * hasDefaultResponse: false, hasErrorResponseObject: true, isRestfulIndex: false, isRestfulShow: false,
   * isRestfulCreate: false, isRestfulUpdate: false, isRestfulDestroy: false, isRestful: false, isDeprecated: false,
   * isCallbackRequest: false, uniqueItems: false hasOptionalParams 
   */
  deleteProductsByQuery(opts){
    this.OAuth2.accessToken = localStorage.getItem('vue-token');
    return new Promise((resolve, reject) => {
      const callback = (error, data, responseString) => {
        const errorsFromHandler = errorHandler(error, data, responseString);
        if (error) {
          reject(error.message);
        } else if (errorsFromHandler.length) {
          const errors = errorsFromHandler.join(', ');
          reject(errors);
        } else {
          resolve(data);
        }
      };
      this.ziqniProductsApi.deleteProductsByQuery(opts, callback);
    });
  }

  /**
   * getAchievementsForProducts: NOT AVAILABLE IN CURRENT RELEASE. Notes: Returns a list of Achievements for the Product id provided
   *
   * BaseName: Products
   * Path: /products/{id}/achievements
   * Http Method: GET, Consumes: , Produces: [{mediaType&#x3D;application/json}]
   * ReturnType: AchievementReducedResponse, ReturnBaseType: AchievementReducedResponse, ReturnContainer: 
   *  RequiredParams:
   *   - id [id] isString isPrimitiveType: true >>> Unique identifier of the resource
   *      |  dataType: String, 
   *      |  isPathParam: true, 
   *      | 
   * OptionalParams:
   *   ops { 
   *   - achievementId [achievementId] isString isPrimitiveType: true >>> Id of an Achievement
   *      |  dataType: String, 
   *      |  isQueryParam: true, 
   *      | 
   *  
   *   - limit [_limit] isIntegerisUnboundedInteger isPrimitiveType: true >>> Limit the returned total records found
   *      |  dataType: Number, 
   *      |  isQueryParam: true, isExplode: true, 
   *      | 
   *  
   *   - skip [_skip] isIntegerisUnboundedInteger isPrimitiveType: true >>> Skip the returned records found and return the next batch of records
   *      |  dataType: Number, 
   *      |  isQueryParam: true, isExplode: true, 
   *      | 
   *   }
   *
   * Constraints:
   * hasAuthMethods: true, hasConsumes: false, hasProduces: true, hasParams: true, hasOptionalParams: true,
   * hasRequiredParams: true, returnTypeIsPrimitive: false, returnSimpleType: true, subresourceOperation: false,
   * isMap: false, isArray: false, isMultipart: false, isResponseBinary: false, isResponseFile: false, hasReference: true,
   * hasDefaultResponse: false, hasErrorResponseObject: true, isRestfulIndex: false, isRestfulShow: false,
   * isRestfulCreate: false, isRestfulUpdate: false, isRestfulDestroy: false, isRestful: false, isDeprecated: false,
   * isCallbackRequest: false, uniqueItems: false hasOptionalParams hasRequiredParams
   */
  getAchievementsForProducts(id,opts){
    this.OAuth2.accessToken = localStorage.getItem('vue-token');
    return new Promise((resolve, reject) => {
      const callback = (error, data, responseString) => {
        const errorsFromHandler = errorHandler(error, data, responseString);
        if (error) {
          reject(error.message);
        } else if (errorsFromHandler.length) {
          const errors = errorsFromHandler.join(', ');
          reject(errors);
        } else {
          resolve(data);
        }
      };
      this.ziqniProductsApi.getAchievementsForProducts(id, opts, callback);
    });
  }

  /**
   * getCompetitionsForProducts: NOT AVAILABLE IN CURRENT RELEASE. Notes: Returns a list of Competitions for the Product id provided
   *
   * BaseName: Products
   * Path: /products/{id}/competitions
   * Http Method: GET, Consumes: , Produces: [{mediaType&#x3D;application/json}]
   * ReturnType: CompetitionReducedResponse, ReturnBaseType: CompetitionReducedResponse, ReturnContainer: 
   *  RequiredParams:
   *   - id [id] isString isPrimitiveType: true >>> Unique identifier of the resource
   *      |  dataType: String, 
   *      |  isPathParam: true, 
   *      | 
   * OptionalParams:
   *   ops { 
   *   - competitionId [competitionId] isString isPrimitiveType: true >>> Id of a Competition
   *      |  dataType: String, 
   *      |  isQueryParam: true, 
   *      | 
   *  
   *   - limit [_limit] isIntegerisUnboundedInteger isPrimitiveType: true >>> Limit the returned total records found
   *      |  dataType: Number, 
   *      |  isQueryParam: true, isExplode: true, 
   *      | 
   *  
   *   - skip [_skip] isIntegerisUnboundedInteger isPrimitiveType: true >>> Skip the returned records found and return the next batch of records
   *      |  dataType: Number, 
   *      |  isQueryParam: true, isExplode: true, 
   *      | 
   *   }
   *
   * Constraints:
   * hasAuthMethods: true, hasConsumes: false, hasProduces: true, hasParams: true, hasOptionalParams: true,
   * hasRequiredParams: true, returnTypeIsPrimitive: false, returnSimpleType: true, subresourceOperation: false,
   * isMap: false, isArray: false, isMultipart: false, isResponseBinary: false, isResponseFile: false, hasReference: true,
   * hasDefaultResponse: false, hasErrorResponseObject: true, isRestfulIndex: false, isRestfulShow: false,
   * isRestfulCreate: false, isRestfulUpdate: false, isRestfulDestroy: false, isRestful: false, isDeprecated: false,
   * isCallbackRequest: false, uniqueItems: false hasOptionalParams hasRequiredParams
   */
  getCompetitionsForProducts(id,opts){
    this.OAuth2.accessToken = localStorage.getItem('vue-token');
    return new Promise((resolve, reject) => {
      const callback = (error, data, responseString) => {
        const errorsFromHandler = errorHandler(error, data, responseString);
        if (error) {
          reject(error.message);
        } else if (errorsFromHandler.length) {
          const errors = errorsFromHandler.join(', ');
          reject(errors);
        } else {
          resolve(data);
        }
      };
      this.ziqniProductsApi.getCompetitionsForProducts(id, opts, callback);
    });
  }

  /**
   * getContestsForProducts: NOT AVAILABLE IN CURRENT RELEASE. Notes: Returns a list of Contests for the Product id provided
   *
   * BaseName: Products
   * Path: /products/{id}/contests
   * Http Method: GET, Consumes: , Produces: [{mediaType&#x3D;application/json}]
   * ReturnType: ContestReducedResponse, ReturnBaseType: ContestReducedResponse, ReturnContainer: 
   *  RequiredParams:
   *   - id [id] isString isPrimitiveType: true >>> Unique identifier of the resource
   *      |  dataType: String, 
   *      |  isPathParam: true, 
   *      | 
   * OptionalParams:
   *   ops { 
   *   - contestId [contestId] isString isPrimitiveType: true >>> Id of a Contest
   *      |  dataType: String, 
   *      |  isQueryParam: true, 
   *      | 
   *  
   *   - limit [_limit] isIntegerisUnboundedInteger isPrimitiveType: true >>> Limit the returned total records found
   *      |  dataType: Number, 
   *      |  isQueryParam: true, isExplode: true, 
   *      | 
   *  
   *   - skip [_skip] isIntegerisUnboundedInteger isPrimitiveType: true >>> Skip the returned records found and return the next batch of records
   *      |  dataType: Number, 
   *      |  isQueryParam: true, isExplode: true, 
   *      | 
   *   }
   *
   * Constraints:
   * hasAuthMethods: true, hasConsumes: false, hasProduces: true, hasParams: true, hasOptionalParams: true,
   * hasRequiredParams: true, returnTypeIsPrimitive: false, returnSimpleType: true, subresourceOperation: false,
   * isMap: false, isArray: false, isMultipart: false, isResponseBinary: false, isResponseFile: false, hasReference: true,
   * hasDefaultResponse: false, hasErrorResponseObject: true, isRestfulIndex: false, isRestfulShow: false,
   * isRestfulCreate: false, isRestfulUpdate: false, isRestfulDestroy: false, isRestful: false, isDeprecated: false,
   * isCallbackRequest: false, uniqueItems: false hasOptionalParams hasRequiredParams
   */
  getContestsForProducts(id,opts){
    this.OAuth2.accessToken = localStorage.getItem('vue-token');
    return new Promise((resolve, reject) => {
      const callback = (error, data, responseString) => {
        const errorsFromHandler = errorHandler(error, data, responseString);
        if (error) {
          reject(error.message);
        } else if (errorsFromHandler.length) {
          const errors = errorsFromHandler.join(', ');
          reject(errors);
        } else {
          resolve(data);
        }
      };
      this.ziqniProductsApi.getContestsForProducts(id, opts, callback);
    });
  }

  /**
   * getProducts: Get products. Notes: Returns a list of Products. This assumes that products have first been uploaded via a POST request or web console
   *
   * BaseName: Products
   * Path: /products
   * Http Method: GET, Consumes: , Produces: [{mediaType&#x3D;application/json}]
   * ReturnType: ProductResponse, ReturnBaseType: ProductResponse, ReturnContainer: 
   *  
   * OptionalParams:
   *   ops { 
   *   - id [id]   >>> The unique identifiers of the resources
   *      |  dataType: [String], baseType: String, 
   *      |  isQueryParam: true, isCollectionFormatMulti: true, isExplode: true, collectionFormat: multi, 
   *      | 
   *  
   *   - limit [_limit] isIntegerisUnboundedInteger isPrimitiveType: true >>> Limit the returned total records found
   *      |  dataType: Number, 
   *      |  isQueryParam: true, isExplode: true, 
   *      | 
   *  
   *   - skip [_skip] isIntegerisUnboundedInteger isPrimitiveType: true >>> Skip the returned records found and return the next batch of records
   *      |  dataType: Number, 
   *      |  isQueryParam: true, isExplode: true, 
   *      | 
   *   }
   *
   * Constraints:
   * hasAuthMethods: true, hasConsumes: false, hasProduces: true, hasParams: true, hasOptionalParams: true,
   * hasRequiredParams: false, returnTypeIsPrimitive: false, returnSimpleType: true, subresourceOperation: false,
   * isMap: false, isArray: false, isMultipart: false, isResponseBinary: false, isResponseFile: false, hasReference: true,
   * hasDefaultResponse: false, hasErrorResponseObject: true, isRestfulIndex: true, isRestfulShow: false,
   * isRestfulCreate: false, isRestfulUpdate: false, isRestfulDestroy: false, isRestful: true, isDeprecated: false,
   * isCallbackRequest: false, uniqueItems: false hasOptionalParams 
   */
  getProducts(opts){
    this.OAuth2.accessToken = localStorage.getItem('vue-token');
    return new Promise((resolve, reject) => {
      const callback = (error, data, responseString) => {
        const errorsFromHandler = errorHandler(error, data, responseString);
        if (error) {
          reject(error.message);
        } else if (errorsFromHandler.length) {
          const errors = errorsFromHandler.join(', ');
          reject(errors);
        } else {
          resolve(data);
        }
      };
      this.ziqniProductsApi.getProducts(opts, callback);
    });
  }

  /**
   * getProductsByQuery: Get products by query. Notes: Retrieve Products from Ziqni database by unique Product ID&#39;s or any other POST body parameters using the POST method
   *
   * BaseName: Products
   * Path: /products/query
   * Http Method: POST, Consumes: [{mediaType&#x3D;application/json}], Produces: [{mediaType&#x3D;application/json}]
   * ReturnType: ProductResponse, ReturnBaseType: ProductResponse, ReturnContainer: 
   *  
   * OptionalParams:
   *   ops { 
   *   - body [body]  isModel: true >>> Retrieve Products from Ziqni database by unique Product ID&#39;s or any other POST body parameters using the POST method
   *      |  dataType: QueryRequest, baseType: QueryRequest, 
   *      |  isBodyParam: true, 
   *      | 
   *   }
   *
   * Constraints:
   * hasAuthMethods: true, hasConsumes: true, hasProduces: true, hasParams: true, hasOptionalParams: true,
   * hasRequiredParams: false, returnTypeIsPrimitive: false, returnSimpleType: true, subresourceOperation: false,
   * isMap: false, isArray: false, isMultipart: false, isResponseBinary: false, isResponseFile: false, hasReference: true,
   * hasDefaultResponse: false, hasErrorResponseObject: true, isRestfulIndex: false, isRestfulShow: false,
   * isRestfulCreate: false, isRestfulUpdate: false, isRestfulDestroy: false, isRestful: false, isDeprecated: false,
   * isCallbackRequest: false, uniqueItems: false hasOptionalParams 
   */
  getProductsByQuery(opts){
    this.OAuth2.accessToken = localStorage.getItem('vue-token');
    return new Promise((resolve, reject) => {
      const callback = (error, data, responseString) => {
        const errorsFromHandler = errorHandler(error, data, responseString);
        if (error) {
          reject(error.message);
        } else if (errorsFromHandler.length) {
          const errors = errorsFromHandler.join(', ');
          reject(errors);
        } else {
          resolve(data);
        }
      };
      this.ziqniProductsApi.getProductsByQuery(opts, callback);
    });
  }

  /**
   * getProductsByRefId: Get products by reference ids. Notes: Returns a list of Products. This assumes that products have first been uploaded via a POST request or web console
   *
   * BaseName: Products
   * Path: /products/find-by-ref-identifiers/
   * Http Method: GET, Consumes: , Produces: [{mediaType&#x3D;application/json}]
   * ReturnType: ProductResponse, ReturnBaseType: ProductResponse, ReturnContainer: 
   *  
   * OptionalParams:
   *   ops { 
   *   - refIds [refIds]   >>> The reference ids  of the resources
   *      |  dataType: [String], baseType: String, 
   *      |  isQueryParam: true, isCollectionFormatMulti: true, isExplode: true, collectionFormat: multi, 
   *      | 
   *  
   *   - limit [_limit] isIntegerisUnboundedInteger isPrimitiveType: true >>> Limit the returned total records found
   *      |  dataType: Number, 
   *      |  isQueryParam: true, isExplode: true, 
   *      | 
   *  
   *   - skip [_skip] isIntegerisUnboundedInteger isPrimitiveType: true >>> Skip the returned records found and return the next batch of records
   *      |  dataType: Number, 
   *      |  isQueryParam: true, isExplode: true, 
   *      | 
   *   }
   *
   * Constraints:
   * hasAuthMethods: true, hasConsumes: false, hasProduces: true, hasParams: true, hasOptionalParams: true,
   * hasRequiredParams: false, returnTypeIsPrimitive: false, returnSimpleType: true, subresourceOperation: false,
   * isMap: false, isArray: false, isMultipart: false, isResponseBinary: false, isResponseFile: false, hasReference: true,
   * hasDefaultResponse: false, hasErrorResponseObject: true, isRestfulIndex: false, isRestfulShow: false,
   * isRestfulCreate: false, isRestfulUpdate: false, isRestfulDestroy: false, isRestful: false, isDeprecated: false,
   * isCallbackRequest: false, uniqueItems: false hasOptionalParams 
   */
  getProductsByRefId(opts){
    this.OAuth2.accessToken = localStorage.getItem('vue-token');
    return new Promise((resolve, reject) => {
      const callback = (error, data, responseString) => {
        const errorsFromHandler = errorHandler(error, data, responseString);
        if (error) {
          reject(error.message);
        } else if (errorsFromHandler.length) {
          const errors = errorsFromHandler.join(', ');
          reject(errors);
        } else {
          resolve(data);
        }
      };
      this.ziqniProductsApi.getProductsByRefId(opts, callback);
    });
  }

  /**
   * updateProducts: Update products. Notes: Update existing Products in the Ziqni database
   *
   * BaseName: Products
   * Path: /products
   * Http Method: PUT, Consumes: [{mediaType&#x3D;application/json}, {mediaType&#x3D;file/csv}], Produces: [{mediaType&#x3D;application/json}]
   * ReturnType: ApiResponse, ReturnBaseType: ApiResponse, ReturnContainer: 
   *  RequiredParams:
   *   - body [body]   >>> Update a Product or multiple Products in the Ziqni database. * Product Ref Id can not be changed after creation * A products Id must exist in the Ziqni database to update the product
   *      |  dataType: [UpdateProductRequest], baseType: UpdateProductRequest, 
   *      |  isBodyParam: true, 
   *      | 
   * 
   *
   * Constraints:
   * hasAuthMethods: true, hasConsumes: true, hasProduces: true, hasParams: true, hasOptionalParams: false,
   * hasRequiredParams: true, returnTypeIsPrimitive: false, returnSimpleType: true, subresourceOperation: false,
   * isMap: false, isArray: false, isMultipart: false, isResponseBinary: false, isResponseFile: false, hasReference: true,
   * hasDefaultResponse: false, hasErrorResponseObject: true, isRestfulIndex: false, isRestfulShow: false,
   * isRestfulCreate: false, isRestfulUpdate: false, isRestfulDestroy: false, isRestful: false, isDeprecated: false,
   * isCallbackRequest: false, uniqueItems: false  hasRequiredParams
   */
  updateProducts(body){
    this.OAuth2.accessToken = localStorage.getItem('vue-token');
    return new Promise((resolve, reject) => {
      const callback = (error, data, responseString) => {
        const errorsFromHandler = errorHandler(error, data, responseString);
        if (error) {
          reject(error.message);
        } else if (errorsFromHandler.length) {
          const errors = errorsFromHandler.join(', ');
          reject(errors);
        } else {
          resolve(data);
        }
      };
      this.ziqniProductsApi.updateProducts(body, callback);
    });
  }

}

export const productsApiZiqni = new ProductsApiZiqni();
export default ProductsApiZiqni;
