// <<< DO NOT EDIT GENERATED FILES DIRECTLY! api-store-actions.mustache >>>
import { delay, cloneDeep } from 'lodash';
import * as types from './mutation-types';
import sdkApiConfig from '@/config/sdkApiConfig';
import updateFields from '@/utils/updateFields';
import { eventsApiZiqni } from '@/generated/ziqni/api/EventsApiZiqni';
import router from '@/router';

import apiResponseFields from '../../../fields/ApiResponseZq.js';
import createEventRequestFields from '../../../fields/CreateEventRequestZq.js';
import eventResponseFields from '../../../fields/EventResponseZq.js';
import queryRequestFields from '../../../fields/QueryRequestZq.js';
import {handleRequest,handleRequestWithBody} from "../../../../../utils/ZiqniAdminApiHandler";

export const handleFields = ({ commit, state }, data) => {
  updateFields(commit, state, data, 'hiddenEventsKey');
};

// Clear events Message
export const handleClearMessage = ({ commit }) => {
  commit(types.CLEAR_MESSAGE);
};

// clear filter
export const handleClearFilter = ({ commit }) => {
  commit(types.CLEAR_FILTER);
};

//EVENTS - EVENTS
export const handleEventsChanges = ({ commit }, data) => {
  commit('SET_EVENTS', data);
};
/**
 * EventResponse from backend.
 * GET getEvents and return [EventResponse]
 * vars: [
 *    [[id]] id[ opts, isArray, isQueryParam]
 *    [[limit]] _limit[ opts, isInteger, isQueryParam]
 *    [[skip]] _skip[ opts, isInteger, isQueryParam]]
 */
export const handleGetEvents_item = ({ commit }, idArray, limit, skip) => {
  commit(types.API_REQUEST);
  let opts = { 
    id: idArray, 
    limit: limit, 
    skip: skip
  };

  return eventsApiZiqni
    .getEvents(
      opts
    )
    .then((response) => {
      commit(types.GETEVENTS_ITEM_SUCCESS, { response });
      return response.results;
    })
    .catch((e) => {
      commit(types.GETEVENTS_ITEM_ERROR, e);
      return [];
    });
};

/**
 * ApiResponse from backend.
 * POST createEvents and return [ApiResponse]
 * vars: [
 *    [[body]] CreateEventRequest[ opts, isArray, isBodyParam, ]]
 */
export const handleCreateEvents = ({ commit }, payload) => {
  const { createEventRequestArray } = payload;

  commit(types.API_REQUEST);
  let opts = { 
    body: createEventRequestArray
  };

  return eventsApiZiqni
    .createEvents(
      opts
    )
    .then((response) => {
      commit(types.CREATEEVENTS_SUCCESS, { response });
      return response.results;
    })
    .catch((e) => {
      commit(types.CREATEEVENTS_ERROR, e);
      return [];
    });
};

/**
 * EventResponse from backend.
 * GET getEvents and return [EventResponse]
 * vars: [
 *    [[id]] id[ opts, isArray, isQueryParam]
 *    [[limit]] _limit[ opts, isInteger, isQueryParam]
 *    [[skip]] _skip[ opts, isInteger, isQueryParam]]
 */
export const handleGetEvents = ({ commit }, payload) => {
  const { idArray, limit, skip } = payload;

  commit(types.API_REQUEST);
  let opts = { 
    id: idArray, 
    limit: limit, 
    skip: skip
  };

  return eventsApiZiqni
    .getEvents(
      opts
    )
    .then((response) => {
      commit(types.GETEVENTS_SUCCESS, { response });
      return response.results;
    })
    .catch((e) => {
      commit(types.GETEVENTS_ERROR, e);
      return [];
    });
};

/**
 * EventResponse from backend.
 * POST getEventsByQuery and return [EventResponse]
 * vars: [
 *    [[body]] QueryRequest[ opts, isBodyParam, ]]
 */
export const handleGetEventsByQuery = ({ commit }, payload) => {
  const { queryRequest } = payload;

  commit(types.API_REQUEST);
  let opts = { 
    body: queryRequest
  };

  return eventsApiZiqni
    .getEventsByQuery(
      opts
    )
    .then((response) => {
      commit(types.GETEVENTSBYQUERY_SUCCESS, { response });
      return response.results;
    })
    .catch((e) => {
      commit(types.GETEVENTSBYQUERY_ERROR, e);
      return [];
    });
};
