// <<< DO NOT EDIT GENERATED FILES DIRECTLY! model-store-fields-all.mustache >>>


import awardScheduling from './AwardSchedulingZq';
import createOptParamModels from './CreateOptParamModelsZq';
import translation from './TranslationZq';

const createRewardTypeRequest = this;

export default {
  objectType: 'CreateRewardTypeRequest',
  allowableValuesKeys: [],
  constraints: [],
  getter: null,
  hiddenFields: [],
  searchableFields: [], 
  searchableAllFields: [],
  translatableFields: [],
  sortableFields: [],
  customFieldsFieldName: ['customFields',],
  metadataFieldName: ['metadata'],
  tagFieldName: ['tags'],
  entityLinksFrom: [],

  route: {
    
    
  },
  fieldList: ['customFields','tags','metadata','name','description','key','translations','addConstraints','unitOfMeasure','scheduling'],
  fields: { 
    customFields: {
      name: 'customFields',
      complexType: null,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['isCustomFieldsArray','isPrimitiveType','isContainer','isFreeFormObject','isMap',
                    'isNullable', ],
      formType: 'CUSTOM',
    },
    tags: {
      name: 'tags',
      complexType: null,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['isTagsArray','isPrimitiveType','isContainer','isArray',
                    'isString', ],
      formType: 'TAG',
    },
    metadata: {
      name: 'metadata',
      complexType: null,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['isMetadataMap','isPrimitiveType','isContainer','isMap',
                    'isString', ],
      formType: 'META',
    },
    name: {
      name: 'name',
      complexType: null,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['required','isPrimitiveType','isString',
                     ],
      formType: null,
    },
    description: {
      name: 'description',
      complexType: null,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['isPrimitiveType','isString',
                     ],
      formType: 'TEXTAREA',
    },
    key: {
      name: 'key',
      complexType: null,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['required','isPrimitiveType','isString',
                     ],
      formType: 'NAMEKEY, showKey: true',
    },
    translations: {
      name: 'translations',
      complexType: translation,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['isContainer','isArray',
                    'isModel', ],
      formType: null,
    },
    addConstraints: {
      name: 'addConstraints',
      complexType: null,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['isPrimitiveType','isContainer','isArray',
                    'isString', ],
      formType: null,
    },
    unitOfMeasure: {
      name: 'unitOfMeasure',
      complexType: null,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['required','isPrimitiveType','isString',
                     ],
      formType: null,
    },
    scheduling: {
      name: 'scheduling',
      complexType: awardScheduling,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['isContainer','isArray',
                    'isModel', ],
      formType: null,
    }
  }
};
