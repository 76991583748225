const Competitions = () => import('@/views/ziqni/competitions');
const SimpleCompetition = () => import('@/views/ziqni/competitions/CreateSimpleCompetition');
const AdvancedCompetition = () => import('@/views/ziqni/competitions/CreateAdvancedCompetition');
const CreateContest = () => import('@/views/ziqni/competitions/create/contest');
// const PreviewContest = () => import('@/views/ziqni/competitions/create/contest/PreviewContest');
const PreviewContest = () => import('@/views/ziqni/competitions/Preview/details/ContestsDetail/PreviewContest');
const PreviewCompetition = () => import('@/views/ziqni/competitions/Preview');
const EditCompetition = () => import('@/views/ziqni/competitions/EditCompetition');
const EditContest = () => import('@/views/ziqni/competitions/EditContest');
// const LeaderboardDetail = () => import ('@/views/ziqni/competitions/Preview/details/LeaderboardDetail');
const ContestLeaderboard = () => import ('@/views/ziqni/competitions/Preview/details/ContestsDetail/ContestLeaderboard');

export default {
  path: 'competitions/',
  component: {
    render(c) {
      return c('router-view');
    },
  },
  children: [
    {
      path: '',
      name: 'Competitions',
      component: Competitions,
    },
    {
      path: 'new-simple-competition',
      name: 'SimpleCompetition',
      component: SimpleCompetition,
      meta: {
        label: 'Create Simple Competition',
      },
    },
    {
      path: 'new-advanced-competition',
      name: 'AdvancedCompetition',
      component: AdvancedCompetition,
      meta: {
        label: 'Advanced Simple Competition',
      },
    },
    {
      path: 'new-advanced-competition',
      name: 'CreateCompetition',
      component: AdvancedCompetition,
      meta: {
        label: 'Advanced Simple Competition',
      },
    },
    {
      path: 'detail/:id/new-contest',
      name: 'CreateContest',
      component: CreateContest,
      meta: {
        label: 'Create Contest',
      },
    },
    {
      path: 'detail/:id/clone/:cloneId',
      name: 'CloneContest',
      component: CreateContest,
      meta: {
        label: 'Clone Contest',
      },
    },
    {
      path: 'detail/:id/contest/:contestId',
      name: 'PreviewContest',
      component: PreviewContest,
      meta: {
        label: 'Preview Contest',
      },
    },
    {
      path: 'detail/:id/leaderboard/:contestId/',
      name: 'ContestLeaderboard',
      component: ContestLeaderboard,
      meta: {
        label: 'Leaderboard',
      },
    },
    {
      path: 'edit-competition/:id',
      name: 'EditCompetition',
      component: EditCompetition,
    },
    {
      path: 'edit-contest/:id',
      name: 'EditContest',
      component: EditContest,
    },
    {
      path: 'detail/:id',
      name: 'PreviewCompetition',
      component: PreviewCompetition,
    },
  ],
}
