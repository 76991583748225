import * as types from './mutation-types';
import updateFields from '@/utils/updateFields';
import { achievementsApiZiqni } from '@/generated/ziqni/api/AchievementsApiZiqni';

export const handleFields = ({ commit, state }, data) => {
  updateFields(commit, state, data, 'hiddenAchievementsKey');
};

// Clear achievements Message
export const handleClearMessage = ({ commit }) => {
  commit(types.CLEAR_MESSAGE);
};

// clear filter
export const handleClearFilter = ({ commit }) => {
  commit(types.CLEAR_FILTER);
};

export const handleGetMissions_item = ({ commit }, idArray, limit, skip) => {
  commit(types.API_REQUEST);
  let opts = {
    id: idArray,
    limit: limit,
    skip: skip
  };

  return achievementsApiZiqni
    .getAchievements(
      opts
    )
    .then((response) => {
      commit(types.GETACHIEVEMENTS_ITEM_SUCCESS, { response });
      return response.results;
    })
    .catch((e) => {
      commit(types.GETACHIEVEMENTS_ITEM_ERROR, e);
      return [];
    });
};

export const handleGetMissionsByQuery = ({ commit }, payload) => {
  const { queryRequest } = payload;
  queryRequest.constraints = ['mission'];

  commit(types.API_REQUEST);
  let opts = {
    body: queryRequest
  };

  return achievementsApiZiqni
    .getAchievementsByQuery(
      opts
    )
    .then((response) => {
      commit(types.GETACHIEVEMENTSBYQUERY_SUCCESS, { response });
      return response.results;
    })
    .catch((e) => {
      commit(types.GETACHIEVEMENTSBYQUERY_ERROR, e);
      return [];
    });
};
