// <<< DO NOT EDIT GENERATED FILES DIRECTLY! model-store-fields-all.mustache >>>


import sourceLanguage from './SourceLanguageZq';

const transformerAllOf = this;

export default {
  objectType: 'Transformer_allOf',
  allowableValuesKeys: [],
  constraints: ['isMap',],
  getter: null,
  hiddenFields: ['source',],
  searchableFields: ['name','source',], 
  searchableAllFields: ['name','source',],
  translatableFields: [],
  sortableFields: ['name',],
  customFieldsFieldName: [],
  metadataFieldName: [],
  tagFieldName: [],
  entityLinksFrom: [],

  route: {
    
    
  },
  fieldList: ['name','source','sourceLanguage'],
  fields: { 
    name: {
      name: 'name',
      complexType: null,
      displayOrder: 1000,
      allowableValuesKeys: [],
      constraints: ['required','isPrimitiveType','isString',
                     ],
      formType: null,
    },
    source: {
      name: 'source',
      complexType: null,
      displayOrder: 1002,
      allowableValuesKeys: [],
      constraints: ['required','isPrimitiveType','isString',
                     ],
      formType: null,
    },
    sourceLanguage: {
      name: 'sourceLanguage',
      complexType: sourceLanguage,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['required',
                     ],
      formType: null,
    }
  }
};
