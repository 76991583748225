 // <<< DO NOT EDIT GENERATED FILES DIRECTLY! >>>
import ziqniAdminApiFields from './fields';
import mutations from './mutations';
import * as actions from './actions';
import * as getters from './getters';

//actionTypes
const initialState = {

  
  
  
  actionTypes: [],
  actionType: {},
  
  
  
  pages: 0,
  resultCount: 0,
  totalRecords: 0,
  hiddenFields: ziqniAdminApiFields.baseFields.hiddenFields,
  originHiddenFields: ziqniAdminApiFields.baseFields.hiddenFields,
  fields: ziqniAdminApiFields.baseFields.fieldList,
  sortableFields: ziqniAdminApiFields.baseFields.sortableFields,
  searchableAllFields: ziqniAdminApiFields.baseFields.searchableAllFields,
  originalFields: ziqniAdminApiFields.baseFields.fieldList,
  meta: {},
  loading: false,
  loaded: true,
  success: false,
  message: null,
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations,
};