import './assets/scss/mainTheme/mainTheme.scss';
import nav from './data/_nav';

const themes = ['default', 'main'];

export default {
    install(Vue, {theme = 'default', store}) {
        this.install.instaled = false;

        Vue.mixin({
            created: () => {
                const themeValue = theme.toLowerCase();

                if (this.install.instaled) return;

                if (!themes.includes(themeValue)) {
                    console.error(`Theme type error -> '${theme}'!!!.Expected values 'default' or 'main'!.`);

                    this.install.instaled = true;
                    return;
                }
                // SET THEME CLASS
                if (themeValue === 'main') {
                    document.body.classList.add('c-nxt-app__main-theme');
                }
                // INIT STORE
                store.dispatch('theme/setTheme', themeValue)
                    .then(() => {
                        store.dispatch('theme/getTheme');
                    })
                    .then(() => {
                        store.dispatch('theme/setMainNavItems', nav);
                    });

                this.install.instaled = true;
            }
        })
    }
}