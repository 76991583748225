// <<< DO NOT EDIT GENERATED FILES DIRECTLY! model-store-fields-all.mustache >>>


import translationEntry from './TranslationEntryZq';

const translation = this;

export default {
  objectType: 'Translation',
  allowableValuesKeys: [],
  constraints: ['isMap',],
  getter: 'translations',
  hiddenFields: ['accountId','created','translations',],
  searchableFields: ['id','entityId','entityType','created','translations','languageKey',], 
  stringBuilderFields: ['entityType','languageKey',],
  operations: ['Update'], 
  searchableAllFields: ['entityId','entityType','created','translations','languageKey',],
  translatableFields: [],
  sortableFields: [],
  customFieldsFieldName: [],
  metadataFieldName: [],
  tagFieldName: [],
  entityLinksFrom: [],

  route: {
    list: 'ListTranslation',
    preview: 'PreviewTranslation',
    update: 'UpdateTranslation',
  },
  skip: 0,
  limit: 10,
  sortBy: [
    {
      "queryField": "languageKey",
      "order": "Asc"
    }
  ],
  fieldList: ['id','accountId','entityId','entityType','version','created','translations','languageKey'],
  fields: { 
    id: {
      name: 'id',
      complexType: null,
      displayOrder: 1000,
      allowableValuesKeys: [],
      constraints: ['required','isPrimitiveType','isString',
                     ],
      formType: null,
    },
    accountId: {
      name: 'accountId',
      complexType: null,
      displayOrder: 1100,
      allowableValuesKeys: [],
      constraints: ['required','isPrimitiveType','isString',
                     ],
      formType: null,
    },
    entityId: {
      name: 'entityId',
      complexType: null,
      displayOrder: 1003,
      allowableValuesKeys: [],
      constraints: ['required','isPrimitiveType','isString',
                     ],
      formType: null,
    },
    entityType: {
      name: 'entityType',
      complexType: null,
      displayOrder: 1004,
      allowableValuesKeys: [],
      constraints: ['required','isPrimitiveType','isString',
                     ],
      formType: null,
    },
    version: {
      name: 'version',
      complexType: null,
      displayOrder: 1099,
      allowableValuesKeys: [],
      constraints: ['required','isPrimitiveType','isNumeric','isLong',
                     ],
      formType: null,
    },
    created: {
      name: 'created',
      complexType: null,
      displayOrder: 1098,
      allowableValuesKeys: [],
      constraints: ['required','isPrimitiveType','isDateTime',
                     ],
      formType: null,
    },
    translations: {
      name: 'translations',
      complexType: translationEntry,
      displayOrder: 1005,
      allowableValuesKeys: [],
      constraints: ['required','isContainer','isArray',
                    'isModel', ],
      formType: null,
    },
    languageKey: {
      name: 'languageKey',
      complexType: null,
      displayOrder: 1001,
      allowableValuesKeys: [],
      constraints: ['required','isPrimitiveType','isString',
                     ],
      formType: null,
    }
  }
};
