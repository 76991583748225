// <<< DO NOT EDIT GENERATED FILES DIRECTLY! api-store-mutations.mustache >>>
import * as types from './mutation-types';
import * as _ from 'lodash';
import {
  handleErrorResponse, handleItemErrorResponse,
  handleItemSuccessResponse,
  handleSuccessResponse
} from "@/utils/ZiqniAdminApiHandler";
import achievementResponseFields from '@/generated/ziqni/fields/AchievementResponseZq.js';
const mutations = {
  [types.API_REQUEST](state) {
    state.loading = true;
    state.loaded = false;
  },
  [types.CLEAR_MESSAGE](state){
    state.message = null;
  },

  [types.UPDATE_FIELDS](state, data) {
    state.hiddenFields = data._hiddenFields;
    state.fields = _.difference(state.originalFields, state.hiddenFields);
    localStorage.setItem('hiddenAchievementsKey', JSON.stringify(state.hiddenFields));
  },

  [types.CLEAR_FILTER](state) {
    state.fields = _.difference(
      state.originalFields,
      state.originHiddenFields
    );
    state.hiddenFields = state.originHiddenFields;
    localStorage.removeItem('hiddenAchievementsKey');
  },

  //AchievementResponse
  [types.GETACHIEVEMENTS_ITEM_SUCCESS](state, response) {
    handleItemSuccessResponse(types.GETACHIEVEMENTS_ITEM_SUCCESS, achievementResponseFields, state, response, 'mission');
  },

  //AchievementResponse
  [types.GETACHIEVEMENTS_ERROR](state, error) {
    handleItemErrorResponse(types.GETACHIEVEMENTS_ITEM_ITEM_ERROR, achievementResponseFields, state, error);
  },

  //AchievementResponse
  [types.GETACHIEVEMENTSBYQUERY_SUCCESS](state, response) {
    const pages = Math.ceil(response.response.meta.totalRecords / response.response.meta.limit);
    state.missions = response.response.results;
    state.fieldDescriptor = _.difference(state.originalFields, state.hiddenFields);
    state.pages = pages;
    state.meta = response.response.meta;
    state.resultCount = response.response.meta.resultCount;
    state.totalRecords = response.response.meta.totalRecords;
    state.loading = false;
    state.loaded = true;
  },

  //AchievementResponse
  [types.GETACHIEVEMENTSBYQUERY_ERROR](state, error) {
    handleErrorResponse(types.GETACHIEVEMENTSBYQUERY_ERROR, achievementResponseFields, state, error);
  },
};

export default mutations;
