import * as types from './mutation-types';
import {leaderboardApiZiqni} from '@/generated/ziqni/api/LeaderboardApiZiqni';
import * as _ from "lodash";

export const handleGetLeaderboardById = ({commit}, data) => {
  commit(types.API_REQUEST);

  const {id, skip, limit} = data;

  const opts = {
    limit: limit,
    skip: skip,
  };

  return leaderboardApiZiqni.getLeaderboardById(id, opts)
    .then((response) => {
      commit(types.GET_LEADERBOARD_SUCCESS, { response });
      return response.results;
    })
    .catch((e) => {
      commit(types.GET_LEADERBOARD_ERROR, e);
      return [];
    });
};


// show/hide header of table
export const handleLeaderboardFields = ({ commit, state }, data) => {
  if (data) {
    const { hiddenFields } = state;
    const _hiddenFields = _.cloneDeep(hiddenFields);
    const hiddenIndex = _hiddenFields.indexOf(data);
    if (hiddenIndex > -1) {
      _hiddenFields.splice(hiddenIndex, 1);
    } else {
      _hiddenFields.push(data);
    }
    commit(types.UPDATE_FIELDS, { _hiddenFields });
  } else {
    const preHiddenFields =
      localStorage.getItem('hiddenKey') &&
      JSON.parse(localStorage.getItem('hiddenKey'));
    preHiddenFields &&
    commit(types.UPDATE_FIELDS, {
      _hiddenFields: preHiddenFields,
    });
  }
};

export const handleClearFilter = ({ commit }) => {
  commit(types.CLEAR_FILTER);
};

export const handleClearMessage = ({commit}) => {
  commit(types.CLEAR_MESSAGE)
}

