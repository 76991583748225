// <<< DO NOT EDIT GENERATED FILES DIRECTLY! api-store-mutation-types.mustache >>>
export const API_REQUEST = 'API_REQUEST';


export const GETCOMPETITIONS_ITEM_REQ = 'GETCOMPETITIONS_ITEM_REQ';
export const GETCOMPETITIONS_ITEM_ERROR = 'GETCOMPETITIONS_ITEM_ERROR';
export const GETCOMPETITIONS_ITEM_SUCCESS = 'GETCOMPETITIONS_ITEM_SUCCESS'

export const UPDATE_FIELDS = 'UPDATE_FIELDS';
export const CLEAR_FILTER = 'CLEAR_FILTER';
export const CLEAR_MESSAGE = 'CLEAR_MESSAGE';

export const CREATECOMPETITIONS_REQ = 'CREATECOMPETITIONS_REQ';
export const CREATECOMPETITIONS_ERROR = 'CREATECOMPETITIONS_ERROR';
export const CREATECOMPETITIONS_SUCCESS = 'CREATECOMPETITIONS_SUCCESS';

export const CREATESIMPLECOMPETITIONS_REQ = 'CREATESIMPLECOMPETITIONS_REQ';
export const CREATESIMPLECOMPETITIONS_ERROR = 'CREATESIMPLECOMPETITIONS_ERROR';
export const CREATESIMPLECOMPETITIONS_SUCCESS = 'CREATESIMPLECOMPETITIONS_SUCCESS';

export const GETCOMPETITIONS_REQ = 'GETCOMPETITIONS_REQ';
export const GETCOMPETITIONS_ERROR = 'GETCOMPETITIONS_ERROR';
export const GETCOMPETITIONS_SUCCESS = 'GETCOMPETITIONS_SUCCESS';

export const GETCOMPETITIONSBRACKETS_REQ = 'GETCOMPETITIONSBRACKETS_REQ';
export const GETCOMPETITIONSBRACKETS_ERROR = 'GETCOMPETITIONSBRACKETS_ERROR';
export const GETCOMPETITIONSBRACKETS_SUCCESS = 'GETCOMPETITIONSBRACKETS_SUCCESS';

export const GETCOMPETITIONSBYQUERY_REQ = 'GETCOMPETITIONSBYQUERY_REQ';
export const GETCOMPETITIONSBYQUERY_ERROR = 'GETCOMPETITIONSBYQUERY_ERROR';
export const GETCOMPETITIONSBYQUERY_SUCCESS = 'GETCOMPETITIONSBYQUERY_SUCCESS';

export const GETCOMPETITIONSTOCLONE_REQ = 'GETCOMPETITIONSTOCLONE_REQ';
export const GETCOMPETITIONSTOCLONE_ERROR = 'GETCOMPETITIONSTOCLONE_ERROR';
export const GETCOMPETITIONSTOCLONE_SUCCESS = 'GETCOMPETITIONSTOCLONE_SUCCESS';

export const GETCONTESTSFORCOMPETITIONS_REQ = 'GETCONTESTSFORCOMPETITIONS_REQ';
export const GETCONTESTSFORCOMPETITIONS_ERROR = 'GETCONTESTSFORCOMPETITIONS_ERROR';
export const GETCONTESTSFORCOMPETITIONS_SUCCESS = 'GETCONTESTSFORCOMPETITIONS_SUCCESS';

export const GETLEADERBOARDBYCOMPETITIONID_REQ = 'GETLEADERBOARDBYCOMPETITIONID_REQ';
export const GETLEADERBOARDBYCOMPETITIONID_ERROR = 'GETLEADERBOARDBYCOMPETITIONID_ERROR';
export const GETLEADERBOARDBYCOMPETITIONID_SUCCESS = 'GETLEADERBOARDBYCOMPETITIONID_SUCCESS';

export const GETLISTOFENTRANTS_REQ = 'GETLISTOFENTRANTS_REQ';
export const GETLISTOFENTRANTS_ERROR = 'GETLISTOFENTRANTS_ERROR';
export const GETLISTOFENTRANTS_SUCCESS = 'GETLISTOFENTRANTS_SUCCESS';

export const UPDATECOMPETITIONS_REQ = 'UPDATECOMPETITIONS_REQ';
export const UPDATECOMPETITIONS_ERROR = 'UPDATECOMPETITIONS_ERROR';
export const UPDATECOMPETITIONS_SUCCESS = 'UPDATECOMPETITIONS_SUCCESS';

export const UPDATECOMPETITIONSENTRIES_REQ = 'UPDATECOMPETITIONSENTRIES_REQ';
export const UPDATECOMPETITIONSENTRIES_ERROR = 'UPDATECOMPETITIONSENTRIES_ERROR';
export const UPDATECOMPETITIONSENTRIES_SUCCESS = 'UPDATECOMPETITIONSENTRIES_SUCCESS';

export const UPDATECOMPETITIONSSTATE_REQ = 'UPDATECOMPETITIONSSTATE_REQ';
export const UPDATECOMPETITIONSSTATE_ERROR = 'UPDATECOMPETITIONSSTATE_ERROR';
export const UPDATECOMPETITIONSSTATE_SUCCESS = 'UPDATECOMPETITIONSSTATE_SUCCESS';

