// <<< DO NOT EDIT GENERATED FILES DIRECTLY! model-store-fields-all.mustache >>>



const messageType = this;

export default {
  objectType: 'MessageType',
  allowableValuesKeys: ['Notification','InboxItem','NotificationInboxItem'],
  constraints: ['isString','isEnum',],
  getter: null,
  hiddenFields: [],
  searchableFields: [],
  searchableAllFields: [],
  translatableFields: [],
  sortableFields: [],
  customFieldsFieldName: [],
  metadataFieldName: [],
  tagFieldName: [],
  entityLinksFrom: [],

  route: {


  },
  fieldList: [],
  fields: {
  }
};
