import mutations from './mutations';
import * as actions from './actions';
import * as getters from './getters';

const initialState = {
  connections: [],
  connection: {},
  status:'',
  connectionType:'',
  transformers :[],
  pages: 0,
  resultCount: 0,
  hiddenFields: ['spaceName', 'objectType'],
  originHiddenFields: ['spaceName'],
  fields: [],
  originalFields: [],
  meta: {},
  loading: false,
  loaded: true,
  success: false,
  message: null,
  sortableFields: ['tags',],
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations,
};
