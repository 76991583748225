import * as types from './mutation-types';

const mutations = {
  [types.HANDLE_PATH](state, data) {
    state.fullPath = data.fullPath;
    state.name = data.name;
    state.params = data.params;
    state.mainPath = data.path;
    state.breadcrumb = data.breadcrumb;
  },
};

export default mutations;
