// <<< DO NOT EDIT GENERATED FILES DIRECTLY! api-store-mutations.mustache >>>
import * as types from './mutation-types';
import * as _ from 'lodash';
import {
  handleErrorResponse, handleItemErrorResponse,
  handleItemSuccessResponse,
  handleSuccessResponse
} from "../../../../../utils/ZiqniAdminApiHandler";
import actionTypeResponseFields from '../../../fields/ActionTypeResponseZq.js';
import apiResponseFields from '../../../fields/ApiResponseZq.js';
import createActionTypeRequestFields from '../../../fields/CreateActionTypeRequestZq.js';
import queryRequestFields from '../../../fields/QueryRequestZq.js';
import updateActionTypeRequestFields from '../../../fields/UpdateActionTypeRequestZq.js';

const mutations = {
    [types.API_REQUEST](state) {
        state.loading = true;
        state.loaded = false;
    },
    [types.CLEAR_MESSAGE](state){
        state.message = null;
    },

    [types.UPDATE_FIELDS](state, data) {
        state.hiddenFields = data._hiddenFields;
        state.fields = _.difference(state.originalFields, state.hiddenFields);
        localStorage.setItem('hiddenActionTypesKey', JSON.stringify(state.hiddenFields));
    },

    [types.CLEAR_FILTER](state) {
        state.fields = _.difference(
            state.originalFields,
            state.originHiddenFields
        );
        state.hiddenFields = state.originHiddenFields;
        localStorage.removeItem('hiddenActionTypesKey');
    },

    //ActionTypeResponse
    [types.GETACTIONTYPES_ITEM_SUCCESS](state, response) {
      handleItemSuccessResponse(types.GETACTIONTYPES_ITEM_SUCCESS,actionTypeResponseFields, state, response, 'actionType');
    },

    //ActionTypeResponse
    [types.GETACTIONTYPES_ERROR](state, error) {
      handleItemErrorResponse(types.GETACTIONTYPES_ITEM_ITEM_ERROR, actionTypeResponseFields, state, error);
    },


    //ApiResponse
    [types.CREATEACTIONTYPES_SUCCESS](state, response) {
      handleSuccessResponse(types.CREATEACTIONTYPES_SUCCESS,apiResponseFields, state, response);
    },

    //ApiResponse
    [types.CREATEACTIONTYPES_ERROR](state, error) {
      handleErrorResponse(types.CREATEACTIONTYPES_ERROR, apiResponseFields, state, error);
    },

    //ApiResponse
    [types.DELETEACTIONTYPES_SUCCESS](state, response) {
      handleSuccessResponse(types.DELETEACTIONTYPES_SUCCESS,apiResponseFields, state, response);
    },

    //ApiResponse
    [types.DELETEACTIONTYPES_ERROR](state, error) {
      handleErrorResponse(types.DELETEACTIONTYPES_ERROR, apiResponseFields, state, error);
    },

    //ApiResponse
    [types.DELETEACTIONTYPESBYQUERY_SUCCESS](state, response) {
      handleSuccessResponse(types.DELETEACTIONTYPESBYQUERY_SUCCESS,apiResponseFields, state, response);
    },

    //ApiResponse
    [types.DELETEACTIONTYPESBYQUERY_ERROR](state, error) {
      handleErrorResponse(types.DELETEACTIONTYPESBYQUERY_ERROR, apiResponseFields, state, error);
    },

    //ActionTypeResponse
    [types.GETACTIONTYPES_SUCCESS](state, response) {
      handleSuccessResponse(types.GETACTIONTYPES_SUCCESS,actionTypeResponseFields, state, response);
    },

    //ActionTypeResponse
    [types.GETACTIONTYPES_ERROR](state, error) {
      handleErrorResponse(types.GETACTIONTYPES_ERROR, actionTypeResponseFields, state, error);
    },

    //ActionTypeResponse
    [types.GETACTIONTYPESBYQUERY_SUCCESS](state, response) {
      handleSuccessResponse(types.GETACTIONTYPESBYQUERY_SUCCESS,actionTypeResponseFields, state, response);
    },

    //ActionTypeResponse
    [types.GETACTIONTYPESBYQUERY_ERROR](state, error) {
      handleErrorResponse(types.GETACTIONTYPESBYQUERY_ERROR, actionTypeResponseFields, state, error);
    },

    //ApiResponse
    [types.UPDATEACTIONTYPES_SUCCESS](state, response) {
      handleSuccessResponse(types.UPDATEACTIONTYPES_SUCCESS,apiResponseFields, state, response);
    },

    //ApiResponse
    [types.UPDATEACTIONTYPES_ERROR](state, error) {
      handleErrorResponse(types.UPDATEACTIONTYPES_ERROR, apiResponseFields, state, error);
    },
};

export default mutations;
