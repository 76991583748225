// <<< DO NOT EDIT GENERATED FILES DIRECTLY! model-store-fields-all.mustache >>>


import conditionalOperator from './ConditionalOperatorZq';

const pointsStrategy = this;

export default {
  objectType: 'PointsStrategy',
  allowableValuesKeys: [],
  constraints: ['isMap',],
  getter: null,
  hiddenFields: [],
  searchableFields: [], 
  searchableAllFields: [],
  translatableFields: [],
  sortableFields: [],
  customFieldsFieldName: [],
  metadataFieldName: [],
  tagFieldName: [],
  entityLinksFrom: [],

  route: {
    
    
  },
  fieldList: ['operator','pointsValueUpper','pointsValue'],
  fields: { 
    operator: {
      name: 'operator',
      complexType: conditionalOperator,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['required',
                     ],
      formType: null,
    },
    pointsValueUpper: {
      name: 'pointsValueUpper',
      complexType: null,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['isPrimitiveType','isNumeric','isNumber',
                     ],
      formType: null,
    },
    pointsValue: {
      name: 'pointsValue',
      complexType: null,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['isPrimitiveType','isNumeric','isNumber',
                     ],
      formType: null,
    }
  }
};
