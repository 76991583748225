const Draft = () => import('@/views/ziqni/messages/draft')
const PreviewDraftMessage = () => import('@/views/ziqni/messages/draft/PreviewDraftMessage')
const CreateDraftMessage = () => import('@/views/ziqni/messages/draft/create')
const EditDraftMessage = () => import('@/views/ziqni/messages/draft/edit')

export default {
  path: 'draft',
  meta: {
    label: 'Messages'
  },
  component: {
    render(c) {
      return c('router-view')
    }
  },
  children: [
    {
      path: '/',
      name: 'Draft',
      component: Draft,

      meta: {
        label: 'Draft',
      },
    },
    {
      path: 'create-draft-message',
      name: 'CreateDraft',
      component: CreateDraftMessage,
      meta: {
        label: 'Create Draft',
      },
    },
    {
      path: ':id',
      name: 'EditDraft',
      component: EditDraftMessage,
      meta: {
        label: 'Edit Draft',
      },
    },
    {
      path: ':id',
      name: 'PreviewDraftMessage',
      component: PreviewDraftMessage,
      meta: {
        label: 'Preview Draft Message',
      },
    },
  ],
}