// <<< DO NOT EDIT GENERATED FILES DIRECTLY! api-store-actions.mustache >>>
import { delay, cloneDeep } from 'lodash';
import * as types from './mutation-types';
import sdkApiConfig from '@/config/sdkApiConfig';
import updateFields from '@/utils/updateFields';
import { translationsApiZiqni } from '@/generated/ziqni/api/TranslationsApiZiqni';
import router from '@/router';

import apiResponseFields from '../../../fields/ApiResponseZq.js';
import createTranslationRequestFields from '../../../fields/CreateTranslationRequestZq.js';
import queryRequestFields from '../../../fields/QueryRequestZq.js';
import translationResponseFields from '../../../fields/TranslationResponseZq.js';
import updateTranslationRequestFields from '../../../fields/UpdateTranslationRequestZq.js';
import {handleRequest,handleRequestWithBody} from "../../../../../utils/ZiqniAdminApiHandler";

export const handleFields = ({ commit, state }, data) => {
  updateFields(commit, state, data, 'hiddenTranslationsKey');
};

// Clear translations Message
export const handleClearMessage = ({ commit }) => {
  commit(types.CLEAR_MESSAGE);
};

// clear filter
export const handleClearFilter = ({ commit }) => {
  commit(types.CLEAR_FILTER);
};

//TRANSLATIONS - TRANSLATIONS
export const handleTranslationsChanges = ({ commit }, data) => {
  commit('SET_TRANSLATIONS', data);
};
/**
 * TranslationResponse from backend.
 * GET getTranslations and return [TranslationResponse]
 * vars: [
 *    [[id]] id[ opts, isArray, isQueryParam]
 *    [[limit]] _limit[ opts, isInteger, isQueryParam]
 *    [[skip]] _skip[ opts, isInteger, isQueryParam]]
 */
export const handleGetTranslations_item = ({ commit }, idArray, limit, skip) => {
  commit(types.API_REQUEST);
  let opts = { 
    id: idArray, 
    limit: limit, 
    skip: skip
  };

  return translationsApiZiqni
    .getTranslations(
      opts
    )
    .then((response) => {
      commit(types.GETTRANSLATIONS_ITEM_SUCCESS, { response });
      return response.results;
    })
    .catch((e) => {
      commit(types.GETTRANSLATIONS_ITEM_ERROR, e);
      return [];
    });
};

/**
 * ApiResponse from backend.
 * POST createTranslations and return [ApiResponse]
 * vars: [
 *    [[createTranslationRequest]] CreateTranslationRequest[ required, isArray, isBodyParam, ]]
 */
export const handleCreateTranslations = ({ commit }, payload) => {
  const { createTranslationRequestArray } = payload;

  commit(types.API_REQUEST);
  

  return translationsApiZiqni
    .createTranslations(
      createTranslationRequestArray
    )
    .then((response) => {
      commit(types.CREATETRANSLATIONS_SUCCESS, { response });
      return response.results;
    })
    .catch((e) => {
      commit(types.CREATETRANSLATIONS_ERROR, e);
      return [];
    });
};

/**
 * ApiResponse from backend.
 * DELETE deleteTranslations and return [ApiResponse]
 * vars: [
 *    [[id]] id[ opts, isArray, isQueryParam]]
 */
export const handleDeleteTranslations = ({ commit }, payload) => {
  const { idArray } = payload;

  commit(types.API_REQUEST);
  let opts = { 
    id: idArray
  };

  return translationsApiZiqni
    .deleteTranslations(
      opts
    )
    .then((response) => {
      commit(types.DELETETRANSLATIONS_SUCCESS, { response });
      return response.results;
    })
    .catch((e) => {
      commit(types.DELETETRANSLATIONS_ERROR, e);
      return [];
    });
};

/**
 * ApiResponse from backend.
 * POST deleteTranslationsByQuery and return [ApiResponse]
 * vars: [
 *    [[body]] QueryRequest[ opts, isBodyParam, ]]
 */
export const handleDeleteTranslationsByQuery = ({ commit }, payload) => {
  const { queryRequest } = payload;

  commit(types.API_REQUEST);
  let opts = { 
    body: queryRequest
  };

  return translationsApiZiqni
    .deleteTranslationsByQuery(
      opts
    )
    .then((response) => {
      commit(types.DELETETRANSLATIONSBYQUERY_SUCCESS, { response });
      return response.results;
    })
    .catch((e) => {
      commit(types.DELETETRANSLATIONSBYQUERY_ERROR, e);
      return [];
    });
};

/**
 * TranslationResponse from backend.
 * GET getTranslations and return [TranslationResponse]
 * vars: [
 *    [[id]] id[ opts, isArray, isQueryParam]
 *    [[limit]] _limit[ opts, isInteger, isQueryParam]
 *    [[skip]] _skip[ opts, isInteger, isQueryParam]]
 */
export const handleGetTranslations = ({ commit }, payload) => {
  const { idArray, limit, skip } = payload;

  commit(types.API_REQUEST);
  let opts = { 
    id: idArray, 
    limit: limit, 
    skip: skip
  };

  return translationsApiZiqni
    .getTranslations(
      opts
    )
    .then((response) => {
      commit(types.GETTRANSLATIONS_SUCCESS, { response });
      return response.results;
    })
    .catch((e) => {
      commit(types.GETTRANSLATIONS_ERROR, e);
      return [];
    });
};

/**
 * TranslationResponse from backend.
 * GET getTranslationsByEntityId and return [TranslationResponse]
 * vars: [
 *    [[refIds]] refIds[ opts, isArray, isQueryParam]
 *    [[limit]] _limit[ opts, isInteger, isQueryParam]
 *    [[skip]] _skip[ opts, isInteger, isQueryParam]]
 */
export const handleGetTranslationsByEntityId = ({ commit }, payload) => {
  const { refIdsArray, limit, skip } = payload;

  commit(types.API_REQUEST);
  let opts = { 
    refIds: refIdsArray, 
    limit: limit, 
    skip: skip
  };

  return translationsApiZiqni
    .getTranslationsByEntityId(
      opts
    )
    .then((response) => {
      commit(types.GETTRANSLATIONSBYENTITYID_SUCCESS, { response });
      return response.results;
    })
    .catch((e) => {
      commit(types.GETTRANSLATIONSBYENTITYID_ERROR, e);
      return [];
    });
};

/**
 * TranslationResponse from backend.
 * POST getTranslationsByQuery and return [TranslationResponse]
 * vars: [
 *    [[body]] QueryRequest[ opts, isBodyParam, ]]
 */
export const handleGetTranslationsByQuery = ({ commit }, payload) => {
  const { queryRequest } = payload;

  commit(types.API_REQUEST);
  let opts = { 
    body: queryRequest
  };

  return translationsApiZiqni
    .getTranslationsByQuery(
      opts
    )
    .then((response) => {
      commit(types.GETTRANSLATIONSBYQUERY_SUCCESS, { response });
      return response.results;
    })
    .catch((e) => {
      commit(types.GETTRANSLATIONSBYQUERY_ERROR, e);
      return [];
    });
};

/**
 * ApiResponse from backend.
 * PUT updateTranslations and return [ApiResponse]
 * vars: [
 *    [[body]] UpdateTranslationRequest[ required, isArray, isBodyParam, ]]
 */
export const handleUpdateTranslations = ({ commit }, payload) => {
  const { updateTranslationRequestArray } = payload;

  commit(types.API_REQUEST);
  

  return translationsApiZiqni
    .updateTranslations(
      updateTranslationRequestArray
    )
    .then((response) => {
      commit(types.UPDATETRANSLATIONS_SUCCESS, { response });
      return response.results;
    })
    .catch((e) => {
      commit(types.UPDATETRANSLATIONS_ERROR, e);
      return [];
    });
};
