// <<< DO NOT EDIT GENERATED FILES DIRECTLY! api-store-mutations.mustache >>>
import * as types from './mutation-types';
import * as _ from 'lodash';
import {
  handleErrorResponse, handleItemErrorResponse,
  handleItemSuccessResponse,
  handleSuccessResponse
} from "../../../../../utils/ZiqniAdminApiHandler";
import apiKeyResponseFields from '../../../fields/ApiKeyResponseZq.js';
import apiResponseFields from '../../../fields/ApiResponseZq.js';
import createApiKeyRequestFields from '../../../fields/CreateApiKeyRequestZq.js';
import queryRequestFields from '../../../fields/QueryRequestZq.js';
import updateApiKeyRequestFields from '../../../fields/UpdateApiKeyRequestZq.js';

const mutations = {
    [types.API_REQUEST](state) {
        state.loading = true;
        state.loaded = false;
    },
    [types.CLEAR_MESSAGE](state){
        state.message = null;
    },

    [types.UPDATE_FIELDS](state, data) {
        state.hiddenFields = data._hiddenFields;
        state.fields = _.difference(state.originalFields, state.hiddenFields);
        localStorage.setItem('hiddenApiKeysKey', JSON.stringify(state.hiddenFields));
    },

    [types.CLEAR_FILTER](state) {
        state.fields = _.difference(
            state.originalFields,
            state.originHiddenFields
        );
        state.hiddenFields = state.originHiddenFields;
        localStorage.removeItem('hiddenApiKeysKey');
    },

    //ApiKeyResponse
    [types.GETAPIKEYS_ITEM_SUCCESS](state, response) {
      handleItemSuccessResponse(types.GETAPIKEYS_ITEM_SUCCESS,apiKeyResponseFields, state, response, 'apiKey');
    },

    //ApiKeyResponse
    [types.GETAPIKEYS_ERROR](state, error) {
      handleItemErrorResponse(types.GETAPIKEYS_ITEM_ITEM_ERROR, apiKeyResponseFields, state, error);
    },


    //ApiResponse
    [types.CREATEAPIKEYS_SUCCESS](state, response) {
      handleSuccessResponse(types.CREATEAPIKEYS_SUCCESS,apiResponseFields, state, response);
    },

    //ApiResponse
    [types.CREATEAPIKEYS_ERROR](state, error) {
      handleErrorResponse(types.CREATEAPIKEYS_ERROR, apiResponseFields, state, error);
    },

    //ApiResponse
    [types.DELETEAPIKEYS_SUCCESS](state, response) {
      handleSuccessResponse(types.DELETEAPIKEYS_SUCCESS,apiResponseFields, state, response);
    },

    //ApiResponse
    [types.DELETEAPIKEYS_ERROR](state, error) {
      handleErrorResponse(types.DELETEAPIKEYS_ERROR, apiResponseFields, state, error);
    },

    //ApiResponse
    [types.DELETEAPIKEYSBYID_SUCCESS](state, response) {
      handleSuccessResponse(types.DELETEAPIKEYSBYID_SUCCESS,apiResponseFields, state, response);
    },

    //ApiResponse
    [types.DELETEAPIKEYSBYID_ERROR](state, error) {
      handleErrorResponse(types.DELETEAPIKEYSBYID_ERROR, apiResponseFields, state, error);
    },

    //ApiResponse
    [types.DELETEAPIKEYSBYQUERY_SUCCESS](state, response) {
      handleSuccessResponse(types.DELETEAPIKEYSBYQUERY_SUCCESS,apiResponseFields, state, response);
    },

    //ApiResponse
    [types.DELETEAPIKEYSBYQUERY_ERROR](state, error) {
      handleErrorResponse(types.DELETEAPIKEYSBYQUERY_ERROR, apiResponseFields, state, error);
    },

    //ApiKeyResponse
    [types.GETAPIKEYS_SUCCESS](state, response) {
      handleSuccessResponse(types.GETAPIKEYS_SUCCESS,apiKeyResponseFields, state, response);
    },

    //ApiKeyResponse
    [types.GETAPIKEYS_ERROR](state, error) {
      handleErrorResponse(types.GETAPIKEYS_ERROR, apiKeyResponseFields, state, error);
    },

    //ApiKeyResponse
    [types.GETAPIKEYSBYID_SUCCESS](state, response) {
      handleSuccessResponse(types.GETAPIKEYSBYID_SUCCESS,apiKeyResponseFields, state, response);
    },

    //ApiKeyResponse
    [types.GETAPIKEYSBYID_ERROR](state, error) {
      handleErrorResponse(types.GETAPIKEYSBYID_ERROR, apiKeyResponseFields, state, error);
    },

    //ApiKeyResponse
    [types.GETAPIKEYSBYQUERY_SUCCESS](state, response) {
      handleSuccessResponse(types.GETAPIKEYSBYQUERY_SUCCESS,apiKeyResponseFields, state, response);
    },

    //ApiKeyResponse
    [types.GETAPIKEYSBYQUERY_ERROR](state, error) {
      handleErrorResponse(types.GETAPIKEYSBYQUERY_ERROR, apiKeyResponseFields, state, error);
    },

    //ApiResponse
    [types.UPDATEAPIKEYS_SUCCESS](state, response) {
      handleSuccessResponse(types.UPDATEAPIKEYS_SUCCESS,apiResponseFields, state, response);
    },

    //ApiResponse
    [types.UPDATEAPIKEYS_ERROR](state, error) {
      handleErrorResponse(types.UPDATEAPIKEYS_ERROR, apiResponseFields, state, error);
    },
};

export default mutations;
