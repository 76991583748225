// <<< DO NOT EDIT GENERATED FILES DIRECTLY! model-store-fields-all.mustache >>>


import eventRefType from './EventRefTypeZq';
import memberMessageAllOf from './MemberMessageAllOfZq';
import messageLink from './MessageLinkZq';
import messageStatus from './MessageStatusZq';
import messageType from './MessageTypeZq';
import modelDefault from './ModelDefaultZq';
import optParamModels from './OptParamModelsZq';

const memberMessage = this;

export default {
  objectType: 'MemberMessage',
  allowableValuesKeys: [],
  constraints: [],
  getter: 'memberMessages',
  hiddenFields: ['spaceName','customFields','metadata',],
  searchableFields: ['id','created','eventRefId','subject','body','expiry',], 
  stringBuilderFields: [],
  operations: ['Create','Update'], 
  searchableAllFields: ['eventRefId','subject','body',],
  translatableFields: ['subject','body',],
  sortableFields: ['id','spaceName','created','tags',],
  customFieldsFieldName: ['customFields',],
  metadataFieldName: ['metadata'],
  tagFieldName: ['tags'],
  entityLinksFrom: [],

  route: {
    list: 'ListMemberMessage',
    preview: 'PreviewMemberMessage',
    create: 'CreateMemberMessage',
    update: 'UpdateMemberMessage',
  },
  skip: 0,
  limit: 10,
  sortBy: [
    {
      "queryField": "name",
      "order": "Asc"
    }
  ],
  fieldList: ['id','spaceName','created','customFields','tags','metadata','eventRefType','eventRefId','messageType','subject','body','status','expiry','links','memberIds'],
  fields: { 
    id: {
      name: 'id',
      complexType: null,
      displayOrder: 2,
      allowableValuesKeys: [],
      constraints: ['required','isPrimitiveType','isString',
                     ],
      formType: null,
    },
    spaceName: {
      name: 'spaceName',
      complexType: null,
      displayOrder: 9000,
      allowableValuesKeys: [],
      constraints: ['required','isPrimitiveType','isString',
                     ],
      formType: null,
    },
    created: {
      name: 'created',
      complexType: null,
      displayOrder: 9001,
      allowableValuesKeys: [],
      constraints: ['required','isPrimitiveType','isDateTime',
                     ],
      formType: null,
    },
    customFields: {
      name: 'customFields',
      complexType: null,
      displayOrder: 5000,
      allowableValuesKeys: [],
      constraints: ['isCustomFieldsArray','isPrimitiveType','isContainer','isFreeFormObject','isMap',
                    'isFreeFormObject','isNullable', ],
      formType: null,
    },
    tags: {
      name: 'tags',
      complexType: null,
      displayOrder: 1,
      allowableValuesKeys: [],
      constraints: ['isTagsArray','isPrimitiveType','isContainer','isArray',
                    'isString', ],
      formType: null,
    },
    metadata: {
      name: 'metadata',
      complexType: null,
      displayOrder: 10000,
      allowableValuesKeys: [],
      constraints: ['isMetadataMap','isPrimitiveType','isContainer','isMap',
                    'isString', ],
      formType: 'META',
    },
    eventRefType: {
      name: 'eventRefType',
      complexType: eventRefType,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['required',
                     ],
      formType: null,
    },
    eventRefId: {
      name: 'eventRefId',
      complexType: null,
      displayOrder: 1001,
      allowableValuesKeys: [],
      constraints: ['isPrimitiveType','isString',
                     ],
      formType: null,
    },
    messageType: {
      name: 'messageType',
      complexType: messageType,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['required',
                     ],
      formType: null,
    },
    subject: {
      name: 'subject',
      complexType: null,
      displayOrder: 1002,
      allowableValuesKeys: [],
      constraints: ['required','isPrimitiveType','isString',
                     ],
      formType: 'TEXTAREA',
    },
    body: {
      name: 'body',
      complexType: null,
      displayOrder: 1003,
      allowableValuesKeys: [],
      constraints: ['required','isPrimitiveType','isString',
                     ],
      formType: 'TEXTAREA',
    },
    status: {
      name: 'status',
      complexType: messageStatus,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['required',
                     ],
      formType: null,
    },
    expiry: {
      name: 'expiry',
      complexType: null,
      displayOrder: 1004,
      allowableValuesKeys: [],
      constraints: ['required','isPrimitiveType','isDateTime',
                     ],
      formType: null,
    },
    links: {
      name: 'links',
      complexType: messageLink,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['required','isModel',
                     ],
      formType: null,
    },
    memberIds: {
      name: 'memberIds',
      complexType: null,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['isPrimitiveType','isContainer','isArray',
                    'isString', ],
      formType: null,
    }
  }
};
