// <<< DO NOT EDIT GENERATED FILES DIRECTLY! api-store-mutations.mustache >>>
import * as types from './mutation-types';
import * as _ from 'lodash';
import {
  handleErrorResponse, handleItemErrorResponse,
  handleItemSuccessResponse,
  handleSuccessResponse
} from "../../../../../utils/ZiqniAdminApiHandler";
import apiResponseFields from '../../../fields/ApiResponseZq.js';
import createCustomFieldRequestFields from '../../../fields/CreateCustomFieldRequestZq.js';
import customFieldResponseFields from '../../../fields/CustomFieldResponseZq.js';
import queryRequestFields from '../../../fields/QueryRequestZq.js';
import updateCustomFieldRequestFields from '../../../fields/UpdateCustomFieldRequestZq.js';

const mutations = {
    [types.API_REQUEST](state) {
        state.loading = true;
        state.loaded = false;
    },
    [types.CLEAR_MESSAGE](state){
        state.message = null;
    },

    [types.UPDATE_FIELDS](state, data) {
        state.hiddenFields = data._hiddenFields;
        state.fields = _.difference(state.originalFields, state.hiddenFields);
        localStorage.setItem('hiddenCustomFieldsKey', JSON.stringify(state.hiddenFields));
    },

    [types.CLEAR_FILTER](state) {
        state.fields = _.difference(
            state.originalFields,
            state.originHiddenFields
        );
        state.hiddenFields = state.originHiddenFields;
        localStorage.removeItem('hiddenCustomFieldsKey');
    },

    //CustomFieldResponse
    [types.GETCUSTOMFIELDS_ITEM_SUCCESS](state, response) {
      handleItemSuccessResponse(types.GETCUSTOMFIELDS_ITEM_SUCCESS,customFieldResponseFields, state, response, 'customField');
    },

    //CustomFieldResponse
    [types.GETCUSTOMFIELDS_ERROR](state, error) {
      handleItemErrorResponse(types.GETCUSTOMFIELDS_ITEM_ITEM_ERROR, customFieldResponseFields, state, error);
    },


    //ApiResponse
    [types.CREATECUSTOMFIELDS_SUCCESS](state, response) {
      handleSuccessResponse(types.CREATECUSTOMFIELDS_SUCCESS,apiResponseFields, state, response);
    },

    //ApiResponse
    [types.CREATECUSTOMFIELDS_ERROR](state, error) {
      handleErrorResponse(types.CREATECUSTOMFIELDS_ERROR, apiResponseFields, state, error);
    },

    //ApiResponse
    [types.DELETECUSTOMFIELDS_SUCCESS](state, response) {
      handleSuccessResponse(types.DELETECUSTOMFIELDS_SUCCESS,apiResponseFields, state, response);
    },

    //ApiResponse
    [types.DELETECUSTOMFIELDS_ERROR](state, error) {
      handleErrorResponse(types.DELETECUSTOMFIELDS_ERROR, apiResponseFields, state, error);
    },

    //ApiResponse
    [types.DELETECUSTOMFIELDSBYQUERY_SUCCESS](state, response) {
      handleSuccessResponse(types.DELETECUSTOMFIELDSBYQUERY_SUCCESS,apiResponseFields, state, response);
    },

    //ApiResponse
    [types.DELETECUSTOMFIELDSBYQUERY_ERROR](state, error) {
      handleErrorResponse(types.DELETECUSTOMFIELDSBYQUERY_ERROR, apiResponseFields, state, error);
    },

    //CustomFieldResponse
    [types.GETCUSTOMFIELDS_SUCCESS](state, response) {
      handleSuccessResponse(types.GETCUSTOMFIELDS_SUCCESS,customFieldResponseFields, state, response);
    },

    //CustomFieldResponse
    [types.GETCUSTOMFIELDS_ERROR](state, error) {
      handleErrorResponse(types.GETCUSTOMFIELDS_ERROR, customFieldResponseFields, state, error);
    },

    //CustomFieldResponse
    [types.GETCUSTOMFIELDSBYQUERY_SUCCESS](state, response) {
      handleSuccessResponse(types.GETCUSTOMFIELDSBYQUERY_SUCCESS,customFieldResponseFields, state, response);
    },

    //CustomFieldResponse
    [types.GETCUSTOMFIELDSBYQUERY_ERROR](state, error) {
      handleErrorResponse(types.GETCUSTOMFIELDSBYQUERY_ERROR, customFieldResponseFields, state, error);
    },

    //ApiResponse
    [types.UPDATECUSTOMFIELDS_SUCCESS](state, response) {
      handleSuccessResponse(types.UPDATECUSTOMFIELDS_SUCCESS,apiResponseFields, state, response);
    },

    //ApiResponse
    [types.UPDATECUSTOMFIELDS_ERROR](state, error) {
      handleErrorResponse(types.UPDATECUSTOMFIELDS_ERROR, apiResponseFields, state, error);
    },
};

export default mutations;
