// <<< DO NOT EDIT GENERATED FILES DIRECTLY! model-store-fields-all.mustache >>>


import actionTypeAdjustmentFactor from './ActionTypeAdjustmentFactorZq';
import modelDefault from './ModelDefaultZq';
import optParamModels from './OptParamModelsZq';
import productAllOf from './ProductAllOfZq';

const product = this;

export default {
  objectType: 'Product',
  allowableValuesKeys: [],
  constraints: [],
  getter: 'products',
  hiddenFields: ['spaceName','customFields','metadata','description','actionTypeAdjustmentFactors',],
  searchableFields: ['id','created','name','description','adjustmentFactor','productRefId',],
  stringBuilderFields: ['name',],
  operations: ['Create','Update'],
  searchableAllFields: ['name','description','productRefId',],
  translatableFields: ['name','description',],
  sortableFields: ['id','spaceName','created','tags','name','description','adjustmentFactor','productRefId',],
  customFieldsFieldName: ['customFields',],
  metadataFieldName: ['metadata'],
  tagFieldName: ['tags'],
  entityLinksFrom: [],

  route: {
    list: 'ListProduct',
    preview: 'PreviewProduct',
    create: 'CreateProduct',
    update: 'UpdateProduct',
  },
  skip: 0,
  limit: 10,
  sortBy: [
    {
      "queryField": "name",
      "order": "Asc"
    }
  ],
  fieldList: ['id','spaceName','created','customFields','tags','metadata','name','description','adjustmentFactor','productRefId','actionTypeAdjustmentFactors', 'icon'],
  fields: {
    id: {
      name: 'id',
      complexType: null,
      displayOrder: 2,
      allowableValuesKeys: [],
      constraints: ['required','isPrimitiveType','isString',
                     ],
      formType: null,
    },
    spaceName: {
      name: 'spaceName',
      complexType: null,
      displayOrder: 9000,
      allowableValuesKeys: [],
      constraints: ['required','isPrimitiveType','isString',
                     ],
      formType: null,
    },
    created: {
      name: 'created',
      complexType: null,
      displayOrder: 9001,
      allowableValuesKeys: [],
      constraints: ['required','isPrimitiveType','isDateTime',
                     ],
      formType: null,
    },
    customFields: {
      name: 'customFields',
      complexType: null,
      displayOrder: 5000,
      allowableValuesKeys: [],
      constraints: ['isCustomFieldsArray','isPrimitiveType','isContainer','isFreeFormObject','isMap',
                    'isFreeFormObject','isNullable', ],
      formType: null,
    },
    tags: {
      name: 'tags',
      complexType: null,
      displayOrder: 1,
      allowableValuesKeys: [],
      constraints: ['isTagsArray','isPrimitiveType','isContainer','isArray',
                    'isString', ],
      formType: null,
    },
    metadata: {
      name: 'metadata',
      complexType: null,
      displayOrder: 10000,
      allowableValuesKeys: [],
      constraints: ['isMetadataMap','isPrimitiveType','isContainer','isMap',
                    'isString', ],
      formType: 'META',
    },
    name: {
      name: 'name',
      complexType: null,
      displayOrder: 1001,
      allowableValuesKeys: [],
      constraints: ['required','isPrimitiveType','isString',
                     ],
      formType: null,
    },
    description: {
      name: 'description',
      complexType: null,
      displayOrder: 1002,
      allowableValuesKeys: [],
      constraints: ['isPrimitiveType','isString',
                     ],
      formType: 'TEXTAREA',
    },
    adjustmentFactor: {
      name: 'adjustmentFactor',
      complexType: null,
      displayOrder: 1003,
      allowableValuesKeys: [],
      constraints: ['required','isPrimitiveType','isNumeric','isDouble',
                     ],
      formType: null,
    },
    productRefId: {
      name: 'productRefId',
      complexType: null,
      displayOrder: 1000,
      allowableValuesKeys: [],
      constraints: ['required','isPrimitiveType','isString',
                     ],
      formType: null,
    },
    actionTypeAdjustmentFactors: {
      name: 'actionTypeAdjustmentFactors',
      complexType: actionTypeAdjustmentFactor,
      displayOrder: 1004,
      allowableValuesKeys: [],
      constraints: ['isContainer','isArray',
                    'isModel', ],
      formType: null,
    },
    icon: {
      name: 'icon',
      complexType: null,
      displayOrder: 1011,
      allowableValuesKeys: [],
      constraints: ['required','isPrimitiveType','isString',
      ],
      formType: null,
    },
  }
};
