// <<< DO NOT EDIT GENERATED FILES DIRECTLY! api-store-mutations.mustache >>>
import * as types from './mutation-types';
import * as _ from 'lodash';
import {
  handleErrorResponse, handleItemErrorResponse,
  handleItemSuccessResponse,
  handleSuccessResponse
} from "../../../../../utils/ZiqniAdminApiHandler";
import achievementReducedResponseFields from '../../../fields/AchievementReducedResponseZq.js';
import apiResponseFields from '../../../fields/ApiResponseZq.js';
import competitionReducedResponseFields from '../../../fields/CompetitionReducedResponseZq.js';
import contestReducedResponseFields from '../../../fields/ContestReducedResponseZq.js';
import createProductRequestFields from '../../../fields/CreateProductRequestZq.js';
import productResponseFields from '../../../fields/ProductResponseZq.js';
import queryRequestFields from '../../../fields/QueryRequestZq.js';
import updateProductRequestFields from '../../../fields/UpdateProductRequestZq.js';

const mutations = {
    [types.API_REQUEST](state) {
        state.loading = true;
        state.loaded = false;
    },
    [types.CLEAR_MESSAGE](state){
        state.message = null;
    },

    [types.UPDATE_FIELDS](state, data) {
        state.hiddenFields = data._hiddenFields;
        state.fields = _.difference(state.originalFields, state.hiddenFields);
        localStorage.setItem('hiddenProductsKey', JSON.stringify(state.hiddenFields));
    },

    [types.CLEAR_FILTER](state) {
        state.fields = _.difference(
            state.originalFields,
            state.originHiddenFields
        );
        state.hiddenFields = state.originHiddenFields;
        localStorage.removeItem('hiddenProductsKey');
    },

    //AchievementReducedResponse
    [types.GETACHIEVEMENTSFORPRODUCTS_ITEM_SUCCESS](state, response) {
      handleItemSuccessResponse(types.GETACHIEVEMENTSFORPRODUCTS_ITEM_SUCCESS,achievementReducedResponseFields, state, response, 'achievementReduced');
    },

    //AchievementReducedResponse
    [types.GETACHIEVEMENTSFORPRODUCTS_ERROR](state, error) {
      handleItemErrorResponse(types.GETACHIEVEMENTSFORPRODUCTS_ITEM_ITEM_ERROR, achievementReducedResponseFields, state, error);
    },


    //CompetitionReducedResponse
    [types.GETCOMPETITIONSFORPRODUCTS_ITEM_SUCCESS](state, response) {
      handleItemSuccessResponse(types.GETCOMPETITIONSFORPRODUCTS_ITEM_SUCCESS,competitionReducedResponseFields, state, response, 'competitionReduced');
    },

    //CompetitionReducedResponse
    [types.GETCOMPETITIONSFORPRODUCTS_ERROR](state, error) {
      handleItemErrorResponse(types.GETCOMPETITIONSFORPRODUCTS_ITEM_ITEM_ERROR, competitionReducedResponseFields, state, error);
    },


    //ContestReducedResponse
    [types.GETCONTESTSFORPRODUCTS_ITEM_SUCCESS](state, response) {
      handleItemSuccessResponse(types.GETCONTESTSFORPRODUCTS_ITEM_SUCCESS,contestReducedResponseFields, state, response, 'contestReduced');
    },

    //ContestReducedResponse
    [types.GETCONTESTSFORPRODUCTS_ERROR](state, error) {
      handleItemErrorResponse(types.GETCONTESTSFORPRODUCTS_ITEM_ITEM_ERROR, contestReducedResponseFields, state, error);
    },


    //ProductResponse
    [types.GETPRODUCTS_ITEM_SUCCESS](state, response) {
      handleItemSuccessResponse(types.GETPRODUCTS_ITEM_SUCCESS,productResponseFields, state, response, 'product');
    },

    //ProductResponse
    [types.GETPRODUCTS_ERROR](state, error) {
      handleItemErrorResponse(types.GETPRODUCTS_ITEM_ITEM_ERROR, productResponseFields, state, error);
    },


    //ApiResponse
    [types.CREATEPRODUCTS_SUCCESS](state, response) {
      handleSuccessResponse(types.CREATEPRODUCTS_SUCCESS,apiResponseFields, state, response);
    },

    //ApiResponse
    [types.CREATEPRODUCTS_ERROR](state, error) {
      handleErrorResponse(types.CREATEPRODUCTS_ERROR, apiResponseFields, state, error);
    },

    //ApiResponse
    [types.DELETEPRODUCTS_SUCCESS](state, response) {
      handleSuccessResponse(types.DELETEPRODUCTS_SUCCESS,apiResponseFields, state, response);
    },

    //ApiResponse
    [types.DELETEPRODUCTS_ERROR](state, error) {
      handleErrorResponse(types.DELETEPRODUCTS_ERROR, apiResponseFields, state, error);
    },

    //ApiResponse
    [types.DELETEPRODUCTSBYQUERY_SUCCESS](state, response) {
      handleSuccessResponse(types.DELETEPRODUCTSBYQUERY_SUCCESS,apiResponseFields, state, response);
    },

    //ApiResponse
    [types.DELETEPRODUCTSBYQUERY_ERROR](state, error) {
      handleErrorResponse(types.DELETEPRODUCTSBYQUERY_ERROR, apiResponseFields, state, error);
    },

    //AchievementReducedResponse
    [types.GETACHIEVEMENTSFORPRODUCTS_SUCCESS](state, response) {
      handleSuccessResponse(types.GETACHIEVEMENTSFORPRODUCTS_SUCCESS,achievementReducedResponseFields, state, response);
    },

    //AchievementReducedResponse
    [types.GETACHIEVEMENTSFORPRODUCTS_ERROR](state, error) {
      handleErrorResponse(types.GETACHIEVEMENTSFORPRODUCTS_ERROR, achievementReducedResponseFields, state, error);
    },

    //CompetitionReducedResponse
    [types.GETCOMPETITIONSFORPRODUCTS_SUCCESS](state, response) {
      handleSuccessResponse(types.GETCOMPETITIONSFORPRODUCTS_SUCCESS,competitionReducedResponseFields, state, response);
    },

    //CompetitionReducedResponse
    [types.GETCOMPETITIONSFORPRODUCTS_ERROR](state, error) {
      handleErrorResponse(types.GETCOMPETITIONSFORPRODUCTS_ERROR, competitionReducedResponseFields, state, error);
    },

    //ContestReducedResponse
    [types.GETCONTESTSFORPRODUCTS_SUCCESS](state, response) {
      handleSuccessResponse(types.GETCONTESTSFORPRODUCTS_SUCCESS,contestReducedResponseFields, state, response);
    },

    //ContestReducedResponse
    [types.GETCONTESTSFORPRODUCTS_ERROR](state, error) {
      handleErrorResponse(types.GETCONTESTSFORPRODUCTS_ERROR, contestReducedResponseFields, state, error);
    },

    //ProductResponse
    [types.GETPRODUCTS_SUCCESS](state, response) {
      handleSuccessResponse(types.GETPRODUCTS_SUCCESS,productResponseFields, state, response);
    },

    //ProductResponse
    [types.GETPRODUCTS_ERROR](state, error) {
      handleErrorResponse(types.GETPRODUCTS_ERROR, productResponseFields, state, error);
    },

    //ProductResponse
    [types.GETPRODUCTSBYQUERY_SUCCESS](state, response) {
      handleSuccessResponse(types.GETPRODUCTSBYQUERY_SUCCESS,productResponseFields, state, response);
    },

    //ProductResponse
    [types.GETPRODUCTSBYQUERY_ERROR](state, error) {
      handleErrorResponse(types.GETPRODUCTSBYQUERY_ERROR, productResponseFields, state, error);
    },

    //ProductResponse
    [types.GETPRODUCTSBYREFID_SUCCESS](state, response) {
      handleSuccessResponse(types.GETPRODUCTSBYREFID_SUCCESS,productResponseFields, state, response);
    },

    //ProductResponse
    [types.GETPRODUCTSBYREFID_ERROR](state, error) {
      handleErrorResponse(types.GETPRODUCTSBYREFID_ERROR, productResponseFields, state, error);
    },

    //ApiResponse
    [types.UPDATEPRODUCTS_SUCCESS](state, response) {
      handleSuccessResponse(types.UPDATEPRODUCTS_SUCCESS,apiResponseFields, state, response);
    },

    //ApiResponse
    [types.UPDATEPRODUCTS_ERROR](state, error) {
      handleErrorResponse(types.UPDATEPRODUCTS_ERROR, apiResponseFields, state, error);
    },
};

export default mutations;
