// <<< DO NOT EDIT GENERATED FILES DIRECTLY! api-store-mutation-types.mustache >>>
export const API_REQUEST = 'API_REQUEST';


export const GETAWARDS_ITEM_REQ = 'GETAWARDS_ITEM_REQ';
export const GETAWARDS_ITEM_ERROR = 'GETAWARDS_ITEM_ERROR';
export const GETAWARDS_ITEM_SUCCESS = 'GETAWARDS_ITEM_SUCCESS'

export const UPDATE_FIELDS = 'UPDATE_FIELDS';
export const CLEAR_FILTER = 'CLEAR_FILTER';
export const CLEAR_MESSAGE = 'CLEAR_MESSAGE';

export const CLAIMAWARDS_REQ = 'CLAIMAWARDS_REQ';
export const CLAIMAWARDS_ERROR = 'CLAIMAWARDS_ERROR';
export const CLAIMAWARDS_SUCCESS = 'CLAIMAWARDS_SUCCESS';

export const GETAWARDS_REQ = 'GETAWARDS_REQ';
export const GETAWARDS_ERROR = 'GETAWARDS_ERROR';
export const GETAWARDS_SUCCESS = 'GETAWARDS_SUCCESS';

export const GETAWARDSBYQUERY_REQ = 'GETAWARDSBYQUERY_REQ';
export const GETAWARDSBYQUERY_ERROR = 'GETAWARDSBYQUERY_ERROR';
export const GETAWARDSBYQUERY_SUCCESS = 'GETAWARDSBYQUERY_SUCCESS';

