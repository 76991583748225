// <<< DO NOT EDIT GENERATED FILES DIRECTLY! api-store-actions.mustache >>>
import { delay, cloneDeep } from 'lodash';
import * as types from './mutation-types';
import sdkApiConfig from '@/config/sdkApiConfig';
import updateFields from '@/utils/updateFields';
import { fileRepositoriesApiZiqni } from '@/generated/ziqni/api/FileRepositoriesApiZiqni';
import router from '@/router';

import apiResponseFields from '../../../fields/ApiResponseZq.js';
import createFileRepositoryRequestFields from '../../../fields/CreateFileRepositoryRequestZq.js';
import fileRepositoryResponseFields from '../../../fields/FileRepositoryResponseZq.js';
import queryRequestFields from '../../../fields/QueryRequestZq.js';
import updateFileRepositoryRequestFields from '../../../fields/UpdateFileRepositoryRequestZq.js';
import {handleRequest,handleRequestWithBody} from "../../../../../utils/ZiqniAdminApiHandler";

export const handleFields = ({ commit, state }, data) => {
  updateFields(commit, state, data, 'hiddenFileRepositoriesKey');
};

// Clear fileRepositories Message
export const handleClearMessage = ({ commit }) => {
  commit(types.CLEAR_MESSAGE);
};

// clear filter
export const handleClearFilter = ({ commit }) => {
  commit(types.CLEAR_FILTER);
};

//FILEREPOSITORIES - FILEREPOSITORIES
export const handleFileRepositoriesChanges = ({ commit }, data) => {
  commit('SET_FILEREPOSITORIES', data);
};
/**
 * FileRepositoryResponse from backend.
 * GET getFileRepositories and return [FileRepositoryResponse]
 * vars: [
 *    [[id]] id[ opts, isArray, isQueryParam]
 *    [[limit]] _limit[ opts, isInteger, isQueryParam]
 *    [[skip]] _skip[ opts, isInteger, isQueryParam]]
 */
export const handleGetFileRepositories_item = ({ commit }, idArray, limit, skip) => {
  commit(types.API_REQUEST);
  let opts = { 
    id: idArray, 
    limit: limit, 
    skip: skip
  };

  return fileRepositoriesApiZiqni
    .getFileRepositories(
      opts
    )
    .then((response) => {
      commit(types.GETFILEREPOSITORIES_ITEM_SUCCESS, { response });
      return response.results;
    })
    .catch((e) => {
      commit(types.GETFILEREPOSITORIES_ITEM_ERROR, e);
      return [];
    });
};

/**
 * ApiResponse from backend.
 * POST createFileRepositories and return [ApiResponse]
 * vars: [
 *    [[body]] CreateFileRepositoryRequest[ required, isArray, isBodyParam, ]]
 */
export const handleCreateFileRepositories = ({ commit }, payload) => {
  const { createFileRepositoryRequestArray } = payload;

  commit(types.API_REQUEST);
  

  return fileRepositoriesApiZiqni
    .createFileRepositories(
      createFileRepositoryRequestArray
    )
    .then((response) => {
      commit(types.CREATEFILEREPOSITORIES_SUCCESS, { response });
      return response.results;
    })
    .catch((e) => {
      commit(types.CREATEFILEREPOSITORIES_ERROR, e);
      return [];
    });
};

/**
 * ApiResponse from backend.
 * DELETE deleteFileRepositories and return [ApiResponse]
 * vars: [
 *    [[id]] id[ opts, isArray, isQueryParam]
 *    [[permanent]] permanent[ opts, isQueryParam]]
 */
export const handleDeleteFileRepositories = ({ commit }, payload) => {
  const { idArray, permanent } = payload;

  commit(types.API_REQUEST);
  let opts = { 
    id: idArray, 
    permanent: permanent
  };

  return fileRepositoriesApiZiqni
    .deleteFileRepositories(
      opts
    )
    .then((response) => {
      commit(types.DELETEFILEREPOSITORIES_SUCCESS, { response });
      return response.results;
    })
    .catch((e) => {
      commit(types.DELETEFILEREPOSITORIES_ERROR, e);
      return [];
    });
};

/**
 * ApiResponse from backend.
 * POST deleteFileRepositoriesByQuery and return [ApiResponse]
 * vars: [
 *    [[body]] QueryRequest[ opts, isBodyParam, ]]
 */
export const handleDeleteFileRepositoriesByQuery = ({ commit }, payload) => {
  const { queryRequest } = payload;

  commit(types.API_REQUEST);
  let opts = { 
    body: queryRequest
  };

  return fileRepositoriesApiZiqni
    .deleteFileRepositoriesByQuery(
      opts
    )
    .then((response) => {
      commit(types.DELETEFILEREPOSITORIESBYQUERY_SUCCESS, { response });
      return response.results;
    })
    .catch((e) => {
      commit(types.DELETEFILEREPOSITORIESBYQUERY_ERROR, e);
      return [];
    });
};

/**
 * FileRepositoryResponse from backend.
 * GET getFileRepositories and return [FileRepositoryResponse]
 * vars: [
 *    [[id]] id[ opts, isArray, isQueryParam]
 *    [[limit]] _limit[ opts, isInteger, isQueryParam]
 *    [[skip]] _skip[ opts, isInteger, isQueryParam]]
 */
export const handleGetFileRepositories = ({ commit }, payload) => {
  const { idArray, limit, skip } = payload;

  commit(types.API_REQUEST);
  let opts = { 
    id: idArray, 
    limit: limit, 
    skip: skip
  };

  return fileRepositoriesApiZiqni
    .getFileRepositories(
      opts
    )
    .then((response) => {
      commit(types.GETFILEREPOSITORIES_SUCCESS, { response });
      return response.results;
    })
    .catch((e) => {
      commit(types.GETFILEREPOSITORIES_ERROR, e);
      return [];
    });
};

/**
 * FileRepositoryResponse from backend.
 * POST getFileRepositoriesByQuery and return [FileRepositoryResponse]
 * vars: [
 *    [[body]] QueryRequest[ opts, isBodyParam, ]]
 */
export const handleGetFileRepositoriesByQuery = ({ commit }, payload) => {
  const { queryRequest } = payload;

  commit(types.API_REQUEST);
  let opts = { 
    body: queryRequest
  };

  return fileRepositoriesApiZiqni
    .getFileRepositoriesByQuery(
      opts
    )
    .then((response) => {
      commit(types.GETFILEREPOSITORIESBYQUERY_SUCCESS, { response });
      return response.results;
    })
    .catch((e) => {
      commit(types.GETFILEREPOSITORIESBYQUERY_ERROR, e);
      return [];
    });
};

/**
 * ApiResponse from backend.
 * PUT updateFileRepositories and return [ApiResponse]
 * vars: [
 *    [[body]] UpdateFileRepositoryRequest[ required, isArray, isBodyParam, ]]
 */
export const handleUpdateFileRepositories = ({ commit }, payload) => {
  const { updateFileRepositoryRequestArray } = payload;

  commit(types.API_REQUEST);
  

  return fileRepositoriesApiZiqni
    .updateFileRepositories(
      updateFileRepositoryRequestArray
    )
    .then((response) => {
      commit(types.UPDATEFILEREPOSITORIES_SUCCESS, { response });
      return response.results;
    })
    .catch((e) => {
      commit(types.UPDATEFILEREPOSITORIES_ERROR, e);
      return [];
    });
};
