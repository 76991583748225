import * as types from './mutation-types';
import { handleErrorResponse, handleSuccessResponse } from "../../../utils/ZiqniAdminApiHandler";
import entrantResponseFields from '../../../generated/ziqni/fields/EntrantResponseZq';
import * as _ from 'lodash';

const mutations = {
  [types.API_REQUEST](state) {
    state.loading = true;
    state.loaded = false;
  },

  [types.CLEAR_MESSAGE](state){
    state.message = null;
  },

  [types.UPDATE_FIELDS](state, data) {
    state.hiddenFields = data._hiddenFields;
    state.fields = _.difference(state.originalFields, state.hiddenFields);
    localStorage.setItem('hiddenEntrantsKey', JSON.stringify(state.hiddenFields));
  },

  [types.CLEAR_FILTER](state) {
    state.fields = _.difference(
      state.originalFields,
      state.originHiddenFields
    );
    state.hiddenFields = state.originHiddenFields;
    localStorage.removeItem('hiddenEntrantsKey');
  },

  //EntrantResponse
  [types.EXPORTENTRANTSBYQUERY_SUCCESS](state, response) {
    state.entrants = response.response.results;
    handleSuccessResponse(types.EXPORTENTRANTSBYQUERY_SUCCESS,entrantResponseFields, state, response);
  },

  //EntrantResponse
  [types.EXPORTENTRANTSBYQUERY_ERROR](state, error) {
    handleErrorResponse(types.EXPORTENTRANTSBYQUERY_ERROR, entrantResponseFields, state, error);
  },

  //EntrantResponse
  [types.GETENTRANTSBYQUERY_SUCCESS](state, response) {
    state.entrants = response.response.results;
    handleSuccessResponse(types.GETENTRANTSBYQUERY_SUCCESS,entrantResponseFields, state, response);
  },

  //EntrantResponse
  [types.GETENTRANTSBYQUERY_ERROR](state, error) {
    handleErrorResponse(types.GETENTRANTSBYQUERY_ERROR, entrantResponseFields, state, error);
  },
};

export default mutations;
