// <<< DO NOT EDIT GENERATED FILES DIRECTLY! api-store-mutation-types.mustache >>>
export const API_REQUEST = 'API_REQUEST';


export const GETTRANSLATIONS_ITEM_REQ = 'GETTRANSLATIONS_ITEM_REQ';
export const GETTRANSLATIONS_ITEM_ERROR = 'GETTRANSLATIONS_ITEM_ERROR';
export const GETTRANSLATIONS_ITEM_SUCCESS = 'GETTRANSLATIONS_ITEM_SUCCESS'

export const UPDATE_FIELDS = 'UPDATE_FIELDS';
export const CLEAR_FILTER = 'CLEAR_FILTER';
export const CLEAR_MESSAGE = 'CLEAR_MESSAGE';

export const CREATETRANSLATIONS_REQ = 'CREATETRANSLATIONS_REQ';
export const CREATETRANSLATIONS_ERROR = 'CREATETRANSLATIONS_ERROR';
export const CREATETRANSLATIONS_SUCCESS = 'CREATETRANSLATIONS_SUCCESS';

export const DELETETRANSLATIONS_REQ = 'DELETETRANSLATIONS_REQ';
export const DELETETRANSLATIONS_ERROR = 'DELETETRANSLATIONS_ERROR';
export const DELETETRANSLATIONS_SUCCESS = 'DELETETRANSLATIONS_SUCCESS';

export const DELETETRANSLATIONSBYQUERY_REQ = 'DELETETRANSLATIONSBYQUERY_REQ';
export const DELETETRANSLATIONSBYQUERY_ERROR = 'DELETETRANSLATIONSBYQUERY_ERROR';
export const DELETETRANSLATIONSBYQUERY_SUCCESS = 'DELETETRANSLATIONSBYQUERY_SUCCESS';

export const GETTRANSLATIONS_REQ = 'GETTRANSLATIONS_REQ';
export const GETTRANSLATIONS_ERROR = 'GETTRANSLATIONS_ERROR';
export const GETTRANSLATIONS_SUCCESS = 'GETTRANSLATIONS_SUCCESS';

export const GETTRANSLATIONSBYENTITYID_REQ = 'GETTRANSLATIONSBYENTITYID_REQ';
export const GETTRANSLATIONSBYENTITYID_ERROR = 'GETTRANSLATIONSBYENTITYID_ERROR';
export const GETTRANSLATIONSBYENTITYID_SUCCESS = 'GETTRANSLATIONSBYENTITYID_SUCCESS';

export const GETTRANSLATIONSBYQUERY_REQ = 'GETTRANSLATIONSBYQUERY_REQ';
export const GETTRANSLATIONSBYQUERY_ERROR = 'GETTRANSLATIONSBYQUERY_ERROR';
export const GETTRANSLATIONSBYQUERY_SUCCESS = 'GETTRANSLATIONSBYQUERY_SUCCESS';

export const UPDATETRANSLATIONS_REQ = 'UPDATETRANSLATIONS_REQ';
export const UPDATETRANSLATIONS_ERROR = 'UPDATETRANSLATIONS_ERROR';
export const UPDATETRANSLATIONS_SUCCESS = 'UPDATETRANSLATIONS_SUCCESS';

