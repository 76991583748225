// const CreateRepository = () => import('@/generated/ziqni/views/fileRepositories/CreateFileRepository.vue');
const CreateRepository = () => import('@/views/ziqni/fileRepositories/CreateFileRepository');
// const EditRepository = () => import('@/views/ziqni/web-assets/EditRepository');
const EditFileRepository = () => import('@/views/ziqni/fileRepositories/EditFileRepository');
const PreviewFileRepository = () => import('@/views/ziqni/fileRepositories/PreviewFileRepository');

//Files
const Files = () => import('@/views/ziqni/web-assets/PreviewRepository');
const UpdateFile = () => import('@/views/ziqni/web-assets/UpdateFile');

const FileOverview = () => import('@/views/ziqni/web-assets/FileOverview');
const FileEditor = () => import('@/views/ziqni/web-assets/FileEditor');
const WebBuilder = () => import('@/views/ziqni/web-assets/FileEditor/WebBuilder');

//Folders
const CreateFolder = () => import('@/views/ziqni/web-assets/CreateFolder');
const UpdateFolder = () => import('@/views/ziqni/web-assets/UpdateFile');
//
const WebAssets = () => import('@/views/ziqni/web-assets');

const PreviewFile = () => import('@/views/ziqni/web-assets/PreviewFile');
const EditFile = () => import('@/views/ziqni/web-assets/EditFile')

export default {
  path: 'cms',
  meta: {
    label: 'CMS',
  },
  component: {
    render(c) {
      return c('router-view');
    },
  },
  children: [
    {
      path: '',
      name: 'FileRepositorys',
      component: WebAssets,
    },
    {
      path: 'create-repository',
      name: 'CreateFileRepository',
      component: CreateRepository,
    },
    {
      path: 'edit-repository/:id',
      name: 'EditFileRepository',
      component: EditFileRepository,
    },
    {
      path: 'preview-repository/:id',
      name: 'PreviewFileRepository',
      component: PreviewFileRepository,
    },
    {
      path: 'preview-repository/:id/:fileId',
      name: 'PreviewFile',
      component: PreviewFile,
    },
    {
      path: 'preview-repository/:id/:fileId/edit',
      name: 'EditFile',
      component: EditFile,
    },
    {
      path: 'web-builder/:id/:fileId',
      name: 'WebBuilder',
      component: WebBuilder,
    },
    /*{
      path: 'preview-repository/:id/:name/properties',
      name: 'PreviewRepository',
      component: PreviewRepository,
    },*/
    {
      path: 'preview-repository/:id/:name',
      meta: {
        label: 'Repository',
      },
      component: {
        render(c) {
          return c('router-view');
        },
      },
      children: [
        {
          path: '',
          name: 'Repository',
          component: Files,
        },
        {
          path: 'file-overview/:fileId/:fileName',
          name: 'FileOverview',
          component: FileOverview,
        },
        {
          path: 'file-overview/:fileId/:fileName/file-editor',
          name: 'Editor',
          component: FileEditor,
        },
        {
          path: 'create-folder',
          name: 'CreateFolder',
          component: CreateFolder,
        },
        {
          path: 'update-file/:fileId/:fileName',
          name: 'UpdateFile',
          component: UpdateFile,
        },

        {
          path: 'update-folder/:fileId/:fileName',
          name: 'UpdateFolder',
          component: UpdateFolder,
        },
      ],
    },
  ],
}