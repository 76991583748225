// <<< DO NOT EDIT GENERATED FILES DIRECTLY! api-store-actions.mustache >>>
import { delay, cloneDeep } from 'lodash';
import * as types from './mutation-types';
import sdkApiConfig from '@/config/sdkApiConfig';
import updateFields from '@/utils/updateFields';
import { unitsOfMeasureApiZiqni } from '@/generated/ziqni/api/UnitsOfMeasureApiZiqni';
import router from '@/router';

import apiResponseFields from '../../../fields/ApiResponseZq.js';
import createUnitOfMeasureRequestFields from '../../../fields/CreateUnitOfMeasureRequestZq.js';
import queryRequestFields from '../../../fields/QueryRequestZq.js';
import unitOfMeasureResponseFields from '../../../fields/UnitOfMeasureResponseZq.js';
import updateUnitOfMeasureRequestFields from '../../../fields/UpdateUnitOfMeasureRequestZq.js';
import {handleRequest,handleRequestWithBody} from "../../../../../utils/ZiqniAdminApiHandler";

export const handleFields = ({ commit, state }, data) => {
  updateFields(commit, state, data, 'hiddenUnitsOfMeasureKey');
};

// Clear unitsOfMeasure Message
export const handleClearMessage = ({ commit }) => {
  commit(types.CLEAR_MESSAGE);
};

// clear filter
export const handleClearFilter = ({ commit }) => {
  commit(types.CLEAR_FILTER);
};

//UNITSOFMEASURE - UNITSOFMEASURE
export const handleUnitsOfMeasureChanges = ({ commit }, data) => {
  commit('SET_UNITSOFMEASURE', data);
};
/**
 * UnitOfMeasureResponse from backend.
 * GET getUnitsOfMeasure and return [UnitOfMeasureResponse]
 * vars: [
 *    [[id]] id[ opts, isArray, isQueryParam]
 *    [[limit]] _limit[ opts, isInteger, isQueryParam]
 *    [[skip]] _skip[ opts, isInteger, isQueryParam]]
 */
export const handleGetUnitsOfMeasure_item = ({ commit }, idArray, limit, skip) => {
  commit(types.API_REQUEST);
  let opts = { 
    id: idArray, 
    limit: limit, 
    skip: skip
  };

  return unitsOfMeasureApiZiqni
    .getUnitsOfMeasure(
      opts
    )
    .then((response) => {
      commit(types.GETUNITSOFMEASURE_ITEM_SUCCESS, { response });
      return response.results;
    })
    .catch((e) => {
      commit(types.GETUNITSOFMEASURE_ITEM_ERROR, e);
      return [];
    });
};

/**
 * ApiResponse from backend.
 * POST createUnitsOfMeasure and return [ApiResponse]
 * vars: [
 *    [[body]] CreateUnitOfMeasureRequest[ required, isArray, isBodyParam, ]]
 */
export const handleCreateUnitsOfMeasure = ({ commit }, payload) => {
  const { createUnitOfMeasureRequestArray } = payload;

  commit(types.API_REQUEST);
  

  return unitsOfMeasureApiZiqni
    .createUnitsOfMeasure(
      createUnitOfMeasureRequestArray
    )
    .then((response) => {
      commit(types.CREATEUNITSOFMEASURE_SUCCESS, { response });
      return response.results;
    })
    .catch((e) => {
      commit(types.CREATEUNITSOFMEASURE_ERROR, e);
      return [];
    });
};

/**
 * ApiResponse from backend.
 * POST deleteCollaboratorsByQuery and return [ApiResponse]
 * vars: [
 *    [[body]] QueryRequest[ opts, isBodyParam, ]]
 */
export const handleDeleteCollaboratorsByQuery = ({ commit }, payload) => {
  const { queryRequest } = payload;

  commit(types.API_REQUEST);
  let opts = { 
    body: queryRequest
  };

  return unitsOfMeasureApiZiqni
    .deleteCollaboratorsByQuery(
      opts
    )
    .then((response) => {
      commit(types.DELETECOLLABORATORSBYQUERY_SUCCESS, { response });
      return response.results;
    })
    .catch((e) => {
      commit(types.DELETECOLLABORATORSBYQUERY_ERROR, e);
      return [];
    });
};

/**
 * ApiResponse from backend.
 * DELETE deleteUnitsOfMeasure and return [ApiResponse]
 * vars: [
 *    [[id]] id[ opts, isArray, isQueryParam]]
 */
export const handleDeleteUnitsOfMeasure = ({ commit }, payload) => {
  const { idArray } = payload;

  commit(types.API_REQUEST);
  let opts = { 
    id: idArray
  };

  return unitsOfMeasureApiZiqni
    .deleteUnitsOfMeasure(
      opts
    )
    .then((response) => {
      commit(types.DELETEUNITSOFMEASURE_SUCCESS, { response });
      return response.results;
    })
    .catch((e) => {
      commit(types.DELETEUNITSOFMEASURE_ERROR, e);
      return [];
    });
};

/**
 * ApiResponse from backend.
 * POST deleteUnitsOfMeasureByQuery and return [ApiResponse]
 * vars: [
 *    [[body]] QueryRequest[ opts, isBodyParam, ]]
 */
export const handleDeleteUnitsOfMeasureByQuery = ({ commit }, payload) => {
  const { queryRequest } = payload;

  commit(types.API_REQUEST);
  let opts = { 
    body: queryRequest
  };

  return unitsOfMeasureApiZiqni
    .deleteUnitsOfMeasureByQuery(
      opts
    )
    .then((response) => {
      commit(types.DELETEUNITSOFMEASUREBYQUERY_SUCCESS, { response });
      return response.results;
    })
    .catch((e) => {
      commit(types.DELETEUNITSOFMEASUREBYQUERY_ERROR, e);
      return [];
    });
};

/**
 * UnitOfMeasureResponse from backend.
 * GET getUnitsOfMeasure and return [UnitOfMeasureResponse]
 * vars: [
 *    [[id]] id[ opts, isArray, isQueryParam]
 *    [[limit]] _limit[ opts, isInteger, isQueryParam]
 *    [[skip]] _skip[ opts, isInteger, isQueryParam]]
 */
export const handleGetUnitsOfMeasure = ({ commit }, payload) => {
  const { idArray, limit, skip } = payload;

  commit(types.API_REQUEST);
  let opts = { 
    id: idArray, 
    limit: limit, 
    skip: skip
  };

  return unitsOfMeasureApiZiqni
    .getUnitsOfMeasure(
      opts
    )
    .then((response) => {
      commit(types.GETUNITSOFMEASURE_SUCCESS, { response });
      return response.results;
    })
    .catch((e) => {
      commit(types.GETUNITSOFMEASURE_ERROR, e);
      return [];
    });
};

/**
 * UnitOfMeasureResponse from backend.
 * POST getUnitsOfMeasureByQuery and return [UnitOfMeasureResponse]
 * vars: [
 *    [[body]] QueryRequest[ opts, isBodyParam, ]]
 */
export const handleGetUnitsOfMeasureByQuery = ({ commit }, payload) => {
  const { queryRequest } = payload;

  commit(types.API_REQUEST);
  let opts = { 
    body: queryRequest
  };

  return unitsOfMeasureApiZiqni
    .getUnitsOfMeasureByQuery(
      opts
    )
    .then((response) => {
      commit(types.GETUNITSOFMEASUREBYQUERY_SUCCESS, { response });
      return response.results;
    })
    .catch((e) => {
      commit(types.GETUNITSOFMEASUREBYQUERY_ERROR, e);
      return [];
    });
};

/**
 * ApiResponse from backend.
 * PUT updateUnitsOfMeasure and return [ApiResponse]
 * vars: [
 *    [[body]] UpdateUnitOfMeasureRequest[ required, isArray, isBodyParam, ]]
 */
export const handleUpdateUnitsOfMeasure = ({ commit }, payload) => {
  const { updateUnitOfMeasureRequestArray } = payload;

  commit(types.API_REQUEST);
  

  return unitsOfMeasureApiZiqni
    .updateUnitsOfMeasure(
      updateUnitOfMeasureRequestArray
    )
    .then((response) => {
      commit(types.UPDATEUNITSOFMEASURE_SUCCESS, { response });
      return response.results;
    })
    .catch((e) => {
      commit(types.UPDATEUNITSOFMEASURE_ERROR, e);
      return [];
    });
};
