// <<< DO NOT EDIT GENERATED FILES DIRECTLY! api-store-actions.mustache >>>
import { delay, cloneDeep } from 'lodash';
import * as types from './mutation-types';
import sdkApiConfig from '@/config/sdkApiConfig';
import updateFields from '@/utils/updateFields';
import { rulesApiZiqni } from '@/generated/ziqni/api/RulesApiZiqni';
import router from '@/router';

import apiResponseFields from '../../../fields/ApiResponseZq.js';
import queryRequestFields from '../../../fields/QueryRequestZq.js';
import responseFields from '../../../fields/ResponseZq.js';
import ruleOperandLookupRequestFields from '../../../fields/RuleOperandLookupRequestZq.js';
import ruleOperandLookupResponseFields from '../../../fields/RuleOperandLookupResponseZq.js';
import ruleResponseFields from '../../../fields/RuleResponseZq.js';
import updateRuleRequestFields from '../../../fields/UpdateRuleRequestZq.js';
import {handleRequest,handleRequestWithBody} from "../../../../../utils/ZiqniAdminApiHandler";

export const handleFields = ({ commit, state }, data) => {
  updateFields(commit, state, data, 'hiddenRulesKey');
};

// Clear rules Message
export const handleClearMessage = ({ commit }) => {
  commit(types.CLEAR_MESSAGE);
};

// clear filter
export const handleClearFilter = ({ commit }) => {
  commit(types.CLEAR_FILTER);
};

//RULES - RULES
export const handleRulesChanges = ({ commit }, data) => {
  commit('SET_RULES', data);
};
/**
 * RuleResponse from backend.
 * GET getRules and return [RuleResponse]
 * vars: [
 *    [[id]] id[ opts, isArray, isQueryParam]
 *    [[limit]] _limit[ opts, isInteger, isQueryParam]
 *    [[skip]] _skip[ opts, isInteger, isQueryParam]]
 */
export const handleGetRules_item = ({ commit }, idArray, limit, skip) => {
  commit(types.API_REQUEST);
  let opts = { 
    id: idArray, 
    limit: limit, 
    skip: skip
  };

  return rulesApiZiqni
    .getRules(
      opts
    )
    .then((response) => {
      commit(types.GETRULES_ITEM_SUCCESS, { response });
      return response.results;
    })
    .catch((e) => {
      commit(types.GETRULES_ITEM_ERROR, e);
      return [];
    });
};

/**
 * ApiResponse from backend.
 * POST deleteRulesByQuery and return [ApiResponse]
 * vars: [
 *    [[body]] QueryRequest[ opts, isBodyParam, ]]
 */
export const handleDeleteRulesByQuery = ({ commit }, payload) => {
  const { queryRequest } = payload;

  commit(types.API_REQUEST);
  let opts = { 
    body: queryRequest
  };

  return rulesApiZiqni
    .deleteRulesByQuery(
      opts
    )
    .then((response) => {
      commit(types.DELETERULESBYQUERY_SUCCESS, { response });
      return response.results;
    })
    .catch((e) => {
      commit(types.DELETERULESBYQUERY_ERROR, e);
      return [];
    });
};

/**
 * Response from backend.
 * GET getRuleSchemas and return [Response]
 * vars: [
 *    [[context]] context[ required, isString, isQueryParam]]
 */
export const handleGetRuleSchemas = ({ commit }, payload) => {
  const { context } = payload;

  commit(types.API_REQUEST);
  

  return rulesApiZiqni
    .getRuleSchemas(
      context
    )
    .then((response) => {
      commit(types.GETRULESCHEMAS_SUCCESS, { response });
      return response.results;
    })
    .catch((e) => {
      commit(types.GETRULESCHEMAS_ERROR, e);
      return [];
    });
};

/**
 * RuleResponse from backend.
 * GET getRules and return [RuleResponse]
 * vars: [
 *    [[id]] id[ opts, isArray, isQueryParam]
 *    [[limit]] _limit[ opts, isInteger, isQueryParam]
 *    [[skip]] _skip[ opts, isInteger, isQueryParam]]
 */
export const handleGetRules = ({ commit }, payload) => {
  const { idArray, limit, skip } = payload;

  commit(types.API_REQUEST);
  let opts = { 
    id: idArray, 
    limit: limit, 
    skip: skip
  };

  return rulesApiZiqni
    .getRules(
      opts
    )
    .then((response) => {
      commit(types.GETRULES_SUCCESS, { response });
      return response.results;
    })
    .catch((e) => {
      commit(types.GETRULES_ERROR, e);
      return [];
    });
};

/**
 * RuleResponse from backend.
 * POST getRulesByQuery and return [RuleResponse]
 * vars: [
 *    [[body]] QueryRequest[ opts, isBodyParam, ]]
 */
export const handleGetRulesByQuery = ({ commit }, payload) => {
  const { queryRequest } = payload;

  commit(types.API_REQUEST);
  let opts = { 
    body: queryRequest
  };

  return rulesApiZiqni
    .getRulesByQuery(
      opts
    )
    .then((response) => {
      commit(types.GETRULESBYQUERY_SUCCESS, { response });
      return response.results;
    })
    .catch((e) => {
      commit(types.GETRULESBYQUERY_ERROR, e);
      return [];
    });
};

/**
 * RuleOperandLookupResponse from backend.
 * POST lookupOperandsRules and return [RuleOperandLookupResponse]
 * vars: [
 *    [[body]] RuleOperandLookupRequest[ required, isArray, isBodyParam, ]]
 */
export const handleLookupOperandsRules = ({ commit }, payload) => {
  const { ruleOperandLookupRequestArray } = payload;

  commit(types.API_REQUEST);
  

  return rulesApiZiqni
    .lookupOperandsRules(
      ruleOperandLookupRequestArray
    )
    .then((response) => {
      commit(types.LOOKUPOPERANDSRULES_SUCCESS, { response });
      return response.results;
    })
    .catch((e) => {
      commit(types.LOOKUPOPERANDSRULES_ERROR, e);
      return [];
    });
};

/**
 * ApiResponse from backend.
 * PUT updateRules and return [ApiResponse]
 * vars: [
 *    [[body]] UpdateRuleRequest[ required, isArray, isBodyParam, ]]
 */
export const handleUpdateRules = ({ commit }, payload) => {
  const { updateRuleRequestArray } = payload;

  commit(types.API_REQUEST);
  

  return rulesApiZiqni
    .updateRules(
      updateRuleRequestArray
    )
    .then((response) => {
      commit(types.UPDATERULES_SUCCESS, { response });
      return response.results;
    })
    .catch((e) => {
      commit(types.UPDATERULES_ERROR, e);
      return [];
    });
};

/**
 * ApiResponse from backend.
 * POST validateRules and return [ApiResponse]
 * vars: [
 *    [[body]] UpdateRuleRequest[ required, isArray, isBodyParam, ]]
 */
export const handleValidateRules = ({ commit }, payload) => {
  const { updateRuleRequestArray } = payload;

  commit(types.API_REQUEST);
  

  return rulesApiZiqni
    .validateRules(
      updateRuleRequestArray
    )
    .then((response) => {
      commit(types.VALIDATERULES_SUCCESS, { response });
      return response.results;
    })
    .catch((e) => {
      commit(types.VALIDATERULES_ERROR, e);
      return [];
    });
};
