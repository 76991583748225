import {sdkApiConfig} from '@/config'

/**
 * Converts table query, sorting and pagination properties into CL POST query structure
 * This method will handle dynamic logic for deciding when to use Should, MultiField search or any other rage query
 * type based on the defined values
 *
 *  - accepts an Object map with "limit", "skip", "sortBy" and "query"
 *  - limit/skip: is used to paginate through the data (default settings come from the global Configuration)
 *  - sortBy: allows to order data in ASC/DESC
 *  - query: accepts the query Object map construct built by the table library and extracts the keys and values
 *
 * @param queryOpt {{limit: (Int), skip: (Int), sortBy: (*|[]), query: {}}}
 * @returns {{multiFields: [], limit: (*), should: [], skip: (*), sortBy: (*|[])}}
 */
export const queryConverter = function(queryOpt) {
  var options = {
    limit: queryOpt.limit || sdkApiConfig.limit,
    skip: queryOpt.skip || sdkApiConfig.skip,
    should: [],
    multiFields: [],
    sortBy: queryOpt.sortBy || [],
  };

  if (typeof queryOpt.query !== 'undefined') {
    // extract query parameters from the "query" object and construct a should (exact match) query
    for (var key in queryOpt.query) {
      if (queryOpt.query.hasOwnProperty(key)) {
        options.should.push({
          queryField: key,
          queryValues: queryOpt.query[key].split(','),
        });
      }
    }

    //TODO: once update comes out needs to be updated to support multi fields
    // if the lookup is for a single column use the multiFields lookup that utilises partial string search
    if (
      options.should.length === 1 &&
      options.should[0]['queryValues'].length === 1 &&
      options.should[0]['queryField'] !== 'id'
    ) {
      options.multiFields = {
        queryFields: [options.should[0]['queryField']],
        queryValue: options.should[0]['queryValues'][0],
      };
      options.should = [];
    }
  }

  return options;
};
