// <<< DO NOT EDIT GENERATED FILES DIRECTLY! api-store-mutation-types.mustache >>>
export const API_REQUEST = 'API_REQUEST';


export const GETTRANSFORMERS_ITEM_REQ = 'GETTRANSFORMERS_ITEM_REQ';
export const GETTRANSFORMERS_ITEM_ERROR = 'GETTRANSFORMERS_ITEM_ERROR';
export const GETTRANSFORMERS_ITEM_SUCCESS = 'GETTRANSFORMERS_ITEM_SUCCESS'

export const UPDATE_FIELDS = 'UPDATE_FIELDS';
export const CLEAR_FILTER = 'CLEAR_FILTER';
export const CLEAR_MESSAGE = 'CLEAR_MESSAGE';

export const CREATETRANSFORMERS_REQ = 'CREATETRANSFORMERS_REQ';
export const CREATETRANSFORMERS_ERROR = 'CREATETRANSFORMERS_ERROR';
export const CREATETRANSFORMERS_SUCCESS = 'CREATETRANSFORMERS_SUCCESS';

export const DELETETRANSFORMERS_REQ = 'DELETETRANSFORMERS_REQ';
export const DELETETRANSFORMERS_ERROR = 'DELETETRANSFORMERS_ERROR';
export const DELETETRANSFORMERS_SUCCESS = 'DELETETRANSFORMERS_SUCCESS';

export const DELETETRANSFORMERSBYQUERY_REQ = 'DELETETRANSFORMERSBYQUERY_REQ';
export const DELETETRANSFORMERSBYQUERY_ERROR = 'DELETETRANSFORMERSBYQUERY_ERROR';
export const DELETETRANSFORMERSBYQUERY_SUCCESS = 'DELETETRANSFORMERSBYQUERY_SUCCESS';

export const GETTRANSFORMERS_REQ = 'GETTRANSFORMERS_REQ';
export const GETTRANSFORMERS_ERROR = 'GETTRANSFORMERS_ERROR';
export const GETTRANSFORMERS_SUCCESS = 'GETTRANSFORMERS_SUCCESS';

export const GETTRANSFORMERSBYQUERY_REQ = 'GETTRANSFORMERSBYQUERY_REQ';
export const GETTRANSFORMERSBYQUERY_ERROR = 'GETTRANSFORMERSBYQUERY_ERROR';
export const GETTRANSFORMERSBYQUERY_SUCCESS = 'GETTRANSFORMERSBYQUERY_SUCCESS';

export const UPDATETRANSFORMERS_REQ = 'UPDATETRANSFORMERS_REQ';
export const UPDATETRANSFORMERS_ERROR = 'UPDATETRANSFORMERS_ERROR';
export const UPDATETRANSFORMERS_SUCCESS = 'UPDATETRANSFORMERS_SUCCESS';

