// <<< DO NOT EDIT GENERATED FILES DIRECTLY! api-store-actions.mustache >>>
import { delay, cloneDeep } from 'lodash';
import * as types from './mutation-types';
import sdkApiConfig from '@/config/sdkApiConfig';
import updateFields from '@/utils/updateFields';
import { computeEnginesApiZiqni } from '@/generated/ziqni/api/ComputeEnginesApiZiqni';
import router from '@/router';

import apiResponseFields from '../../../fields/ApiResponseZq.js';
import computeEngineResponseFields from '../../../fields/ComputeEngineResponseZq.js';
import createComputeEngineRequestFields from '../../../fields/CreateComputeEngineRequestZq.js';
import queryRequestFields from '../../../fields/QueryRequestZq.js';
import updateComputeEngineRequestFields from '../../../fields/UpdateComputeEngineRequestZq.js';
import {handleRequest,handleRequestWithBody} from "../../../../../utils/ZiqniAdminApiHandler";

export const handleFields = ({ commit, state }, data) => {
  updateFields(commit, state, data, 'hiddenComputeEnginesKey');
};

// Clear computeEngines Message
export const handleClearMessage = ({ commit }) => {
  commit(types.CLEAR_MESSAGE);
};

// clear filter
export const handleClearFilter = ({ commit }) => {
  commit(types.CLEAR_FILTER);
};

//COMPUTEENGINES - COMPUTEENGINES
export const handleComputeEnginesChanges = ({ commit }, data) => {
  commit('SET_COMPUTEENGINES', data);
};
/**
 * ComputeEngineResponse from backend.
 * GET getComputeEngines and return [ComputeEngineResponse]
 * vars: [
 *    [[id]] id[ opts, isArray, isQueryParam]
 *    [[limit]] _limit[ opts, isInteger, isQueryParam]
 *    [[skip]] _skip[ opts, isInteger, isQueryParam]]
 */
export const handleGetComputeEngines_item = ({ commit }, idArray, limit, skip) => {
  commit(types.API_REQUEST);
  let opts = { 
    id: idArray, 
    limit: limit, 
    skip: skip
  };

  return computeEnginesApiZiqni
    .getComputeEngines(
      opts
    )
    .then((response) => {
      commit(types.GETCOMPUTEENGINES_ITEM_SUCCESS, { response });
      return response.results;
    })
    .catch((e) => {
      commit(types.GETCOMPUTEENGINES_ITEM_ERROR, e);
      return [];
    });
};

/**
 * ApiResponse from backend.
 * POST createComputeEngines and return [ApiResponse]
 * vars: [
 *    [[body]] CreateComputeEngineRequest[ required, isBodyParam, ]]
 */
export const handleCreateComputeEngines = ({ commit }, payload) => {
  const { createComputeEngineRequest } = payload;

  commit(types.API_REQUEST);
  

  return computeEnginesApiZiqni
    .createComputeEngines(
      createComputeEngineRequest
    )
    .then((response) => {
      commit(types.CREATECOMPUTEENGINES_SUCCESS, { response });
      return response.results;
    })
    .catch((e) => {
      commit(types.CREATECOMPUTEENGINES_ERROR, e);
      return [];
    });
};

/**
 * ApiResponse from backend.
 * DELETE deleteComputeEnginesById and return [ApiResponse]
 * vars: [
 *    [[id]] id[ required, isString, isPathParam, ]]
 */
export const handleDeleteComputeEnginesById = ({ commit }, payload) => {
  const { id } = payload;

  commit(types.API_REQUEST);
  

  return computeEnginesApiZiqni
    .deleteComputeEnginesById(
      id
    )
    .then((response) => {
      commit(types.DELETECOMPUTEENGINESBYID_SUCCESS, { response });
      return response.results;
    })
    .catch((e) => {
      commit(types.DELETECOMPUTEENGINESBYID_ERROR, e);
      return [];
    });
};

/**
 * ApiResponse from backend.
 * POST deleteComputeEnginesByQuery and return [ApiResponse]
 * vars: [
 *    [[body]] QueryRequest[ opts, isBodyParam, ]]
 */
export const handleDeleteComputeEnginesByQuery = ({ commit }, payload) => {
  const { queryRequest } = payload;

  commit(types.API_REQUEST);
  let opts = { 
    body: queryRequest
  };

  return computeEnginesApiZiqni
    .deleteComputeEnginesByQuery(
      opts
    )
    .then((response) => {
      commit(types.DELETECOMPUTEENGINESBYQUERY_SUCCESS, { response });
      return response.results;
    })
    .catch((e) => {
      commit(types.DELETECOMPUTEENGINESBYQUERY_ERROR, e);
      return [];
    });
};

/**
 * ComputeEngineResponse from backend.
 * GET getComputeEngines and return [ComputeEngineResponse]
 * vars: [
 *    [[id]] id[ opts, isArray, isQueryParam]
 *    [[limit]] _limit[ opts, isInteger, isQueryParam]
 *    [[skip]] _skip[ opts, isInteger, isQueryParam]]
 */
export const handleGetComputeEngines = ({ commit }, payload) => {
  const { idArray, limit, skip } = payload;

  commit(types.API_REQUEST);
  let opts = { 
    id: idArray, 
    limit: limit, 
    skip: skip
  };

  return computeEnginesApiZiqni
    .getComputeEngines(
      opts
    )
    .then((response) => {
      commit(types.GETCOMPUTEENGINES_SUCCESS, { response });
      return response.results;
    })
    .catch((e) => {
      commit(types.GETCOMPUTEENGINES_ERROR, e);
      return [];
    });
};

/**
 * ComputeEngineResponse from backend.
 * GET getComputeEnginesById and return [ComputeEngineResponse]
 * vars: [
 *    [[id]] id[ required, isString, isPathParam, ]]
 */
export const handleGetComputeEnginesById = ({ commit }, payload) => {
  const { id } = payload;

  commit(types.API_REQUEST);
  

  return computeEnginesApiZiqni
    .getComputeEnginesById(
      id
    )
    .then((response) => {
      commit(types.GETCOMPUTEENGINESBYID_SUCCESS, { response });
      return response.results;
    })
    .catch((e) => {
      commit(types.GETCOMPUTEENGINESBYID_ERROR, e);
      return [];
    });
};

/**
 * ComputeEngineResponse from backend.
 * POST getComputeEnginesByQuery and return [ComputeEngineResponse]
 * vars: [
 *    [[body]] QueryRequest[ opts, isBodyParam, ]]
 */
export const handleGetComputeEnginesByQuery = ({ commit }, payload) => {
  const { queryRequest } = payload;

  commit(types.API_REQUEST);
  let opts = { 
    body: queryRequest
  };

  return computeEnginesApiZiqni
    .getComputeEnginesByQuery(
      opts
    )
    .then((response) => {
      commit(types.GETCOMPUTEENGINESBYQUERY_SUCCESS, { response });
      return response.results;
    })
    .catch((e) => {
      commit(types.GETCOMPUTEENGINESBYQUERY_ERROR, e);
      return [];
    });
};

/**
 * ApiResponse from backend.
 * PUT updateComputeEngines and return [ApiResponse]
 * vars: [
 *    [[body]] UpdateComputeEngineRequest[ required, isBodyParam, ]]
 */
export const handleUpdateComputeEngines = ({ commit }, payload) => {
  const { updateComputeEngineRequest } = payload;

  commit(types.API_REQUEST);
  

  return computeEnginesApiZiqni
    .updateComputeEngines(
      updateComputeEngineRequest
    )
    .then((response) => {
      commit(types.UPDATECOMPUTEENGINES_SUCCESS, { response });
      return response.results;
    })
    .catch((e) => {
      commit(types.UPDATECOMPUTEENGINES_ERROR, e);
      return [];
    });
};
