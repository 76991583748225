/**
 * Simple error handler
 * @param error (*)
 * @param responseObj {{ errors: [(*)] }}
 * @param responseString {String}
 * @returns {[]}
 */
export const errorHandler = function(error, responseObj, responseString) {
  if (responseObj === null && error !== null) {
    responseObj =
      typeof responseString === 'string'
        ? JSON.parse(responseString).body
        : responseString.body;
  }

  let errors = [];

  if (responseObj.errors && responseObj.errors.length > 0) {
    if (Array.isArray(responseObj.errors)) {
      responseObj.errors.forEach(error => {
        if (error.detail) {
          error.detail.forEach(detail => {
            errors.push(detail.message);
          });
        } else {
          errors.push(error.message);
        }
      });
    } else if (typeof responseObj.errors === 'string') {
      errors.push(responseObj.errors);
    }
  }

  return errors;
};
