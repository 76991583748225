// <<< DO NOT EDIT GENERATED FILES DIRECTLY! model-store-fields-all.mustache >>>


import contestStatus from './ContestStatusZq';
import roundType from './RoundTypeZq';
import tournamentStrategies from './TournamentStrategiesZq';

const contestAllOf = this;

export default {
  objectType: 'Contest_allOf',
  allowableValuesKeys: [],
  constraints: ['isMap',],
  getter: null,
  hiddenFields: [],
  searchableFields: ['row','name','description','termsAndConditions','round','minNumberOfEntrants','scheduledStartDate','scheduledEndDate','actualStartDate','actualEndDate','statusCode',], 
  searchableAllFields: ['name','description','termsAndConditions',],
  translatableFields: ['name','description','termsAndConditions',],
  sortableFields: [],
  customFieldsFieldName: [],
  metadataFieldName: [],
  tagFieldName: [],
  entityLinksFrom: [],

  route: {
    
    
  },
  fieldList: ['competitionId','row','name','description','termsAndConditions','round','roundType','entrantsFromContest','maxNumberOfEntrants','minNumberOfEntrants','scheduledStartDate','scheduledEndDate','actualStartDate','actualEndDate','strategies','status','statusCode','constraints','icon','banner','bannerLowResolution','bannerHighResolution'],
  fields: { 
    competitionId: {
      name: 'competitionId',
      complexType: null,
      displayOrder: 1001,
      allowableValuesKeys: [],
      constraints: ['required','isPrimitiveType','isString',
                     ],
      formType: null,
    },
    row: {
      name: 'row',
      complexType: null,
      displayOrder: 1006,
      allowableValuesKeys: [],
      constraints: ['required','isPrimitiveType','isNumeric','isInteger','isShort',
                     ],
      formType: null,
    },
    name: {
      name: 'name',
      complexType: null,
      displayOrder: 1001,
      allowableValuesKeys: [],
      constraints: ['required','isPrimitiveType','isString',
                     ],
      formType: null,
    },
    description: {
      name: 'description',
      complexType: null,
      displayOrder: 1002,
      allowableValuesKeys: [],
      constraints: ['isPrimitiveType','isString',
                     ],
      formType: 'TEXTAREA',
    },
    termsAndConditions: {
      name: 'termsAndConditions',
      complexType: null,
      displayOrder: 1002,
      allowableValuesKeys: [],
      constraints: ['isPrimitiveType','isString',
                     ],
      formType: 'TEXTAREA',
    },
    round: {
      name: 'round',
      complexType: null,
      displayOrder: 1006,
      allowableValuesKeys: [],
      constraints: ['required','isPrimitiveType','isNumeric','isInteger','isShort',
                     ],
      formType: null,
    },
    roundType: {
      name: 'roundType',
      complexType: roundType,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['required',
                     ],
      formType: null,
    },
    entrantsFromContest: {
      name: 'entrantsFromContest',
      complexType: null,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['required','isPrimitiveType','isContainer','isArray',
                    'isString', ],
      formType: null,
    },
    maxNumberOfEntrants: {
      name: 'maxNumberOfEntrants',
      complexType: null,
      displayOrder: 1001,
      allowableValuesKeys: [],
      constraints: ['isPrimitiveType','isNumeric','isInteger','isShort',
                     ],
      formType: null,
    },
    minNumberOfEntrants: {
      name: 'minNumberOfEntrants',
      complexType: null,
      displayOrder: 2001,
      allowableValuesKeys: [],
      constraints: ['required','isPrimitiveType','isNumeric','isInteger','isShort',
                     ],
      formType: null,
    },
    scheduledStartDate: {
      name: 'scheduledStartDate',
      complexType: null,
      displayOrder: 1004,
      allowableValuesKeys: [],
      constraints: ['required','isPrimitiveType','isDateTime',
                     ],
      formType: null,
    },
    scheduledEndDate: {
      name: 'scheduledEndDate',
      complexType: null,
      displayOrder: 1004,
      allowableValuesKeys: [],
      constraints: ['required','isPrimitiveType','isDateTime',
                     ],
      formType: null,
    },
    actualStartDate: {
      name: 'actualStartDate',
      complexType: null,
      displayOrder: 1004,
      allowableValuesKeys: [],
      constraints: ['isPrimitiveType','isDateTime','isReadOnly',
                     ],
      formType: null,
    },
    actualEndDate: {
      name: 'actualEndDate',
      complexType: null,
      displayOrder: 1004,
      allowableValuesKeys: [],
      constraints: ['isPrimitiveType','isDateTime','isReadOnly',
                     ],
      formType: null,
    },
    strategies: {
      name: 'strategies',
      complexType: tournamentStrategies,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['isModel',
                     ],
      formType: null,
    },
    status: {
      name: 'status',
      complexType: contestStatus,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['required',
                     ],
      formType: null,
    },
    statusCode: {
      name: 'statusCode',
      complexType: null,
      displayOrder: 1006,
      allowableValuesKeys: [],
      constraints: ['required','isPrimitiveType','isNumeric','isInteger','isUnboundedInteger','isReadOnly',
                     ],
      formType: null,
    },
    constraints: {
      name: 'constraints',
      complexType: null,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['isConstraintsArray','required','isPrimitiveType','isContainer','isArray',
                    'isString', ],
      formType: null,
    },
    icon: {
      name: 'icon',
      complexType: null,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['isPrimitiveType','isString',
                     ],
      formType: null,
    },
    banner: {
      name: 'banner',
      complexType: null,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['isPrimitiveType','isString',
                     ],
      formType: null,
    },
    bannerLowResolution: {
      name: 'bannerLowResolution',
      complexType: null,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['isPrimitiveType','isString',
                     ],
      formType: null,
    },
    bannerHighResolution: {
      name: 'bannerHighResolution',
      complexType: null,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['isPrimitiveType','isString',
                     ],
      formType: null,
    }
  }
};
