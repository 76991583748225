const Template = () => import('@/views/ziqni/messages/template');

export default  {
  path: 'template',
  meta: {
    label: 'Messages'
  },
  component: {
    render(c) {
      return c('router-view')
    }
  },
  children: [
    {
      path: '/',
      name: 'Template',
      component: Template,

      meta: {
        label: 'Template',
      },
    },
    /* {
       path: 'create-draft.js-message',
       name: 'CreateDraft',
       component: CreateDraftMessage,
       meta: {
         label: 'Create Draft',
       },
     },*/
    /* {
       path: ':id',
       name: 'PreviewDraftMessage',
       component: PreviewDraftMessage,
       meta: {
         label: 'Preview Draft Message',
       },
     },*/
  ],
}