// <<< DO NOT EDIT GENERATED FILES DIRECTLY! model-store-fields-all.mustache >>>


import eventRefType from './EventRefTypeZq';
import messageLink from './MessageLinkZq';
import messageStatus from './MessageStatusZq';
import messageType from './MessageTypeZq';
import translation from './TranslationZq';

const createMemberMessageRequestAllOf = this;

export default {
  objectType: 'CreateMemberMessageRequest_allOf',
  allowableValuesKeys: [],
  constraints: ['isMap',],
  getter: null,
  hiddenFields: [],
  searchableFields: [], 
  searchableAllFields: [],
  translatableFields: [],
  sortableFields: [],
  customFieldsFieldName: [],
  metadataFieldName: [],
  tagFieldName: [],
  entityLinksFrom: [],

  route: {
    
    
  },
  fieldList: ['eventRefType','eventRefId','messageType','subject','body','status','expireAfterDate','expireAfterDays','links','memberIds','translations'],
  fields: { 
    eventRefType: {
      name: 'eventRefType',
      complexType: eventRefType,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['required',
                     ],
      formType: null,
    },
    eventRefId: {
      name: 'eventRefId',
      complexType: null,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['isPrimitiveType','isString',
                     ],
      formType: null,
    },
    messageType: {
      name: 'messageType',
      complexType: messageType,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['required',
                     ],
      formType: null,
    },
    subject: {
      name: 'subject',
      complexType: null,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['required','isPrimitiveType','isString',
                     ],
      formType: null,
    },
    body: {
      name: 'body',
      complexType: null,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['required','isPrimitiveType','isString',
                     ],
      formType: 'TEXTAREA',
    },
    status: {
      name: 'status',
      complexType: messageStatus,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['required',
                     ],
      formType: null,
    },
    expireAfterDate: {
      name: 'expireAfterDate',
      complexType: null,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['isPrimitiveType','isDateTime',
                     ],
      formType: null,
    },
    expireAfterDays: {
      name: 'expireAfterDays',
      complexType: null,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['isPrimitiveType','isNumeric','isInteger','isShort',
                     ],
      formType: null,
    },
    links: {
      name: 'links',
      complexType: messageLink,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['required','isContainer','isArray',
                    'isModel', ],
      formType: null,
    },
    memberIds: {
      name: 'memberIds',
      complexType: null,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['isPrimitiveType','isContainer','isArray',
                    'isString', ],
      formType: null,
    },
    translations: {
      name: 'translations',
      complexType: translation,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['isContainer','isArray',
                    'isModel', ],
      formType: null,
    }
  }
};
