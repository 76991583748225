// <<< DO NOT EDIT GENERATED FILES DIRECTLY! >>>


















export const transformers = state => state.transformers; 
export const transformer = state => state.transformer; 















export const sortableFields = state => state.sortableFields;
export const fields = state => state.fields;
export const pages = state => state.pages;
export const loading = state => state.loading;
export const resultCount = state => state.resultCount;
export const totalRecords = state => state.totalRecords;
export const originalFields = state => state.originalFields;
export const searchableAllFields = state => state.searchableAllFields;
export const message = state => state.message;
export const success = state => state.success;
