// <<< DO NOT EDIT GENERATED FILES DIRECTLY! api-store-mutation-types.mustache >>>
export const API_REQUEST = 'API_REQUEST';


export const GETCONTESTS_ITEM_REQ = 'GETCONTESTS_ITEM_REQ';
export const GETCONTESTS_ITEM_ERROR = 'GETCONTESTS_ITEM_ERROR';
export const GETCONTESTS_ITEM_SUCCESS = 'GETCONTESTS_ITEM_SUCCESS'

export const UPDATE_FIELDS = 'UPDATE_FIELDS';
export const CLEAR_FILTER = 'CLEAR_FILTER';
export const CLEAR_MESSAGE = 'CLEAR_MESSAGE';

export const CREATECONTESTS_REQ = 'CREATECONTESTS_REQ';
export const CREATECONTESTS_ERROR = 'CREATECONTESTS_ERROR';
export const CREATECONTESTS_SUCCESS = 'CREATECONTESTS_SUCCESS';

export const GETCHILDRENOFCONTEST_REQ = 'GETCHILDRENOFCONTEST_REQ';
export const GETCHILDRENOFCONTEST_ERROR = 'GETCHILDRENOFCONTEST_ERROR';
export const GETCHILDRENOFCONTEST_SUCCESS = 'GETCHILDRENOFCONTEST_SUCCESS';

export const GETCONTESTS_REQ = 'GETCONTESTS_REQ';
export const GETCONTESTS_ERROR = 'GETCONTESTS_ERROR';
export const GETCONTESTS_SUCCESS = 'GETCONTESTS_SUCCESS';

export const GETCONTESTSBYQUERY_REQ = 'GETCONTESTSBYQUERY_REQ';
export const GETCONTESTSBYQUERY_ERROR = 'GETCONTESTSBYQUERY_ERROR';
export const GETCONTESTSBYQUERY_SUCCESS = 'GETCONTESTSBYQUERY_SUCCESS';

export const GETCONTESTSLEADERBOARDBYID_REQ = 'GETCONTESTSLEADERBOARDBYID_REQ';
export const GETCONTESTSLEADERBOARDBYID_ERROR = 'GETCONTESTSLEADERBOARDBYID_ERROR';
export const GETCONTESTSLEADERBOARDBYID_SUCCESS = 'GETCONTESTSLEADERBOARDBYID_SUCCESS';

export const GETCONTESTSTOCLONE_REQ = 'GETCONTESTSTOCLONE_REQ';
export const GETCONTESTSTOCLONE_ERROR = 'GETCONTESTSTOCLONE_ERROR';
export const GETCONTESTSTOCLONE_SUCCESS = 'GETCONTESTSTOCLONE_SUCCESS';

export const GETLISTOFENTRANTSFORCONTEST_REQ = 'GETLISTOFENTRANTSFORCONTEST_REQ';
export const GETLISTOFENTRANTSFORCONTEST_ERROR = 'GETLISTOFENTRANTSFORCONTEST_ERROR';
export const GETLISTOFENTRANTSFORCONTEST_SUCCESS = 'GETLISTOFENTRANTSFORCONTEST_SUCCESS';

export const GETPARENTSOFCONTEST_REQ = 'GETPARENTSOFCONTEST_REQ';
export const GETPARENTSOFCONTEST_ERROR = 'GETPARENTSOFCONTEST_ERROR';
export const GETPARENTSOFCONTEST_SUCCESS = 'GETPARENTSOFCONTEST_SUCCESS';

export const UPDATECONTEST_REQ = 'UPDATECONTEST_REQ';
export const UPDATECONTEST_ERROR = 'UPDATECONTEST_ERROR';
export const UPDATECONTEST_SUCCESS = 'UPDATECONTEST_SUCCESS';

export const UPDATECONTESTSSTATE_REQ = 'UPDATECONTESTSSTATE_REQ';
export const UPDATECONTESTSSTATE_ERROR = 'UPDATECONTESTSSTATE_ERROR';
export const UPDATECONTESTSSTATE_SUCCESS = 'UPDATECONTESTSSTATE_SUCCESS';

