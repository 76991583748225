// <<< DO NOT EDIT GENERATED FILES DIRECTLY! api-store-mutations.mustache >>>
import * as types from './mutation-types';
import * as _ from 'lodash';
import {
  handleErrorResponse, handleItemErrorResponse,
  handleItemSuccessResponse,
  handleSuccessResponse
} from "../../../../../utils/ZiqniAdminApiHandler";
import apiResponseFields from '../../../fields/ApiResponseZq.js';
import createInstantWinRequestFields from '../../../fields/CreateInstantWinRequestZq.js';
import instantWinResponseFields from '../../../fields/InstantWinResponseZq.js';
import queryRequestFields from '../../../fields/QueryRequestZq.js';
import updateInstantWinRequestFields from '../../../fields/UpdateInstantWinRequestZq.js';

const mutations = {
    [types.API_REQUEST](state) {
        state.loading = true;
        state.loaded = false;
    },
    [types.CLEAR_MESSAGE](state){
        state.message = null;
    },

    [types.UPDATE_FIELDS](state, data) {
        state.hiddenFields = data._hiddenFields;
        state.fields = _.difference(state.originalFields, state.hiddenFields);
        localStorage.setItem('hiddenInstantWinsKey', JSON.stringify(state.hiddenFields));
    },

    [types.CLEAR_FILTER](state) {
        state.fields = _.difference(
            state.originalFields,
            state.originHiddenFields
        );
        state.hiddenFields = state.originHiddenFields;
        localStorage.removeItem('hiddenInstantWinsKey');
    },

    //InstantWinResponse
    [types.GETINSTANTWINS_ITEM_SUCCESS](state, response) {
      handleItemSuccessResponse(types.GETINSTANTWINS_ITEM_SUCCESS,instantWinResponseFields, state, response, 'instantWin');
    },

    //InstantWinResponse
    [types.GETINSTANTWINS_ERROR](state, error) {
      handleItemErrorResponse(types.GETINSTANTWINS_ITEM_ITEM_ERROR, instantWinResponseFields, state, error);
    },


    //ApiResponse
    [types.CREATEINSTANTWINS_SUCCESS](state, response) {
      handleSuccessResponse(types.CREATEINSTANTWINS_SUCCESS,apiResponseFields, state, response);
    },

    //ApiResponse
    [types.CREATEINSTANTWINS_ERROR](state, error) {
      handleErrorResponse(types.CREATEINSTANTWINS_ERROR, apiResponseFields, state, error);
    },

    //ApiResponse
    [types.DELETEINSTANTWINS_SUCCESS](state, response) {
      handleSuccessResponse(types.DELETEINSTANTWINS_SUCCESS,apiResponseFields, state, response);
    },

    //ApiResponse
    [types.DELETEINSTANTWINS_ERROR](state, error) {
      handleErrorResponse(types.DELETEINSTANTWINS_ERROR, apiResponseFields, state, error);
    },

    //ApiResponse
    [types.DELETEINSTANTWINSBYQUERY_SUCCESS](state, response) {
      handleSuccessResponse(types.DELETEINSTANTWINSBYQUERY_SUCCESS,apiResponseFields, state, response);
    },

    //ApiResponse
    [types.DELETEINSTANTWINSBYQUERY_ERROR](state, error) {
      handleErrorResponse(types.DELETEINSTANTWINSBYQUERY_ERROR, apiResponseFields, state, error);
    },

    //InstantWinResponse
    [types.GETINSTANTWINS_SUCCESS](state, response) {
      handleSuccessResponse(types.GETINSTANTWINS_SUCCESS,instantWinResponseFields, state, response);
    },

    //InstantWinResponse
    [types.GETINSTANTWINS_ERROR](state, error) {
      handleErrorResponse(types.GETINSTANTWINS_ERROR, instantWinResponseFields, state, error);
    },

    //InstantWinResponse
    [types.GETINSTANTWINSBYQUERY_SUCCESS](state, response) {
      handleSuccessResponse(types.GETINSTANTWINSBYQUERY_SUCCESS,instantWinResponseFields, state, response);
    },

    //InstantWinResponse
    [types.GETINSTANTWINSBYQUERY_ERROR](state, error) {
      handleErrorResponse(types.GETINSTANTWINSBYQUERY_ERROR, instantWinResponseFields, state, error);
    },

    //ApiResponse
    [types.UPDATEINSTANTWINS_SUCCESS](state, response) {
      handleSuccessResponse(types.UPDATEINSTANTWINS_SUCCESS,apiResponseFields, state, response);
    },

    //ApiResponse
    [types.UPDATEINSTANTWINS_ERROR](state, error) {
      handleErrorResponse(types.UPDATEINSTANTWINS_ERROR, apiResponseFields, state, error);
    },
};

export default mutations;
