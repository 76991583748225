export default {
    /**
     * Default value used for Pagination Component
     * totalRecords (available value as a response from the SDK API) divided by maxRowsperPage, rounded up
     * @type {number}
     */
    pages: 10,
    /**
     * The default number for the amount of records/rows to be populated inside the table
     * @type {number}
     */
    maxRowsPerPage: 10,
    /**
     * An array list used to generate how many records/rows can be populated inside the table
     * @type {number[]}
     */
    itemsPerPage: [10, 20, 40, 60, 80, 100, 200],
    /**
     * Default API sorting criteria, this pattern is exclusive to the CL SDK API
     * @type {{queryField: string, order: string}[]}
     */
    sortBy: [
        {
            queryField: 'created',
            order: 'Desc',
        }
    ],
    /**
     * Default table date formatter
     * @param dateValue
     * @returns {string}
     */
    dateFormatter: function (dateValue) {
        var date = new Date(dateValue);

        return date.toLocaleString('en-GB', {
            timeZone: 'UTC',
            dateStyle: 'short',
            timeStyle: 'short',
        })
    },
    /**
     * Maximum length of text, used in the table cell output to limit the maximum allowed character length
     * @type {number}
     */
    maxTextLength: 20
}