import * as types from './mutation-types';
import * as _ from "lodash";
import {handleErrorResponse, handleSuccessResponse} from "@/utils/ZiqniAdminApiHandler";
import leaderboardResponseFields from "@/generated/ziqni/fields/LeaderboardResponseZq";

const mutations = {
  [types.API_REQUEST](state) {
    state.loading = true;
    state.loaded = false;
    state.message = null;
  },

  [types.CLEAR_MESSAGE](state){
    state.message = null;
  },

  [types.GET_LEADERBOARD_SUCCESS](state, response) {
    handleSuccessResponse(types.GET_LEADERBOARD_SUCCESS, leaderboardResponseFields, state, response);
  },

  [types.GET_LEADERBOARD_ERROR](state, error) {
    handleErrorResponse(types.GET_LEADERBOARD_ERROR, leaderboardResponseFields, state, error);
  },

  [types.UPDATE_FIELDS](state, data) {
    state.hiddenFields = data._hiddenFields;
    state.fields = _.difference(state.originalFields, state.hiddenFields);
    localStorage.setItem('hiddenLeaderboardKey', JSON.stringify(state.hiddenFields));
  },

  [types.CLEAR_FILTER](state) {
    state.fields = _.difference(
      state.originalFields,
      state.originHiddenFields
    );
    state.hiddenFields = state.originHiddenFields;
    localStorage.removeItem('hiddenLeaderboardKey');
  },
};

export default mutations;
