// <<< DO NOT EDIT GENERATED FILES DIRECTLY! api-store-mutation-types.mustache >>>
export const API_REQUEST = 'API_REQUEST';


export const GETACTIONTYPES_ITEM_REQ = 'GETACTIONTYPES_ITEM_REQ';
export const GETACTIONTYPES_ITEM_ERROR = 'GETACTIONTYPES_ITEM_ERROR';
export const GETACTIONTYPES_ITEM_SUCCESS = 'GETACTIONTYPES_ITEM_SUCCESS'

export const UPDATE_FIELDS = 'UPDATE_FIELDS';
export const CLEAR_FILTER = 'CLEAR_FILTER';
export const CLEAR_MESSAGE = 'CLEAR_MESSAGE';

export const CREATEACTIONTYPES_REQ = 'CREATEACTIONTYPES_REQ';
export const CREATEACTIONTYPES_ERROR = 'CREATEACTIONTYPES_ERROR';
export const CREATEACTIONTYPES_SUCCESS = 'CREATEACTIONTYPES_SUCCESS';

export const DELETEACTIONTYPES_REQ = 'DELETEACTIONTYPES_REQ';
export const DELETEACTIONTYPES_ERROR = 'DELETEACTIONTYPES_ERROR';
export const DELETEACTIONTYPES_SUCCESS = 'DELETEACTIONTYPES_SUCCESS';

export const DELETEACTIONTYPESBYQUERY_REQ = 'DELETEACTIONTYPESBYQUERY_REQ';
export const DELETEACTIONTYPESBYQUERY_ERROR = 'DELETEACTIONTYPESBYQUERY_ERROR';
export const DELETEACTIONTYPESBYQUERY_SUCCESS = 'DELETEACTIONTYPESBYQUERY_SUCCESS';

export const GETACTIONTYPES_REQ = 'GETACTIONTYPES_REQ';
export const GETACTIONTYPES_ERROR = 'GETACTIONTYPES_ERROR';
export const GETACTIONTYPES_SUCCESS = 'GETACTIONTYPES_SUCCESS';

export const GETACTIONTYPESBYQUERY_REQ = 'GETACTIONTYPESBYQUERY_REQ';
export const GETACTIONTYPESBYQUERY_ERROR = 'GETACTIONTYPESBYQUERY_ERROR';
export const GETACTIONTYPESBYQUERY_SUCCESS = 'GETACTIONTYPESBYQUERY_SUCCESS';

export const UPDATEACTIONTYPES_REQ = 'UPDATEACTIONTYPES_REQ';
export const UPDATEACTIONTYPES_ERROR = 'UPDATEACTIONTYPES_ERROR';
export const UPDATEACTIONTYPES_SUCCESS = 'UPDATEACTIONTYPES_SUCCESS';

