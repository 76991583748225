// <<< DO NOT EDIT GENERATED FILES DIRECTLY! api-store-mutation-types.mustache >>>
export const API_REQUEST = 'API_REQUEST';


export const GETACCOUNTMESSAGES_ITEM_REQ = 'GETACCOUNTMESSAGES_ITEM_REQ';
export const GETACCOUNTMESSAGES_ITEM_ERROR = 'GETACCOUNTMESSAGES_ITEM_ERROR';
export const GETACCOUNTMESSAGES_ITEM_SUCCESS = 'GETACCOUNTMESSAGES_ITEM_SUCCESS'

export const UPDATE_FIELDS = 'UPDATE_FIELDS';
export const CLEAR_FILTER = 'CLEAR_FILTER';
export const CLEAR_MESSAGE = 'CLEAR_MESSAGE';

export const DELETEACCOUNTMESSAGES_REQ = 'DELETEACCOUNTMESSAGES_REQ';
export const DELETEACCOUNTMESSAGES_ERROR = 'DELETEACCOUNTMESSAGES_ERROR';
export const DELETEACCOUNTMESSAGES_SUCCESS = 'DELETEACCOUNTMESSAGES_SUCCESS';

export const DELETEACCOUNTMESSAGESBYQUERY_REQ = 'DELETEACCOUNTMESSAGESBYQUERY_REQ';
export const DELETEACCOUNTMESSAGESBYQUERY_ERROR = 'DELETEACCOUNTMESSAGESBYQUERY_ERROR';
export const DELETEACCOUNTMESSAGESBYQUERY_SUCCESS = 'DELETEACCOUNTMESSAGESBYQUERY_SUCCESS';

export const GETACCOUNTMESSAGES_REQ = 'GETACCOUNTMESSAGES_REQ';
export const GETACCOUNTMESSAGES_ERROR = 'GETACCOUNTMESSAGES_ERROR';
export const GETACCOUNTMESSAGES_SUCCESS = 'GETACCOUNTMESSAGES_SUCCESS';

export const GETACCOUNTMESSAGESBYQUERY_REQ = 'GETACCOUNTMESSAGESBYQUERY_REQ';
export const GETACCOUNTMESSAGESBYQUERY_ERROR = 'GETACCOUNTMESSAGESBYQUERY_ERROR';
export const GETACCOUNTMESSAGESBYQUERY_SUCCESS = 'GETACCOUNTMESSAGESBYQUERY_SUCCESS';

export const UPDATEACCOUNTMESSAGESSTATUS_REQ = 'UPDATEACCOUNTMESSAGESSTATUS_REQ';
export const UPDATEACCOUNTMESSAGESSTATUS_ERROR = 'UPDATEACCOUNTMESSAGESSTATUS_ERROR';
export const UPDATEACCOUNTMESSAGESSTATUS_SUCCESS = 'UPDATEACCOUNTMESSAGESSTATUS_SUCCESS';

