// <<< DO NOT EDIT GENERATED FILES DIRECTLY! api-store-mutations.mustache >>>
import * as types from './mutation-types';
import * as _ from 'lodash';
import {
  handleErrorResponse, handleItemErrorResponse,
  handleItemSuccessResponse,
  handleSuccessResponse
} from "../../../../../utils/ZiqniAdminApiHandler";
import apiResponseFields from '../../../fields/ApiResponseZq.js';
import awardResponseFields from '../../../fields/AwardResponseZq.js';
import claimAwardRequestFields from '../../../fields/ClaimAwardRequestZq.js';
import queryRequestFields from '../../../fields/QueryRequestZq.js';

const mutations = {
    [types.API_REQUEST](state) {
        state.loading = true;
        state.loaded = false;
    },
    [types.CLEAR_MESSAGE](state){
        state.message = null;
    },

    [types.UPDATE_FIELDS](state, data) {
        state.hiddenFields = data._hiddenFields;
        state.fields = _.difference(state.originalFields, state.hiddenFields);
        localStorage.setItem('hiddenAwardsKey', JSON.stringify(state.hiddenFields));
    },

    [types.CLEAR_FILTER](state) {
        state.fields = _.difference(
            state.originalFields,
            state.originHiddenFields
        );
        state.hiddenFields = state.originHiddenFields;
        localStorage.removeItem('hiddenAwardsKey');
    },

    //AwardResponse
    [types.GETAWARDS_ITEM_SUCCESS](state, response) {
      handleItemSuccessResponse(types.GETAWARDS_ITEM_SUCCESS,awardResponseFields, state, response, 'award');
    },

    //AwardResponse
    [types.GETAWARDS_ERROR](state, error) {
      handleItemErrorResponse(types.GETAWARDS_ITEM_ITEM_ERROR, awardResponseFields, state, error);
    },


    //ApiResponse
    [types.CLAIMAWARDS_SUCCESS](state, response) {
      handleSuccessResponse(types.CLAIMAWARDS_SUCCESS,apiResponseFields, state, response);
    },

    //ApiResponse
    [types.CLAIMAWARDS_ERROR](state, error) {
      handleErrorResponse(types.CLAIMAWARDS_ERROR, apiResponseFields, state, error);
    },

    //AwardResponse
    [types.GETAWARDS_SUCCESS](state, response) {
      handleSuccessResponse(types.GETAWARDS_SUCCESS,awardResponseFields, state, response);
    },

    //AwardResponse
    [types.GETAWARDS_ERROR](state, error) {
      handleErrorResponse(types.GETAWARDS_ERROR, awardResponseFields, state, error);
    },

    //AwardResponse
    [types.GETAWARDSBYQUERY_SUCCESS](state, response) {
      handleSuccessResponse(types.GETAWARDSBYQUERY_SUCCESS,awardResponseFields, state, response);
    },

    //AwardResponse
    [types.GETAWARDSBYQUERY_ERROR](state, error) {
      handleErrorResponse(types.GETAWARDSBYQUERY_ERROR, awardResponseFields, state, error);
    },
};

export default mutations;
