// <<< DO NOT EDIT GENERATED FILES DIRECTLY! model-store-fields-all.mustache >>>


import competitionAllOf from './CompetitionAllOfZq';
import competitionStatus from './CompetitionStatusZq';
import competitionType from './CompetitionTypeZq';
import dependancy from './DependancyZq';
import dependantOn from './DependantOnZq';
import modelDefault from './ModelDefaultZq';
import optParamModels from './OptParamModelsZq';
import productReduced from './ProductReducedZq';

const competition = this;

export default {
  objectType: 'Competition',
  allowableValuesKeys: [],
  constraints: [],
  getter: 'competitions',
  hiddenFields: ['spaceName','customFields','metadata',],
  searchableFields: ['id','created','numberOfRounds','numberOfGroupStages','name','description','termsAndConditions','maxNumberOfEntrants','minNumberOfEntrants','scheduledStartDate','scheduledEndDate','actualStartDate','actualEndDate','statusCode',], 
  stringBuilderFields: [],
  operations: ['Create','Update'], 
  searchableAllFields: ['name','description','termsAndConditions',],
  translatableFields: ['name','description','termsAndConditions',],
  sortableFields: ['id','spaceName','created','tags',],
  customFieldsFieldName: ['customFields',],
  metadataFieldName: ['metadata'],
  tagFieldName: ['tags'],
  entityLinksFrom: ['Rules','Reward','Award','Contest',],

  route: {
    list: 'ListCompetition',
    preview: 'PreviewCompetition',
    create: 'CreateCompetition',
    update: 'UpdateCompetition',
  },
  skip: 0,
  limit: 10,
  sortBy: [
    {
      "queryField": "name",
      "order": "Asc"
    }
  ],
  fieldList: ['id','spaceName','created','customFields','tags','metadata','competitionType','numberOfRounds','numberOfGroupStages','name','description','termsAndConditions','maxNumberOfEntrants','minNumberOfEntrants','entrantMemberType','dependantOn','scheduledStartDate','scheduledEndDate','actualStartDate','actualEndDate','status','statusCode','constraints','products','entrantMemberTagsFilter','productTagsFilter','icon','banner','bannerLowResolution','bannerHighResolution'],
  fields: { 
    id: {
      name: 'id',
      complexType: null,
      displayOrder: 2,
      allowableValuesKeys: [],
      constraints: ['required','isPrimitiveType','isString',
                     ],
      formType: null,
    },
    spaceName: {
      name: 'spaceName',
      complexType: null,
      displayOrder: 9000,
      allowableValuesKeys: [],
      constraints: ['required','isPrimitiveType','isString',
                     ],
      formType: null,
    },
    created: {
      name: 'created',
      complexType: null,
      displayOrder: 9001,
      allowableValuesKeys: [],
      constraints: ['required','isPrimitiveType','isDateTime',
                     ],
      formType: null,
    },
    customFields: {
      name: 'customFields',
      complexType: null,
      displayOrder: 5000,
      allowableValuesKeys: [],
      constraints: ['isCustomFieldsArray','isPrimitiveType','isContainer','isFreeFormObject','isMap',
                    'isFreeFormObject','isNullable', ],
      formType: null,
    },
    tags: {
      name: 'tags',
      complexType: null,
      displayOrder: 1,
      allowableValuesKeys: [],
      constraints: ['isTagsArray','isPrimitiveType','isContainer','isArray',
                    'isString', ],
      formType: null,
    },
    metadata: {
      name: 'metadata',
      complexType: null,
      displayOrder: 10000,
      allowableValuesKeys: [],
      constraints: ['isMetadataMap','isPrimitiveType','isContainer','isMap',
                    'isString', ],
      formType: 'META',
    },
    competitionType: {
      name: 'competitionType',
      complexType: competitionType,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['required',
                     ],
      formType: null,
    },
    numberOfRounds: {
      name: 'numberOfRounds',
      complexType: null,
      displayOrder: 1006,
      allowableValuesKeys: [],
      constraints: ['required','isPrimitiveType','isNumeric','isInteger','isShort',
                     ],
      formType: null,
    },
    numberOfGroupStages: {
      name: 'numberOfGroupStages',
      complexType: null,
      displayOrder: 1006,
      allowableValuesKeys: [],
      constraints: ['isPrimitiveType','isNumeric','isInteger','isUnboundedInteger',
                     ],
      formType: null,
    },
    name: {
      name: 'name',
      complexType: null,
      displayOrder: 1001,
      allowableValuesKeys: [],
      constraints: ['required','isPrimitiveType','isString',
                     ],
      formType: null,
    },
    description: {
      name: 'description',
      complexType: null,
      displayOrder: 1002,
      allowableValuesKeys: [],
      constraints: ['isPrimitiveType','isString',
                     ],
      formType: 'TEXTAREA',
    },
    termsAndConditions: {
      name: 'termsAndConditions',
      complexType: null,
      displayOrder: 1003,
      allowableValuesKeys: [],
      constraints: ['isPrimitiveType','isString',
                     ],
      formType: 'TEXTAREA',
    },
    maxNumberOfEntrants: {
      name: 'maxNumberOfEntrants',
      complexType: null,
      displayOrder: 1006,
      allowableValuesKeys: [],
      constraints: ['isPrimitiveType','isNumeric','isInteger','isShort',
                     ],
      formType: null,
    },
    minNumberOfEntrants: {
      name: 'minNumberOfEntrants',
      complexType: null,
      displayOrder: 1006,
      allowableValuesKeys: [],
      constraints: ['required','isPrimitiveType','isNumeric','isInteger','isShort',
                     ],
      formType: null,
    },
    entrantMemberType: {
      name: 'entrantMemberType',
      complexType: null,
      displayOrder: 1005,
      allowableValuesKeys: [],
      constraints: ['isPrimitiveType','isString',
                     ],
      formType: null,
    },
    dependantOn: {
      name: 'dependantOn',
      complexType: dependancy,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['required','isModel',
                     ],
      formType: null,
    },
    scheduledStartDate: {
      name: 'scheduledStartDate',
      complexType: null,
      displayOrder: 1006,
      allowableValuesKeys: [],
      constraints: ['required','isPrimitiveType','isDateTime',
                     ],
      formType: null,
    },
    scheduledEndDate: {
      name: 'scheduledEndDate',
      complexType: null,
      displayOrder: 1006,
      allowableValuesKeys: [],
      constraints: ['required','isPrimitiveType','isDateTime',
                     ],
      formType: null,
    },
    actualStartDate: {
      name: 'actualStartDate',
      complexType: null,
      displayOrder: 1006,
      allowableValuesKeys: [],
      constraints: ['isPrimitiveType','isDateTime','isReadOnly',
                     ],
      formType: null,
    },
    actualEndDate: {
      name: 'actualEndDate',
      complexType: null,
      displayOrder: 1006,
      allowableValuesKeys: [],
      constraints: ['isPrimitiveType','isDateTime','isReadOnly',
                     ],
      formType: null,
    },
    status: {
      name: 'status',
      complexType: competitionStatus,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['required',
                     ],
      formType: null,
    },
    statusCode: {
      name: 'statusCode',
      complexType: null,
      displayOrder: 1006,
      allowableValuesKeys: [],
      constraints: ['isPrimitiveType','isNumeric','isInteger','isUnboundedInteger','isReadOnly',
                     ],
      formType: null,
    },
    constraints: {
      name: 'constraints',
      complexType: null,
      displayOrder: 1006,
      allowableValuesKeys: ['notifyMember','memberAcknowledgmentRequired','isPublic','autoStart','autoStop','optinRequiredForEntrants',],
      constraints: ['isConstraintsArray','required','isPrimitiveType','isContainer','isArray',
                    'isString', ],
      formType: null,
    },
    products: {
      name: 'products',
      complexType: productReduced,
      displayOrder: 1006,
      allowableValuesKeys: [],
      constraints: ['required','isContainer','isArray',
                    'isModel', ],
      formType: null,
    },
    entrantMemberTagsFilter: {
      name: 'entrantMemberTagsFilter',
      complexType: dependantOn,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['isModel',
                     ],
      formType: null,
    },
    productTagsFilter: {
      name: 'productTagsFilter',
      complexType: dependantOn,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['isModel',
                     ],
      formType: null,
    },
    icon: {
      name: 'icon',
      complexType: null,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['isPrimitiveType','isString',
                     ],
      formType: null,
    },
    banner: {
      name: 'banner',
      complexType: null,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['isPrimitiveType','isString',
                     ],
      formType: null,
    },
    bannerLowResolution: {
      name: 'bannerLowResolution',
      complexType: null,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['isPrimitiveType','isString',
                     ],
      formType: null,
    },
    bannerHighResolution: {
      name: 'bannerHighResolution',
      complexType: null,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['isPrimitiveType','isString',
                     ],
      formType: null,
    }
  }
};
