import * as _ from 'lodash';
import apiResponse from '/src/generated/ziqni/fields/ApiResponseZq';

export const handleSuccessResponse = (mutationType, fieldDescriptor, state, data) => {
    if (apiResponse.objectType === fieldDescriptor.objectType){
        if (mutationType === "UPDATETRANSLATIONS_SUCCESS") {
            state.updateTranslationFields = data.response.results.map(item => item.id);
        }
        if (mutationType === "CREATETRANSLATIONS_SUCCESS") {
            state.createTranslationFields = data.response.results.map(item => item.id);
        }
        state.loading = false;
        state.loaded = true;
        state.success = true;
        state.message = 'Success';
        return data.response.results;
    } else {
        if (mutationType === 'EXPORTENTRANTSBYQUERY_SUCCESS') return;

        const pages = Math.ceil(data.response.meta.totalRecords / data.response.meta.limit);

        let complexType = null;
        if (fieldDescriptor.fields.results && fieldDescriptor.fields.results.complexType) {
            complexType = fieldDescriptor.fields.results.complexType;
        }
        if (complexType) {
            state[complexType.getter] = data.response.results;
        }

        if (mutationType === "GETAVAILABLEROLESFORCOLLABORATORS_SUCCESS") {
            state.collaboratorsRoles = data.response.results[0].roles;
        }

        if (mutationType === "GETLOGEVENTSBYQUERY_SUCCESS") {
            state.logEvents = data.response.results;
        }

        if (mutationType === "GETINSTANTWINSBYQUERY_SUCCESS") {
            state.instantWins = data.response.results;
        }

        if (data.response.results.length > 0) {
            if (mutationType === "GETCOLLABORATORS_SUCCESS") {
                delete data.response.results[0].created;
            }
            const fieldDescriptor = Object.keys(data.response.results[0]);
            fieldDescriptor.push('actions');
            fieldDescriptor.unshift('select')
            state.originalFields = _.cloneDeep(fieldDescriptor);

            const fields = Object.keys(data.response.results[0]);
            fields.push('actions');
            fields.unshift('select')
            state.fields =  _.cloneDeep(fields);
        }
        state.fieldDescriptor = _.difference(state.originalFields, state.hiddenFields);
        state.pages = pages;
        state.meta = data.response.meta;
        state.resultCount = data.response.meta.resultCount;
        state.totalRecords = data.response.meta.totalRecords;
        state.loading = false;
        state.loaded = true;
        return data.response.results;
    }
};

export const handleErrorResponse = (mutationType, fieldDescriptor, state, error) => {
    state.loading = false;
    state.loaded = true;
    state.success = false;
    console.log("+++ [ERROR] mutationType >> " + mutationType + ", fieldDescriptor >> " + fieldDescriptor.objectType);
    console.log(error);

    let parsedError = undefined;
    try {
        parsedError = JSON.parse(error);
    } catch (e) {}

    if (typeof parsedError === 'object' && parsedError.hasOwnProperty('errors') && Array.isArray(parsedError.errors)) {
        const messages = [];
        parsedError.errors.forEach(error => {
            messages.push(error.message);
        })
        state.message = messages.join(', ');
    } else {
        state.message = error;
    }
};

export const handleItemSuccessResponse = (mutationType, fieldDescriptor, state, data, fieldName) => {
    if(apiResponse.objectType === fieldDescriptor.objectType){
        throw new Error("Your config is wrong, talk to Carel");
    }
    else {
        state[fieldName] = data.response.results[0]; //Should really see if you actually got a result
        state.loading = false;
        state.loaded = true;
        return data.response.results[0];
    }
};

export const handleItemErrorResponse = (mutationType, fieldDescriptor, state, error) => {
    state.loading = false;
    state.loaded = true;
    state.success = false;
    console.log("+++ [ERROR] mutationType >> " + mutationType + ", fieldDescriptor >> " + fieldDescriptor.objectType);
    console.log(error);
    state.message = error;
};
