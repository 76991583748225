import {cloneDeep} from "lodash";
import * as types from "@/store/modules/events/mutation-types";

/**
 *  Helper for store handleFields
 */
const updateFields = (commit, state, data, storageKey) => {
    const preHiddenFields = localStorage.getItem(storageKey) && JSON.parse(localStorage.getItem(storageKey));
    if (data) {
        const {hiddenFields} = state;
        const _hiddenFields = cloneDeep(hiddenFields);
        const hiddenIndex = _hiddenFields.indexOf(data);
        if (hiddenIndex > -1) {
            _hiddenFields.splice(hiddenIndex, 1);
        } else {
            _hiddenFields.push(data);
        }
        const updatedData = {
            _hiddenFields,
        };
        commit(types.UPDATE_FIELDS, updatedData);
    } else if (preHiddenFields && preHiddenFields.length) {
        const updatedData = {
            _hiddenFields: preHiddenFields,
        };
        commit(types.UPDATE_FIELDS, updatedData);
    } else if (state.hiddenFields && state.hiddenFields.length) {
        const updatedData = {
            _hiddenFields: state.hiddenFields,
        };
        commit(types.UPDATE_FIELDS, updatedData);
    }
};

export default updateFields;
