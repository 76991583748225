// <<< DO NOT EDIT GENERATED FILES DIRECTLY! api-store-actions.mustache >>>
import { delay, cloneDeep } from 'lodash';
import * as types from './mutation-types';
import sdkApiConfig from '@/config/sdkApiConfig';
import updateFields from '@/utils/updateFields';
import { collaboratorsApiZiqni } from '@/generated/ziqni/api/CollaboratorsApiZiqni';
import router from '@/router';

import apiResponseFields from '../../../fields/ApiResponseZq.js';
import collaboratorResponseFields from '../../../fields/CollaboratorResponseZq.js';
import collaboratorRolesResponseFields from '../../../fields/CollaboratorRolesResponseZq.js';
import createCollaboratorRequestFields from '../../../fields/CreateCollaboratorRequestZq.js';
import queryRequestFields from '../../../fields/QueryRequestZq.js';
import updateCollaboratorRequestFields from '../../../fields/UpdateCollaboratorRequestZq.js';
import {handleRequest,handleRequestWithBody} from "../../../../../utils/ZiqniAdminApiHandler";

export const handleFields = ({ commit, state }, data) => {
  updateFields(commit, state, data, 'hiddenCollaboratorsKey');
};

// Clear collaborators Message
export const handleClearMessage = ({ commit }) => {
  commit(types.CLEAR_MESSAGE);
};

// clear filter
export const handleClearFilter = ({ commit }) => {
  commit(types.CLEAR_FILTER);
};

//COLLABORATORS - COLLABORATORS
export const handleCollaboratorsChanges = ({ commit }, data) => {
  commit('SET_COLLABORATORS', data);
};
/**
 * CollaboratorResponse from backend.
 * GET getCollaborators and return [CollaboratorResponse]
 * vars: [
 *    [[emails]] emails[ opts, isArray, isQueryParam]
 *    [[limit]] _limit[ opts, isInteger, isQueryParam]
 *    [[skip]] _skip[ opts, isInteger, isQueryParam]]
 */
export const handleGetCollaborators_item = ({ commit }, emailsArray, limit, skip) => {
  commit(types.API_REQUEST);
  let opts = { 
    emails: emailsArray, 
    limit: limit, 
    skip: skip
  };

  return collaboratorsApiZiqni
    .getCollaborators(
      opts
    )
    .then((response) => {
      commit(types.GETCOLLABORATORS_ITEM_SUCCESS, { response });
      return response.results;
    })
    .catch((e) => {
      commit(types.GETCOLLABORATORS_ITEM_ERROR, e);
      return [];
    });
};

/**
 * ApiResponse from backend.
 * POST createCollaborators and return [ApiResponse]
 * vars: [
 *    [[body]] CreateCollaboratorRequest[ required, isArray, isBodyParam, ]]
 */
export const handleCreateCollaborators = ({ commit }, payload) => {
  const { createCollaboratorRequestArray } = payload;

  commit(types.API_REQUEST);
  

  return collaboratorsApiZiqni
    .createCollaborators(
      createCollaboratorRequestArray
    )
    .then((response) => {
      commit(types.CREATECOLLABORATORS_SUCCESS, { response });
      return response.results;
    })
    .catch((e) => {
      commit(types.CREATECOLLABORATORS_ERROR, e);
      return [];
    });
};

/**
 * ApiResponse from backend.
 * DELETE deleteCollaborators and return [ApiResponse]
 * vars: [
 *    [[emails]] emails[ opts, isArray, isQueryParam]]
 */
export const handleDeleteCollaborators = ({ commit }, payload) => {
  const { emailsArray } = payload;

  commit(types.API_REQUEST);
  let opts = { 
    emails: emailsArray
  };

  return collaboratorsApiZiqni
    .deleteCollaborators(
      opts
    )
    .then((response) => {
      commit(types.DELETECOLLABORATORS_SUCCESS, { response });
      return response.results;
    })
    .catch((e) => {
      commit(types.DELETECOLLABORATORS_ERROR, e);
      return [];
    });
};

/**
 * CollaboratorRolesResponse from backend.
 * GET getAvailableRolesForCollaborators and return [CollaboratorRolesResponse]
 * vars: []
 */
export const handleGetAvailableRolesForCollaborators = ({ commit }, payload) => {

  commit(types.API_REQUEST);
  

  return collaboratorsApiZiqni
    .getAvailableRolesForCollaborators(
      
    )
    .then((response) => {
      commit(types.GETAVAILABLEROLESFORCOLLABORATORS_SUCCESS, { response });
      return response.results;
    })
    .catch((e) => {
      commit(types.GETAVAILABLEROLESFORCOLLABORATORS_ERROR, e);
      return [];
    });
};

/**
 * CollaboratorResponse from backend.
 * GET getCollaborators and return [CollaboratorResponse]
 * vars: [
 *    [[emails]] emails[ opts, isArray, isQueryParam]
 *    [[limit]] _limit[ opts, isInteger, isQueryParam]
 *    [[skip]] _skip[ opts, isInteger, isQueryParam]]
 */
export const handleGetCollaborators = ({ commit }, payload) => {
  const { emailsArray, limit, skip } = payload;

  commit(types.API_REQUEST);
  let opts = { 
    emails: emailsArray, 
    limit: limit, 
    skip: skip
  };

  return collaboratorsApiZiqni
    .getCollaborators(
      opts
    )
    .then((response) => {
      commit(types.GETCOLLABORATORS_SUCCESS, { response });
      return response.results;
    })
    .catch((e) => {
      commit(types.GETCOLLABORATORS_ERROR, e);
      return [];
    });
};

/**
 * CollaboratorResponse from backend.
 * POST getCollaboratorsByQuery and return [CollaboratorResponse]
 * vars: [
 *    [[body]] QueryRequest[ opts, isBodyParam, ]]
 */
export const handleGetCollaboratorsByQuery = ({ commit }, payload) => {
  const { queryRequest } = payload;

  commit(types.API_REQUEST);
  let opts = { 
    body: queryRequest
  };

  return collaboratorsApiZiqni
    .getCollaboratorsByQuery(
      opts
    )
    .then((response) => {
      commit(types.GETCOLLABORATORSBYQUERY_SUCCESS, { response });
      return response.results;
    })
    .catch((e) => {
      commit(types.GETCOLLABORATORSBYQUERY_ERROR, e);
      return [];
    });
};

/**
 * ApiResponse from backend.
 * PUT updateCollaborators and return [ApiResponse]
 * vars: [
 *    [[body]] UpdateCollaboratorRequest[ required, isArray, isBodyParam, ]]
 */
export const handleUpdateCollaborators = ({ commit }, payload) => {
  const { updateCollaboratorRequestArray } = payload;

  commit(types.API_REQUEST);
  

  return collaboratorsApiZiqni
    .updateCollaborators(
      updateCollaboratorRequestArray
    )
    .then((response) => {
      commit(types.UPDATECOLLABORATORS_SUCCESS, { response });
      return response.results;
    })
    .catch((e) => {
      commit(types.UPDATECOLLABORATORS_ERROR, e);
      return [];
    });
};
