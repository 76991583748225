const Dashboard = () => import('@/views/ziqni/Dashboard/index');

export default [
  {
    path: '',
    name: 'Dashboard',
    component: Dashboard,
  },
  {
    path: 'dashboard',
    name: 'Dashboard',
    component: Dashboard,
  },
];
