// <<< DO NOT EDIT GENERATED FILES DIRECTLY! model-store-fields-all.mustache >>>



const createCollaboratorRequest = this;

export default {
  objectType: 'CreateCollaboratorRequest',
  allowableValuesKeys: [],
  constraints: ['isMap',],
  getter: null,
  hiddenFields: [],
  searchableFields: [], 
  searchableAllFields: [],
  translatableFields: [],
  sortableFields: [],
  customFieldsFieldName: [],
  metadataFieldName: [],
  tagFieldName: [],
  entityLinksFrom: [],

  route: {
    
    
  },
  fieldList: ['email','addRoles'],
  fields: { 
    email: {
      name: 'email',
      complexType: null,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['required','isPrimitiveType','isString',
                     ],
      formType: null,
    },
    addRoles: {
      name: 'addRoles',
      complexType: null,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['required','isPrimitiveType','isContainer','isArray',
                    'isString', ],
      formType: null,
    }
  }
};
