import Vue from 'vue';

const controls = [
  { key: 'ClFormRow', path: './components/formComponents/' },
  { key: 'CIInput', path: './components/formComponents/' },
  { key: 'CITextArea', path: './components/formComponents/' },
  { key: 'CICustomFieldsView', path: './components/' },
  { key: 'CIMetaDataView', path: './components/' },
  { key: 'CISelectEntites', path: './components/' },
  { key: 'CISelectLanguage', path: './components/' },
  { key: 'CIArrayField', path: './components/' },
  { key: 'CIDataTable', path: './components/' },
  { key: 'CIMetaDataField', path: './components/' },
  { key: 'ZqActionTypeAdjustmentFactorsField', path: './components/' },
  { key: 'ZqSelectMembers', path: './components/' },
  { key: 'CITagField', path: './components/' },
  { key: 'CITagsView', path: './components/' },
  { key: 'CIIconField', path: './components/CIIconField/' },
  { key: 'CICustomFields', path: './components/' },
  { key: 'CINameKeyField', path: './components/' },
  { key: 'CISelectTranformer', path: './components/' },
  { key: 'ClCardArrow', path: './UI/' },
  { key: 'ClCardRow', path: './UI/' },
  { key: 'FormBuilder', path: './' },
];
controls.forEach((item) => {
  Vue.component(item.key, () => import(item.path + item.key + '.vue'));
});
