// <<< DO NOT EDIT GENERATED FILES DIRECTLY! model-store-fields-all.mustache >>>


import leaderboardAllOf from './LeaderboardAllOfZq';
import leaderboardEntry from './LeaderboardEntryZq';
import modelDefault from './ModelDefaultZq';

const leaderboard = this;

export default {
  objectType: 'Leaderboard',
  allowableValuesKeys: [],
  constraints: [],
  getter: null,
  hiddenFields: ['spaceName',],
  searchableFields: ['id','created',], 
  searchableAllFields: [],
  translatableFields: [],
  sortableFields: ['id','spaceName','created',],
  customFieldsFieldName: [],
  metadataFieldName: [],
  tagFieldName: [],
  entityLinksFrom: [],

  route: {
    
    
  },
  fieldList: ['id','spaceName','created','leaderboardEntries','constraints','sequence'],
  fields: { 
    id: {
      name: 'id',
      complexType: null,
      displayOrder: 2,
      allowableValuesKeys: [],
      constraints: ['required','isPrimitiveType','isString',
                     ],
      formType: null,
    },
    spaceName: {
      name: 'spaceName',
      complexType: null,
      displayOrder: 9000,
      allowableValuesKeys: [],
      constraints: ['required','isPrimitiveType','isString',
                     ],
      formType: null,
    },
    created: {
      name: 'created',
      complexType: null,
      displayOrder: 9001,
      allowableValuesKeys: [],
      constraints: ['required','isPrimitiveType','isDateTime',
                     ],
      formType: null,
    },
    leaderboardEntries: {
      name: 'leaderboardEntries',
      complexType: leaderboardEntry,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['isContainer','isArray',
                    'isModel', ],
      formType: null,
    },
    constraints: {
      name: 'constraints',
      complexType: null,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['required','isPrimitiveType','isContainer','isArray',
                    'isString', ],
      formType: null,
    },
    sequence: {
      name: 'sequence',
      complexType: null,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['isPrimitiveType','isNumeric','isInteger','isUnboundedInteger',
                     ],
      formType: null,
    }
  }
};
