// <<< DO NOT EDIT GENERATED FILES DIRECTLY! api-store-actions.mustache >>>
import { delay, cloneDeep } from 'lodash';
import * as types from './mutation-types';
import sdkApiConfig from '@/config/sdkApiConfig';
import updateFields from '@/utils/updateFields';
import { contestsApiZiqni } from '@/generated/ziqni/api/ContestsApiZiqni';
import router from '@/router';

import apiResponseFields from '../../../fields/ApiResponseZq.js';
import cloneContestResponseFields from '../../../fields/CloneContestResponseZq.js';
import contestLeaderboardResponseFields from '../../../fields/ContestLeaderboardResponseZq.js';
import contestResponseFields from '../../../fields/ContestResponseZq.js';
import createContestForCompetitionRequestFields from '../../../fields/CreateContestForCompetitionRequestZq.js';
import entrantResponseFields from '../../../fields/EntrantResponseZq.js';
import queryRequestFields from '../../../fields/QueryRequestZq.js';
import updateContestRequestFields from '../../../fields/UpdateContestRequestZq.js';
import updateContestStateRequestFields from '../../../fields/UpdateContestStateRequestZq.js';
import {handleRequest,handleRequestWithBody} from "../../../../../utils/ZiqniAdminApiHandler";

export const handleFields = ({ commit, state }, data) => {
  updateFields(commit, state, data, 'hiddenContestsKey');
};

// Clear contests Message
export const handleClearMessage = ({ commit }) => {
  commit(types.CLEAR_MESSAGE);
};

// clear filter
export const handleClearFilter = ({ commit }) => {
  commit(types.CLEAR_FILTER);
};

//CONTESTS - CONTESTS
export const handleContestsChanges = ({ commit }, data) => {
  commit('SET_CONTESTS', data);
};
/**
 * ContestResponse from backend.
 * GET getContests and return [ContestResponse]
 * vars: [
 *    [[id]] id[ opts, isArray, isQueryParam]
 *    [[limit]] _limit[ opts, isInteger, isQueryParam]
 *    [[skip]] _skip[ opts, isInteger, isQueryParam]]
 */
export const handleGetContests_item = ({ commit }, idArray, limit, skip) => {
  commit(types.API_REQUEST);
  let opts = { 
    id: idArray, 
    limit: limit, 
    skip: skip
  };

  return contestsApiZiqni
    .getContests(
      opts
    )
    .then((response) => {
      commit(types.GETCONTESTS_ITEM_SUCCESS, { response });
      return response.results;
    })
    .catch((e) => {
      commit(types.GETCONTESTS_ITEM_ERROR, e);
      return [];
    });
};

/**
 * ApiResponse from backend.
 * POST createContests and return [ApiResponse]
 * vars: [
 *    [[body]] CreateContestForCompetitionRequest[ required, isBodyParam, ]]
 */
export const handleCreateContests = ({ commit }, payload) => {
  const { createContestForCompetitionRequest } = payload;

  commit(types.API_REQUEST);
  

  return contestsApiZiqni
    .createContests(
      createContestForCompetitionRequest
    )
    .then((response) => {
      commit(types.CREATECONTESTS_SUCCESS, { response });
      return response.results;
    })
    .catch((e) => {
      commit(types.CREATECONTESTS_ERROR, e);
      return [];
    });
};

/**
 * ContestResponse from backend.
 * GET getChildrenOfContest and return [ContestResponse]
 * vars: [
 *    [[id]] id[ required, isString, isPathParam, ]
 *    [[limit]] _limit[ opts, isInteger, isQueryParam]
 *    [[skip]] _skip[ opts, isInteger, isQueryParam]]
 */
export const handleGetChildrenOfContest = ({ commit }, payload) => {
  const { id, limit, skip } = payload;

  commit(types.API_REQUEST);
  let opts = { 
    limit: limit, 
    skip: skip
  };

  return contestsApiZiqni
    .getChildrenOfContest(
      id,opts
    )
    .then((response) => {
      commit(types.GETCHILDRENOFCONTEST_SUCCESS, { response });
      return response.results;
    })
    .catch((e) => {
      commit(types.GETCHILDRENOFCONTEST_ERROR, e);
      return [];
    });
};

/**
 * ContestResponse from backend.
 * GET getContests and return [ContestResponse]
 * vars: [
 *    [[id]] id[ opts, isArray, isQueryParam]
 *    [[limit]] _limit[ opts, isInteger, isQueryParam]
 *    [[skip]] _skip[ opts, isInteger, isQueryParam]]
 */
export const handleGetContests = ({ commit }, payload) => {
  const { idArray, limit, skip } = payload;

  commit(types.API_REQUEST);
  let opts = { 
    id: idArray, 
    limit: limit, 
    skip: skip
  };

  return contestsApiZiqni
    .getContests(
      opts
    )
    .then((response) => {
      commit(types.GETCONTESTS_SUCCESS, { response });
      return response.results;
    })
    .catch((e) => {
      commit(types.GETCONTESTS_ERROR, e);
      return [];
    });
};

/**
 * ContestResponse from backend.
 * POST getContestsByQuery and return [ContestResponse]
 * vars: [
 *    [[body]] QueryRequest[ opts, isBodyParam, ]]
 */
export const handleGetContestsByQuery = ({ commit }, payload) => {
  const { queryRequest } = payload;

  commit(types.API_REQUEST);
  let opts = { 
    body: queryRequest
  };

  return contestsApiZiqni
    .getContestsByQuery(
      opts
    )
    .then((response) => {
      commit(types.GETCONTESTSBYQUERY_SUCCESS, { response });
      return response.results;
    })
    .catch((e) => {
      commit(types.GETCONTESTSBYQUERY_ERROR, e);
      return [];
    });
};

/**
 * ContestLeaderboardResponse from backend.
 * GET getContestsLeaderboardById and return [ContestLeaderboardResponse]
 * vars: [
 *    [[id]] id[ required, isString, isPathParam, ]
 *    [[limit]] _limit[ opts, isInteger, isQueryParam]
 *    [[skip]] _skip[ opts, isInteger, isQueryParam]]
 */
export const handleGetContestsLeaderboardById = ({ commit }, payload) => {
  const { id, limit, skip } = payload;

  commit(types.API_REQUEST);
  let opts = { 
    limit: limit, 
    skip: skip
  };

  return contestsApiZiqni
    .getContestsLeaderboardById(
      id,opts
    )
    .then((response) => {
      commit(types.GETCONTESTSLEADERBOARDBYID_SUCCESS, { response });
      return response.results;
    })
    .catch((e) => {
      commit(types.GETCONTESTSLEADERBOARDBYID_ERROR, e);
      return [];
    });
};

/**
 * CloneContestResponse from backend.
 * GET getContestsToClone and return [CloneContestResponse]
 * vars: [
 *    [[id]] id[ required, isString, isPathParam, ]]
 */
export const handleGetContestsToClone = ({ commit }, payload) => {
  const { id } = payload;

  commit(types.API_REQUEST);
  

  return contestsApiZiqni
    .getContestsToClone(
      id
    )
    .then((response) => {
      commit(types.GETCONTESTSTOCLONE_SUCCESS, { response });
      return response.results;
    })
    .catch((e) => {
      commit(types.GETCONTESTSTOCLONE_ERROR, e);
      return [];
    });
};

/**
 * EntrantResponse from backend.
 * GET getListOfEntrantsForContest and return [EntrantResponse]
 * vars: [
 *    [[id]] id[ required, isString, isPathParam, ]
 *    [[limit]] _limit[ opts, isInteger, isQueryParam]
 *    [[skip]] _skip[ opts, isInteger, isQueryParam]]
 */
export const handleGetListOfEntrantsForContest = ({ commit }, payload) => {
  const { id, limit, skip } = payload;

  commit(types.API_REQUEST);
  let opts = { 
    limit: limit, 
    skip: skip
  };

  return contestsApiZiqni
    .getListOfEntrantsForContest(
      id,opts
    )
    .then((response) => {
      commit(types.GETLISTOFENTRANTSFORCONTEST_SUCCESS, { response });
      return response.results;
    })
    .catch((e) => {
      commit(types.GETLISTOFENTRANTSFORCONTEST_ERROR, e);
      return [];
    });
};

/**
 * ContestResponse from backend.
 * GET getParentsOfContest and return [ContestResponse]
 * vars: [
 *    [[id]] id[ required, isString, isPathParam, ]
 *    [[limit]] _limit[ opts, isInteger, isQueryParam]
 *    [[skip]] _skip[ opts, isInteger, isQueryParam]]
 */
export const handleGetParentsOfContest = ({ commit }, payload) => {
  const { id, limit, skip } = payload;

  commit(types.API_REQUEST);
  let opts = { 
    limit: limit, 
    skip: skip
  };

  return contestsApiZiqni
    .getParentsOfContest(
      id,opts
    )
    .then((response) => {
      commit(types.GETPARENTSOFCONTEST_SUCCESS, { response });
      return response.results;
    })
    .catch((e) => {
      commit(types.GETPARENTSOFCONTEST_ERROR, e);
      return [];
    });
};

/**
 * ApiResponse from backend.
 * PUT updateContest and return [ApiResponse]
 * vars: [
 *    [[body]] UpdateContestRequest[ required, isBodyParam, ]]
 */
export const handleUpdateContest = ({ commit }, payload) => {
  const { updateContestRequest } = payload;

  commit(types.API_REQUEST);
  

  return contestsApiZiqni
    .updateContest(
      updateContestRequest
    )
    .then((response) => {
      commit(types.UPDATECONTEST_SUCCESS, { response });
      return response.results;
    })
    .catch((e) => {
      commit(types.UPDATECONTEST_ERROR, e);
      return [];
    });
};

/**
 * ApiResponse from backend.
 * PUT updateContestsState and return [ApiResponse]
 * vars: [
 *    [[body]] UpdateContestStateRequest[ opts, isArray, isBodyParam, ]]
 */
export const handleUpdateContestsState = ({ commit }, payload) => {
  const { updateContestStateRequestArray } = payload;

  commit(types.API_REQUEST);
  let opts = { 
    body: updateContestStateRequestArray
  };

  return contestsApiZiqni
    .updateContestsState(
      opts
    )
    .then((response) => {
      commit(types.UPDATECONTESTSSTATE_SUCCESS, { response });
      return response.results;
    })
    .catch((e) => {
      commit(types.UPDATECONTESTSSTATE_ERROR, e);
      return [];
    });
};
