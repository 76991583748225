export const sqsConnections = state => state.sqsConnections;
export const sqsConnection = state => state.sqsConnection;
export const kafkaConnections = state => state.kafkaConnections;
export const kafkaConnection = state => state.kafkaConnection;
export const rabbitMqConnections = state => state.rabbitMqConnections;
export const rabbitMqConnection = state => state.rabbitMqConnection;
export const sortableFields = state => state.sortableFields;
export const fields = state => state.fields;
export const pages = state => state.pages;
export const loading = state => state.loading;
export const resultCount = state => state.resultCount;
export const totalRecords = state => state.totalRecords;
export const originalFields = state => state.originalFields;
export const searchableAllFields = state => state.searchableAllFields;
export const message = state => state.message;
export const success = state => state.success;
