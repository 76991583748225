export const API_REQUEST = 'API_REQUEST';

export const GET_SPACES_FOR_USER_REQUEST = 'GET_SPACES_FOR_USER_REQUEST';
export const GET_SPACES_FOR_USER_REQUEST_SUCCESS = 'GET_SPACES_FOR_USER_REQUEST_SUCCESS';
export const GET_SPACES_FOR_USER_REQUEST_ERROR = 'GET_SPACES_FOR_USER_REQUEST_ERROR';

export const GET_SPACES_BY_NAME_REQUEST = 'GET_SPACES_BY_NAME_REQUEST';
export const GET_SPACES_BY_NAME_REQUEST_SUCCESS = 'GET_SPACES_BY_NAME_REQUEST';
export const GET_SPACES_BY_NAME_REQUEST_ERROR = 'GET_SPACES_BY_NAME_REQUEST_ERROR';

export const UPDATE_SPACES_REQUEST = 'UPDATE_SPACES_REQUEST';
export const UPDATE_SPACES_REQUEST_SUCCESS = 'UPDATE_SPACES_REQUEST_SUCCESS';
export const UPDATE_SPACES_REQUEST_ERROR = 'UPDATE_SPACES_REQUEST_ERROR';

export const CREATE_SPACES_REQUEST = 'CREATE_SPACES_REQUEST';
export const CREATE_SPACES_REQUEST_SUCCESS = 'CREATE_SPACES_REQUEST_SUCCESS';
export const CREATE_SPACES_REQUEST_ERROR = 'CREATE_SPACES_REQUEST_ERROR';

export const APP_SWITCHER_CREATED = 'APP_SWITCHER_CREATED';
