// <<< DO NOT EDIT GENERATED FILES DIRECTLY! api-store-actions.mustache >>>
import { delay, cloneDeep } from 'lodash';
import * as types from './mutation-types';
import sdkApiConfig from '@/config/sdkApiConfig';
import updateFields from '@/utils/updateFields';
import { apiKeysApiZiqni } from '@/generated/ziqni/api/ApiKeysApiZiqni';
import router from '@/router';

import apiKeyResponseFields from '../../../fields/ApiKeyResponseZq.js';
import apiResponseFields from '../../../fields/ApiResponseZq.js';
import createApiKeyRequestFields from '../../../fields/CreateApiKeyRequestZq.js';
import queryRequestFields from '../../../fields/QueryRequestZq.js';
import updateApiKeyRequestFields from '../../../fields/UpdateApiKeyRequestZq.js';
import {handleRequest,handleRequestWithBody} from "../../../../../utils/ZiqniAdminApiHandler";

export const handleFields = ({ commit, state }, data) => {
  updateFields(commit, state, data, 'hiddenApiKeysKey');
};

// Clear apiKeys Message
export const handleClearMessage = ({ commit }) => {
  commit(types.CLEAR_MESSAGE);
};

// clear filter
export const handleClearFilter = ({ commit }) => {
  commit(types.CLEAR_FILTER);
};

//APIKEYS - APIKEYS
export const handleApiKeysChanges = ({ commit }, data) => {
  commit('SET_APIKEYS', data);
};
/**
 * ApiKeyResponse from backend.
 * GET getApiKeys and return [ApiKeyResponse]
 * vars: [
 *    [[id]] id[ opts, isArray, isQueryParam]
 *    [[limit]] _limit[ opts, isInteger, isQueryParam]
 *    [[skip]] _skip[ opts, isInteger, isQueryParam]]
 */
export const handleGetApiKeys_item = ({ commit }, idArray, limit, skip) => {
  commit(types.API_REQUEST);
  let opts = { 
    id: idArray, 
    limit: limit, 
    skip: skip
  };

  return apiKeysApiZiqni
    .getApiKeys(
      opts
    )
    .then((response) => {
      commit(types.GETAPIKEYS_ITEM_SUCCESS, { response });
      return response.results;
    })
    .catch((e) => {
      commit(types.GETAPIKEYS_ITEM_ERROR, e);
      return [];
    });
};

/**
 * ApiResponse from backend.
 * POST createApiKeys and return [ApiResponse]
 * vars: [
 *    [[body]] CreateApiKeyRequest[ required, isBodyParam, ]]
 */
export const handleCreateApiKeys = ({ commit }, payload) => {
  const { createApiKeyRequest } = payload;

  commit(types.API_REQUEST);
  

  return apiKeysApiZiqni
    .createApiKeys(
      createApiKeyRequest
    )
    .then((response) => {
      commit(types.CREATEAPIKEYS_SUCCESS, { response });
      return response.results;
    })
    .catch((e) => {
      commit(types.CREATEAPIKEYS_ERROR, e);
      return [];
    });
};

/**
 * ApiResponse from backend.
 * DELETE deleteApiKeys and return [ApiResponse]
 * vars: [
 *    [[id]] id[ opts, isArray, isQueryParam]]
 */
export const handleDeleteApiKeys = ({ commit }, payload) => {
  const { idArray } = payload;

  commit(types.API_REQUEST);
  let opts = { 
    id: idArray
  };

  return apiKeysApiZiqni
    .deleteApiKeys(
      opts
    )
    .then((response) => {
      commit(types.DELETEAPIKEYS_SUCCESS, { response });
      return response.results;
    })
    .catch((e) => {
      commit(types.DELETEAPIKEYS_ERROR, e);
      return [];
    });
};

/**
 * ApiResponse from backend.
 * DELETE deleteApiKeysById and return [ApiResponse]
 * vars: [
 *    [[id]] id[ required, isString, isPathParam, ]]
 */
export const handleDeleteApiKeysById = ({ commit }, payload) => {
  const { id } = payload;

  commit(types.API_REQUEST);
  

  return apiKeysApiZiqni
    .deleteApiKeysById(
      id
    )
    .then((response) => {
      commit(types.DELETEAPIKEYSBYID_SUCCESS, { response });
      return response.results;
    })
    .catch((e) => {
      commit(types.DELETEAPIKEYSBYID_ERROR, e);
      return [];
    });
};

/**
 * ApiResponse from backend.
 * POST deleteApiKeysByQuery and return [ApiResponse]
 * vars: [
 *    [[body]] QueryRequest[ opts, isBodyParam, ]]
 */
export const handleDeleteApiKeysByQuery = ({ commit }, payload) => {
  const { queryRequest } = payload;

  commit(types.API_REQUEST);
  let opts = { 
    body: queryRequest
  };

  return apiKeysApiZiqni
    .deleteApiKeysByQuery(
      opts
    )
    .then((response) => {
      commit(types.DELETEAPIKEYSBYQUERY_SUCCESS, { response });
      return response.results;
    })
    .catch((e) => {
      commit(types.DELETEAPIKEYSBYQUERY_ERROR, e);
      return [];
    });
};

/**
 * ApiKeyResponse from backend.
 * GET getApiKeys and return [ApiKeyResponse]
 * vars: [
 *    [[id]] id[ opts, isArray, isQueryParam]
 *    [[limit]] _limit[ opts, isInteger, isQueryParam]
 *    [[skip]] _skip[ opts, isInteger, isQueryParam]]
 */
export const handleGetApiKeys = ({ commit }, payload) => {
  const { idArray, limit, skip } = payload;

  commit(types.API_REQUEST);
  let opts = { 
    id: idArray, 
    limit: limit, 
    skip: skip
  };

  return apiKeysApiZiqni
    .getApiKeys(
      opts
    )
    .then((response) => {
      commit(types.GETAPIKEYS_SUCCESS, { response });
      return response.results;
    })
    .catch((e) => {
      commit(types.GETAPIKEYS_ERROR, e);
      return [];
    });
};

/**
 * ApiKeyResponse from backend.
 * GET getApiKeysById and return [ApiKeyResponse]
 * vars: [
 *    [[id]] id[ required, isString, isPathParam, ]]
 */
export const handleGetApiKeysById = ({ commit }, payload) => {
  const { id } = payload;

  commit(types.API_REQUEST);
  

  return apiKeysApiZiqni
    .getApiKeysById(
      id
    )
    .then((response) => {
      commit(types.GETAPIKEYSBYID_SUCCESS, { response });
      return response.results;
    })
    .catch((e) => {
      commit(types.GETAPIKEYSBYID_ERROR, e);
      return [];
    });
};

/**
 * ApiKeyResponse from backend.
 * POST getApiKeysByQuery and return [ApiKeyResponse]
 * vars: [
 *    [[body]] QueryRequest[ opts, isBodyParam, ]]
 */
export const handleGetApiKeysByQuery = ({ commit }, payload) => {
  const { queryRequest } = payload;

  commit(types.API_REQUEST);
  let opts = { 
    body: queryRequest
  };

  return apiKeysApiZiqni
    .getApiKeysByQuery(
      opts
    )
    .then((response) => {
      commit(types.GETAPIKEYSBYQUERY_SUCCESS, { response });
      return response.results;
    })
    .catch((e) => {
      commit(types.GETAPIKEYSBYQUERY_ERROR, e);
      return [];
    });
};

/**
 * ApiResponse from backend.
 * PUT updateApiKeys and return [ApiResponse]
 * vars: [
 *    [[body]] UpdateApiKeyRequest[ required, isBodyParam, ]]
 */
export const handleUpdateApiKeys = ({ commit }, payload) => {
  const { updateApiKeyRequest } = payload;

  commit(types.API_REQUEST);
  

  return apiKeysApiZiqni
    .updateApiKeys(
      updateApiKeyRequest
    )
    .then((response) => {
      commit(types.UPDATEAPIKEYS_SUCCESS, { response });
      return response.results;
    })
    .catch((e) => {
      commit(types.UPDATEAPIKEYS_ERROR, e);
      return [];
    });
};
