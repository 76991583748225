// <<< DO NOT EDIT GENERATED FILES DIRECTLY! api-store-actions.mustache >>>
import { delay, cloneDeep } from 'lodash';
import * as types from './mutation-types';
import sdkApiConfig from '@/config/sdkApiConfig';
import updateFields from '@/utils/updateFields';
import { actionTypesApiZiqni } from '@/generated/ziqni/api/ActionTypesApiZiqni';
import router from '@/router';

import actionTypeResponseFields from '../../../fields/ActionTypeResponseZq.js';
import apiResponseFields from '../../../fields/ApiResponseZq.js';
import createActionTypeRequestFields from '../../../fields/CreateActionTypeRequestZq.js';
import queryRequestFields from '../../../fields/QueryRequestZq.js';
import updateActionTypeRequestFields from '../../../fields/UpdateActionTypeRequestZq.js';
import {handleRequest,handleRequestWithBody} from "../../../../../utils/ZiqniAdminApiHandler";

export const handleFields = ({ commit, state }, data) => {
  updateFields(commit, state, data, 'hiddenActionTypesKey');
};

// Clear actionTypes Message
export const handleClearMessage = ({ commit }) => {
  commit(types.CLEAR_MESSAGE);
};

// clear filter
export const handleClearFilter = ({ commit }) => {
  commit(types.CLEAR_FILTER);
};

//ACTIONTYPES - ACTIONTYPES
export const handleActionTypesChanges = ({ commit }, data) => {
  commit('SET_ACTIONTYPES', data);
};
/**
 * ActionTypeResponse from backend.
 * GET getActionTypes and return [ActionTypeResponse]
 * vars: [
 *    [[id]] id[ opts, isArray, isQueryParam]
 *    [[limit]] _limit[ opts, isInteger, isQueryParam]
 *    [[skip]] _skip[ opts, isInteger, isQueryParam]]
 */
export const handleGetActionTypes_item = ({ commit }, idArray, limit, skip) => {
  commit(types.API_REQUEST);
  let opts = { 
    id: idArray, 
    limit: limit, 
    skip: skip
  };

  return actionTypesApiZiqni
    .getActionTypes(
      opts
    )
    .then((response) => {
      commit(types.GETACTIONTYPES_ITEM_SUCCESS, { response });
      return response.results;
    })
    .catch((e) => {
      commit(types.GETACTIONTYPES_ITEM_ERROR, e);
      return [];
    });
};

/**
 * ApiResponse from backend.
 * POST createActionTypes and return [ApiResponse]
 * vars: [
 *    [[body]] CreateActionTypeRequest[ required, isArray, isBodyParam, ]]
 */
export const handleCreateActionTypes = ({ commit }, payload) => {
  const { createActionTypeRequestArray } = payload;

  commit(types.API_REQUEST);
  

  return actionTypesApiZiqni
    .createActionTypes(
      createActionTypeRequestArray
    )
    .then((response) => {
      commit(types.CREATEACTIONTYPES_SUCCESS, { response });
      return response.results;
    })
    .catch((e) => {
      commit(types.CREATEACTIONTYPES_ERROR, e);
      return [];
    });
};

/**
 * ApiResponse from backend.
 * DELETE deleteActionTypes and return [ApiResponse]
 * vars: [
 *    [[id]] id[ opts, isArray, isQueryParam]]
 */
export const handleDeleteActionTypes = ({ commit }, payload) => {
  const { idArray } = payload;

  commit(types.API_REQUEST);
  let opts = { 
    id: idArray
  };

  return actionTypesApiZiqni
    .deleteActionTypes(
      opts
    )
    .then((response) => {
      commit(types.DELETEACTIONTYPES_SUCCESS, { response });
      return response.results;
    })
    .catch((e) => {
      commit(types.DELETEACTIONTYPES_ERROR, e);
      return [];
    });
};

/**
 * ApiResponse from backend.
 * POST deleteActionTypesByQuery and return [ApiResponse]
 * vars: [
 *    [[body]] QueryRequest[ opts, isBodyParam, ]]
 */
export const handleDeleteActionTypesByQuery = ({ commit }, payload) => {
  const { queryRequest } = payload;

  commit(types.API_REQUEST);
  let opts = { 
    body: queryRequest
  };

  return actionTypesApiZiqni
    .deleteActionTypesByQuery(
      opts
    )
    .then((response) => {
      commit(types.DELETEACTIONTYPESBYQUERY_SUCCESS, { response });
      return response.results;
    })
    .catch((e) => {
      commit(types.DELETEACTIONTYPESBYQUERY_ERROR, e);
      return [];
    });
};

/**
 * ActionTypeResponse from backend.
 * GET getActionTypes and return [ActionTypeResponse]
 * vars: [
 *    [[id]] id[ opts, isArray, isQueryParam]
 *    [[limit]] _limit[ opts, isInteger, isQueryParam]
 *    [[skip]] _skip[ opts, isInteger, isQueryParam]]
 */
export const handleGetActionTypes = ({ commit }, payload) => {
  const { idArray, limit, skip } = payload;

  commit(types.API_REQUEST);
  let opts = { 
    id: idArray, 
    limit: limit, 
    skip: skip
  };

  return actionTypesApiZiqni
    .getActionTypes(
      opts
    )
    .then((response) => {
      commit(types.GETACTIONTYPES_SUCCESS, { response });
      return response.results;
    })
    .catch((e) => {
      commit(types.GETACTIONTYPES_ERROR, e);
      return [];
    });
};

/**
 * ActionTypeResponse from backend.
 * POST getActionTypesByQuery and return [ActionTypeResponse]
 * vars: [
 *    [[body]] QueryRequest[ opts, isBodyParam, ]]
 */
export const handleGetActionTypesByQuery = ({ commit }, payload) => {
  const { queryRequest } = payload;

  commit(types.API_REQUEST);
  let opts = { 
    body: queryRequest
  };

  return actionTypesApiZiqni
    .getActionTypesByQuery(
      opts
    )
    .then((response) => {
      commit(types.GETACTIONTYPESBYQUERY_SUCCESS, { response });
      return response.results;
    })
    .catch((e) => {
      commit(types.GETACTIONTYPESBYQUERY_ERROR, e);
      return [];
    });
};

/**
 * ApiResponse from backend.
 * PUT updateActionTypes and return [ApiResponse]
 * vars: [
 *    [[body]] UpdateActionTypeRequest[ required, isArray, isBodyParam, ]]
 */
export const handleUpdateActionTypes = ({ commit }, payload) => {
  const { updateActionTypeRequestArray } = payload;

  commit(types.API_REQUEST);
  

  return actionTypesApiZiqni
    .updateActionTypes(
      updateActionTypeRequestArray
    )
    .then((response) => {
      commit(types.UPDATEACTIONTYPES_SUCCESS, { response });
      return response.results;
    })
    .catch((e) => {
      commit(types.UPDATEACTIONTYPES_ERROR, e);
      return [];
    });
};
