export const API_REQUEST = 'API_REQUEST';


export const UPDATE_FIELDS = 'UPDATE_FIELDS';
export const CLEAR_FILTER = 'CLEAR_FILTER';
export const CLEAR_MESSAGE = 'CLEAR_MESSAGE';

export const GETLOGEVENTSBYQUERY_REQ = 'GETLOGEVENTSBYQUERY_REQ';
export const GETLOGEVENTSBYQUERY_ERROR = 'GETLOGEVENTSBYQUERY_ERROR';
export const GETLOGEVENTSBYQUERY_SUCCESS = 'GETLOGEVENTSBYQUERY_SUCCESS';

