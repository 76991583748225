// <<< DO NOT EDIT GENERATED FILES DIRECTLY! api-store-mutations.mustache >>>
import * as types from './mutation-types';
import * as _ from 'lodash';
import {
  handleErrorResponse, handleItemErrorResponse,
  handleItemSuccessResponse,
  handleSuccessResponse
} from "../../../../../utils/ZiqniAdminApiHandler";
import apiResponseFields from '../../../fields/ApiResponseZq.js';
import createWebhookRequestFields from '../../../fields/CreateWebhookRequestZq.js';
import queryRequestFields from '../../../fields/QueryRequestZq.js';
import updateStateRequestFields from '../../../fields/UpdateStateRequestZq.js';
import updateWebhookRequestFields from '../../../fields/UpdateWebhookRequestZq.js';
import webhookResponseFields from '../../../fields/WebhookResponseZq.js';
import webhookTriggersResponseFields from '../../../fields/WebhookTriggersResponseZq.js';

const mutations = {
    [types.API_REQUEST](state) {
        state.loading = true;
        state.loaded = false;
    },
    [types.CLEAR_MESSAGE](state){
        state.message = null;
    },

    [types.UPDATE_FIELDS](state, data) {
        state.hiddenFields = data._hiddenFields;
        state.fields = _.difference(state.originalFields, state.hiddenFields);
        localStorage.setItem('hiddenWebhooksKey', JSON.stringify(state.hiddenFields));
    },

    [types.CLEAR_FILTER](state) {
        state.fields = _.difference(
            state.originalFields,
            state.originHiddenFields
        );
        state.hiddenFields = state.originHiddenFields;
        localStorage.removeItem('hiddenWebhooksKey');
    },

    //WebhookResponse
    [types.GETWEBHOOKS_ITEM_SUCCESS](state, response) {
      handleItemSuccessResponse(types.GETWEBHOOKS_ITEM_SUCCESS,webhookResponseFields, state, response, 'webhook');
    },

    //WebhookResponse
    [types.GETWEBHOOKS_ERROR](state, error) {
      handleItemErrorResponse(types.GETWEBHOOKS_ITEM_ITEM_ERROR, webhookResponseFields, state, error);
    },


    //ApiResponse
    [types.CREATEWEBHOOKS_SUCCESS](state, response) {
      handleSuccessResponse(types.CREATEWEBHOOKS_SUCCESS,apiResponseFields, state, response);
    },

    //ApiResponse
    [types.CREATEWEBHOOKS_ERROR](state, error) {
      handleErrorResponse(types.CREATEWEBHOOKS_ERROR, apiResponseFields, state, error);
    },

    //ApiResponse
    [types.DELETEWEBHOOKS_SUCCESS](state, response) {
      handleSuccessResponse(types.DELETEWEBHOOKS_SUCCESS,apiResponseFields, state, response);
    },

    //ApiResponse
    [types.DELETEWEBHOOKS_ERROR](state, error) {
      handleErrorResponse(types.DELETEWEBHOOKS_ERROR, apiResponseFields, state, error);
    },

    //ApiResponse
    [types.DELETEWEBHOOKSBYQUERY_SUCCESS](state, response) {
      handleSuccessResponse(types.DELETEWEBHOOKSBYQUERY_SUCCESS,apiResponseFields, state, response);
    },

    //ApiResponse
    [types.DELETEWEBHOOKSBYQUERY_ERROR](state, error) {
      handleErrorResponse(types.DELETEWEBHOOKSBYQUERY_ERROR, apiResponseFields, state, error);
    },

    //WebhookResponse
    [types.GETWEBHOOKS_SUCCESS](state, response) {
      handleSuccessResponse(types.GETWEBHOOKS_SUCCESS,webhookResponseFields, state, response);
    },

    //WebhookResponse
    [types.GETWEBHOOKS_ERROR](state, error) {
      handleErrorResponse(types.GETWEBHOOKS_ERROR, webhookResponseFields, state, error);
    },

    //WebhookResponse
    [types.GETWEBHOOKSBYQUERY_SUCCESS](state, response) {
      handleSuccessResponse(types.GETWEBHOOKSBYQUERY_SUCCESS,webhookResponseFields, state, response);
    },

    //WebhookResponse
    [types.GETWEBHOOKSBYQUERY_ERROR](state, error) {
      handleErrorResponse(types.GETWEBHOOKSBYQUERY_ERROR, webhookResponseFields, state, error);
    },

    //WebhookTriggersResponse
    [types.GETWEBHOOKSTRIGGERS_SUCCESS](state, response) {
      handleSuccessResponse(types.GETWEBHOOKSTRIGGERS_SUCCESS,webhookTriggersResponseFields, state, response);
    },

    //WebhookTriggersResponse
    [types.GETWEBHOOKSTRIGGERS_ERROR](state, error) {
      handleErrorResponse(types.GETWEBHOOKSTRIGGERS_ERROR, webhookTriggersResponseFields, state, error);
    },

    //ApiResponse
    [types.UPDATEWEBHOOKS_SUCCESS](state, response) {
      handleSuccessResponse(types.UPDATEWEBHOOKS_SUCCESS,apiResponseFields, state, response);
    },

    //ApiResponse
    [types.UPDATEWEBHOOKS_ERROR](state, error) {
      handleErrorResponse(types.UPDATEWEBHOOKS_ERROR, apiResponseFields, state, error);
    },

    //ApiResponse
    [types.UPDATEWEBHOOKSSTATE_SUCCESS](state, response) {
      handleSuccessResponse(types.UPDATEWEBHOOKSSTATE_SUCCESS,apiResponseFields, state, response);
    },

    //ApiResponse
    [types.UPDATEWEBHOOKSSTATE_ERROR](state, error) {
      handleErrorResponse(types.UPDATEWEBHOOKSSTATE_ERROR, apiResponseFields, state, error);
    },
};

export default mutations;
