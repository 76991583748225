import { cloneDeep } from 'lodash';
import * as types from './mutation-types';

import { connectionsApiZiqni } from '@/generated/ziqni/api/ConnectionsApiZiqni';
import { transformersApiZiqni } from '@/generated/ziqni/api/TransformersApiZiqni';

export const handleConnectionChanges = ({ commit }, data) => {
  commit('SET_CONNECTION', data);
};

// Get CustomFields from backend
export const handleGetConnections = ({ commit }, id, skip, limit) => {
  const body = {
    id,
    limit,
    skip,
  };
  commit(types.API_REQUEST);

  return connectionsApiZiqni
    .getConnections(body)
    .then((data) => {
      const pages = Math.ceil(data.meta.resultCount / data.meta.limit);
      commit(types.GET_CONNECTIONS_SUCCESS, { data, pages });
      return data.results;
    })
    .catch((e) => {
      commit(types.GET_CONNECTIONS_ERROR, e);
      return [];
    });
};

// Update selected Custom Field
export const handleUpdateConnections = ({ commit }, formData) => {
  commit(types.API_REQUEST);
  return new Promise((resolve, reject) => {
    connectionsApiZiqni
      .updateConnections(formData)
      .then((data) => {
        commit(types.UPDATE_CONNECTION_SUCCESS, data);
        resolve(data.results)
      })
      .catch((e) => {
        commit(types.UPDATE_CONNECTION_ERROR, e);
        reject(e)
      });
  });
};

export const describeConnectionObject = ({ commit }, connectionType) => {
  commit(types.API_REQUEST);
  return new Promise((resolve, reject) => {
    connectionsApiZiqni
      .describeConnectionObject(connectionType)
      .then((data) => {
        resolve(data.results[0])
      })
      .catch((e) => {
        commit(types.CREATE_CONNECTION_ERROR, e);
        reject(e)
      });
  });
}

// Create Connection
export const handleCreateConnection = ({ commit }, formData) => {
  commit(types.API_REQUEST);
  return new Promise((resolve, reject) => {
    connectionsApiZiqni
      .createConnections(formData)
      .then((data) => {
        commit(types.CREATE_CONNECTION_SUCCESS, data)
        resolve(data.results)
      })
      .catch((e) => {
        commit(types.CREATE_CONNECTION_ERROR, e)
        reject(e)
      });
  });
};

// show/hide header of table
export const handleFields = ({ commit, state }, data) => {
  if (data) {
    const { hiddenFields } = state;
    const _hiddenFields = cloneDeep(hiddenFields);
    const hiddenIndex = _hiddenFields.indexOf(data);
    if (hiddenIndex > -1) {
      _hiddenFields.splice(hiddenIndex, 1);
    } else {
      _hiddenFields.push(data);
    }

    commit(types.UPDATE_FIELDS, { _hiddenFields });
  } else {
    const preHiddenFields =
      localStorage.getItem('hiddenConnectionKey') &&
      JSON.parse(localStorage.getItem('hiddenConnectionKey'));
    preHiddenFields &&
      commit(types.UPDATE_FIELDS, {
        _hiddenFields: preHiddenFields,
      });
  }
};

// Clear Message
export const handleClearMessage = ({ commit }) => {
  commit(types.CLEAR_MESSAGE);
};

// clear filter
export const handleClearFilter = ({ commit }) => {
  commit(types.CLEAR_FILTER);
};

export const handleConnectionType = ({ commit }, data) => {
  commit(types.CONNECTION_TYPE, data);
};

export const handleGetTransformers = ({ commit }, data) => {
  const { queryData, sortBy, skip, limit } = data;

  const body = {
    limit,
    skip,
    sortBy,
    query: queryData,
  };
  commit(types.API_REQUEST);

  return transformersApiZiqni
    .getTransformersByQuery(body)
    .then((data) => {
      commit(types.GET_TRANSFORMERS_SUCCESS, data);
      return data.results;
    })
    .catch((e) => {
      commit(types.GET_TRANSFORMERS_ERROR, e);
      return [];
    });
};
